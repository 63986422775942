import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    drawerWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    drawerWidth: number;
  }
}

export const VISIUS_CARD_TEXT1_COLOR = "rgba(255,255,255)";
export const VISIUS_CARD_TEXT2_COLOR = "rgba(255,255,255,0.5)";
export const VISIUS_CARD_WIDTH = 379;
export const VISIUS_CARD_HEIGHT = 152;

export const COLORS = {
  BLACK: "#33415E",
  NEW_BLACK: "#122045",
  WHITE: "white",
  ALMOST_WHITE: "#FBFBFB",
  DEFAULT_BACKGROUND: "#F4F7F9",

  LIGHT_BLUE: "#DFE6FB",
  BLUE1: "#E7F3FF",
  BLUE2: "#D1E1FF",
  BLUE3: "#A3BEF2",
  BLUE4: "#5189F4",
  BLUE5: "#467CE5",
  BLUE6: "#3066CB",
  BLUE7: "#265ABC",
  BLUE_MAIN: "#2C67FF",

  GREY0: "#EAF0FF",
  GREY1: "#EEEEEE",
  GREY2: "#DEE2EB",
  GREY3: "#B2B9CA",
  GREY6: "#98A0B1",
  GREY7: "#8790A5",
  GREY8: "#BBBBBB",
  GREY_NEUTRAL: "#888888",
  DARK_GREY: "#676666",

  GREEN: "#35BD6E",
  DARK_GREEN: "#2D9F5C",
};

export default createMuiTheme({
  drawerWidth: 256,

  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiIconButton: {
      disableRipple: true,
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
    MuiSvgIcon: {
      fontSize: "small",
    },
  },

  palette: {
    primary: {
      100: COLORS.BLUE1,
      200: COLORS.BLUE2,
      300: COLORS.BLUE3,
      400: COLORS.BLUE4,
      500: COLORS.BLUE5,
      light: COLORS.LIGHT_BLUE,
      600: COLORS.BLUE6,
      main: COLORS.BLUE_MAIN,
      700: COLORS.BLUE7,
      dark: COLORS.NEW_BLACK,
    },
    secondary: {
      main: "#00000",
      light: "#00000",
      dark: "#055EEA",
    },
    error: {
      main: "#FF3D00",
      light: "#EB5757",
    },
    warning: {
      main: "#F1B90D",
      light: "#E9B600",
    },
    info: {
      main: "#F2C94C",
      light: "#5189F4",
    },
    success: {
      main: "#27AE60",
      light: "#27AE60",
    },
    grey: {
      50: COLORS.GREY0,
      100: COLORS.GREY1,
      200: COLORS.GREY2,
      300: COLORS.GREY3,
      400: COLORS.ALMOST_WHITE,
      500: COLORS.GREY_NEUTRAL,
      600: COLORS.GREY6,
      700: COLORS.BLACK,
      800: COLORS.GREY8,
      900: COLORS.DARK_GREY,
    },
    common: {
      black: "#33415E",
      white: "#FFFFFF",
    },
    text: {
      primary: COLORS.NEW_BLACK,
      secondary: COLORS.GREY_NEUTRAL,
    },
    background: {
      default: COLORS.DEFAULT_BACKGROUND,
    },
  },

  typography: {
    h2: {
      fontFamily: "Inter, sans-serif",
      fontSize: "20px",
      fontWeight: 500,
      letterSpacing: 0.15,
    },
    // group title
    h3: {
      fontFamily: "Inter, sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "29px",
    },
    h4: {
      fontFamily: "Inter, sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "22px",
    },
    h5: {
      fontFamily: "Inter, sans-serif",
      fontSize: "15px",
      fontWeight: 700,
      lineHeight: "18px",
    },
    body1: {
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: 0.5,
    },
    body2: {
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "17px",
    },
    button: {
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: 1.25,
    },
    subtitle1: {
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: 0.5,
    },
    subtitle2: {
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: 0.1,
    },
    caption: {
      fontFamily: "Inter, sans-serif",
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: 0.1,
    },
    overline: {
      fontFamily: "Inter, sans-serif",
      fontSize: "10px",
      fontWeight: 400,
      letterSpacing: 1.5,
      textTransform: "uppercase",
    },
  },

  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      containedPrimary: {
        boxShadow: "none",
        textTransform: "none",
        borderRadius: "12px",
        background: "linear-gradient(90deg, #0891F2 0%, #055EEA 99.94%)",
        "&:hover": {
          background: "linear-gradient(90deg, #2C67FF 0%, #055EEA 99.94%)",
          boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",
        },
        "&:active": {
          backgroundColor: COLORS.BLUE7,
        },
      },
      outlinedPrimary: {
        textTransform: "none",
        border: `1.5px solid ${COLORS.BLUE_MAIN}`,
        borderRadius: "12px",

        "&:hover": {
          border: "1.5px solid #055EEA",
          background: "#F4F7F9",
          boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",
        },
      },
    },

    MuiFormControl: {
      marginNormal: {
        marginBottom: 4,
      },
    },

    MuiFormLabel: {
      root: {
        "&.Mui-disabled": {
          color: COLORS.GREY3,
        },
      },
    },

    MuiList: {
      root: {
        "& .MuiListItem-root": {
          padding: "16px",
        },
        "& .MuiLink-root": {
          display: "block",
          marginTop: 8,
          marginBottom: 0,
          marginLeft: 8,
          marginRight: 8,

          "& .MuiListItem-root": {
            padding: "2px 8px",

            "& .MuiListItemIcon-root": {
              minWidth: 48,
              marginLeft: 18,
            },

            "&:hover": {
              backgroundColor: "transparent",
              color: COLORS.BLUE_MAIN,

              "& .MuiListItemIcon-root": {
                color: COLORS.BLUE_MAIN,
              },

              "& .MuiListItemIcon-root svg path": {
                fill: COLORS.BLUE_MAIN,
              },
            },

            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: COLORS.BLUE6,

              "& .MuiListItemIcon-root svg path": {
                fill: COLORS.BLUE_MAIN,
              },
            },
          },
        },
      },
    },

    MuiButtonBase: {
      root: {
        "& .MuiListItemIcon-root": {
          minWidth: 35,
        },
      },
    },

    MuiPaper: {
      root: {
        "& .MuiAutocomplete-root .MuiInputLabel-outlined": {
          transform: "translate(16px, 16px) scale(1)",
        },
        "& .MuiAutocomplete-root .MuiInputLabel-outlined.MuiInputLabel-shrink":
          {
            transform: "translate(16px, -6px) scale(0.75)",
          },
      },
      elevation8: {
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      },
    },

    MuiInputBase: {
      root: {
        borderColor: COLORS.GREY3,
        color: COLORS.GREY6,

        "&:hover": {
          borderColor: COLORS.BLACK,
        },

        "& .MuiInputBase-input": {
          backgroundColor: "transparent",
          fontFamily: "Inter, sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: 0.5,
          color: COLORS.DARK_GREY,
        },
      },

      input: {
        // hak to disable autofill styling in Chrome
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          paddingTop: 6,
          paddingBottom: 7,
          paddingLeft: 9,
          "& .MuiInputBase-input": {
            paddingTop: 8,
            paddingBottom: 8,
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          paddingLeft: 9,
        },
      },
    },

    MuiSelect: {
      outlined: {
        paddingTop: 14.5,
        paddingBottom: 14.5,
      },
    },

    MuiTab: {
      root: {
        maxWidth: "none",
        paddingLeft: 16,
        paddingRight: 16,
      },
      labelIcon: {
        minHeight: 0,
        "& .MuiTab-wrapper > *:first-child": {
          marginBottom: 0,
          marginRight: 16,
        },
      },
      wrapper: {
        flexDirection: "row",
        alignItems: "center",
      },
    },

    MuiTabs: {
      flexContainer: {
        height: "100%",
      },
    },

    MuiTableCell: {
      root: {
        padding: "0 32px 0 0",
        height: 52,

        "&.MuiTableCell-head": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 14,
          lineHeight: 1.15,
        },

        "&.MuiTableCell-footer": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: 14,
          borderBottom: 0,
        },
      },
    },

    MuiFormHelperText: {
      root: {
        lineHeight: 1,
      },
    },
  },
});
