import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TFunction } from "i18next";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  CloudDoneOutlined,
  CloudOffOutlined,
  CloudQueueOutlined,
  CloudUploadOutlined,
  Launch,
  PlayArrow,
} from "@material-ui/icons";

import { selectUserData } from "../../../auth/authSlice";
import { deleteVideos, moveVideos } from "../../locationSlice";
import { getLocationSelector } from "../../selectors";
import { LocationId } from "../../../groups/data-types";
import {
  UPLOAD_STATE,
  UploadData,
  VIDEO_STATE,
  VideoData,
  VideoId,
  VIDEO_PREPROCESSING_STATE,
  VIDEO_PROCESSING_STATE,
} from "../../data-mapper";

import I18N from "../../../../app/i18n/strings";
import { bytes2str, hasCloneVideo } from "../../../../app/utils";

import ContextMenu, { initialState } from "../../../../components/ContextMenu";
import ModalVideoPlayer from "../../../../components/ModalVideoPlayer";
import LocationControl from "../../components/LocationControl";
import ProgressIndicator from "../../../../components/ProgressIndicator";
import Tooltip from "./Tooltip";

import { ReactComponent as UncheckBoxIcon } from "../../../../app/assets/unCheckedCheckbox.svg";
import { ReactComponent as CheckBoxIcon } from "../../../../app/assets/checkedCheckbox.svg";
import { ReactComponent as ForwardIcon } from "../../../../app/assets/forwardIcon.svg";
import { ReactComponent as DeleteForeverIcon } from "../../../../app/assets/deleteForeverIcon.svg";
import { ReactComponent as ReadyDataIcon } from "../../../../app/assets/readyDataIcon.svg";

import { COLORS } from "../../../../theme";

const MAX_ROW_TITLE_WIDTH = 220;

interface VideosListProps {
  locationId: LocationId;
  videos: VideoData[];
  uploads: UploadData[];
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    "& .MuiPaper-root": {
      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",

      "& .MuiList-root .MuiButtonBase-root:last-of-type": {
        color: theme.palette.error.main,

        "& svg path": {
          fill: theme.palette.error.main,
        },
      },

      "& svg ": {
        maxWidth: 13,
        "& path": {
          fill: "#676666",
        },
      },

      "& .MuiListItem-root": {
        padding: "10px 16px",
        maxHeight: "16px",
        boxSizing: "content-box",

        "& .MuiListItemIcon-root": {
          minWidth: "30px",
        },

        "& .MuiTypography-root": {
          fontWeight: 500,
        },
      },
    },

    "& .MuiList-padding": {
      padding: "5px 0",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: theme.spacing(3),
      background: theme.palette.grey[400],
      boxShadow: " 0px 4px 8px rgba(18, 32, 69, 0.05)",
      border: "1px solid rgba(44, 103, 255, 0.1)",

      "& .MuiButton-containedPrimary": {
        "& svg path": {
          fill: theme.palette.common.white,
        },

        "&.Mui-disabled": {
          background: "#DFE6FB",
          color: "white",

          "& svg path": {
            fill: "white",
          },
        },
      },
    },
  },
  table: {
    width: "auto",
  },
  tr: {
    cursor: "pointer",

    "&:hover, &.highlighted": {
      backgroundColor: COLORS.BLUE1,
    },
  },
  tdPadding: {
    paddingLeft: theme.spacing(2),
  },
  tdLinks: {
    whiteSpace: "nowrap",
  },
  tdDate: {
    whiteSpace: "nowrap",
  },
  icon: {
    display: "block",
  },
  uploading: {
    color: theme.palette.warning.light,
  },
  uploaded: {
    color: theme.palette.success.light,
  },
  videoTitle: {
    whiteSpace: "nowrap",
    maxWidth: MAX_ROW_TITLE_WIDTH,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  failed: {
    color: theme.palette.error.light,
  },
  readyData: {
    transform: "scale(0.9)",
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  locationControl: {
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
  dialogTitle: {
    padding: "32px 32px 0 32px",
  },
  dialogContent: {
    maxWidth: 420,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  button: {
    marginRight: theme.spacing(4),
    textTransform: "none",
    fontWeight: 600,
  },
  deleteButton: {
    background: "linear-gradient(90deg, #FF5F49 0%, #FF3D00 99.94%)",
    borderRadius: theme.spacing(1.5),
    textTransform: "none",

    "& svg path": {
      fill: theme.palette.common.white,
      transform: "scale(0.9)",
    },
  },
  replaceButtonIcon: {
    marginRight: theme.spacing(1.5),
  },
}));

interface IconGetterOptions {
  classes: ReturnType<typeof useStyles>;
  video: VideoData;
  upload?: UploadData;
  t: TFunction;
}

const getIcon = ({
  classes,
  video,
  upload,
  t,
}: IconGetterOptions): ReactElement => {
  const isTransferActive =
    video.state === VIDEO_STATE.UPLOADING &&
    upload?.state === UPLOAD_STATE.UPLOADING;
  const isTransferFailed =
    video.state === VIDEO_STATE.UPLOADING &&
    upload?.state === UPLOAD_STATE.FAILED;

  if (video.isBillingDenied) {
    return (
      <Tooltip
        title={
          (
            <Typography variant="caption">
              {t(I18N.location.MSG_BILLING_FAILED)}
            </Typography>
          ) ?? ""
        }
      >
        <CloudOffOutlined className={`${classes.icon} ${classes.failed}`} />
      </Tooltip>
    );
  }

  if (video.state === VIDEO_STATE.UPLOADING) {
    if (isTransferActive) {
      return (
        <Tooltip
          title={
            (
              <Typography variant="caption">
                {t(I18N.location.MSG_UPLOADING)}
              </Typography>
            ) ?? ""
          }
        >
          <CloudUploadOutlined
            className={`${classes.icon} ${classes.uploading}`}
          />
        </Tooltip>
      );
    }

    if (isTransferFailed) {
      return (
        <Tooltip
          title={
            (
              <Typography variant="caption">
                {t(I18N.location.MSG_UPLOAD_FAILED)}
              </Typography>
            ) ?? ""
          }
        >
          <CloudOffOutlined className={`${classes.icon} ${classes.failed}`} />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={
          (
            <Typography variant="caption">
              {t(I18N.location.MSG_UPLOAD_PROCESSING)}
            </Typography>
          ) ?? ""
        }
      >
        <CloudQueueOutlined
          className={`${classes.icon} ${classes.uploading}`}
        />
      </Tooltip>
    );
  }

  if (video.state === VIDEO_STATE.FAILED) {
    return (
      <Tooltip
        title={
          (
            <Typography variant="caption">
              {t(I18N.location.MSG_UPLOAD_FAILED)}
            </Typography>
          ) ?? ""
        }
      >
        <CloudOffOutlined className={`${classes.icon} ${classes.failed}`} />
      </Tooltip>
    );
  }

  if (
    video.videoProcessingState === VIDEO_PROCESSING_STATE.PROCESSING ||
    video.videoProcessingState === VIDEO_PROCESSING_STATE.PENDING
  ) {
    return (
      <Tooltip
        title={
          (
            <Typography variant="caption">
              {t(I18N.main.TITLE_PREPROCESSING_PROGRESS)}
            </Typography>
          ) ?? ""
        }
      >
        <div>
          <ProgressIndicator />
        </div>
      </Tooltip>
    );
  }

  if (
    video.videoProcessingState === VIDEO_PROCESSING_STATE.STOPPED ||
    video.videoProcessingState === VIDEO_PROCESSING_STATE.FINISHED
  ) {
    return (
      <Tooltip title={<Typography variant="caption">Отчет готов</Typography>}>
        <ReadyDataIcon className={classes.readyData} />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        (
          <Typography variant="caption">
            {t(I18N.location.MSG_UPLOAD_COMPLETED)}
          </Typography>
        ) ?? ""
      }
    >
      <CloudDoneOutlined
        fontSize="small"
        className={`${classes.icon} ${classes.uploaded}`}
      />
    </Tooltip>
  );
};

export default function VideosList(props: VideosListProps): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId, videos, uploads } = props;
  const [checked, setChecked] = useState<Set<VideoId>>(new Set());
  const [highlighted, setHighlighted] = useState<VideoId | undefined>(
    undefined
  );
  const [menuState, setMenuState] = useState(initialState);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [replaceDialogOpen, setReplaceDialogOpen] = useState(false);
  const [isDisabledLocation, setDisabledLocation] = useState(false);
  const [newLocationId, setNewLocationId] = useState<LocationId>();
  const [videoOpened, setVideoOpened] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();

  const user = useSelector(selectUserData);

  const isDemoLocation = useMemo(() => hasCloneVideo(videos), [videos]);
  const isCanNotBeDeleted = useMemo(() => {
    const videoHasPreprocessing = videos.some(({ filePreprocessing }) => {
      return (
        filePreprocessing?.jobState === VIDEO_PREPROCESSING_STATE.SCHEDULED ||
        filePreprocessing?.jobState === VIDEO_PREPROCESSING_STATE.PROCESSING
      );
    });
    return (
      (isDemoLocation && checked.size !== videos.length) ||
      videoHasPreprocessing
    );
  }, [videos, checked, isDemoLocation]);

  const selectLocation = useMemo(
    () => getLocationSelector(newLocationId),
    [newLocationId]
  );
  const locationName = useSelector(selectLocation)?.title as string;

  const isAdmin = user?.isAdmin === true;

  const handleDisableLocation = (value: boolean) => {
    setDisabledLocation(value);
  };

  const handleVideoOpen = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      const url = (evt.target as HTMLElement).closest("a")?.href;
      if (url !== undefined) {
        setVideoUrl(url);
        setVideoOpened(true);
      }
    },
    [setVideoUrl, setVideoOpened]
  );
  const handleVideoClose = useCallback(
    () => setVideoOpened(false),
    [setVideoOpened]
  );
  const handleLinksClick = useCallback(
    (evt: MouseEvent) => evt.stopPropagation(),
    []
  );

  const handleCheckAllVideos = useCallback(() => {
    if (checked.size === videos.length) {
      // uncheck all
      return setChecked(new Set<VideoId>());
    }

    const allIds = videos.map(({ id }) => id);
    setChecked(new Set(allIds));
  }, [setChecked, checked, videos]);

  const handleCheckVideo = useCallback(
    (videoId) => {
      const checkedVideos = new Set<VideoId>(checked);
      if (checked.has(videoId)) {
        checkedVideos.delete(videoId);
      } else {
        checkedVideos.add(videoId);
      }

      setChecked(checkedVideos);
    },
    [setChecked, checked]
  );

  const handleContextMenu = useCallback(
    (evt: MouseEvent, videoId: VideoId) => {
      evt.preventDefault();
      setHighlighted(checked.size === 0 ? videoId : undefined);
      setMenuState({ x: evt.clientX, y: evt.clientY });
    },
    [setHighlighted, setMenuState, checked]
  );

  const handleDelete = useCallback(() => {
    setDeleteDialogOpen(false);
    dispatch(
      deleteVideos({
        locationId,
        videoIds:
          highlighted !== undefined
            ? [highlighted]
            : Array.from(checked.values()),
      })
    );
  }, [dispatch, highlighted, checked, locationId]);
  const handleDeleteDialogShow = useCallback(() => {
    setMenuState(initialState);
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);
  const handleDeleteCancel = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  // Open replace video dialog
  const handleReplaceDialogShow = useCallback(() => {
    setMenuState(initialState);
    setReplaceDialogOpen(true);
  }, [setReplaceDialogOpen]);
  const handleReplaceCancel = useCallback(() => {
    setReplaceDialogOpen(false);
  }, [setReplaceDialogOpen]);
  const handleReplaceVideo = useCallback(() => {
    dispatch(
      moveVideos({
        oldLocationId: locationId,
        locationId: newLocationId as string,
        videoIds:
          highlighted !== undefined
            ? [highlighted]
            : Array.from(checked.values()),
        locationName: locationName,
      })
    );
  }, [dispatch, highlighted, checked, newLocationId, locationId, locationName]);

  const isNotReplaced = useMemo(() => {
    const checkReplaced =
      newLocationId === undefined || newLocationId === locationId;
    return checkReplaced;
  }, [newLocationId, locationId]);

  const totalSeconds = videos.reduce((totalDuration, { duration }) => {
    const hours = parseInt(duration.slice(0, 2));
    const minutes = parseInt(duration.slice(3, 5));
    const seconds = parseInt(duration.slice(6, 8));
    return totalDuration + hours * 60 * 60 + minutes * 60 + seconds;
  }, 0);
  const hours = Math.floor(totalSeconds / 60 / 60);
  const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
  const seconds = totalSeconds - hours * 60 * 60 - minutes * 60;
  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");

  useEffect(() => {
    const dialogOpen = (event: {
      returnValue: string;
      preventDefault: () => void;
    }): void => {
      const videosHasUploading = uploads.some(
        ({ state }) => state !== UPLOAD_STATE.UPLOADED
      );
      if (videosHasUploading) {
        event.preventDefault();
        event.returnValue =
          "The upload of the file will be interrupted if you close the page!";
      }
    };

    window.addEventListener("beforeunload", dialogOpen);
    return () => window.removeEventListener("beforeunload", dialogOpen);
  }, [uploads]);

  return (
    <>
      <ContextMenu
        x={menuState.x}
        y={menuState.y}
        onClose={() => {
          setHighlighted(undefined);
          setMenuState(initialState);
        }}
        className={classes.main}
      >
        <MenuItem
          key="move"
          disabled={isDemoLocation}
          onClick={handleReplaceDialogShow}
        >
          <ListItemIcon>
            <ForwardIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption">
                {t(I18N.location.BTN_MOVE_VIDEO)}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          key="delete"
          disabled={isCanNotBeDeleted}
          onClick={handleDeleteDialogShow}
        >
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption">
                {t(I18N.location.BTN_DELETE_VIDEO)}
              </Typography>
            }
          />
        </MenuItem>
      </ContextMenu>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color="primary"
                  icon={<UncheckBoxIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  onClick={handleCheckAllVideos}
                  checked={checked.size === videos.length}
                />
              </TableCell>
              <TableCell />
              <TableCell>{t(I18N.location.TABLE_FILE_NAME)}</TableCell>
              <TableCell>{t(I18N.location.TABLE_DATE)}</TableCell>
              <TableCell>{t(I18N.location.TABLE_DURATION)}</TableCell>
              <TableCell>{t(I18N.location.TABLE_SIZE)}</TableCell>
              {isAdmin && (
                <>
                  <TableCell />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((video) => {
              const videoUpload = uploads.find(
                ({ fileId }) => fileId === video.id
              );
              const isTransferActive =
                video.state === VIDEO_STATE.UPLOADING &&
                videoUpload?.state === UPLOAD_STATE.UPLOADING;
              const durationStr = video.duration ?? "\u2013";
              const sizeStr =
                video.state === VIDEO_STATE.UPLOADED
                  ? bytes2str(video.size)
                  : "\u2013";

              return (
                <TableRow
                  className={`${classes.tr} ${
                    checked.has(video.id) || highlighted === video.id
                      ? "highlighted"
                      : ""
                  }`}
                  key={video.id}
                  onContextMenu={(evt) => handleContextMenu(evt, video.id)}
                  onClick={() => handleCheckVideo(video.id)}
                >
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checked.has(video.id)}
                      icon={<UncheckBoxIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleCheckVideo(video.id);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {getIcon({ classes, video, upload: videoUpload, t })}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      className={classes.videoTitle}
                      title={video.name}
                      variant="body2"
                    >
                      {video.name}
                    </Typography>
                    {isTransferActive && (
                      <LinearProgress
                        variant="determinate"
                        style={{ marginTop: 4 }}
                        value={(videoUpload as UploadData).progress * 100}
                      />
                    )}
                  </TableCell>
                  <TableCell className={classes.tdDate}>
                    {video.date.format("YYYY/MM/DD HH:mm")}
                  </TableCell>
                  <TableCell>{durationStr}</TableCell>
                  <TableCell>{sizeStr}</TableCell>
                  {isAdmin && (
                    <>
                      <TableCell
                        className={classes.tdLinks}
                        onClick={handleLinksClick}
                      >
                        {video.url !== undefined && (
                          <>
                            <Link
                              href={video.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton size="small">
                                <Launch fontSize="small" />
                              </IconButton>
                            </Link>
                            <Link href={video.url} onClick={handleVideoOpen}>
                              <IconButton size="small">
                                <PlayArrow fontSize="small" />
                              </IconButton>
                            </Link>
                          </>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell style={{ whiteSpace: "nowrap" }}>
                {t(I18N.location.MSG_NUM_FILES, { count: videos.length })}
              </TableCell>
              <TableCell />
              <TableCell>{`${hoursStr}:${minutesStr}:${secondsStr}`}</TableCell>
              <TableCell>
                {bytes2str(videos.reduce((total, { size }) => total + size, 0))}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Dialog
        className={classes.dialog}
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>
          <Typography variant="h4">
            {t(I18N.location.MSG_DELETE_VIDEOS_TITLE)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              style={{ marginBottom: 24 }}
              color="textPrimary"
              variant="body2"
            >
              {t(I18N.location.DLG_DELETE_VIDEOS, {
                count: checked.size > 0 ? checked.size : 1,
              })}
            </Typography>
            <Typography variant="body2">
              {t(I18N.location.MSG_DELETE_VIDEOS, {
                count: checked.size > 0 ? checked.size : 1,
              })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 16px" }}>
          <Button
            className={classes.button}
            onClick={handleDeleteCancel}
            color="primary"
          >
            {t(I18N.main.DLG_CANCEL)}
          </Button>
          <Button
            startIcon={<DeleteForeverIcon />}
            onClick={handleDelete}
            color="secondary"
            variant="contained"
            autoFocus
            className={classes.deleteButton}
          >
            {t(I18N.location.BTN_DELETE_VIDEO)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className={classes.dialog}
        open={replaceDialogOpen}
        onClose={handleReplaceCancel}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography component="p" variant="h4">
            {t(I18N.location.DLG_REPLACE_VIDEOS)}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <LocationControl
            className={classes.locationControl}
            locationId={newLocationId}
            isLocationDisabled={handleDisableLocation}
            onChange={setNewLocationId}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            className={classes.button}
            onClick={handleReplaceCancel}
          >
            {t(I18N.main.DLG_CANCEL)}
          </Button>
          <Button
            disabled={isNotReplaced || isDisabledLocation}
            onClick={handleReplaceVideo}
            color="primary"
            variant="contained"
          >
            <ForwardIcon className={classes.replaceButtonIcon} />
            {t(I18N.location.BTN_MOVE_VIDEO)}
          </Button>
        </DialogActions>
      </Dialog>

      {videoUrl !== undefined && (
        <ModalVideoPlayer
          open={videoOpened}
          onClose={handleVideoClose}
          url={videoUrl}
        />
      )}
    </>
  );
}
