import { Container } from '@material-ui/core'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import I18N from '../../app/i18n/strings'
import { useAppLayoutStyles, useContentStyles } from '../../app/styles'
import VisiusAlert, { NotificationAlert } from '../../components/Alert'
import VisiusAppBar from '../../components/AppBar'
import Link from '../../components/Link'
import { selectAuthenticated } from '../auth/authSlice'
import { NOTIFICATION_TYPE } from '../notifications/notificationsSlice'
import {
  confirmEmail,
  requestConfirmation,
  selectConfirmationDetails,
  selectConfirmationError,
  selectIsConfirmationCompleted,
  selectResendCompleted,
  selectResendStarted,
  TokenInfo
} from './confirmEmailSlice'

const _isRequestValid = (userEmail?: string, token?: string): boolean => {
  return Boolean(userEmail) && Boolean(token)
}

export default function ConfirmEmail (): ReactElement {
  const location = useLocation()
  const dispatch = useDispatch()
  const classes = useContentStyles()
  const layoutClasses = useAppLayoutStyles()
  const { t } = useTranslation()

  const confirmationError = useSelector(selectConfirmationError)
  const isAuthenticated = useSelector(selectAuthenticated)
  const isConfirmationCompleted = useSelector(selectIsConfirmationCompleted)
  const savedTokenInfo = useSelector(selectConfirmationDetails)
  const isResendStarted = useSelector(selectResendStarted)
  const isResendCompleted = useSelector(selectResendCompleted)

  const query = new URLSearchParams(location.search)
  const userEmail = query.get('userEmail') ?? undefined
  const token = query.get('token') ?? undefined

  const hasError = Boolean(!_isRequestValid(userEmail, token) || confirmationError)
  const alertMessage = useMemo((): string|ReactElement => {
    if (hasError) {
      return t(confirmationError ?? I18N.confirmEmail.MSG_INVALID_LINK)
    }

    if (isResendCompleted) {
      return t(I18N.confirmEmail.MSG_RESEND_COMPLETED, { email: userEmail })
    }

    if (isConfirmationCompleted) {
      return (
        <Link to='/'>
          {t(I18N.confirmEmail.LINK_GO_HOME)}
        </Link>
      )
    }

    return t(I18N.common.PLEASE_WAIT)
  }, [t, hasError, confirmationError, isResendCompleted, isConfirmationCompleted, userEmail])

  const alertTitle = useMemo((): string => {
    if (hasError) {
      return t(I18N.confirmEmail.TITLE_ERROR)
    }

    if (isConfirmationCompleted) {
      return t(I18N.confirmEmail.TITLE_SUCCESS)
    }

    return t(I18N.confirmEmail.TITLE_BASIC)
  }, [t, hasError, isConfirmationCompleted])

  useEffect((): void => {
    if (!_isRequestValid(userEmail, token)) {
      return
    }
    if (userEmail === savedTokenInfo?.userEmail && token === savedTokenInfo?.token) {
      return
    }

    const payload: TokenInfo = {
      userEmail: userEmail as string,
      token: token as string
    }
    dispatch(confirmEmail(payload))
  }, [dispatch, userEmail, token, savedTokenInfo])

  const alert: NotificationAlert = {
    type: hasError ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.INFO,
    title: alertTitle,
    message: alertMessage,
    actionText: hasError || confirmationError !== undefined
      ? t(I18N.confirmEmail.BTN_RESEND)
      : undefined,
    actionDisabled: isResendStarted,
    actionCallback: () => dispatch(requestConfirmation())
  }

  return (
    <div>
      {
        isAuthenticated && <VisiusAppBar noDrawer title={t(I18N.confirmEmail.TITLE_BASIC)} />
      }
      <div className={layoutClasses.toolbar} />
      <Container className={classes.main}>
        <VisiusAlert data={alert} />
      </Container>
    </div>
  )
}
