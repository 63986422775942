import moment from "moment";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { DateRange } from "../DateRangePicker";
import { ChartVariant, SortOrder } from "./Chart";
import { ResultKeys } from "./bar/types";
import { RawResult } from "../../features/dashboard/charts/RawResult";

import { defaultResultKeys } from "../../features/dashboard/Page";

export type TickLabelContentGetter = (value: string) => string[];

export const TIME_RANGE_FORMAT = "HH:mm";

export function getDefaultTimeRange(): DateRange {
  return {
    start: moment("00:00", TIME_RANGE_FORMAT).toDate(),
    end: moment("23:59", TIME_RANGE_FORMAT).toDate(),
  };
}

/**
 * Reads date from query
 * @param query - parsed query string (URL search params
 * @param key - query arg to read
 */
export function readDate(query: URLSearchParams, key: string): Date | null {
  const queryParam = query.get(key);
  if (queryParam === null) {
    return null;
  }

  if (moment(queryParam).isValid()) {
    return moment(queryParam).toDate();
  }

  return null;
}

/**
 * Reads time ranges from query
 * @param query - parsed query string (URL search params
 */
export function readTimeRanges(query: URLSearchParams): DateRange[] {
  let timeRanges: DateRange[] = [];

  try {
    timeRanges = JSON.parse(query.get("time") ?? "[]").map(
      ({ start, end }: { start: string; end: string }) => {
        const startTime = moment(start, TIME_RANGE_FORMAT);
        const endTime = moment(end, TIME_RANGE_FORMAT);
        if (!startTime.isValid() || !endTime.isValid()) {
          throw new Error("invalid time format");
        }

        return {
          start: startTime.toDate(),
          end: endTime.toDate(),
        };
      }
    );
  } catch (err) {}

  if (timeRanges.length === 0) {
    timeRanges.push(getDefaultTimeRange());
  }

  return timeRanges;
}

/**
 * Reads day from query
 * @param query - parsed query string (URL search params)
 */
export function readDay(query: URLSearchParams): number[] {
  const fullWeek = [0, 1, 2, 3, 4, 5, 6];

  try {
    const dayQueryString = query.get("day") ?? "";
    const res = JSON.parse(dayQueryString);
    if (res.length === 0) {
      return fullWeek;
    }
    if (
      !Array.isArray(res) ||
      res.find((day) => typeof day !== "number") !== undefined
    ) {
      throw new Error("invalid day format");
    }
    return res;
  } catch (err) {
    return fullWeek;
  }
}

/**
 * Reads objects from query
 * @param query - parsed query string (URL search params)
 */
export function readResultKey(
  query: URLSearchParams
): Array<ResultKeys<RawResult>> {
  let resultKeys: Array<ResultKeys<RawResult>> = [];

  try {
    const objectsString = query.get("objects") ?? "";
    const res = JSON.parse(objectsString);
    if (
      res.findIndex(
        (entry: string) =>
          ![
            "men",
            "cars",
            "bikes",
            "oldMen",
            "middleMen",
            "youngMen",
            "unknownMen",
            "oldWomen",
            "middleWomen",
            "youngWomen",
            "unknownWomen",
            "oldUnknown",
            "middleUnknown",
            "youngUnknown",
            "unknownUnknown",
            "ageJun",
            "ageMiddle",
            "ageSenior",
            "ageUnknown",
            "gMale",
            "gFemale",
            "gUnknown",
            "uniqCount",
            "repeatingCount",
            "repeatingObjectCount",
            "objectCount",
          ].includes(entry)
      ) !== -1
    ) {
      throw new Error("invalid keys format");
    }
    return res;
  } catch (err) {}

  if (resultKeys.length === 0) {
    resultKeys = defaultResultKeys;
  }
  return resultKeys;
}

type ValueGetter<T, R> = (datum: T) => R;

interface ScaleGetterOptions<T, M> {
  data: T[];
  size: number;
  round?: boolean;
  padding?: number;
  reverse?: boolean;

  getter: ValueGetter<T, M>;
}

export function isSortable(chartVariant: ChartVariant): boolean {
  return chartVariant !== "linear";
}

export function sortChartData<T>(
  data: T[],
  resultKeys: Array<ResultKeys<T>>,
  sortOrder: SortOrder
): T[] {
  if (sortOrder === "none") {
    return data;
  }

  return data.sort((a, b) => {
    const aValue = resultKeys.reduce((sum, key) => {
      const value = a[key];
      return sum + (typeof value === "number" ? value : 0);
    }, 0);
    const bValue = resultKeys.reduce((sum, key) => {
      const value = b[key];
      return sum + (typeof value === "number" ? value : 0);
    }, 0);
    if (aValue < bValue) {
      return sortOrder === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });
}

export function getScaleBand<T>(
  options: ScaleGetterOptions<T, string>
): ScaleBand<string> {
  return scaleBand<string>({
    range: [0, options.size],
    round: Boolean(options.round),
    domain: options.data.map(options.getter),
    paddingInner: 0,
    paddingOuter: 0.1,
  });
}

export function getScaleLinear<T>(
  options: ScaleGetterOptions<T, number>
): ScaleLinear<number, number> {
  const isReverseScale = options?.reverse ?? false;
  return scaleLinear<number>({
    range: isReverseScale ? [0, options.size] : [options.size, 0],
    round: false,
    domain: [0, Math.max(...options.data.map(options.getter))],
  });
}
