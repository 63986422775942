import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../theme";

export const useWorkFlowStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    padding: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  stepperRoot: {
    padding: 0,
    background: "transparent",
    width: "100%",

    "& .MuiStep-root": {
      "& .MuiStepConnector-root": {
        maxWidth: "265px",
      },
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    "& .MuiStepLabel-iconContainer": {
      borderWidth: 8,
      borderRadius: 48,
      borderColor: COLORS.BLUE1,
      backgroundColor: COLORS.BLUE1,
      borderStyle: "solid",
    },
    "& .MuiStepIcon-root": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: COLORS.BLUE1,
      borderWidth: 0,
      borderRadius: 48,
      borderColor: COLORS.BLUE1,
      backgroundColor: COLORS.BLUE1,
      borderStyle: "solid",
    },
    "& .MuiStepIcon-text": {
      fill: COLORS.GREY7,
    },
    "& .MuiStepLabel-active": {
      color: COLORS.BLUE5,
    },
  },
}));
