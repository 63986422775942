import { Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import I18N from '../../app/i18n/strings'

export default function NotYetImplementedTab (): ReactElement {
  const { t } = useTranslation()
  return (
    <Typography>
      {t(I18N.profile.MSG_NOT_IMPLEMENTED)}
    </Typography>
  )
}
