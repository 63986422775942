import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebounce } from "react-use";
import { useSelector } from "react-redux";
import { makeStyles, Theme, useTheme } from "@material-ui/core";

import { AxisBottom, AxisLeft, TickRendererProps } from "@visx/axis";
import { GridColumns, GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { PatternLines } from "@visx/pattern";
import {
  defaultStyles as defaultTooltipStyles,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { ScaleOrdinal } from "d3-scale";

import { MaxYGetter } from "../../features/dashboard/charts/utils";
import { COLORS } from "../../theme";
import VisiusBarGroup from "./bar/GroupView";
import VisiusBarStack from "./bar/StackView";
import { ResultKeys } from "./bar/types";
import {
  getScaleBand,
  getScaleLinear,
  sortChartData,
  TickLabelContentGetter,
} from "./chart-utils";
import VisiusBrush, { BrushExtent } from "./common/Brush";
import DateTickLabel from "./common/DateTickLabel";
import LinearChartView from "./linear/LinearView";

import {
  selectIsMenMode,
  selectIsMenUniteMode,
} from "../../features/dashboard/dashboardSlice";

const INITIAL_BAR_WIDTH = 25;
const BRUSH_HEIGHT = 80;
const BRUSH_TOP_OFFSET = 16;
const BRUSH_BOTTOM_OFFSET = 5;
const MAX_HORIZONTAL_CHART_HEIGHT = 500;

export type ChartVariant = "linear" | "stack" | "group" | "pie";
export type SortOrder = "asc" | "desc" | "none";

export interface ChartProps<T> {
  width: number;
  height?: number;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
  data: T[];
  variant: ChartVariant;
  resultKeys: Array<ResultKeys<T>>;
  sortOrder?: SortOrder;

  onHeightChange?: (height: number) => void;
}

interface InternalChartProps<T> extends ChartProps<T> {
  tickLabelWidth?: number;
  brush?: boolean;
  horizontal?: boolean;

  getX: (data: T) => string;
  getComparableX: (x: string) => number;
  getXLabel?: TickLabelContentGetter;
  getMaxY: MaxYGetter<T>;
  tooltipRenderer?: (tooltipData: T) => ReactElement;
}

const useChartStyles = makeStyles<Theme>((theme: Theme) => ({
  label: {
    userSelect: "none",

    // "caption" typography
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: 0.1,
  },
  legend: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3.5),
  },
}));

/**
 * Returns tick label element
 *
 * @param {TickLabelContentGetter} getLabel - function, returns array of strings to render
 * @returns {ReactElement} - tick label
 */
const getTickLabelRenderer = (
  getLabel?: TickLabelContentGetter
): ((props: TickRendererProps) => ReactElement | null) => {
  if (getLabel === undefined) {
    return () => null;
  }

  return (props: TickRendererProps): ReactElement | null => {
    if (props.formattedValue === undefined) {
      return null;
    }

    const tickLabels = getLabel(props.formattedValue);
    return <DateTickLabel {...props} content={tickLabels} />;
  };
};

interface ChartOffset {
  top: number;
  bottom: number;
  left: number;
  right: number;
  legend: number;
}

/**
 * Returns calculated chart's height
 * @param {T} - chart type
 * @param {InternalChartProps<T>} props - chart props
 * @returns {number} - props.height if set, calculated chart height for horizontal charts, 0 otherwise
 */
function getChartHeight<T>(
  props: InternalChartProps<T>,
  offset: ChartOffset
): number {
  if (props.height !== undefined) {
    return props.height - offset.top - offset.bottom - offset.legend;
  }

  if (props.horizontal ?? false) {
    switch (props.variant) {
      case "stack": {
        return Math.min(
          MAX_HORIZONTAL_CHART_HEIGHT,
          props.data.length * INITIAL_BAR_WIDTH
        );
      }
      case "group":
      default: {
        return Math.min(
          MAX_HORIZONTAL_CHART_HEIGHT,
          props.data.length * props.resultKeys.length * INITIAL_BAR_WIDTH
        );
      }
    }
  }

  return 0;
}

export default function VisiusChart<T>(
  props: InternalChartProps<T>
): ReactElement | null {
  const {
    data,
    tickLabelWidth,
    colorScale,
    resultKeys,
    brush: hasBrushControl = false,
    horizontal: isHorizontal = false,
    variant,
    sortOrder = "none",

    onHeightChange: handleHeightChange,
    tooltipRenderer,
    getX,
    getComparableX,
    getXLabel,
    getMaxY,
  } = props;
  const legendRef = useRef<HTMLDivElement>(null);
  const legendHeight = legendRef.current?.offsetHeight ?? 0;
  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const [width, setWidth] = useState(props.width);
  useDebounce(() => setWidth(props.width), 100, [props.width]);
  const offset = useMemo(
    () => ({
      left: isHorizontal ? 80 : 50,
      right: isHorizontal ? 70 : 50,
      top: isHorizontal ? 20 : 40,
      bottom:
        30 +
        (props.brush !== undefined
          ? BRUSH_HEIGHT + BRUSH_TOP_OFFSET + BRUSH_BOTTOM_OFFSET
          : 0),
      legend: legendHeight,
    }),
    [isHorizontal, props.brush, legendHeight]
  );
  const chartWidth = width - offset.left - offset.right;
  const chartHeight = getChartHeight<T>(props, offset);
  const height =
    props.height ?? chartHeight + offset.top + offset.bottom + offset.legend;
  const theme = useTheme();

  // filtered data to display on the chart, reset when input data changed
  const [filteredData, setFilteredData] = useState<T[]>(data);
  const [brushExtent, setBrushExtent] = useState<BrushExtent>();

  // sort filtered data
  const chartDataset = useMemo(
    () => sortChartData(filteredData, resultKeys, sortOrder),
    [filteredData, resultKeys, sortOrder]
  );

  // notify parent component when component's height is has been calculated
  useEffect(() => {
    if (handleHeightChange === undefined) {
      return;
    }

    handleHeightChange(height);
  }, [handleHeightChange, height]);

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      getScaleBand<T>({
        data: chartDataset,
        size: isHorizontal ? chartHeight : chartWidth,
        getter: getX,
      }),
    [getX, chartDataset, chartHeight, chartWidth, isHorizontal]
  );
  const yScale = useMemo(
    () =>
      getScaleLinear<T>({
        data: chartDataset,
        size: isHorizontal ? chartWidth : chartHeight,
        reverse: isHorizontal,
        getter: getMaxY,
      }),
    [getMaxY, chartDataset, chartHeight, chartWidth, isHorizontal]
  );

  useEffect(() => {
    if (brushExtent === undefined) {
      return setFilteredData(data);
    }

    const minX = getComparableX(brushExtent.x0);
    const maxX = getComparableX(brushExtent.x1);
    const viewData = data.filter((datum) => {
      const x = getComparableX(getX(datum));
      return x >= minX && x <= maxX;
    });
    setFilteredData(viewData);
  }, [getX, getComparableX, setFilteredData, data, brushExtent]);

  // Tooltip
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,

    showTooltip,
    hideTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // automatically position tooltip with respect to chart bounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const chartView = useMemo((): ReactElement => {
    switch (variant) {
      case "linear": {
        return (
          <LinearChartView
            colorScale={colorScale}
            data={chartDataset}
            resultKeys={resultKeys}
            showTooltip={showTooltip}
            hideTooltip={hideTooltip}
            groupBy={getX}
            xScale={xScale}
            yScale={yScale}
            height={chartHeight}
            offset={offset}
          />
        );
      }
      case "stack": {
        return (
          <VisiusBarStack
            colorScale={colorScale}
            data={chartDataset}
            resultKeys={resultKeys}
            showTooltip={showTooltip}
            hideTooltip={hideTooltip}
            groupBy={getX}
            xScale={xScale}
            yScale={yScale}
            {...(props.horizontal === true
              ? { width: chartWidth, horizontal: true }
              : { height: chartHeight })}
          />
        );
      }
      case "group":
      default: {
        return (
          <VisiusBarGroup
            {...(props.horizontal === true
              ? { width: chartWidth, horizontal: true }
              : { height: chartHeight })}
            colorScale={colorScale}
            data={chartDataset}
            resultKeys={resultKeys}
            showTooltip={showTooltip}
            hideTooltip={hideTooltip}
            groupBy={getX}
            xScale={xScale}
            yScale={yScale}
          />
        );
      }
    }
  }, [
    chartDataset,
    chartHeight,
    chartWidth,
    colorScale,
    getX,
    hideTooltip,
    offset,
    props.horizontal,
    resultKeys,
    showTooltip,
    variant,
    xScale,
    yScale,
  ]);

  // fix issues with overlapping tick labels width by manually hiding ticks when needed
  const getTickComponent = useMemo(
    () => getTickLabelRenderer(getXLabel),
    [getXLabel]
  );
  const classes = useChartStyles();

  const xAxisProps = {
    scale: xScale,
    stroke: COLORS.GREY3,
    tickStroke: COLORS.GREY3,
    tickComponent: getTickComponent,
    tickClassName: classes.label,
  };
  const yAxisProps = {
    scale: yScale,
    tickClassName: classes.label,
    hideAxisLine: true,
    hideTicks: true,
  };

  const xTickValues = useMemo(() => {
    if (tickLabelWidth === undefined) {
      return undefined;
    }

    return xAxisProps.scale.domain().filter((v, index, arr) => {
      const skipRatio = Math.ceil((tickLabelWidth * arr.length) / chartWidth);
      return index % skipRatio === 0;
    });
  }, [tickLabelWidth, xAxisProps.scale, chartWidth]);

  const increasedFormatTick = (value: number, index: number) =>
    index % 2 === 0 ? `${value * 4}` : "";

  const formatTick = (value: number, index: number) =>
    index % 2 === 0 ? `${value}` : "";

  if (width === 0) {
    return null;
  }

  return (
    <div style={{ position: "absolute", width, height }}>
      <svg ref={containerRef} width={width} height={height - offset.legend}>
        {isHorizontal ? (
          <GridColumns
            top={offset.top}
            left={offset.left}
            scale={yScale}
            width={chartWidth}
            height={chartHeight}
            stroke={COLORS.GREY1}
            pointerEvents="none"
          />
        ) : (
          <GridRows
            top={offset.top}
            left={offset.left}
            scale={yScale}
            width={chartWidth}
            height={chartHeight}
            stroke={COLORS.GREY1}
            pointerEvents="none"
          />
        )}
        <Group left={offset.left} top={offset.top}>
          {chartView}
        </Group>
        {hasBrushControl && (
          <VisiusBrush
            left={offset.left}
            top={height - BRUSH_HEIGHT - offset.legend - BRUSH_BOTTOM_OFFSET}
            data={data}
            getMaxY={getMaxY}
            getX={getX}
            height={BRUSH_HEIGHT}
            width={chartWidth}
            onChange={setBrushExtent}
            resultKeys={resultKeys}
            variant={variant}
            extent={brushExtent}
          />
        )}
        {isHorizontal ? (
          <>
            <AxisLeft left={offset.left} top={offset.top} {...xAxisProps} />
            <AxisBottom
              left={offset.left}
              top={chartHeight + offset.top}
              tickFormat={
                isMenMode && !isMenUniteMode ? increasedFormatTick : formatTick
              }
              {...yAxisProps}
            />
          </>
        ) : (
          <>
            <AxisLeft
              left={offset.left}
              top={offset.top}
              tickFormat={
                isMenMode && !isMenUniteMode ? increasedFormatTick : formatTick
              }
              {...yAxisProps}
            />
            <AxisBottom
              left={offset.left}
              top={chartHeight + offset.top}
              tickValues={xTickValues}
              {...xAxisProps}
            />
          </>
        )}
        {tooltipRenderer !== undefined && tooltipOpen && tooltipData && (
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultTooltipStyles,
              padding: "8px",
              backgroundColor: COLORS.WHITE,
              color: COLORS.BLACK,
              border: `solid 1px transparent`,
              borderLeftWidth: theme.spacing(0.5),
              borderLeftColor: COLORS.BLUE5,
              borderRadius: theme.spacing(0.5),
              boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",
              zIndex: 3000,
            }}
          >
            {tooltipRenderer(tooltipData as T)}
          </TooltipInPortal>
        )}
        <PatternLines
          id="pattern-hover-bar"
          height={16}
          width={16}
          stroke={COLORS.WHITE}
          strokeWidth={1}
          orientation={["diagonal"]}
        />
      </svg>
    </div>
  );
}
