import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface DashboardState {
  onlyMenMode: boolean,
  onlyMenUniteMode: boolean,
  peopleMode: {
    common: boolean,
    uniq: boolean,
    repeating: boolean,
  };
}

export const initialState: DashboardState = {
  onlyMenMode: false,
  onlyMenUniteMode: false,
  peopleMode: {
    common: true,
    uniq: false,
    repeating: false,
  },

};

const slice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setOnlyMenMode(state: DashboardState, action: PayloadAction<boolean>) {
      state.onlyMenMode = action.payload;
    },
    setOnlyMenUniteMode(state: DashboardState, action: PayloadAction<boolean>) {
      state.onlyMenUniteMode = action.payload;
    },
    setPeopleMode(state: DashboardState, action: PayloadAction<string>) {
      switch (action.payload) {
        case "common":
          state.peopleMode = {
            common: true,
            uniq: false,
            repeating: false,
          };
          break;
        case "uniq":
          state.peopleMode = {
            common: false,
            uniq: true,
            repeating: false,
          };
          break;
        case "repeating":
          state.peopleMode = {
            common: false,
            uniq: false,
            repeating: true,
          };
          break;
        default:
          state.peopleMode = {
            common: true,
            uniq: false,
            repeating: false,
          };
      }
    },
  },
});

export const { actions, reducer } = slice;

export const selectIsMenMode = (state: RootState): boolean => {
  return state.dashboard.onlyMenMode;
};

export const selectPeopleMode = (state: RootState): any => {
  return state.dashboard.peopleMode;
};

export const selectIsMenUniteMode = (state: RootState): any => {
  return state.dashboard.onlyMenUniteMode;
};
