import React, { ReactElement, useCallback, useMemo } from "react";
import moment from "moment";

import VisiusBarGroupsChart, {
  ChartProps,
} from "../../../components/charts/Chart";
import { AggregationResult, ObjectDetectionResultMap } from "../chart-types";
import ChartTooltip from "./ChartTooltip";
import { RawResult } from "./RawResult";
import { generateMaxYGetter } from "./utils";

const KEY_DATE_FORMAT = "YYYY-MM-DD";

interface ResultGroupingChartProps extends ChartProps<RawResult> {
  aggregator: (key: string, values: RawResult[]) => AggregationResult;
  vertical?: boolean;
}

const xLabelGetter = (xValue: string): string[] => {
  const date = moment(xValue, "YYYY-MM-DD");
  const dateStr = date.format("DD MMM YYYY");

  return [dateStr];
};

const getX = ({ key }: AggregationResult): string => key;

const getComparableX = (x: string): number => {
  return moment(x, KEY_DATE_FORMAT).toDate().getTime();
};

export default function ResultAggregationChart(
  props: ResultGroupingChartProps
): ReactElement | null {
  const {
    width,
    height,
    colorScale,
    data,
    variant,
    resultKeys,
    vertical,
    sortOrder,
    aggregator,
    onHeightChange,
  } = props;

  const averageDataMap = useMemo(
    () =>
      data.reduce((res: ObjectDetectionResultMap, record: RawResult) => {
        const key = moment(record.time).format(KEY_DATE_FORMAT);
        if (res[key] === undefined) {
          res[key] = [];
        }
        res[key].push(record);
        return res;
      }, {}),
    [data]
  );

  const averageData = useMemo(
    () =>
      Object.entries(averageDataMap).reduce(
        (res: AggregationResult[], [key, values]) => {
          return [...res, aggregator(key, values)];
        },
        []
      ),
    [aggregator, averageDataMap]
  );

  const getTooltip = useCallback(
    (tooltipData: AggregationResult): ReactElement => {
      return (
        <ChartTooltip
          data={tooltipData}
          colorScale={colorScale}
          resultKeys={resultKeys}
          tooltipDataGetter={(data) =>
            xLabelGetter(getX(data as AggregationResult))
          }
        />
      );
    },
    [colorScale, resultKeys]
  );

  const maxYGetter = useMemo(
    () => generateMaxYGetter<AggregationResult>(resultKeys, variant),
    [resultKeys, variant]
  );

  if (width < 10) {
    return null;
  }

  const isVertical = variant === "linear" || vertical;
  const defaultVerticalChartHeight = 214;

  return (
    <VisiusBarGroupsChart<AggregationResult>
      width={width}
      height={height ?? (isVertical ? defaultVerticalChartHeight : undefined)}
      colorScale={colorScale}
      data={averageData}
      resultKeys={resultKeys}
      tooltipRenderer={getTooltip}
      getX={getX}
      getComparableX={getComparableX}
      getMaxY={maxYGetter}
      getXLabel={xLabelGetter}
      tickLabelWidth={70}
      onHeightChange={onHeightChange}
      variant={variant}
      horizontal={!isVertical}
      sortOrder={sortOrder}
    />
  );
}
