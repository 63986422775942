import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  workFlowWrap: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  tab: {
    fontWeight: 600,
    color: "#122045",
    textTransform: "none",

    "& svg path": {
      fill: "#122045",
    },
  },
  // content
  contentBlock: {
    marginTop: theme.spacing(3),
    "& + $header": {
      marginTop: theme.spacing(4),
    },

    "& button": {
      fontWeight: 600,
      textTransform: "none",
    },

    "& .MuiButton-containedPrimary .Mui-disabled": {
      marginRight: theme.spacing(2),
    },
  },
  mainBtn: {
    marginRight: theme.spacing(2),
    "&.Mui-disabled": {
      background: "#DFE6FB",
      color: "white",
    },
  },
  offsetContentBlock: {
    marginTop: theme.spacing(2),
  },
  steps: {
    marginTop: "auto",

    "& > .MuiTypography-root": {
      margin: theme.spacing(2, 0),
    },
  },

  // content header
  header: {
    display: "flex",
    alignItems: "center",
    height: 52,

    "& + $contentBlock": {
      marginTop: 0, // theme.spacing(1) // <-- not sure what's better
    },

    "& svg": {
      marginRight: 18,

      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  headerIcon: {
    marginRight: theme.spacing(2),
  },

  // text/controls within block content
  input: {
    margin: 0,

    "& .MuiFormLabel-root": {
      fontWeight: 400,
      fontSize: "14px",
      transform: "translate(12px, 20px) scale(1)",
      color: "#888888",

      "&.Mui-disabled": {
        color: "#DFE6FB",
      },
    },

    "& .MuiInputBase-root": {
      margin: "4px 0 24px",
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      width: "640px",
      background: "#FFFFFF",
      padding: "5px 0",

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "& fieldset": {
        border: "none ",
      },
    },
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  inputHelper: {
    marginBottom: theme.spacing(4),
    fontSize: "14px",
    fontWeight: 400,
  },
  paragraph: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nextStepParagraph: {
    color: theme.palette.grey[600],
  },
}));
