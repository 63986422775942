import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";

import { selectUserData } from "../auth/authSlice";
import {
  actions,
  editProfile,
  ProfileUpdate,
  selectError,
  selectIsConfirmed,
  selectStarted,
} from "./profileSlice";

import I18N from "../../app/i18n/strings";
import { useFormStyles } from "../../app/styles";

import Form, {
  FormValidatorOptions,
  getRequiredValidator,
} from "../../components/Form";
import Link from "../../components/Link";
import TextField from "../../components/TextField";
import { requestConfirmation } from "../confirm-email/confirmEmailSlice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    background: "transparent",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(6),
    },
    border: 0,
  },
  content: {
    padding: theme.spacing(0, 6, 6, 6),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1.5),

    "& .MuiButton-root": {
      textTransform: "none",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  saveButton: {
    whiteSpace: "nowrap",
    textTransform: "none",
  },
}));

interface FormData {
  companyName: string;
  name: string;
  phoneNumber: string;
}

export default function ProfileTab(): ReactElement {
  const classes = useFormStyles();
  const pageClasses = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileError = useSelector(selectError);
  const submitStarted = useSelector(selectStarted);
  const userData = useSelector(selectUserData);

  const isConfirmed = useSelector(selectIsConfirmed);

  const _onClick = (): void => {
    dispatch(requestConfirmation());
  };

  const _onChange = (): void => {
    if (profileError !== undefined) {
      dispatch(actions.reset());
    }
  };
  const _onSubmit = (data: FormData): void => {
    const update: ProfileUpdate = {
      name: data.name,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
    };
    dispatch(editProfile(update));
  };

  return (
    <Card className={pageClasses.root} variant="outlined">
      <CardContent className={pageClasses.content}>
        <Form<FormData>
          onSubmit={_onSubmit}
          validate={(options: FormValidatorOptions<FormData>) =>
            getRequiredValidator(["name", "companyName", "phoneNumber"])(
              options
            )
          }
        >
          <Typography className={pageClasses.title} variant="h4">
            {t(I18N.profile.TITLE_PROFILE)}
          </Typography>
          <div className={classes.labeledInputField}>
            <InputLabel required>{t(I18N.profile.LABEL_COMPANY)}</InputLabel>
            <TextField
              required
              variant="outlined"
              placeholder={t(I18N.profile.PLACEHOLDER_COMPANY)}
              name="companyName"
              defaultValue={userData?.companyName}
              onChange={_onChange}
              autoFocus
              errorPosition="right"
            />
          </div>
          <div className={classes.labeledInputField}>
            <InputLabel required>{t(I18N.profile.LABEL_NAME)}</InputLabel>
            <TextField
              required
              variant="outlined"
              placeholder={t(I18N.profile.PLACEHOLDER_NAME)}
              name="name"
              autoComplete="name"
              onChange={_onChange}
              defaultValue={userData?.name}
              errorPosition="right"
            />
          </div>
          <div className={classes.labeledInputField}>
            <InputLabel required>{t(I18N.profile.LABEL_PHONE)}</InputLabel>
            <TextField
              required
              variant="outlined"
              placeholder={t(I18N.profile.PLACEHOLDER_PHONE)}
              name="phoneNumber"
              autoComplete="phone"
              onChange={_onChange}
              defaultValue={userData?.phoneNumber}
              errorPosition="right"
            />
          </div>
          <div className={classes.labeledButtonField}>
            <InputLabel>{t(I18N.profile.LABEL_EMAIL)}</InputLabel>
            <TextField
              disabled
              variant="outlined"
              placeholder={t(I18N.profile.PLACEHOLDER_EMAIL)}
              name="email"
              inputProps={{ type: "email" }}
              defaultValue={userData?.email}
            />
            {!isConfirmed && (
              <div className={pageClasses.buttonWrapper}>
                <Button
                  type="button"
                  variant="text"
                  color="secondary"
                  onClick={_onClick}
                >
                  {t(I18N.profile.BTN_CONFIRM_EMAIL)}
                </Button>
              </div>
            )}
          </div>
          <div className={classes.labeledButtonField}>
            <InputLabel>{t(I18N.profile.LABEL_PASSWORD)}</InputLabel>
            <TextField
              disabled
              variant="outlined"
              placeholder={t(I18N.profile.PLACEHOLDER_PASSWORD)}
              name="password"
              inputProps={{ type: "password" }}
              type="password"
              value="******"
            />
            <Link className={pageClasses.buttonWrapper} to="?tab=chpassw">
              <Button
                className={pageClasses.saveButton}
                variant="text"
                color="primary"
              >
                {t(I18N.profile.BTN_CHANGE_PASSWORD)}
              </Button>
            </Link>
          </div>
          <div className={classes.formFooterActionsProfile}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitStarted}
              startIcon={<Save />}
            >
              {t(I18N.profile.BTN_EDIT_PROFILE)}
            </Button>
          </div>
        </Form>
      </CardContent>
    </Card>
  );
}
