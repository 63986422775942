import { Theme, Tooltip, withStyles } from "@material-ui/core";

export default withStyles((theme: Theme) => ({
  tooltip: {
    padding: '6px 12px',
    background: "#122045",
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",

    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
}))(Tooltip);