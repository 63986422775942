import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../app/assets/logo.svg'
import I18N from '../app/i18n/strings'
import { useFormStyles } from '../app/styles'
import VisiusLink from './Link'

interface AuthPageProps {
  title: string
  footer?: ReactElement
}

export default function AuthTemplate (props: React.PropsWithChildren<AuthPageProps>): ReactElement {
  const classes = useFormStyles()
  const { t } = useTranslation()

  return (
    <Container maxWidth='xs' className={classes.root}>
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <VisiusLink to='/'>
            <img src={logo} alt='Visius' />
          </VisiusLink>
        </div>
        <div>
          <Typography variant='h2' className={classes.header}>
            {props.title}
          </Typography>
          {props.children}
        </div>
        <Typography className={classes.pageFooter} color='textSecondary'>
          <VisiusLink to='#' color='inherit'>
            {t(I18N.common.LINK_HELP)}
          </VisiusLink>
          <span className={classes.divider}>|</span>
          <VisiusLink to='#' color='inherit'>
            {t(I18N.common.LINK_SUPPORT)}
          </VisiusLink>
        </Typography>
      </div>
    </Container>
  )
}
