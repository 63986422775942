export enum AUTH_URL {
  ACCOUNT = '/api/account/info',
  CHANGE_PASSWORD = '/api/account/reset-password',
  CONFIRM_EMAIL = '/api/account/email-confirmation',
  EDIT_ACCOUNT = '/api/account/update',
  FORGOT_PASSWORD = '/api/account/forgot-password',
  LOGIN = '/api/auth/login',
  LOGOUT = '/api/auth/logout',
  RESEND_EMAIL_CONFIRMATION = '/api/account/resend-confirmation-email',
  RESET_PASSWORD = '/api/account/reset-password-by-token',
  SIGNUP = '/api/account/register',
  USER_LIST = '/api/admin/users'
}

export enum MAIN_URL {
  GROUPS = '/api/location-groups',
  GROUP = '/api/location-groups/{id}',
  GROUP_BASE = '/api/location-groups',
  LOCATION = '/api/locations/{id}',
  LOCATION_BASE = '/api/locations'
}

export enum BILLING_URL {
  SERVER_URL = '/api/account/billing/url',
  USERS = '/billingApi/users/currentTariff/{id}',
  TARIFFS = '/billingApi/tariffs',
  INVOICE_PAYMENT = '/billingApi/payments/invoicePayment',
}
