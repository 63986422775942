import { ARCHIVE_STATE, GroupData, GroupDto, GroupMap, GroupsMap, LocationData, LocationPreviewDto } from './data-types'
import moment from 'moment'
import { TariffData, UserTariffData } from '../profile/tariffs/tariffHelper'
import { TariffDto, UserTariffDto } from '../profile/tariffs/tariffTypes'
import { clamp, hourFromMilliseconds, hourToMilliseconds } from '../../app/utils'

export const groupToGroupMap = (group: GroupData): GroupMap => {
  return {
    ...group,
    locations: new Map(group.locations.map(location => [
      location.id,
      location
    ]))
  }
}

/**
 * Converts an Array of location groups into a HashMap
 * @param groups {GroupData[]} - list of location groups
 * @returns GroupsMap - HashMap of location groups
 */
export const groupsArrayToMap = (groups: GroupData[]): GroupsMap => {
  return new Map(groups.map(group => [
    group.id,
    {
      ...group,
      locations: new Map(group.locations.map(location => [
        location.id,
        location
      ]))
    }
  ]))
}

/**
   * Converts HashMap of location groups into an Array
   * @param groups
   */
export const groupsMapToArray = (groups: GroupsMap): GroupData[] => {
  return Array
    .from(groups.values())
    .map(({ locations, ...group }: GroupMap) => ({
      ...group,
      locations: Array.from(locations.values())
    }))
}

export const locationDtoToLocation = (location: LocationPreviewDto): LocationData => {
  return {
    id: location.id,
    title: location.title ?? 'n/a',
    streams: location.videoStreamCount,
    videos: location.videoFilesCount,
    thumbnail: location.imagePreviewUrl ?? undefined,
    isArchived: location.state === ARCHIVE_STATE.ARCHIVED,
    isRemoved: location.isRemoved,
    createdAt: moment(location.createAt),
    archivedAt: location.archivedAt !== null ? moment(location.archivedAt) : undefined,
    removedAt: location.removedAt !== null ? moment(location.removedAt) : undefined,
    videoFilesSize: location.videoFilesSize,
    isCameraConnected: location.isCameraConnected,
    processingCount: location.processingCount,
    completedCount: location.completedCount
  }
}

export const groupDtoToGroup = (domain: GroupDto, filter?: GroupOrLocationFilter): GroupData => {
  const locations = domain.locations.map(locationDtoToLocation)
  return {
    id: domain.id,
    createdAt: moment(domain.createAt),
    title: domain.title,
    isArchived: domain.state === ARCHIVE_STATE.ARCHIVED,
    isRemoved: domain.isRemoved,
    locations: filter !== undefined
      ? locations.filter(filter)
      : locations
  }
}

export const currentTariffDtoTariffData = (tariff: UserTariffDto): UserTariffData => {
  const timeBalancePercantage = tariff.timeBalance / hourToMilliseconds(tariff.maxHours) * 100
  const elem = {
    id: tariff.tariffSnapshotId,
    name: tariff.name ?? '-',
    maxHours: tariff.maxHours,
    purchase: tariff.purchase,
    timeBalanceHour: hourFromMilliseconds(tariff.timeBalance),
    timeBalancePercantage: clamp(timeBalancePercantage, 0, 100),
    timeBalanceString: tariff.timeBalanceString,
    lastPaymentDate: tariff.lastPaymentDate !== null ? moment.utc(tariff.lastPaymentDate) : null,
    isSystem: tariff.isSystem
  }

  return elem
}

export const tariffDtoTariffData = (tariff: TariffDto): TariffData => {
  return {
    id: tariff.id,
    tariffSnapshotId: tariff.tariffSnapshotId,
    name: tariff.name ?? '-',
    maxHours: tariff.maxHours,
    purchase: tariff.purchase,
    isSystem: tariff.isSystem
  }
}

type GroupOrLocationFilter = (group: LocationData|GroupData) => boolean
