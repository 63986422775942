import React, { ReactElement } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

import Link, { LinkProps } from '@material-ui/core/Link'

export default function VisiusLink ({ children, ...rest }: React.PropsWithChildren<LinkProps&RouterLinkProps>): ReactElement {
  return (
    <Link component={RouterLink} {...rest}>
      {children}
    </Link>
  )
}
