import React, { ReactElement, useCallback, useState } from "react";
import {
  Card,
  CardContent,
  LinearProgress,
  makeStyles,
  SvgIconTypeMap,
  Theme,
  Typography,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { d3format } from "../../../app/i18n/d3";
import { COLORS } from "../../../theme";
import GenderStats, { DetailData } from "./GenderStats";

interface StatsCardProps {
  number: number;
  title: string | JSX.Element;
  iconComponent?:
    | OverridableComponent<SvgIconTypeMap<unknown, "svg">>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  progress?: number;
  isDetailStats?: boolean;
  detailData?: DetailData;
}

const useStyles = (color?: string): ReturnType<typeof makeStyles> =>
  makeStyles((theme: Theme) => ({
    cardWrap: {
      boxShadow: "none",
      borderRadius: 8,
      border: "1px solid",
      borderColor: COLORS.GREY2,
    },
    card: {
      padding: theme.spacing(3),
    },
    number: {
      fontSize: 32,
      lineHeight: "32px",
      fontWeight: 700,
    },
    numberWithProgress: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: theme.spacing(1),
    },
    progress: {
      height: theme.spacing(1),
      "&.MuiLinearProgress-colorPrimary": {
        backgroundColor: COLORS.BLUE1,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: color ?? COLORS.BLUE6,
      },
    },
    title: {
      marginTop: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleIcon: {
      color: color ?? COLORS.BLUE6,
    },
  }));

export default function StatsCard(props: StatsCardProps): ReactElement {
  const classes = useStyles(props.color)();
  const [hideDetails, setHideDetails] = useState<boolean>(true);

  const handleHideDetails = useCallback(() => {
    setHideDetails((prev) => !prev);
  }, [hideDetails, setHideDetails]);

  return (
    <Card className={classes.cardWrap}>
      <CardContent className={classes.card}>
        <Typography
          variant="subtitle1"
          className={
            props.progress === undefined
              ? classes.number
              : classes.numberWithProgress
          }
        >
          {d3format(props.number)}
        </Typography>
        {props.progress !== undefined &&
          (props.isDetailStats ? (
            <>
              {props.title === "Мужчины" ? (
                <GenderStats
                  detailData={props.detailData}
                  colorOld={"#11B0C7"}
                  colorMiddle={"#59C8D8"}
                  colorYoung={"#ACE3EB"}
                  colorUnknown={"#DBF3F7"}
                  hideDetails={hideDetails}
                />
              ) : null}
              {props.title === "Женщины" ? (
                <GenderStats
                  detailData={props.detailData}
                  colorOld={"#EE645A"}
                  colorMiddle={"#F3938B"}
                  colorYoung={"#F9C9C5"}
                  colorUnknown={"#FCE8E6"}
                  hideDetails={hideDetails}
                />
              ) : null}
              {props.title === "Не определено" ? (
                <GenderStats
                  detailData={props.detailData}
                  colorOld={"#888888"}
                  colorMiddle={"#BBBBBB"}
                  colorYoung={"#E2E0E0"}
                  colorUnknown={"#F0F0F0"}
                  hideDetails={hideDetails}
                />
              ) : null}
            </>
          ) : (
            <LinearProgress
              value={props.progress * 100}
              className={classes.progress}
              variant="determinate"
            />
          ))}
        {props.isDetailStats ? (
          <div className={classes.title}>
            <div style={{ display: "flex", gap: "16px" }}>
              {typeof props.title === "string" ? (
                <Typography variant="h5">{props.title}</Typography>
              ) : (
                props.title
              )}

              <Typography
                variant="h5"
                style={{ color: "#2C67FF", cursor: "pointer" }}
                onClick={handleHideDetails}
              >
                {hideDetails ? "Детали" : "Скрыть"}
              </Typography>
            </div>
            {props.iconComponent !== undefined && (
              <props.iconComponent className={classes.titleIcon} />
            )}
          </div>
        ) : (
          <div className={classes.title}>
            <div>
              <Typography variant="h5">{props.title}</Typography>
            </div>
            {props.iconComponent !== undefined && (
              <props.iconComponent className={classes.titleIcon} />
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
