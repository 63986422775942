import { TextFieldProps } from "@material-ui/core";
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useRef,
  useState,
} from "react";
import I18N from "../app/i18n/strings";
import { useFormStyles } from "../app/styles";
import { TextFieldValdationProps } from "./TextField";

export type FormValidator<T> = (
  options: FormValidatorOptions<T>
) => InputValidationErrors<T>;

type FormChangesState<T> = {
  [propName in keyof T]?: boolean;
};

export interface FormValidatorOptions<T> {
  values: {
    [propName in keyof T]?: string;
  };
  hasChanges: FormChangesState<T>;
  force: boolean;
}

export type InputValidationErrors<T> = {
  [propName in keyof T]?: string[];
};

interface FormProps<T> {
  onSubmit?: (values: T) => void;
  validate?: FormValidator<T>;
  children: ReactElement[];
}

export function getRequiredValidator<T>(requiredFields: Array<keyof T>) {
  return ({
    values,
    hasChanges,
    force: forceValidation,
  }: FormValidatorOptions<T>): InputValidationErrors<T> => {
    const res: InputValidationErrors<T> = {};
    for (const field of requiredFields) {
      if (
        values[field] === "" &&
        (hasChanges[field] === true || forceValidation)
      ) {
        res[field] = [I18N.common.ERROR_FIELD_REQUIRED];
      }
    }
    return res;
  };
}

export function getConfirmationValidator<T>(
  passwordField: keyof T,
  confirmationField: keyof T
) {
  return ({ values }: FormValidatorOptions<T>): InputValidationErrors<T> => {
    const res: InputValidationErrors<T> = {};
    const isConfirmationValid =
      values[confirmationField] === "" ||
      values[passwordField] === values[confirmationField];
    if (!isConfirmationValid) {
      res[confirmationField] = [I18N.passwordReset.ERROR_PASSWORDS_MISMATCH];
    }
    return res;
  };
}

export function getPhoneValidator<T>(phoneNumberField: keyof T) {
  return ({ values }: FormValidatorOptions<T>): InputValidationErrors<T> => {
    const res: InputValidationErrors<T> = {};
    const regexNumber = /^\+\d{10,15}$/;
    const isPhoneNumberValid = values[phoneNumberField]?.match(regexNumber)
    if (!isPhoneNumberValid && values[phoneNumberField]?.length !== 0) {
      res[phoneNumberField] = [I18N.passwordReset.ERROR_PHONE_NUMBER_INCORRECT]
    }
    return res
  };
}

export function getRulesValidator<T>(passwordField: keyof T) {
  return ({ values }: FormValidatorOptions<T>): InputValidationErrors<T> => {
    const res: InputValidationErrors<T> = {};
    const inputString = values[passwordField] as string;
    const regExpUppercase = /[A-Z]/;
    const regExpNumber = /[0-9]/;
    const regExpSymbol = /[!"#$%&'()*+,-./:;><=?\\@[\]^_`{|}~]/;

    if (inputString === "") {
      return res;
    }

    const errors: string[] = [];
    if (inputString.length < 6) {
      errors.push(I18N.passwordReset.ERROR_INVALID_PASSWORD_LENGTH);
    }
    if (inputString.search(regExpSymbol) === -1) {
      errors.push(I18N.passwordReset.ERROR_INVALID_PASSWORD_SYMBOL);
    }
    if (inputString.search(regExpUppercase) === -1) {
      errors.push(I18N.passwordReset.ERROR_INVALID_PASSWORD_UPPERCASE);
    }
    if (inputString.search(regExpNumber) === -1) {
      errors.push(I18N.passwordReset.ERROR_INVALID_PASSWORD_NUMBER);
    }

    res[passwordField] = errors;
    return res;
  };
}

type ChildMapper = (elem: ReactElement) => ReactElement;

const mapChildrenRecursive = (
  children: ReactElement[],
  mapper: ChildMapper
): ReactElement[] => {
  return React.Children.map(children, (child: ReactElement) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    const { children: nestedChildren } = (child as ReactElement).props;
    return mapper(
      nestedChildren !== undefined
        ? React.cloneElement(child as ReactElement, {
            children: mapChildrenRecursive(nestedChildren, mapper),
          })
        : child
    );
  });
};

function getFormValues<T>(form: HTMLFormElement): T {
  const formData = new FormData(form);
  return Array.from(formData.entries()).reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: value,
    }),
    {}
  ) as T;
}

interface ValidationOptions<T> {
  form: HTMLFormElement;
  hasChanges: FormChangesState<T>;
  forceValidation?: boolean;

  validator?: (options: FormValidatorOptions<T>) => InputValidationErrors<T>;
}

/**
 * Validates form fields and sets validation errors
 */
function validateForm<T>({
  form,
  hasChanges,
  forceValidation = false,
  validator,
}: ValidationOptions<T>): InputValidationErrors<T> {
  if (validator === undefined) {
    return {};
  }

  const options: FormValidatorOptions<T> = {
    values: getFormValues(form),
    hasChanges,
    force: forceValidation,
  };
  return validator(options);
}

export default function Form<T>(props: FormProps<T>): ReactElement {
  const classes = useFormStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const [validationErrors, setValidationErrors] = useState<
    InputValidationErrors<T>
  >({});
  const [formHasChanges, setFormChanges] = useState<FormChangesState<T>>({});

  /**
   * Input changes handler: validates form fields and sets validation errors
   */
  const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const hasChanges = {
      ...formHasChanges,
      [evt.target.name]: true,
    };
    const errors = validateForm({
      form: formRef.current as HTMLFormElement,
      hasChanges,
      validator: props.validate,
    });
    setValidationErrors(errors);
    setFormChanges(hasChanges);
  };

  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();

    const form = formRef.current as HTMLFormElement;
    const enforcedChanges: FormChangesState<T> = Object.keys(
      getFormValues(form)
    ).reduce((res, key) => ({ ...res, [key]: true }), {});
    const errors = validateForm({
      form: formRef.current as HTMLFormElement,
      hasChanges: enforcedChanges,
      forceValidation: true,
      validator: props.validate,
    });

    // Set `hasChanges` attribbute to `true` for all the form fields in order to enforce form validation
    setFormChanges(enforcedChanges);

    // 1. Check for validation issues.
    //    Set focus to the first input field having errors
    for (const field in errors) {
      if (
        errors[field] !== undefined &&
        (errors[field] as string[]).length > 0
      ) {
        form[field].focus();
        return;
      }
    }

    // 2. Call Submit handler if no errors found
    const values = getFormValues<T>(form);
    if (props.onSubmit !== undefined) {
      props.onSubmit(values);
    }
  };

  return (
    <form
      ref={formRef}
      className={classes.form}
      noValidate
      onSubmit={handleSubmit}
    >
      {mapChildrenRecursive(props.children, (child) => {
        // Return Element itself for non-text children
        if (!React.isValidElement(child) || child.props === undefined) {
          return child;
        }
        const textFieldProps = child.props as TextFieldProps &
          TextFieldValdationProps;
        const textFieldName = textFieldProps.name as keyof T;
        if (textFieldName === undefined) {
          return child;
        }

        // Set validation-related props for Text fields
        const fieldErrors = validationErrors[textFieldName];
        const hasValidationError =
          fieldErrors !== undefined && fieldErrors.length > 0;
        const validationProps: TextFieldProps & TextFieldValdationProps = {
          errors: hasValidationError
            ? (validationErrors[textFieldName] as string[])
            : textFieldProps.errors?.map((err) => err.toString()),
          onChange: (evt: ChangeEvent<HTMLInputElement>) => {
            if (textFieldProps.onChange !== undefined) {
              textFieldProps.onChange(evt);
            }
            handleChange(evt);
          },
        };
        return React.cloneElement(child, validationProps);
      })}
    </form>
  );
}
