import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../../app/store'
import { HTTP_METHOD, request } from '../../app/utils'
import { AUTH_URL } from '../../app/api-url'
import { SIGNUP_STATE, SignUpState, FormError } from './signUpTypes'
import { loadUserData } from '../auth/authSlice'
import { ERROR_CODE, ErrorPayload, getErrorPayload } from '../../app/errors'
import I18N from '../../app/i18n/strings'
import { History } from 'history'

export const initialState: SignUpState = {
  state: SIGNUP_STATE.NONE
}

const authSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    reset () { return initialState },
    signupStarted (state: SignUpState) {
      state.state = SIGNUP_STATE.SIGNUP_STARTED
    },
    signupFailed (state: SignUpState, action: PayloadAction<ErrorPayload>) {
      state.state = SIGNUP_STATE.SIGNUP_FAILED
      switch (action.payload.code) {
        case ERROR_CODE.DUPLICATE_USER_NAME: {
          state.error = {
            email: [I18N.signUp.ERROR_DUPLICATE_USER_EMAIL]
          }
          break
        }
        case ERROR_CODE.DUPLICATE_USER_EMAIL: {
          state.error = {
            email: [I18N.signUp.ERROR_DUPLICATE_USER_EMAIL]
          }
          break
        }
        case ERROR_CODE.INVALID_EMAIL: {
          state.error = {
            email: [I18N.signUp.ERROR_INVALID_USER_EMAIL]
          }
          break
        }
        case ERROR_CODE.INVALID_USER_NAME: {
          state.error = {
            email: [I18N.signUp.ERROR_INVALID_USER_EMAIL]
          }
          break
        }
        case ERROR_CODE.INVALID_INPUT_DATA:{
          state.error = {
            email: [I18N.signUp.ERROR_INVALID_USER_EMAIL]
          }
          break
        }
        default: {
          state.error = {
            password: [I18N.signUp.ERROR_INVALID_INPUT_DATA]
          }
        }
      }
    }
  }
})

export const {
  actions,
  reducer
} = authSlice

interface SignUpOptions {
  name: string
  email: string
  phoneNumber: string
  password: string
  history: History
  redirectTo: string
}

export const signUp = ({ name, email, phoneNumber, password, history, redirectTo }: SignUpOptions): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.signupStarted())
    await request(AUTH_URL.SIGNUP, { method: HTTP_METHOD.POST, data: { name, email, phoneNumber, password } })
    history.push(redirectTo)
    dispatch(actions.reset())
  } catch (err) {
    const errorPayload = getErrorPayload(err)
    dispatch(actions.signupFailed(errorPayload))
    return
  }

  await dispatch(loadUserData(true))
}

export const selectIsSignUpStarted = (state: RootState): boolean => state.signUp.state === SIGNUP_STATE.SIGNUP_STARTED
export const selectSignUpError = (state: RootState): FormError|undefined => {
  if (state.signUp.state !== SIGNUP_STATE.SIGNUP_FAILED) {
    return
  }

  return state.signUp.error
}
