import React, { ReactElement, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { Button, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import {
  Add,
  Close,
  VideocamOutlined,
  VideocamOffOutlined,
} from "@material-ui/icons";

import deepEqual from "deep-equal";

import { actions, restartProcessing, stopProcessing } from "../locationSlice";
import { LocationId } from "../../groups/data-types";
import { StreamData, VIDEO_PROCESSING_STATE } from "../data-mapper";
import { getStreamsSelector } from "../selectors";

import I18N from "../../../app/i18n/strings";

import { TabId } from "../Page";
import { Line } from "../line-editor/components/DrawLine";
import StreamsList from "./components/StreamsList";
import StreamCreator from "./StreamCreator";
import WorkFlow from "../workflow/WorkFlow";
import useStyles from "../styles";

import { ReactComponent as CameraIcon } from "../../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../../app/assets/webcamIcon.svg";

interface StreamProps {
  locationId?: LocationId;
  showTitle: boolean;
  isContentLoaded?: boolean;
  currentTab: TabId;
  visibleTabs: TabId[];
  handleTabChange?: (evt: any, tabIndex: number) => void;
}

export default function StreamsTab(props: StreamProps): ReactElement {
  const {
    locationId,
    showTitle,
    isContentLoaded,
    currentTab,
    visibleTabs,
    handleTabChange,
  } = props;
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectStreams = useMemo(
    () => getStreamsSelector(locationId),
    [locationId]
  );
  const streams = useSelector(selectStreams, deepEqual);
  const tmpStream = streams.find(({ isTmp }) => isTmp);

  const handleCreateTmpStream = useCallback(() => {
    dispatch(actions.createStream(locationId as LocationId));
  }, [dispatch, locationId]);

  const handleDeleteTmpStream = useCallback(() => {
    dispatch(
      actions.removeStream({
        // it is safe to assume that locationId tmpStream both exist
        // because that's a precondition for "Delete" button to be shown
        locationId: locationId as LocationId,
        streamId: (tmpStream as StreamData).id,
      })
    );
  }, [dispatch, locationId, tmpStream]);

  const savedStreams = streams.filter(({ isTmp = false }) => !isTmp);
  const hasSavedStream = savedStreams.length > 0;
  const streamWithoutLine = streams.find(({ line }) => line === undefined);
  const streamWithLine = streams.find(({ line }) => line !== undefined);
  const everyStream = streams.find(({ id }) => id !== undefined);

  const handleRestartProcessing = useCallback(() => {
    dispatch(
      restartProcessing({
        locationId: locationId as LocationId,
        line: (streamWithLine as StreamData).line as Line,
        streamId: (everyStream as StreamData).id,
      })
    );
  }, [dispatch, locationId, streamWithLine, everyStream]);

  const handleStopProcessing = useCallback(() => {
    dispatch(stopProcessing(locationId as LocationId));
  }, [dispatch, locationId]);

  const stepsControl = useMemo((): ReactElement | null => {
    if (!hasSavedStream) {
      return (
        <div
          className={
            hasSavedStream ? classes.offsetContentBlock : classes.contentBlock
          }
        >
          <Button
            color="primary"
            disabled={locationId === undefined}
            onClick={handleCreateTmpStream}
            startIcon={<Add />}
            variant="text"
          >
            {t(I18N.location.BTN_ADD_STREAM)}
          </Button>
        </div>
      );
    }
    // show Line Editor only when:
    //  1. No stream is being created right now
    //  2. There are no streams with line drawn (within the given Location)
    const isDrawLineBtnAvailable =
      tmpStream === undefined &&
      streamWithoutLine !== undefined &&
      streamWithLine === undefined;

    const hasNotYetPreProcessedStream = streams.some(
      ({ thumbnailUrl }) => thumbnailUrl === undefined
    );

    if (
      isDrawLineBtnAvailable &&
      !hasNotYetPreProcessedStream &&
      streams.length === 1
    ) {
      const drawLine = `${location.pathname}?tab=line&stream=${
        streamWithoutLine?.id ?? ""
      }`;

      return <Redirect to={drawLine} />;
    }

    const streamNotInProcessing =
      streams.find(({ videoProcessingState }) => {
        return (
          videoProcessingState === VIDEO_PROCESSING_STATE.NOT_STARTED ||
          videoProcessingState === VIDEO_PROCESSING_STATE.STOPPED ||
          videoProcessingState ===
            VIDEO_PROCESSING_STATE.PREPARING_INFRASTRUCTURE_ERROR ||
          videoProcessingState === VIDEO_PROCESSING_STATE.STOPPING_ERROR ||
          videoProcessingState === VIDEO_PROCESSING_STATE.NOTIFICATION_ERROR
        );
      }) !== undefined;

    const streamInProcessing =
      streams.find(({ videoProcessingState }) => {
        return (
          videoProcessingState !== VIDEO_PROCESSING_STATE.NOT_STARTED &&
          videoProcessingState !== VIDEO_PROCESSING_STATE.STOPPED
        );
      }) !== undefined;

    const streamIsConnected =
      streams.find(({ videoProcessingState }) => {
        return videoProcessingState === VIDEO_PROCESSING_STATE.PROCESSING;
      }) !== undefined;

    // это проверка будет нужна позднее,
    // когда появится несколько сессий stream
    const streamConnected = streamInProcessing || streamIsConnected;
    if (streamWithLine !== undefined) {
      return (
        <div className={classes.steps}>
          <div className={classes.contentBlock}>
            {streamNotInProcessing && !streamConnected ? (
              <Button
                className={classes.backButton}
                color="primary"
                variant="contained"
                onClick={handleRestartProcessing}
                disabled={!streamNotInProcessing}
                startIcon={<VideocamOutlined />}
              >
                {t(I18N.location.BTN_STEP_CONNECT_CAMERA)}
              </Button>
            ) : (
              <Button
                color="primary"
                className={classes.mainBtn}
                variant="contained"
                onClick={handleStopProcessing}
                disabled={!streamIsConnected}
                startIcon={<VideocamOffOutlined />}
              >
                {t(I18N.location.BTN_STEP_DISABLE_CAMERA)}
              </Button>
            )}
          </div>
        </div>
      );
    }

    return null;
  }, [
    streams,
    streamWithLine,
    streamWithoutLine,
    tmpStream,
    locationId,
    hasSavedStream,
    t,
    handleStopProcessing,
    handleRestartProcessing,
    classes,
    handleCreateTmpStream,
    location,
  ]);

  const content = useMemo((): ReactElement => {
    if (streams.length === 0) {
      return (
        <>
          <div className={classes.workFlowWrap}>
            {isContentLoaded && (
              <WorkFlow streams={streams} locationId={locationId} />
            )}
          </div>
          {!showTitle && (
            <Tabs
              value={visibleTabs.indexOf(currentTab)}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                key="video"
                className={classes.tab}
                icon={<CameraIcon />}
                label={<span>{t(I18N.location.TAB_FILES)}</span>}
              />
              <Tab
                key="stream"
                className={classes.tab}
                label={t(I18N.location.TAB_STREAMS)}
                icon={<WebcamIcon />}
              />
            </Tabs>
          )}
          <Typography variant="body2" className={classes.contentBlock}>
            {t(
              locationId === undefined
                ? I18N.location.MSG_LOCATION_REQUIRED
                : I18N.location.MSG_EMPTY
            )}
          </Typography>
        </>
      );
    }

    return (
      <>
        <div className={classes.workFlowWrap}>
          {isContentLoaded && !streamWithLine && (
            <WorkFlow streams={streams} locationId={locationId} />
          )}
        </div>
        {!showTitle && (
          <Tabs
            value={visibleTabs.indexOf(currentTab)}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              key="video"
              className={classes.tab}
              icon={<CameraIcon />}
              label={<span>{t(I18N.location.TAB_FILES)}</span>}
            />
            <Tab
              key="stream"
              className={classes.tab}
              label={t(I18N.location.TAB_STREAMS)}
              icon={<WebcamIcon />}
            />
          </Tabs>
        )}
        {tmpStream !== undefined && hasSavedStream && (
          <div className={classes.header}>
            <IconButton
              className={classes.headerIcon}
              size="small"
              onClick={handleDeleteTmpStream}
            >
              <Close />
            </IconButton>
            <Typography>{t(I18N.location.TITLE_UPLOADS)}</Typography>
          </div>
        )}

        {tmpStream !== undefined && (
          <StreamCreator
            locationId={locationId as LocationId}
            stream={tmpStream}
          />
        )}

        {hasSavedStream && <StreamsList streams={savedStreams} />}
      </>
    );
  }, [
    streams,
    tmpStream,
    locationId,
    hasSavedStream,
    savedStreams,
    handleDeleteTmpStream,
    t,
    classes,
  ]);

  return (
    <>
      {showTitle && (
        <div className={classes.header}>
          <CameraIcon className={classes.headerIcon} color="primary" />
          <Typography variant="h4">{t(I18N.location.TITLE_STREAM)}</Typography>
        </div>
      )}
      {content}
      {stepsControl}
    </>
  );
}
