import React, { ReactElement, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

import { ParentSize } from "@visx/responsive";
import {
  defaultStyles as defaultTooltipStyles,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { localPoint } from "@visx/visx";
import { ScaleOrdinal } from "d3-scale";

import I18N from "../../../app/i18n/strings";
import { selectIsMenMode, selectIsMenUniteMode } from "../dashboardSlice";

import { ResultKeys } from "../../../components/charts/bar/types";
import { ChartVariant } from "../../../components/charts/Chart";
import VisiusLegend from "../../../components/charts/Legend";
import PieChart from "../../../components/charts/pie/PieChart";

import { AggregationResult } from "../chart-types";
import ChartTooltip from "../charts/ChartTooltip";
import PeopleCategoryPercentage from "../charts/PeopleCategoryPercentage";
import { getX, getXTooltipLabel, RawResult } from "../charts/RawResult";

import ChartCardHeader from "./ChartCardHeader";

import { COLORS } from "../../../theme";
import { DataForTotalPie } from "../constants";

interface TotalChartProps {
  resultKeys: Array<ResultKeys<RawResult>>;
  colorScale: ScaleOrdinal<ResultKeys<RawResult>, string>;
  data: Array<DataForTotalPie>;
  variants: ChartVariant[];
}

const useStyles = makeStyles((theme: Theme) => ({
  cardWrap: {
    boxShadow: "none",
    borderRadius: 8,
    border: "1px solid",
    borderColor: COLORS.GREY2,
  },
  cardContent: {
    padding: theme.spacing(4, 3),
  },
  legend: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3.5),
  },
}));

export default function TotalPieCard(props: TotalChartProps): ReactElement {
  const { data, colorScale, resultKeys } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [chartVariant, setChartVariant] = useState<ChartVariant>("pie");
  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const hasEtcData =
    data.findIndex(({ label, value }) => {
      return !resultKeys.includes(label) && value > 0;
    }) !== -1;
  const theme = useTheme();

  const etcNumber = data.reduce((sum, { label, value }) => {
    if (resultKeys.includes(label)) {
      return sum;
    }

    return sum + value;
  }, 0);

  // Tooltip
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,

    showTooltip,
    hideTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // automatically position tooltip with respect to chart bounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const getTooltip = useCallback(
    (totalData: RawResult): ReactElement => {
      return (
        <ChartTooltip
          data={totalData}
          colorScale={colorScale}
          resultKeys={resultKeys}
          tooltipDataGetter={(data: RawResult | AggregationResult) =>
            getXTooltipLabel(getX(data as RawResult))
          }
        />
      );
    },
    [colorScale, resultKeys]
  );

  const getTooltipHandler = useCallback(
    (datumIndex: string) => {
      if (showTooltip === undefined) {
        return;
      }

      return (event: MouseEvent): void => {
        const coords = localPoint(
          (event.target as SVGElement).ownerSVGElement as Element,
          event
        );

        const foundData = data.find((i) => i.label === datumIndex);

        showTooltip({
          tooltipLeft: coords?.x,
          tooltipTop: coords?.y,
          tooltipData: foundData
            ? foundData
            : { label: "etc", color: "F0F0F0", value: etcNumber },
        });
      };
    },
    [showTooltip, data]
  );

  const tTip = getTooltip !== undefined && tooltipOpen && tooltipData && (
    <TooltipInPortal
      // set this to random so it correctly updates with parent bounds
      key={Math.random()}
      top={tooltipTop}
      left={tooltipLeft}
      style={{
        ...defaultTooltipStyles,
        padding: "8px 8px 0 0",

        backgroundColor: COLORS.WHITE,
        color: COLORS.BLACK,
        border: `solid 1px transparent`,
        borderLeftWidth: theme.spacing(0.5),
        borderLeftColor: COLORS.BLUE5,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",
        zIndex: 3000,
      }}
    >
      {getTooltip(tooltipData as RawResult)}
    </TooltipInPortal>
  );

  return (
    <Card className={classes.cardWrap}>
      <CardContent className={classes.cardContent}>
        <ChartCardHeader
          chartVariant={chartVariant}
          onChartVariantChange={setChartVariant}
          title={
            isMenMode || isMenUniteMode
              ? t(I18N.chart.TITLE_RELATIVE_WALKERS_CHART)
              : t(I18N.chart.TITLE_RELATIVE_CHART)
          }
          variants={props.variants}
          tooltip={
            <div>
              <Typography className="text2">
                {t(I18N.chart.TOOLTIP_TOTAL_PIE_SUMMARY)}
              </Typography>
              <Typography className="text1">
                {t(I18N.chart.TOOLTIP_TOTAL_PIE[0])}
              </Typography>
              <Typography className="text1">
                {t(I18N.chart.TOOLTIP_TOTAL_PIE[1])}
              </Typography>
            </div>
          }
        />
        <ParentSize>
          {(parent): ReactElement | null => {
            if (chartVariant === "group") {
              return (
                <PeopleCategoryPercentage
                  width={parent.width}
                  height={298}
                  data={props.data}
                  resultKeys={props.resultKeys}
                  colorScale={props.colorScale}
                  containerRef={containerRef}
                  hideTooltip={hideTooltip}
                  getTooltipHandler={getTooltipHandler}
                  tTip={tTip}
                />
              );
            }
            return (
              <PieChart
                width={parent.width}
                height={parent.width}
                resultKeys={props.resultKeys}
                colorScale={props.colorScale}
                data={props.data}
                containerRef={containerRef}
                hideTooltip={hideTooltip}
                getTooltipHandler={getTooltipHandler}
                tTip={tTip}
              />
            );
          }}
        </ParentSize>
        <div className={classes.legend}>
          <VisiusLegend
            colorScale={props.colorScale}
            resultKeys={props.resultKeys}
            showEtc={chartVariant === "pie" ? hasEtcData : false}
            hideLabels={isMenMode && !isMenUniteMode}
          />
        </div>
      </CardContent>
    </Card>
  );
}
