import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import I18N from '../../app/i18n/strings'
import { useFormStyles } from '../../app/styles'
import AuthTemplate from '../../components/AuthPage'
import Form, { getRequiredValidator } from '../../components/Form'
import VisiusLink from '../../components/Link'
import TextField from '../../components/TextField'
import {
  actions,
  login,
  selectAuthenticated,
  selectLoginError,
  selectLoginStarted
} from './authSlice'

interface LocationState {
  referrer: Location
}

interface LoginData {
  email: string
  password: string
}

export default function SignIn (): ReactElement {
  const classes = useFormStyles()
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()

  const isAuthCompleted = useSelector(selectAuthenticated)
  const isLoginPending = useSelector(selectLoginStarted)
  const loginError = useSelector(selectLoginError)
  const { t } = useTranslation()

  const handleSubmit = useCallback((data: LoginData): void => {
    dispatch(login(data))
  }, [dispatch])

  const handleInput = useCallback(() => {
    if (loginError !== undefined) {
      dispatch(actions.clearError())
    }
  }, [dispatch, loginError])

  const handleValidation = useMemo(() => getRequiredValidator(['email', 'password']), [])

  // Redirect to the initially requested page after a successfull login
  if (isAuthCompleted) {
    const defaultLocation = { pathname: '/' }
    const { referrer = defaultLocation } = location?.state ?? { referrer: defaultLocation }
    return <Redirect to={referrer} />
  }

  const signUpLink = (
    <VisiusLink to='/signup'>
      {t(I18N.signIn.LINK_SIGN_UP)}
    </VisiusLink>
  )

  return (
    <AuthTemplate title={t(I18N.signIn.TITLE_SIGN_IN)}>
      <Form
        onSubmit={handleSubmit}
        validate={handleValidation}
      >
        <Typography variant='body2' className={classes.formIntro}>
          {t(I18N.signIn.MSG_NO_ACCOUNT)} {signUpLink}
        </Typography>
        <TextField
          required
          label='Email'
          name='email'
          autoComplete='email'
          inputProps={{ type: 'email' }}
          autoFocus
          errors={loginError?.email}
          onChange={handleInput}
        />
        <TextField
          required
          name='password'
          label={t(I18N.signIn.LABEL_PASSWORD)}
          type='password'
          autoComplete='current-password'
          errors={loginError?.password}
          onChange={handleInput}
        />
        <div className={classes.formLink}>
          <VisiusLink
            to='/reset-password'
            color='inherit'
          >
            {t(I18N.passwordReset.LINK_RESET)}
          </VisiusLink>
        </div>
        <div className={classes.formFooterActions}>
          <Button
            type='submit'
            className={classes.loginBtn}
            variant='contained'
            color='primary'
            disabled={isLoginPending}
          >
            {t(I18N.signIn.BTN_LOGIN)}
          </Button>
        </div>
      </Form>
    </AuthTemplate>
  )
}
