import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, Typography } from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import { bytes2str } from "../../../app/utils";
import { LocationData } from "../data-types";

interface LocationSummaryProps {
  data: LocationData;
}

const useStyles = makeStyles((theme: Theme) => ({
  locationSummary: {
    marginTop: "",
    color: theme.palette.text.secondary,
  },
}));

export default function LocationSummary(
  props: LocationSummaryProps
): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data } = props;

  return (
    <>
      <Typography className={classes.locationSummary} variant="caption">
        {data.videos > 0
          ? t(I18N.main.MSG_VIDEO_FILES_NUMBER, { count: data.videos })
          : data.streams > 0
          ? t(I18N.main.MSG_VIDEO_STREAM)
          : t(I18N.main.MSG_VIDEO_NONE)}
      </Typography>

      {data.streams === 0 && (
        <Typography className={classes.locationSummary} variant="caption">
          {bytes2str(data.videoFilesSize ? data.videoFilesSize : 0)}
        </Typography>
      )}
    </>
  );
}
