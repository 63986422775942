import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH_URL } from "../../app/api-url";
import { ERROR_CODE, getErrorPayload } from "../../app/errors";
import I18N from "../../app/i18n/strings";
import { RootState } from "../../app/store";
import { HTTP_METHOD, request } from "../../app/utils";

export enum PROFILE_CHANGE_STATE {
  NONE,
  STARTED,
  FAILED,
  SAVED,
}

export interface FormError {
  name?: string[];
  email?: string[];
  phoneNumber?: string[];
  companyName?: string[];
  password?: string[];
  newPassword?: string[];
  confirmation?: string[];
}

export interface ProfileChangeState {
  state: PROFILE_CHANGE_STATE;
  error?: FormError;
}

export const initialState: ProfileChangeState = {
  state: PROFILE_CHANGE_STATE.NONE,
};

export interface ProfileUpdate {
  name?: string;
  companyName?: string;
  phoneNumber?: string;
  currentPassword?: string;
  newPassword?: string;
  [propName: string]: string | undefined;
}

export const editProfile = createAsyncThunk(
  "profile/edit",
  async (profileUpdate: ProfileUpdate) => {
    try {
      await request(
        profileUpdate.newPassword !== undefined
          ? AUTH_URL.CHANGE_PASSWORD
          : AUTH_URL.EDIT_ACCOUNT,
        {
          method: HTTP_METHOD.PUT,
          data: profileUpdate,
        }
      );
    } catch (err) {
      const errorPayload = getErrorPayload(err);
      throw errorPayload;
    }
  }
);

const slice = createSlice({
  name: "profileView",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProfile.pending, (state) => {
        state.state = PROFILE_CHANGE_STATE.STARTED;
      })
      .addCase(editProfile.fulfilled, (state) => {
        state.state = PROFILE_CHANGE_STATE.SAVED;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.state = PROFILE_CHANGE_STATE.FAILED;
        switch (action.error.code) {
          case ERROR_CODE.PROFILE_INCORRECT_PASSWORD: {
            state.error = {
              password: [I18N.profile.ERROR_INCORRECT_PASSWORD],
            };
          }
        }
      });
  },
});

export const { actions, reducer } = slice;

export const selectIsConfirmed = (state: RootState): boolean => {
  return state.auth.user.emailConfirmed;
};

export const selectStarted = ({ profileView }: RootState): boolean => {
  return profileView.state === PROFILE_CHANGE_STATE.STARTED;
};

export const selectError = ({
  profileView,
}: RootState): FormError | undefined => {
  if (profileView.state !== PROFILE_CHANGE_STATE.FAILED) {
    return;
  }

  return profileView.error;
};
