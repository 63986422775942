import { makeStyles, Theme } from '@material-ui/core'
import { COLORS } from '../../../theme'

export default makeStyles<Theme>((theme: Theme) => ({
  locationsList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2, 2)
  },
  locationsContainer: {
    padding: theme.spacing(0, 0, 0, 4)
  },
  accordion: {
    background: 'transparent',
    border: 'none',
    padding: 0,
    marginTop: theme.spacing(1),
    '&:first-child': {
      marginTop: theme.spacing(0)
    },
    '&::before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0, 0)
    }
  },
  accordionTitle: {
    width: 'max-content',
    padding: 0,
    marginBottom: theme.spacing(2.5),

    '& .MuiTypography-root': {
      marginRight: theme.spacing(1),
    },

    '&.Mui-focused': {
      background: 'transparent'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&.MuiButtonBase-root': {
      justifyContent: 'left'
    },
    '& .MuiAccordionSummary-content': {
      flexGrow: 0,
      margin: 0,
      alignItems: 'center'
    },
    '&.Mui-expanded': {
      minHeight: 0
    },
    '& .Mui-expanded': {
      margin: 0,

      '&:active': {
        fill: theme.palette.primary.main,
      },

      '& svg path': {
        fill: theme.palette.primary.main
      }
    }
  },
  textIsGrey: {
    color: COLORS.GREY_NEUTRAL
  },
  locationIcon: {
    display: 'flex',
    width: '2rem',
    height: '1.5rem',
    alignItems: 'center',
    alignContent: 'flex-start',
    
    '& svg path': {
      fill: COLORS.GREY_NEUTRAL
    }
  },
  editModeClickHandler: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1
  },
  textField: {
    position: 'relative',
    zIndex: 2,
    '& .MuiInputBase-root': {
      borderRadius: 0
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(0, 1)
    }
  },
  dialog: {
    borderRadius: theme.spacing(1.5),

    "& .MuiDialogActions-root": {
      padding: theme.spacing(1, 3, 2),
    },
  },
}))
