import React, { ReactElement } from "react";

import { RawResult } from "./RawResult";
import ResultGroupChart from "./ResultAggregation";
import { AggregationResult } from "../chart-types";

import { ChartProps } from "../../../components/charts/Chart";

const avg = (...args: number[]): number => {
  return args.reduce((res, arg) => res + arg) / args.length;
};

export default function AverageNumberChart(
  props: ChartProps<RawResult>
): ReactElement {
  return (
    <ResultGroupChart
      {...props}
      aggregator={(key, values): AggregationResult => ({
        key,
        men: avg(...values.map(({ men }) => men)),
        cars: avg(...values.map(({ cars }) => cars)),
        bikes: avg(...values.map(({ bikes }) => bikes)),
        oldMen: avg(...values.map(({ oldMen }) => oldMen)),
        middleMen: avg(...values.map(({ middleMen }) => middleMen)),
        youngMen: avg(...values.map(({ youngMen }) => youngMen)),
        unknownMen: avg(...values.map(({ unknownMen }) => unknownMen)),
        oldWomen: avg(...values.map(({ oldWomen }) => oldWomen)),
        middleWomen: avg(...values.map(({ middleWomen }) => middleWomen)),
        youngWomen: avg(...values.map(({ youngWomen }) => youngWomen)),
        unknownWomen: avg(...values.map(({ unknownWomen }) => unknownWomen)),
        oldUnknown: avg(...values.map(({ oldUnknown }) => oldUnknown)),
        middleUnknown: avg(...values.map(({ middleUnknown }) => middleUnknown)),
        youngUnknown: avg(...values.map(({ youngUnknown }) => youngUnknown)),
        unknownUnknown: avg(
          ...values.map(({ unknownUnknown }) => unknownUnknown)
        ),
      })}
    />
  );
}
