import {
  Action,
  configureStore,
  EnhancedStore,
  Middleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import thunk from "redux-thunk";
import { reducer as authReducer } from "../features/auth/authSlice";
import { reducer as confirmEmailReducer } from "../features/confirm-email/confirmEmailSlice";
import { reducer as archiveReducer } from "../features/groups/archive/archiveSlice";
import { reducer as videoDataReducer } from "../features/groups/main/videoSlice";
import { reducer as trashReducer } from "../features/groups/trash/trashSlice";
import { reducer as locationsReducer } from "../features/location/locationSlice";
import { reducer as notificationsReducer } from "../features/notifications/notificationsSlice";
import { reducer as resetPasswordReducer } from "../features/password-reset/passwordResetSlice";
import { reducer as profileViewReducer } from "../features/profile/profileSlice";
import { reducer as signUpReducer } from "../features/signup/signUpSlice";
import { reducer as tariffReducer } from "../features/profile/tariffs/tariffSlice";
import { reducer as dashBoardReducer } from "../features/dashboard/dashboardSlice";

import logger from "./logger";

enableMapSet();

const middlewares: Middleware[] = [thunk];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export const createStore = (): EnhancedStore => {
  return configureStore({
    middleware: middlewares,
    reducer: {
      archive: archiveReducer,
      auth: authReducer,
      confirmEmail: confirmEmailReducer,
      location: locationsReducer,
      notifications: notificationsReducer,
      passwordReset: resetPasswordReducer,
      profileView: profileViewReducer,
      signUp: signUpReducer,
      tariff: tariffReducer,
      trash: trashReducer,
      videoData: videoDataReducer,
      dashboard: dashBoardReducer,
    },
  });
};

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
