import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HTTP_METHOD, request } from '../../../app/utils'
import { BILLING_URL } from '../../../app/api-url'
import { getErrorPayload } from '../../../app/errors'
import { RootState } from '../../../app/store'
import { currentTariffDtoTariffData, tariffDtoTariffData } from '../../groups/data-mapper'
import { INVOICE_FORM_STATE, TariffData, TariffsState, TARIFF_STATE, UserTariffData } from './tariffHelper'
import { UserTariffDto, TariffDto, InvoiceFormDto } from './tariffTypes'

const getCurrentTariff = async (billingUrl: string, id: string): Promise<UserTariffDto> => {
  const innerUrl = BILLING_URL.USERS.replace('{id}', id)
  const url = `${billingUrl}${innerUrl}`
  const data = await request(url, { isCorsRequest: true })
  return data as UserTariffDto
}

const getTariffs = async (billingUrl: string): Promise<TariffDto[]> => {
  const url = `${billingUrl}${BILLING_URL.TARIFFS}`
  const data = await request(url, { isCorsRequest: true })
  return data as TariffDto[]
}

/**
 * Get billing server url
 */
export const loadBillingUrl = createAsyncThunk<string | undefined>(
  'billingApi/url',
  async () => {
    const data = await request(BILLING_URL.SERVER_URL)
    return data as string
  }
)

/**
 * Get current user tariff by userId
 * @param {string} userId - userId
 */
export const loadCurrentTariff = createAsyncThunk<UserTariffData | undefined, string>(
  'billingApi/users',
  async (userId: string, { getState }) => {
    const rootState = getState() as RootState
    if (rootState.tariff.billingUrl === undefined) return undefined

    const currentTariff = await getCurrentTariff(rootState.tariff.billingUrl, userId)
    return currentTariffDtoTariffData(currentTariff)
  }
)

/**
 * Get tariffs list
 */
export const loadTariffs = createAsyncThunk<TariffDto[]>(
  'billingApi/tariffs',
  async (_, { getState }) => {
    const rootState = getState() as RootState
    if (rootState.tariff.billingUrl === undefined) return []

    const tariffs = await getTariffs(rootState.tariff.billingUrl)
    return tariffs.map(t => tariffDtoTariffData(t))
  }
)

/**
 * Invoice payment request
 */
export const createInvoicePayment = createAsyncThunk(
  'billingApi/invoicePayment',
  async (paymentData: InvoiceFormDto, { getState }) => {
    try {
      const rootState = getState() as RootState
      if (rootState.tariff.billingUrl === undefined) throw new Error('No billingUrl')

      const billingUrl = rootState.tariff.billingUrl as string
      const url = `${billingUrl}${BILLING_URL.INVOICE_PAYMENT}`
      await request(url, {
        method: HTTP_METHOD.POST,
        isCorsRequest: true,
        data: paymentData
      })
    } catch (err) {
      const errorPayload = getErrorPayload(err)
      throw errorPayload
    }
  }
)

export const initialState: TariffsState = {
  billingUrl: undefined,
  tariffState: TARIFF_STATE.NONE,
  tariffListState: TARIFF_STATE.NONE,
  invoiceFormState: INVOICE_FORM_STATE.NONE
}

const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    reset () { return initialState },
    setSelectedTariff (state: TariffsState, action: PayloadAction<TariffData | undefined>) {
      state.selectedTariff = action.payload
    },
    clearInvoiceFormState (state: TariffsState) {
      state.invoiceFormState = INVOICE_FORM_STATE.NONE
    },
    clearError (state: TariffsState) {
      delete state.error
      state.tariffState = TARIFF_STATE.NONE
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadBillingUrl.pending, (state, action) => {
        state.tariffState = TARIFF_STATE.NONE
        state.tariffListState = TARIFF_STATE.NONE
        state.invoiceFormState = INVOICE_FORM_STATE.NONE
      })
      .addCase(loadBillingUrl.fulfilled, (state, action) => {
        if (state.billingUrl !== action.payload) {
          state.billingUrl = action.payload
        }
      })
      .addCase(loadBillingUrl.rejected, (state, action) => {
        state.billingUrl = undefined
        console.log({ state, action })
      })

      .addCase(loadCurrentTariff.pending, (state, action) => {
        state.tariffState = TARIFF_STATE.LOADING_DATA
      })
      .addCase(loadCurrentTariff.fulfilled, (state, action) => {
        state.tariffState = TARIFF_STATE.DATA_LOADED
        state.currentTariff = action.payload
      })
      .addCase(loadCurrentTariff.rejected, (state, action) => {
        state.tariffState = TARIFF_STATE.ERROR
        console.log({ state, action })
      })

      .addCase(loadTariffs.pending, (state, action) => {
        state.tariffListState = TARIFF_STATE.LOADING_DATA
      })
      .addCase(loadTariffs.fulfilled, (state, action) => {
        state.tariffListState = TARIFF_STATE.DATA_LOADED
        state.tariffs = action.payload
      })
      .addCase(loadTariffs.rejected, (state, action) => {
        state.tariffListState = TARIFF_STATE.ERROR
        console.log({ state, action })
      })
      .addCase(createInvoicePayment.pending, (state, action) => {
        state.invoiceFormState = INVOICE_FORM_STATE.STARTED
      })
      .addCase(createInvoicePayment.fulfilled, (state, action) => {
        state.invoiceFormState = INVOICE_FORM_STATE.SAVED
      })
      .addCase(createInvoicePayment.rejected, (state, action) => {
        state.invoiceFormState = INVOICE_FORM_STATE.FAILED
        console.log({ state, action })
      })
  }
})

export const {
  actions,
  reducer
} = tariffSlice

// Selectors
export const selectBillingUrl = (state: RootState): string|undefined => {
  return state.tariff.billingUrl
}
export const selectUserTariff = (state: RootState): UserTariffData|undefined => {
  return state.tariff.currentTariff
}
export const selectTariffs = (state: RootState): TariffDto[]|undefined => {
  return state.tariff.tariffs
}
export const selectIsTariffsLoading = (state: RootState): boolean => {
  return state.tariff.tariffListState === TARIFF_STATE.LOADING_DATA
}
export const selectIsCurrentTariffError = (state: RootState): boolean => {
  return state.tariff.tariffState === TARIFF_STATE.ERROR
}
export const selectIsLoadingError = (state: RootState): boolean => {
  return state.tariff.tariffListState === TARIFF_STATE.ERROR ||
    state.tariff.invoiceFormState === INVOICE_FORM_STATE.FAILED
}
export const getSelectedTariff = (state: RootState): TariffData|undefined => {
  return state.tariff.selectedTariff
}

export const selectInvoiceStarted = (state: RootState): boolean => state.tariff.invoiceFormState === INVOICE_FORM_STATE.STARTED
export const selectInvoiceSuccessed = (state: RootState): boolean => state.tariff.invoiceFormState === INVOICE_FORM_STATE.SAVED
