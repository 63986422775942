import React from "react";
import { useSelector } from "react-redux";
import { ScaleOrdinal } from "d3-scale";

import {
  selectIsMenMode,
  selectIsMenUniteMode,
  selectPeopleMode,
} from "../dashboardSlice";
import StatsCard from "./StatsCard";
import moment from "moment";
import { ResultKeys } from "../../../components/charts/bar/types";
import { RawResult } from "../charts/RawResult";
import { getDetailData } from "../constants";
import { useStyles } from "../styles";
import { DateRange } from "../../../components/DateRangePicker";

import { ReactComponent as WalkerIcon } from "../../../app/assets/walkerIcon.svg";
import { ReactComponent as CarIcon } from "../../../app/assets/carIcon.svg";
import { ReactComponent as BicycleIcon } from "../../../app/assets/bicycleIcon.svg";
import { ReactComponent as MenIcon } from "../../../app/assets/menIcon.svg";
import { ReactComponent as WomenIcon } from "../../../app/assets/womenIcon.svg";
import { ReactComponent as UnknownIcon } from "../../../app/assets/unknownIcon.svg";

interface IProps {
  resultKeysURL: Array<ResultKeys<RawResult>>;
  totalMen: number;
  totalCars: number;
  totalBikes: number;
  totalCount: number;
  totalObjects: number;
  totalObjectCount: number;
  totalUniqCount: number;
  totalRepeatingObjectCount: number;
  totals: RawResult;
  dateRange: DateRange | undefined;
  colorScale: ScaleOrdinal<ResultKeys<RawResult>, string, never>;
}

export default function StatsContainer(props: IProps) {
  const {
    resultKeysURL,
    totals,
    totalCount,
    totalMen,
    totalCars,
    totalBikes,
    totalObjects,
    totalObjectCount,
    totalUniqCount,
    totalRepeatingObjectCount,
    dateRange,
    colorScale,
  } = props;
  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const peopleMode = useSelector(selectPeopleMode);
  const dashboardClasses = useStyles({ isMenMode });

  const totalDetailMen =
    totals.oldMenObject +
    totals.middleMenObject +
    totals.youngMenObject +
    totals.unknownMenObject;

  const totalDetailMenUniq =
    totals.oldMenUniq +
    totals.middleMenUniq +
    totals.youngMenUniq +
    totals.unknownMenUniq;

  const totalDetailMenRepeatingObject =
    totals.oldMenRepeatingObject +
    totals.middleMenRepeatingObject +
    totals.youngMenRepeatingObject +
    totals.unknownMenRepeatingObject;

  const totalDetailWomen =
    totals.oldWomenObject +
    totals.middleWomenObject +
    totals.youngWomenObject +
    totals.unknownWomenObject;

  const totalDetailWomenUniq =
    totals.oldWomenUniq +
    totals.middleWomenUniq +
    totals.youngWomenUniq +
    totals.unknownWomenUniq;

  const totalDetailWomenRepeatingObject =
    totals.oldWomenRepeatingObject +
    totals.middleWomenRepeatingObject +
    totals.youngWomenRepeatingObject +
    totals.unknownWomenRepeatingObject;

  const totalDetailUnknown =
    totals.oldUnknownObject +
    totals.middleUnknownObject +
    totals.youngUnknownObject +
    totals.unknownUnknownObject;

  const totalDetailUnknownUniq =
    totals.oldUnknownUniq +
    totals.middleUnknownUniq +
    totals.youngUnknownUniq +
    totals.unknownUnknownUniq;

  const totalDetailUnknownRepeatingObject =
    totals.oldUnknownRepeatingObject +
    totals.middleUnknownRepeatingObject +
    totals.youngUnknownRepeatingObject +
    totals.unknownUnknownRepeatingObject;

  const detailMensData = {
    ...getDetailData("Men", totals, peopleMode),
  };

  const detailWomenData = {
    ...getDetailData("Women", totals, peopleMode),
  };
  const detailUnknownData = {
    ...getDetailData("Unknown", totals, peopleMode),
  };

  return (
    <>
      <div
        className={`${dashboardClasses.card}`}
        style={{
          width: "296px",
          maxWidth: "296px",
        }}
      >
        <StatsCard
          number={isMenMode ? totalCount : totalObjects}
          title={
            isMenMode ? (
              <div>
                Появлений с&nbsp;
                <span style={{ color: "#2C67FF" }}>
                  {moment(dateRange?.start).format("DD.MM.YYYY")}
                </span>
                &nbsp;по&nbsp;
                <span style={{ color: "#2C67FF" }}>
                  {moment(dateRange?.end).format("DD.MM.YYYY")}
                </span>
              </div>
            ) : (
              <div>
                Появлений всего распознано с&nbsp;
                <span style={{ color: "#2C67FF" }}>
                  {moment(dateRange?.start).format("DD.MM.YYYY")}
                </span>
                &nbsp;по&nbsp;
                <span style={{ color: "#2C67FF" }}>
                  {moment(dateRange?.end).format("DD.MM.YYYY")}
                </span>
              </div>
            )
          }
        />
      </div>
      {resultKeysURL.includes("men") && !isMenMode && (
        <div className={dashboardClasses.card}>
          <StatsCard
            number={totalMen}
            progress={totalMen / totalObjects}
            title="Пешеходов"
            color={colorScale("men")}
            iconComponent={WalkerIcon}
          />
        </div>
      )}
      {resultKeysURL.includes("men") && isMenMode && isMenUniteMode && (
        <div className={dashboardClasses.card}>
          <StatsCard
            number={
              peopleMode.common
                ? totalObjectCount
                : peopleMode.uniq
                ? totalUniqCount
                : totalRepeatingObjectCount
            }
            progress={totalObjects / totalObjects}
            title="Пешеходов"
            color={colorScale("men")}
            iconComponent={WalkerIcon}
          />
        </div>
      )}

      {resultKeysURL.includes("bikes") && (
        <div className={dashboardClasses.card}>
          <StatsCard
            number={totalBikes}
            progress={totalBikes / totalObjects}
            title="Велосипедистов"
            color={colorScale("bikes")}
            iconComponent={BicycleIcon}
          />
        </div>
      )}

      {resultKeysURL.includes("cars") && (
        <div className={dashboardClasses.card}>
          <StatsCard
            number={totalCars}
            progress={totalCars / totalObjects}
            title="Автомобилей"
            color={colorScale("cars")}
            iconComponent={CarIcon}
          />
        </div>
      )}

      {(resultKeysURL.includes("oldMen") ||
        resultKeysURL.includes("middleMen") ||
        resultKeysURL.includes("youngMen") ||
        resultKeysURL.includes("unknownMen")) &&
        isMenMode &&
        !isMenUniteMode && (
          <div className={dashboardClasses.card}>
            <StatsCard
              isDetailStats={true}
              detailData={detailMensData}
              number={
                peopleMode.common
                  ? totalDetailMen
                  : peopleMode.uniq
                  ? totalDetailMenUniq
                  : totalDetailMenRepeatingObject
              }
              progress={totalDetailMen / totalObjects}
              title="Мужчины"
              iconComponent={MenIcon}
            />
          </div>
        )}
      {(resultKeysURL.includes("oldWomen") ||
        resultKeysURL.includes("middleWomen") ||
        resultKeysURL.includes("youngWomen") ||
        resultKeysURL.includes("unknownWomen")) &&
        isMenMode &&
        !isMenUniteMode && (
          <div className={dashboardClasses.card}>
            <StatsCard
              isDetailStats={true}
              detailData={detailWomenData}
              number={
                peopleMode.common
                  ? totalDetailWomen
                  : peopleMode.uniq
                  ? totalDetailWomenUniq
                  : totalDetailWomenRepeatingObject
              }
              progress={totalDetailWomen / totalObjects}
              title="Женщины"
              iconComponent={WomenIcon}
            />
          </div>
        )}
      {(resultKeysURL.includes("oldUnknown") ||
        resultKeysURL.includes("middleUnknown") ||
        resultKeysURL.includes("youngUnknown") ||
        resultKeysURL.includes("unknownUnknown")) &&
        isMenMode &&
        !isMenUniteMode && (
          <div className={dashboardClasses.card}>
            <StatsCard
              isDetailStats={true}
              detailData={detailUnknownData}
              number={
                peopleMode.common
                  ? totalDetailUnknown
                  : peopleMode.uniq
                  ? totalDetailUnknownUniq
                  : totalDetailUnknownRepeatingObject
              }
              progress={totalDetailUnknown / totalObjects}
              title="Не определено"
              iconComponent={UnknownIcon}
            />
          </div>
        )}
    </>
  );
}
