import FakeIndexedDB from "fake-indexeddb";
import FakeIDBKeyRange from "fake-indexeddb/lib/FDBKeyRange";
import { DateRange } from "../components/DateRangePicker";
import { RawResult } from "../features/dashboard/charts/RawResult";
import { ISODateString } from "../features/groups/data-types";
import { StreamId, VideoId } from "../features/location/data-mapper";

interface AnalyticsQuery {
  sourceIds: Array<StreamId | VideoId>;
  startDate?: Date;
  endDate?: Date;
}

interface DateRangeQuery {
  sourceIds: Array<StreamId | VideoId>;
}

interface IndexedDBShim {
  indexedDB: IDBFactory;
  IDBKeyRange: IDBKeyRange;
}

interface QueryGetterOptions {
  db: IDBDatabase;
  sourceId: StreamId | VideoId;
  startDate?: Date;
  endDate?: Date;
}

type AnalyticsPayload = Map<
  VideoId | StreamId,
  Map<ISODateString, Partial<RawResult>>
>;

const ANALYTICS_STORE_NAME = "analytics";
const DB_NAME = "visius-data";
const DB_VERSION = 1;
// JS allows dates in a range <Jan 1, 1970 - 100 000 000 days> ... <Jan 1, 1970 + 100 000 000 days>
const MIN_JS_DATE: Date = new Date(-0.1e9 * 1000 * 60 * 60 * 24);
const MAX_JS_DATE: Date = new Date(0.1e9 * 1000 * 60 * 60 * 24);

// eslint-disable-next-line no-restricted-globals
let indexedDB = self.indexedDB;
// eslint-disable-next-line no-restricted-globals
let IDBKeyRange = self.IDBKeyRange;
let dbInstance: IDBDatabase | undefined;

/**
 * Creates analytics data store within the given IndexedDB database
 * @param {IDBDatabase} db - IDBDatabase instance
 * @returns {void}
 */
async function _createResultsStore(db: IDBDatabase): Promise<void> {
  const store = db.createObjectStore(ANALYTICS_STORE_NAME, {
    keyPath: ["sourceId", "time"],
  });
  store.createIndex("query", ["sourceId", "time"], { unique: true });
  store.createIndex("sourceId", "sourceId");

  return await new Promise((resolve, reject) => {
    store.transaction.oncomplete = () => resolve();
    store.transaction.onerror = () => reject(store.transaction.error.message);
  });
}

/**
 * Deletes indexedDB database for the analytics data
 * @returns {Promise<void>}
 */
export async function deleteDatabase(): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);
    request.onerror = () => {
      reject(new Error(request.error?.message));
    };
    request.onsuccess = () => resolve();
    request.onblocked = (evt: Event) => {
      console.log(evt);
    };
  });
}

/**
 * Opens IndexedDB database for the analytics data, creates required stores
 * @param {Promise<IndexedDBShim>} shim - indexedDB shim description
 */
async function openDb(shim?: IndexedDBShim): Promise<IDBDatabase> {
  if (shim !== undefined) {
    // eslint-disable-next-line no-native-reassign, no-global-assign
    indexedDB = shim.indexedDB;
    // @ts-expect-error we're going to replace native IDBKeyRange with a fake one
    // eslint-disable-next-line no-native-reassign, no-global-assign
    IDBKeyRange = shim.IDBKeyRange;
  }

  return await new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = () => {
      reject(new Error(request.error?.message));
    };
    request.onsuccess = () => {
      const db = request.result;
      // Register event listener for the 'versionchange' event
      // in order to be able to close existing DB connections when DB is updated/deleted
      db.onversionchange = () => {
        db.close();
        dbInstance = undefined;
      };
      resolve(db);
    };
    request.onupgradeneeded = async function (evt: IDBVersionChangeEvent) {
      try {
        const db = request.result;
        db.onversionchange = () => {
          db.close();
          dbInstance = undefined;
        };
        const upgradePromises = [];

        // initialize DB if not exists with a single 'results' store
        if (evt.oldVersion === 0) {
          upgradePromises.push(_createResultsStore(db));
        }

        // new stores might be added here in future DB versions:
        // if (evt.oldVersion...) {
        //     ...
        // }

        if (upgradePromises.length > 0) {
          await Promise.all(upgradePromises);
        }

        resolve(db);
      } catch (err) {
        reject(err);
      }
    };
  });
}

/**
 * Returns IndexedDB-API compatible DB instance
 * @returns {Promise<IDBDatabase>} - indexedDB instance
 */
export async function getDb(): Promise<IDBDatabase> {
  if (dbInstance === undefined) {
    try {
      dbInstance = await openDb();
    } catch (err) {
      dbInstance = await openDb({
        indexedDB: FakeIndexedDB,
        IDBKeyRange: FakeIDBKeyRange,
      });
    }
  }

  return dbInstance;
}

/**
 * Returns date range of the stored analytics data
 * @param {Array<VideoId|StreamId>} sourceIds - list of video/stream IDs to query
 * @returns {Promise<DateRange|undefined>} - dataset date range
 */
export async function getSourceDateRange({
  sourceIds,
}: DateRangeQuery): Promise<DateRange | undefined> {
  const db = await getDb();
  const promises: Array<Promise<Date | undefined>> = [];
  const transaction = db.transaction([ANALYTICS_STORE_NAME], "readonly");
  const index = transaction.objectStore(ANALYTICS_STORE_NAME).index("query");

  for (const sourceId of sourceIds) {
    const keyRange = IDBKeyRange.bound(
      [sourceId, MIN_JS_DATE],
      [sourceId, MAX_JS_DATE]
    );

    for (const direction of ["prev", "next"]) {
      promises.push(
        new Promise<Date | undefined>((resolve, reject) => {
          const request = index.openCursor(
            keyRange,
            direction as "prev" | "next"
          );
          request.onerror = function () {
            reject(new Error(request.error?.message));
          };
          request.onsuccess = function () {
            const cursor = request.result;
            if (cursor === null) {
              return resolve(undefined);
            }

            resolve(cursor.value.time as Date);
          };
        })
      );
    }
  }

  const res = await Promise.all(promises);
  const dates = res
    .filter((date) => date !== undefined)
    .sort((a, b) => (a as Date).getTime() - (b as Date).getTime()) as Date[];

  return dates.length > 0
    ? {
        start: dates[0],
        end: dates[dates.length - 1],
      }
    : undefined;
}

/**
 * Returns DB cursor to query analytics dataset for given Sources and date range
 * @param {IDBDatabase} db - db instance
 * @param {VideoId|StreamId} sourceId - source media ID
 * @param {Date|undefined} startDate - date range start
 * @param {Date|undefined} endDate - date range end
 * @returns {IDBRequest<IDBCursorWithValue|null>} - cursor request
 */
function getQueryCursor({
  db,
  sourceId,
  startDate,
  endDate,
}: QueryGetterOptions): IDBRequest<IDBCursorWithValue | null> {
  const transaction = db.transaction([ANALYTICS_STORE_NAME], "readonly");
  if (startDate === undefined || endDate === undefined) {
    const queryCursor = IDBKeyRange.only(sourceId);
    return transaction
      .objectStore(ANALYTICS_STORE_NAME)
      .index("sourceId")
      .openCursor(queryCursor);
  }

  const upperBound = new Date(endDate);
  // TODO: add support for Timezones
  upperBound.setHours(23, 59, 59);
  const queryCursor = IDBKeyRange.bound(
    [sourceId, startDate],
    [sourceId, upperBound]
  );
  return transaction
    .objectStore(ANALYTICS_STORE_NAME)
    .index("query")
    .openCursor(queryCursor);
}

/**
 * Returns analytics data for the given Sources within the given date range
 * @param {Array<VideoId|StreamId>} sourceIds - list of video/stream IDs to query
 * @param {Date|undefined} startDate - start date
 * @param {Date|undefined} endDate - end date
 *
 * @returns {Promise<RawResult[]>} - analytics dataset
 */
export async function queryAnalytics({
  sourceIds,
  startDate,
  endDate,
}: AnalyticsQuery): Promise<RawResult[]> {
  const db = await getDb();
  const viewData: Map<Date, RawResult> = new Map();
  const promises: Array<Promise<void>> = [];
  for (const sourceId of sourceIds) {
    promises.push(
      new Promise<void>((resolve, reject) => {
        const request = getQueryCursor({ db, sourceId, startDate, endDate });
        request.onerror = function () {
          reject(new Error(request.error?.message));
        };
        request.onsuccess = function () {
          const cursor = request.result;
          if (cursor === null) {
            return resolve();
          }

          if (viewData.has(cursor.value.time)) {
            const {
              men,
              cars,
              bikes,

              oldMen,
              middleMen,
              youngMen,
              unknownMen,
              oldWomen,
              middleWomen,
              youngWomen,
              unknownWomen,
              oldUnknown,
              middleUnknown,
              youngUnknown,
              unknownUnknown,

              oldMenObject,
              middleMenObject,
              youngMenObject,
              unknownMenObject,
              oldWomenObject,
              middleWomenObject,
              youngWomenObject,
              unknownWomenObject,
              oldUnknownObject,
              middleUnknownObject,
              youngUnknownObject,
              unknownUnknownObject,

              oldMenUniq,
              middleMenUniq,
              youngMenUniq,
              unknownMenUniq,
              oldWomenUniq,
              middleWomenUniq,
              youngWomenUniq,
              unknownWomenUniq,
              oldUnknownUniq,
              middleUnknownUniq,
              youngUnknownUniq,
              unknownUnknownUniq,

              oldMenRepeating,
              middleMenRepeating,
              youngMenRepeating,
              unknownMenRepeating,
              oldWomenRepeating,
              middleWomenRepeating,
              youngWomenRepeating,
              unknownWomenRepeating,
              oldUnknownRepeating,
              middleUnknownRepeating,
              youngUnknownRepeating,
              unknownUnknownRepeating,

              oldMenRepeatingObject,
              middleMenRepeatingObject,
              youngMenRepeatingObject,
              unknownMenRepeatingObject,
              oldWomenRepeatingObject,
              middleWomenRepeatingObject,
              youngWomenRepeatingObject,
              unknownWomenRepeatingObject,
              oldUnknownRepeatingObject,
              middleUnknownRepeatingObject,
              youngUnknownRepeatingObject,
              unknownUnknownRepeatingObject,
            } = viewData.get(cursor.value.time) as RawResult;
            viewData.set(cursor.value.time, {
              time: cursor.value.time,
              cars: Math.max(cars, cursor.value.cars),
              bikes: Math.max(bikes, cursor.value.bikes),
              men: Math.max(men, cursor.value.men),

              oldMen: Math.max(oldMen, cursor.value.oldMen),
              middleMen: Math.max(middleMen, cursor.value.middleMen),
              youngMen: Math.max(youngMen, cursor.value.youngMen),
              unknownMen: Math.max(unknownMen, cursor.value.unknownMen),
              oldWomen: Math.max(oldWomen, cursor.value.oldWomen),
              middleWomen: Math.max(middleWomen, cursor.value.middleWomen),
              youngWomen: Math.max(youngWomen, cursor.value.youngWomen),
              unknownWomen: Math.max(unknownWomen, cursor.value.unknownWomen),
              oldUnknown: Math.max(oldUnknown, cursor.value.oldUnknown),
              middleUnknown: Math.max(
                middleUnknown,
                cursor.value.middleUnknown
              ),
              youngUnknown: Math.max(youngUnknown, cursor.value.youngUnknown),
              unknownUnknown: Math.max(
                unknownUnknown,
                cursor.value.unknownUnknown
              ),

              oldMenObject: Math.max(oldMenObject, cursor.value.oldMenObject),
              middleMenObject: Math.max(
                middleMenObject,
                cursor.value.middleMenObject
              ),
              youngMenObject: Math.max(youngMenObject, cursor.value.youngMenObject),
              unknownMenObject: Math.max(
                unknownMenObject,
                cursor.value.unknownMenObject
              ),
              oldWomenObject: Math.max(oldWomenObject, cursor.value.oldWomenObject),
              middleWomenObject: Math.max(
                middleWomenObject,
                cursor.value.middleWomenObject
              ),
              youngWomenObject: Math.max(
                youngWomenObject,
                cursor.value.youngWomenObject
              ),
              unknownWomenObject: Math.max(
                unknownWomenObject,
                cursor.value.unknownWomenObject
              ),
              oldUnknownObject: Math.max(
                oldUnknownObject,
                cursor.value.oldUnknownObject
              ),
              middleUnknownObject: Math.max(
                middleUnknownObject,
                cursor.value.middleUnknownObject
              ),
              youngUnknownObject: Math.max(
                youngUnknownObject,
                cursor.value.youngUnknownObject
              ),
              unknownUnknownObject: Math.max(
                unknownUnknownObject,
                cursor.value.unknownUnknownObject
              ),

              oldMenUniq: Math.max(oldMenUniq, cursor.value.oldMenUniq),
              middleMenUniq: Math.max(
                middleMenUniq,
                cursor.value.middleMenUniq
              ),
              youngMenUniq: Math.max(youngMenUniq, cursor.value.youngMenUniq),
              unknownMenUniq: Math.max(
                unknownMenUniq,
                cursor.value.unknownMenUniq
              ),
              oldWomenUniq: Math.max(oldWomenUniq, cursor.value.oldWomenUniq),
              middleWomenUniq: Math.max(
                middleWomenUniq,
                cursor.value.middleWomenUniq
              ),
              youngWomenUniq: Math.max(
                youngWomenUniq,
                cursor.value.youngWomenUniq
              ),
              unknownWomenUniq: Math.max(
                unknownWomenUniq,
                cursor.value.unknownWomenUniq
              ),
              oldUnknownUniq: Math.max(
                oldUnknownUniq,
                cursor.value.oldUnknownUniq
              ),
              middleUnknownUniq: Math.max(
                middleUnknownUniq,
                cursor.value.middleUnknownUniq
              ),
              youngUnknownUniq: Math.max(
                youngUnknownUniq,
                cursor.value.youngUnknownUniq
              ),
              unknownUnknownUniq: Math.max(
                unknownUnknownUniq,
                cursor.value.unknownUnknownUniq
              ),

              oldMenRepeating: Math.max(
                oldMenRepeating,
                cursor.value.oldMenRepeating
              ),
              middleMenRepeating: Math.max(
                middleMenRepeating,
                cursor.value.middleMenRepeating
              ),
              youngMenRepeating: Math.max(
                youngMenRepeating,
                cursor.value.youngMenRepeating
              ),
              unknownMenRepeating: Math.max(
                unknownMenRepeating,
                cursor.value.unknownMenRepeating
              ),
              oldWomenRepeating: Math.max(
                oldWomenRepeating,
                cursor.value.oldWomenRepeating
              ),
              middleWomenRepeating: Math.max(
                middleWomenRepeating,
                cursor.value.middleWomenRepeating
              ),
              youngWomenRepeating: Math.max(
                youngWomenRepeating,
                cursor.value.youngWomenRepeating
              ),
              unknownWomenRepeating: Math.max(
                unknownWomenRepeating,
                cursor.value.unknownWomenRepeating
              ),
              oldUnknownRepeating: Math.max(
                oldUnknownRepeating,
                cursor.value.oldUnknownRepeating
              ),
              middleUnknownRepeating: Math.max(
                middleUnknownRepeating,
                cursor.value.middleUnknownRepeating
              ),
              youngUnknownRepeating: Math.max(
                youngUnknownRepeating,
                cursor.value.youngUnknownRepeating
              ),
              unknownUnknownRepeating: Math.max(
                unknownUnknownRepeating,
                cursor.value.unknownUnknownRepeating
              ),

              oldMenRepeatingObject: Math.max(
                oldMenRepeatingObject,
                cursor.value.oldMenRepeatingObject
              ),
              middleMenRepeatingObject: Math.max(
                middleMenRepeatingObject,
                cursor.value.middleMenRepeatingObject
              ),
              youngMenRepeatingObject: Math.max(
                youngMenRepeatingObject,
                cursor.value.youngMenRepeatingObject
              ),
              unknownMenRepeatingObject: Math.max(
                unknownMenRepeatingObject,
                cursor.value.unknownMenRepeatingObject
              ),
              oldWomenRepeatingObject: Math.max(
                oldWomenRepeatingObject,
                cursor.value.oldWomenRepeatingObject
              ),
              middleWomenRepeatingObject: Math.max(
                middleWomenRepeatingObject,
                cursor.value.middleWomenRepeatingObject
              ),
              youngWomenRepeatingObject: Math.max(
                youngWomenRepeatingObject,
                cursor.value.youngWomenRepeatingObject
              ),
              unknownWomenRepeatingObject: Math.max(
                unknownWomenRepeatingObject,
                cursor.value.unknownWomenRepeatingObject
              ),
              oldUnknownRepeatingObject: Math.max(
                oldUnknownRepeatingObject,
                cursor.value.oldUnknownRepeatingObject
              ),
              middleUnknownRepeatingObject: Math.max(
                middleUnknownRepeatingObject,
                cursor.value.middleUnknownRepeatingObject
              ),
              youngUnknownRepeatingObject: Math.max(
                youngUnknownRepeatingObject,
                cursor.value.youngUnknownRepeatingObject
              ),
              unknownUnknownRepeatingObject: Math.max(
                unknownUnknownRepeatingObject,
                cursor.value.unknownUnknownRepeatingObject
              ),
              ageJun: 0,
              ageMiddle: 0,
              ageSenior: 0,
              ageUnknown: 0,
              gMale: 0,
              gFemale: 0,
              gUnknown: 0,
            });
          } else {
            viewData.set(cursor.value.time, cursor.value);
          }
          cursor.continue();
        };
      })
    );
  }

  await Promise.all(promises);
  return Array.from(viewData.values());
}

/**
 * Saves analytics to DB
 *
 * @param {AnalyticsPayload} inputData - data to save
 * @returns {Promise<void>}
 */
export async function saveAnalytics(
  inputData: AnalyticsPayload
): Promise<void> {
  const db = await getDb();

  await new Promise<void>((resolve, reject) => {
    const transaction = db.transaction([ANALYTICS_STORE_NAME], "readwrite");
    const store = transaction.objectStore(ANALYTICS_STORE_NAME);
    for (const [sourceId, streamData] of inputData.entries()) {
      for (const [, analyticsEntry] of streamData) {
        const key = IDBKeyRange.only([sourceId, analyticsEntry.time as Date]);
        const query = store.get(key);
        query.onerror = () => reject(query.error);
        query.onsuccess = () => {
          const entryTemplate = query.result ?? {
            bikes: 0,
            cars: 0,
            men: 0,

            oldMen: 0,
            middleMen: 0,
            youngMen: 0,
            unknownMen: 0,
            oldWomen: 0,
            middleWomen: 0,
            youngWomen: 0,
            unknownWomen: 0,
            oldUnknown: 0,
            middleUnknown: 0,
            youngUnknown: 0,
            unknownUnknown: 0,

            oldMenObject: 0,
            middleMenObject: 0,
            youngMenObject: 0,
            unknownMenObject: 0,
            oldWomenObject: 0,
            middleWomenObject: 0,
            youngWomenObject: 0,
            unknownWomenObject: 0,
            oldUnknownObject: 0,
            middleUnknownObject: 0,
            youngUnknownObject: 0,
            unknownUnknownObject: 0,

            oldMenUniq: 0,
            middleMenUniq: 0,
            youngMenUniq: 0,
            unknownMenUniq: 0,
            oldWomenUniq: 0,
            middleWomenUniq: 0,
            youngWomenUniq: 0,
            unknownWomenUniq: 0,
            oldUnknownUniq: 0,
            middleUnknownUniq: 0,
            youngUnknownUniq: 0,
            unknownUnknownUniq: 0,

            oldMenRepeating: 0,
            middleMenRepeating: 0,
            youngMenRepeating: 0,
            unknownMenRepeating: 0,
            oldWomenRepeating: 0,
            middleWomenRepeating: 0,
            youngWomenRepeating: 0,
            unknownWomenRepeating: 0,
            oldUnknownRepeating: 0,
            middleUnknownRepeating: 0,
            youngUnknownRepeating: 0,
            unknownUnknownRepeating: 0,

            oldMenRepeatingObject: 0,
            middleMenRepeatingObject: 0,
            youngMenRepeatingObject: 0,
            unknownMenRepeatingObject: 0,
            oldWomenRepeatingObject: 0,
            middleWomenRepeatingObject: 0,
            youngWomenRepeatingObject: 0,
            unknownWomenRepeatingObject: 0,
            oldUnknownRepeatingObject: 0,
            middleUnknownRepeatingObject: 0,
            youngUnknownRepeatingObject: 0,
            unknownUnknownRepeatingObject: 0,

            ageJun: 0,
            ageMiddle: 0,
            ageSenior: 0,
            ageUnknown: 0,
            gMale: 0,
            gFemale: 0,
            gUnknown: 0,
          };
          store.put({ ...entryTemplate, ...analyticsEntry, sourceId });
        };
      }
    }
    transaction.onerror = () => reject(transaction.error);
    transaction.oncomplete = () => resolve();
  });
}

/**
 * Deletes analytics for the given source from the DB
 *
 * @param {Array<StreamId|VideoId>} sourceIds - source IDs to clear datasets
 * @returns {Promise<void>}
 */
export async function deleteAnalytics(
  sourceIds: Array<StreamId | VideoId>
): Promise<void> {
  const db = await getDb();

  const promises = [];
  const transaction = db.transaction([ANALYTICS_STORE_NAME], "readwrite");
  const store = transaction.objectStore(ANALYTICS_STORE_NAME);

  for (const sourceId of sourceIds) {
    const keyRange = IDBKeyRange.bound(
      [sourceId, MIN_JS_DATE],
      [sourceId, MAX_JS_DATE]
    );

    promises.push(
      new Promise<void>((resolve, reject) => {
        const request = store.delete(keyRange);
        request.onerror = () => reject(new Error(request.error?.message));
        request.onsuccess = () => resolve();
      })
    );
  }
  await Promise.all(promises);
}
