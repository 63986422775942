import React, {
  MouseEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { makeStyles, Theme } from "@material-ui/core";
import { Drag } from "@visx/drag";
import { localPoint } from "@visx/event";
import { MarkerCircle } from "@visx/marker";
import { LinePath } from "@visx/shape";

import clsx from "clsx";
import ProgressIndicator from "../../../../components/ProgressIndicator";

interface Point {
  x: number;
  y: number;
}
export type Line = Point[];

export interface LineDrawingProps {
  width: number;
  imgUrl: string;
  onChange: (line: Line) => void;

  points?: Line;
  targetWidth?: number;
  targetHeight?: number;
}

// left/right/top/down margin to make it possible to attach line endings to edges of the image
const IMAGE_MARGIN = 0;
// threshold for selecting active point
const MARKER_THRESHOLD = 7;
// maximum image width (in the UI)
const MAX_THUMBNAIL_WIDTH = 720;
// maximum image height (in the UI)
const MAX_THUMBNAIL_HEIGHT = 390;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    touchAction: "none",
    position: "relative",
    overflow: "hidden",
  },
  img: {
    position: "absolute",
    top: IMAGE_MARGIN,
    left: IMAGE_MARGIN,
    pointerEvents: "none",
    userSelect: "none",
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  canvas: {
    position: "relative",
    zIndex: 1,
    "&.loaded": {
      cursor: "crosshair",
    },
  },
}));

export default function LineDrawing({
  width,
  imgUrl,
  onChange,
  points = [],
  targetWidth,
  targetHeight,
}: LineDrawingProps): ReactElement | null {
  const classes = useStyles();
  // dragged = true => one of line points is currently being dragged, i.e. line is being modified
  const [isDragged, setDragged] = useState(false);
  const [line, setLine] = useState<Line>(points ?? []);

  // imgSize - actual image size (img.naturalWidth)
  // thumbnailSize - width/height of a scaled image thumbnail as shown to the user
  // isImageLoaded - true if the image has been loaded and rendered
  // thumbnailScale - <thumbnail width>/<actual image width>
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [thumbnailSize, setThumbnailSize] = useState({ width: 0, height: 0 });
  const isImageLoaded = imgSize.width > 0;
  useEffect(() => {
    if (thumbnailSize.width === 0 && width > 0) {
      const defaultWidth = Math.min(width - IMAGE_MARGIN * 2, 480);
      setThumbnailSize({
        width: defaultWidth,
        height: (defaultWidth / 16) * 9,
      });
    }
  }, [width, thumbnailSize]);
  const thumbnailScale = thumbnailSize.width / imgSize.width;

  const [selectedPoint, setSelectedPoint] = useState<number>(-1);
  const imgRef = useRef<HTMLImageElement>(null);

  /**
   * Converts X coords from Canvas space to Image space
   */
  const canvasX2image = useCallback(
    (coordinate: number): number => {
      const thumbnailX = Math.min(
        Math.max(coordinate - IMAGE_MARGIN, 0),
        thumbnailSize.width
      );

      // round result to prevent values greater than image actual size
      return Math.ceil(thumbnailX / thumbnailScale);
    },
    [thumbnailSize.width, thumbnailScale]
  );

  /**
   * Converts Y coords from Canvas space to Image space
   */
  const canvasY2image = useCallback(
    (coordinate: number): number => {
      const thumbnailY = Math.min(
        Math.max(coordinate - IMAGE_MARGIN, 0),
        thumbnailSize.height
      );

      // round result to prevent values greater than image actual size
      return Math.ceil(thumbnailY / thumbnailScale);
    },
    [thumbnailSize.height, thumbnailScale]
  );

  /**
   * Converts coords (both X and Y) from Image space to Canvas space
   */
  const image2canvas = useCallback(
    (coordinate: number): number => {
      if (imgRef.current === null) {
        return 0;
      }

      return coordinate * thumbnailScale + IMAGE_MARGIN;
    },
    [thumbnailScale]
  );

  const handleImageLoaded = useCallback(
    (evt: SyntheticEvent<HTMLImageElement>) => {
      const img = evt.target as HTMLImageElement;
      const { naturalWidth, naturalHeight } = img;
      const aspectRatio = naturalWidth / naturalHeight;

      const newThumbnailWidth = Math.min(
        aspectRatio >= 1
          ? targetWidth! - IMAGE_MARGIN * 2
          : MAX_THUMBNAIL_HEIGHT,
        aspectRatio < 1 ? targetHeight! - IMAGE_MARGIN * 2 : MAX_THUMBNAIL_WIDTH
      );

      const imgWidth =
        aspectRatio >= 1
          ? Math.min(targetWidth ?? naturalWidth, naturalWidth)
          : newThumbnailWidth;
      const imgHeight =
        aspectRatio < 1
          ? Math.min(targetHeight ?? naturalHeight, naturalHeight)
          : imgWidth / aspectRatio;

      setImgSize({ width: imgWidth, height: imgHeight });
      setThumbnailSize({
        width: newThumbnailWidth,
        height: (imgHeight * newThumbnailWidth) / imgWidth,
      });
    },
    [setImgSize, setThumbnailSize, width, targetWidth, targetHeight]
  );

  /**
   * Selects point under the cursor on hover
   */
  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      if (isDragged) {
        return;
      }

      const point = localPoint(evt);
      if (point === null) {
        if (selectedPoint !== -1) {
          setSelectedPoint(-1);
        }

        return;
      }

      const hoveredPoint = line.findIndex(({ x, y }) => {
        return (
          Math.abs(point.x - image2canvas(x)) < MARKER_THRESHOLD &&
          Math.abs(point.y - image2canvas(y)) < MARKER_THRESHOLD
        );
      });
      setSelectedPoint(hoveredPoint);
    },
    [image2canvas, setSelectedPoint, isDragged, line, selectedPoint]
  );

  /**
   * Creates a new line using the current coords as a starting point
   */
  const handleDragStart = useCallback(
    ({ x = 0, y = 0 }) => {
      setDragged(true);
      if (selectedPoint === -1) {
        //
        setLine([
          {
            x: canvasX2image(x),
            y: canvasY2image(y),
          },
        ]);
      }
    },
    [setLine, canvasX2image, canvasY2image, setDragged, selectedPoint]
  );

  /**
   * Modifies coordinates for the line start or line end
   */
  const handleDragMove = useCallback(
    ({
      x = 0,
      y = 0,
      dx,
      dy,
    }: {
      x?: number;
      y?: number;
      dx: number;
      dy: number;
    }) => {
      const editablePoint = selectedPoint === -1 ? 1 : selectedPoint;
      const newPoints = [...line];
      newPoints[editablePoint] = {
        x: canvasX2image(x + dx),
        y: canvasY2image(y + dy),
      };
      if (editablePoint !== selectedPoint) {
        setSelectedPoint(editablePoint);
      }

      setLine(newPoints);
    },
    [setLine, canvasX2image, canvasY2image, selectedPoint, line]
  );

  /**
   * Finalizes point move mode
   */
  const handleDragEnd = useCallback(() => {
    if (isDragged) {
      setDragged(false);
      if (line.length < 2) {
        setLine([]);
        onChange([]);
      } else {
        onChange(line);
      }
    }
  }, [onChange, setDragged, isDragged, line]);

  const canvasWidth = thumbnailSize.width + IMAGE_MARGIN * 2;
  const canvasHeight = thumbnailSize.height + IMAGE_MARGIN * 2;

  return width === 0 ? null : (
    <div
      className={classes.container}
      style={{
        width: canvasWidth,
        height: canvasHeight,
      }}
    >
      <img
        ref={imgRef}
        alt=""
        width={thumbnailSize.width}
        height={thumbnailSize.height}
        className={classes.img}
        src={imgUrl}
        onLoad={handleImageLoaded}
        style={{ display: isImageLoaded ? "" : "none" }}
      />
      {!isImageLoaded && (
        <div className={classes.loading}>
          <ProgressIndicator size={32} />
        </div>
      )}
      <svg
        className={clsx(classes.canvas, { loaded: isImageLoaded })}
        width={canvasWidth}
        height={canvasHeight}
        onMouseMove={isImageLoaded ? handleMouseMove : undefined}
      >
        <rect
          stroke="lightgrey"
          fill="transparent"
          width={canvasWidth}
          height={canvasHeight}
        />
        {isImageLoaded && (
          <>
            <MarkerCircle
              id="marker-circle"
              fill="white"
              stroke="#666"
              strokeWidth={1}
              size={1.5}
              refX={2.25}
            />
            <LinePath
              stroke="#42FF00"
              strokeWidth={6}
              data={line}
              x={(d) => image2canvas(d.x)}
              y={(d) => image2canvas(d.y)}
            />
            <LinePath
              stroke="red"
              strokeWidth={2}
              data={line}
              x={(d) => image2canvas(d.x)}
              y={(d) => image2canvas(d.y)}
              markerStart="url(#marker-circle)"
              markerEnd="url(#marker-circle)"
            />
            {/* decorate the point currently being dragged */}
            {selectedPoint !== -1 && line[selectedPoint] && (
              <rect
                stroke="red"
                fill="transparent"
                strokeWidth={1.5}
                width={MARKER_THRESHOLD * 2}
                height={MARKER_THRESHOLD * 2}
                x={image2canvas(line[selectedPoint].x) - MARKER_THRESHOLD}
                y={image2canvas(line[selectedPoint].y) - MARKER_THRESHOLD}
                rx={2}
                pointerEvents="none"
              />
            )}
            <Drag
              width={canvasWidth}
              height={canvasHeight}
              resetOnStart
              onDragStart={handleDragStart}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
            >
              {({ dragStart, dragEnd, dragMove }) => (
                <g>
                  {/* drag and drop area */}
                  <rect
                    fill="transparent"
                    width={canvasWidth}
                    height={canvasHeight}
                    onMouseDown={dragStart}
                    onMouseLeave={dragEnd}
                    onMouseUp={dragEnd}
                    onMouseMove={dragMove}
                    onTouchStart={dragStart}
                    onTouchCancel={dragEnd}
                    onTouchEnd={dragEnd}
                    onTouchMove={dragMove}
                  />
                </g>
              )}
            </Drag>
          </>
        )}
      </svg>
    </div>
  );
}
