import { makeStyles, Theme } from "@material-ui/core";

const drawerWidth = 256;

export default makeStyles((theme: Theme) => ({
  header: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: theme.spacing(16),
    width: (props: { leftPanelIsOpen?: boolean }) =>
      props.leftPanelIsOpen
        ? `calc(100% - ${drawerWidth}px - 48px)`
        : `calc(100% - 104px - 48px)`,
    display: "flex",
    flexDirection: "column",
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
  },
  generalInfo: {
    display: "flex",
  },
  infoItems: {
    display: "flex",
    columnGap: theme.spacing(1),
    marginLeft: theme.spacing(2),

    "& button": {
      color: theme.palette.text.secondary,
      textTransform: "none",

      "& svg": {
        transform: "scale(0.7)",
      },

      "&:hover": {
        background: "transparent",
        cursor: "auto",
      },
    },

    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  btn: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,

    "&.MuiButton-containedPrimary": {
      "&.Mui-disabled": {
        color: "#FBFBFB",
        boxShadow: "none",
        background: "#DFE6FB",
      },
    },

    "&.MuiButton-outlinedPrimary": {
      "&.Mui-disabled": {
        color: "#DFE6FB",
        borderColor: "#DFE6FB",

        "& svg path ": {
          fill: "#DFE6FB",
        },
      },
    },
  },
  title: {
    flexGrow: 1,
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  divider: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  trashPaper: {
    borderRadius: 12,

    "& .MuiDialogActions-root": {
      padding: theme.spacing(1, 3, 2),
    },
  },
}));
