import React, {
  ReactElement
} from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import { getSelectedTariff } from '../tariffSlice'

import { Button, Card, CardContent, Checkbox, makeStyles, Theme, Typography } from '@material-ui/core'
import Link from '../../../../components/Link'

import pageStyles from '../../../groups/components/pageStyles'
import I18N from '../../../../app/i18n/strings'

import invoiceIcon from '../../../../app/assets/invoiceIcon.svg'

import { COLORS } from '../../../../theme'
import tariffStyles, { MAX_TARIFF_CARD_WIDTH } from '../tariffStyles'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: MAX_TARIFF_CARD_WIDTH
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7)
  },
  changeLink: {
    paddingLeft: 10
  },
  tariffInfo: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectPayment: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  paymentsType: {
    width: '100%',
    height: theme.spacing(12),
    display: 'flex',
    border: '1px solid',
    borderColor: COLORS.GREY2,
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  typeName: {
    display: 'flex',
    alignItems: 'center'
  },
  banksLogo: {
    height: theme.spacing(8),
    marginRight: 10
  },
  invoiceIcon: {
    height: theme.spacing(5),
    marginRight: theme.spacing(9)
  },
  checkbox: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5)
  }
}))

export default function ConfirmPayments (): ReactElement {
  const { t } = useTranslation()
  const classes = pageStyles()
  const cardClasses = useStyles()
  const tariffClasses = tariffStyles()
  const history = useHistory()

  const selectedTariff = useSelector(getSelectedTariff)

  const navigateBack = (): void => {
    history.goBack()
  }

  const navigateForward = (): void => {
    history.push('?tab=payments&inner=invoiceForm')
  }

  return (
    <>
      <div className={clsx(cardClasses.card, tariffClasses.card)}>
        <Card className={tariffClasses.cardWrap}>
          <CardContent className={clsx(tariffClasses.innerCard, tariffClasses.flexContent)}>

            <div>
              <Typography variant='h2' className={cardClasses.title}>
                {t(I18N.payments.CONFIRM_TITLE)}
              </Typography>

              {selectedTariff !== undefined && (
                <div>
                  <div className={clsx(cardClasses.tariffInfo, cardClasses.title)}>
                    <Typography variant='h6'>
                      {t(I18N.payments.SELECTED_STRING)}
                      <b> +{selectedTariff?.maxHours} часов </b>
                      {t(I18N.payments.BY_TARIFF)} <b>{selectedTariff?.name}</b>
                    </Typography>

                    <Link to='?tab=payments&inner=tariffs' className={cardClasses.changeLink}>
                      <Typography variant='h6'>
                        {t(I18N.common.BTN_CHANGE)}
                      </Typography>
                    </Link>
                  </div>

                  <Typography variant='h2' className={cardClasses.title}>
                    {t(I18N.payments.TO_PAYMENTS)} {selectedTariff?.purchase} ₽
                  </Typography>

                  <Typography variant='h6' className={cardClasses.selectPayment}>
                    {t(I18N.payments.SELECT_PAYMENTS_TYPE)}
                  </Typography>

                  <div className={cardClasses.paymentsType}>
                    <div className={cardClasses.typeName}>
                      <Checkbox
                        color='primary'
                        checked
                        className={cardClasses.checkbox}
                      />

                      <Typography variant='h6'>
                        {t(I18N.payments.PAYMENT_INVOICE_TYPE)}
                      </Typography>
                    </div>
                    <img src={invoiceIcon} alt='' className={cardClasses.invoiceIcon} />
                  </div>
                </div>
              )}

              {selectedTariff === undefined && (
                <Typography variant='h6' className={cardClasses.tariffInfo}>
                  {t(I18N.payments.OPERATION_CANCELLED)}
                </Typography>
              )}
            </div>

            <div className={tariffClasses.grid}>
              <Button
                color='primary'
                className={clsx(classes.btn, tariffClasses.leftBtn)}
                variant='outlined'
                onClick={navigateBack}
              >
                {t(I18N.common.BTN_BACK)}
              </Button>

              <Button
                color='primary'
                className={classes.btn}
                variant='contained'
                disabled={selectedTariff === undefined}
                onClick={navigateForward}
              >
                {t(I18N.common.BTN_CONTINUE)}
              </Button>
            </div>

          </CardContent>
        </Card>
      </div>
    </>
  )
}
