import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { LocationData, LocationId } from "../data-types";
import { getUploadsSelector } from "../../location/selectors";
import { UPLOAD_STATE } from "../../location/data-mapper";

import { VISIUS_COLORS } from "../../../app/styles";
import { VISIUS_CARD_TEXT1_COLOR } from "../../../theme";

import Tooltip from "./Tooltip";
import { ReactComponent as WebcamErrorIcon } from "../../../app/assets/webcamError.svg";
import { ReactComponent as ReadyDataIcon } from "../../../app/assets/readyDataIcon.svg";
import { ReactComponent as WebcamIcon } from "../../../app/assets/webcamIcon.svg";
import { ReactComponent as DownloadDataIcon } from "../../../app/assets/downloadDataIcon.svg";
import { ReactComponent as DownloadDataErrorIcon } from "../../../app/assets/downloadDataError.svg";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: "flex",
    position: "relative",
    color: VISIUS_CARD_TEXT1_COLOR,
    marginTop: theme.spacing(-1),
  },
  summary: {
    fontWeight: 500,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    height: 24,
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: "1px solid black",
    borderRadius: theme.spacing(1),

    "& $icon": {
      border: "none",
      margin: 0,
    },

    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  icon: {
    height: 24,
    width: 32,
    padding: "3px 8px",
    border: "1px solid grey",
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  warn: {
    color: VISIUS_COLORS.WARN,
    background: `rgba(241, 185, 13, 0.1)`,
    border: "1px solid rgba(241, 185, 13, 0.5)",

    "& path": {
      fill: `${VISIUS_COLORS.WARN} !important`,
    },
  },
  error: {
    color: VISIUS_COLORS.ERROR,
    background: `rgba(255, 61, 0, 0.1)`,
    border: "1px solid rgba(255, 61, 0, 0.5)",

    "& path": {
      fill: `${VISIUS_COLORS.ERROR} !important`,
    },
  },
  dataSuccess: {
    color: VISIUS_COLORS.SUCCESS,
    background: `rgba(44, 103, 255, 0.1)`,
    border: "1px solid rgba(44, 103, 255, 0.5)",
  },
  webcamSuccess: {
    color: VISIUS_COLORS.CAM_SUCCESS,
    background: `rgba(53, 189, 110, 0.1)`,
    border: "1px solid rgba(53, 189, 110, 0.5)",

    "& path": {
      fill: `${VISIUS_COLORS.CAM_SUCCESS} !important`,
    },
  },
}));

export interface LocationUploadStatsProps {
  locationId: LocationId;
  data: LocationData;
}

type UploadInfo = "failed" | "progress" | "completed" | null;
const UPLOAD_INFO_DISPLAY_INTERVAL = 10000;

export default function UploadStats(
  props: LocationUploadStatsProps
): ReactElement | null {
  const classes = useStyles();
  const { locationId, data } = props;
  const uploadsSelector = useMemo(
    () => getUploadsSelector(locationId),
    [locationId]
  );
  const uploads = useSelector(uploadsSelector);

  const progressNumber = uploads.filter(
    ({ state }) => state === UPLOAD_STATE.UPLOADING
  ).length;
  const failedNumber = uploads.filter(
    ({ state }) => state === UPLOAD_STATE.FAILED
  ).length;
  const completedNumber = uploads.filter(
    ({ state }) => state === UPLOAD_STATE.UPLOADED
  ).length;

  const [displayedUploadInfo, setDisplayedUploadInfo] = useState<
    UploadInfo | UploadInfo[]
  >(null);

  /* Switch between available upload statuses once in <UPLOAD_INFO_DISPLAY_INTERVAL>ms */
  useEffect(() => {
    const availableInfo: UploadInfo[] = [];
    if (progressNumber > 0) {
      availableInfo.push("progress");
    }
    if (failedNumber > 0) {
      availableInfo.push("failed");
    }
    if (completedNumber > 0) {
      availableInfo.push("completed");
    }

    const handler = (): void => {
      if (availableInfo.length === 0) {
        return setDisplayedUploadInfo(null);
      }
      setDisplayedUploadInfo(availableInfo);
    };

    handler();
    const interval = setInterval(handler, UPLOAD_INFO_DISPLAY_INTERVAL);
    return () => clearInterval(interval);
  }, [setDisplayedUploadInfo, progressNumber, failedNumber, completedNumber]);

  // status for non-stream location
  if (data.id === locationId && data.streams === 0)
    return (
      <div className={classes.container}>
        {displayedUploadInfo?.includes("failed") && (
          <div className={`${classes.iconWrapper} ${classes.error}`}>
            <Tooltip
              title={
                <Typography variant="caption">{`Не загружено ${failedNumber} видео`}</Typography>
              }
            >
              <DownloadDataErrorIcon className={`${classes.icon}`} />
            </Tooltip>

            <Typography variant="caption" color="textPrimary">
              {failedNumber}
            </Typography>
          </div>
        )}
        {data.processingCount !== 0 || data.completedCount !== 0 ? (
          <>
            {data.videos === data.completedCount ? (
              <Tooltip
                title={<Typography variant="caption">Отчёт готов</Typography>}
              >
                <ReadyDataIcon
                  className={`${classes.icon} ${classes.dataSuccess}`}
                />
              </Tooltip>
            ) : (
              <div className={`${classes.iconWrapper} ${classes.dataSuccess}`}>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Отчёт готов частично
                    </Typography>
                  }
                >
                  <ReadyDataIcon className={`${classes.icon} `} />
                </Tooltip>
                <Typography variant="caption" color="textPrimary">
                  {`${data.completedCount} из ${data.videos}`}
                </Typography>
              </div>
            )}
          </>
        ) : null}
        {displayedUploadInfo?.includes("progress") && (
          <div className={`${classes.iconWrapper} ${classes.warn}`}>
            <Tooltip
              title={
                <Typography variant="caption">{`Загружается ${progressNumber} видео`}</Typography>
              }
            >
              <DownloadDataIcon className={`${classes.icon}`} />
            </Tooltip>

            <Typography variant="caption" color="textPrimary">
              {progressNumber}
            </Typography>
          </div>
        )}
      </div>
    );

  // status for stream location
  if (
    data.id === locationId &&
    data.streams !== 0 &&
    data.isCameraConnected !== null
  )
    return (
      <div className={classes.container}>
        {data.isCameraConnected ? (
          <Tooltip
            title={<Typography variant="caption">Камера подключена</Typography>}
          >
            <WebcamIcon
              className={`${classes.icon} ${classes.webcamSuccess}`}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={<Typography variant="caption">Камера отключена</Typography>}
          >
            <WebcamErrorIcon className={`${classes.icon} ${classes.error}`} />
          </Tooltip>
        )}
        {data.completedCount === data.streams ? (
          <Tooltip
            title={<Typography variant="caption">Отчёт готов</Typography>}
          >
            <ReadyDataIcon
              className={`${classes.icon} ${classes.dataSuccess}`}
            />
          </Tooltip>
        ) : null}
      </div>
    );

  return null;
}
