import { Moment } from 'moment'
import { ADMIN_VIEW_STORAGE_KEY } from '../features/auth/authSlice'
import { VideoData } from '../features/location/data-mapper'
import { ERROR_CODE, RequestError } from './errors'
import { d3format } from './i18n/d3'
import I18N from './i18n/strings'

export enum HTTP_METHOD {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}

interface RequestOptions {
  method?: HTTP_METHOD
  data?: Record<string, unknown>|Array<Record<string, unknown>>
  raw?: boolean
  keepalive?: boolean
  isCorsRequest?: boolean
}

/**
 * Returns new RequestError object that represents Fetch error
 *
 * @param {Response} response - fetch response
 * @returns {RequestError} - error
 */
async function _getFetchError (response: Response): Promise<RequestError> {
  const contentType = response.headers.get('content-type')
  if (contentType?.includes('application/json') === true) {
    const { errorStatusCode: code, details: message } = await response.json()
    return new RequestError(code, message)
  }

  if (response.status === 401) {
    return new RequestError(ERROR_CODE.GENERIC_ACCESS_DENIED)
  }

  const message = await response.text()
  return new RequestError(ERROR_CODE.UNKNOWN_ERROR,
    message === '' ? I18N.common.ERROR_UNKNOWN : message)
}

/**
 * Send async request to the given URL
 *
 * @param {string} url - URL address
 * @param {RequestOptions} options - additional request options
 */
export async function request<T> (url: string, options?: RequestOptions): Promise<Response|T|null> {
  const {
    method = HTTP_METHOD.GET,
    data,
    keepalive,
    isCorsRequest,
    raw: returnRawResponse = false
  } = options ?? {}

  const params: RequestInit = {
    method,
    credentials: 'include',
    keepalive
  }

  if (isCorsRequest === true) params.mode = 'cors'

  // `HubSessionId` header uniquely identifies a browser Tab
  // `ReplacedUserId` is used to load other users data for admins
  params.headers = {
    HubSessionId: sessionStorage.getItem('HUB_SESSION_ID') ?? ''
  }
  const adminViewUserId = sessionStorage.getItem(ADMIN_VIEW_STORAGE_KEY)
  if (adminViewUserId !== null) {
    params.headers.ReplacedUserId = adminViewUserId
  }
  if (data !== undefined) {
    params.headers['Content-Type'] = 'application/json'
    params.body = JSON.stringify(data)
  }

  const response = await fetch(url, params)
  if (!response.ok) {
    const error = await _getFetchError(response)
    throw error
  }

  if (returnRawResponse) {
    return response
  }

  if (response.status === 204) {
    return null
  }

  return await response.json() as T
}

export function bytes2gb (bytes: number): number {
  return bytes / 0.1e10
}

export function bytes2mb (bytes: number): number {
  return bytes / 0.1e7
}

/**
 * Returns MB representation for less then 100,000,000 bytes, GB - otherwise
 *
 * @param {number} bytes - bytes
 * @returns {string} - `<N> MB` or `<N> GB`
 */
export function bytes2str (bytes: number): string {
  if (bytes > 0.1e9) {
    return `${d3format(bytes2gb(bytes))} ГБ`
  }

  return `${d3format(bytes2mb(bytes))} МБ`
}

function _isValidTab<T extends string> (tabs: T[], name: string): name is T {
  return (tabs as string[]).includes(name)
}

/**
 * Returns current tab ID based on
 *  - `tab` query param within a given URL
 *  - given list of possible tab IDs
 *
 * @param tabs
 * @param query
 */
export function getCurrentTab<T extends string> (tabs: T[], query: URLSearchParams): T {
  const tabQuery = query.get('tab')
  if (tabQuery === null || !_isValidTab(tabs, tabQuery)) {
    return tabs[0]
  }

  return tabQuery
}

interface DateSortableObj {
  createdAt: Moment
}

export function sortByDateAsc (a: DateSortableObj, b: DateSortableObj): -1|0|1 {
  if (a.createdAt < b.createdAt) { return -1 }
  if (a.createdAt > b.createdAt) { return 1 }
  return 0
}

export function sortByDateDesc (a: DateSortableObj, b: DateSortableObj): -1|0|1 {
  if (a.createdAt > b.createdAt) { return -1 }
  if (a.createdAt < b.createdAt) { return 1 }
  return 0
}

export const sortBy = (keys: string[]) => (a: Record<string, unknown>, b: Record<string, unknown>): -1|0|1 => {
  for (const key of keys) {
    const aValue = a[key]
    const bValue = b[key]
    if (typeof aValue !== 'string' && typeof aValue !== 'number') {
      return 0
    }
    if (typeof bValue !== 'string' && typeof bValue !== 'number') {
      return 0
    }

    if (aValue < bValue) { return -1 }
    if (aValue > bValue) { return 1 }
  }
  return 0
}

/**
 * Checks that video file has a supported extension.
 *
 * @param {File} file - downloadable file
 * @returns {boolean} - true/false (valid/not valid)
 */

export function isValidFileType (file: File): boolean {
  const regExp = /\.(flv|mxf|gxf|ts|ps|3gp|3gpp|mpg|wmv|asf|avi|isma|ismv|dvr-ms|mkv|wav|mov|mp4|m4a|m4v)/i
  const isValid = file.name.search(regExp) !== -1
  return isValid
}

/**
 * Checks if the location has video clones.
 *
 * @param {videos} videoData[] - videos from location
 * @returns {boolean} - true/false (hasClone or not)
 */

export function hasCloneVideo (videos: VideoData[]): boolean {
  const hasClone = videos.findIndex(({ isClone }) => {
    return isClone
  }) !== -1
  return hasClone
}

export function hourToMilliseconds (hours: number): number {
  return hours * 60 * 60 * 1000
}

export function hourFromMilliseconds (ms: number): number {
  return Math.floor(ms / (60 * 60 * 1000))
}

export function clamp (x: number, min: number, max: number): number {
  return Math.max(Math.min(x, max), min)
}

export function declOfNum(n:number, text_arr: string[]) {
  n = Math.abs(n) % 100
  const n1 = n % 10
  if(n > 10 && n < 20) {
    return text_arr[2]
  }

  if(n1 > 1 && n1 < 5) {
    return text_arr[1]
  }

  if(n1 === 1) {
    return text_arr[0]
  }

  return text_arr[2]
}
