import { Dialog } from '@material-ui/core'
import Hls from 'hls.js'
import React, { ReactElement, useCallback, useRef } from 'react'

interface VideoDialogProps {
  open: boolean
  url: string
  useHls?: boolean

  onClose: () => void
}

export default function ModalVideoPlayer ({ open, url, onClose }: VideoDialogProps): ReactElement|null {
  const videoElemRef = useRef<HTMLVideoElement>(null)
  const { current: hls } = useRef<Hls>(new Hls())
  const urlObj = new URL(url)
  const useHls = /\.m3u8$/.test(urlObj.pathname)
  const src = useHls ? undefined : url

  const handleLoad = useCallback(() => {
    const videoElem = videoElemRef.current as HTMLVideoElement
    if (useHls) {
      hls.loadSource(url)
      hls.attachMedia(videoElem)
    }
  }, [hls, url, useHls])

  const handleUnload = useCallback(() => {
    const videoElem = videoElemRef.current as HTMLVideoElement
    videoElem.pause()
    if (useHls) {
      hls.stopLoad()
      hls.detachMedia()
    }
  }, [hls, useHls])

  return (
    <Dialog onClose={onClose} open={open} onEntered={handleLoad} onExit={handleUnload}>
      <video ref={videoElemRef} src={src} controls autoPlay />
    </Dialog>
  )
}
