import React, { ReactElement, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { GroupId } from "../data-types";
import { createTmpLocation } from "./videoSlice";

import I18N from "../../../app/i18n/strings";
import { ReactComponent as PlusIcon } from "../../../app/assets/plusIcon.svg";
import { useLocationCardStyles } from "../components/BaseLocationCard";

export interface LocationProps {
  title: string;
}

interface CardProps {
  groupId: GroupId;
  disabled?: boolean;
}

export default function AddLocationCard({
  groupId,
  disabled = false,
}: CardProps): ReactElement {
  const classes = useLocationCardStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleLocationCreate = useCallback(() => {
    dispatch(createTmpLocation(groupId, t(I18N.main.TMP_LOCATION_TITLE)));
  }, [dispatch, t, groupId]);

  return (
    <Card
      variant="outlined"
      className={`${classes.card} ${classes.iconCard} ${
        disabled ? classes.disabled : classes.enabled
      }`}
      onClick={disabled ? undefined : handleLocationCreate}
    >
      <CardContent style={{ height: "100%" }}>
        <div className={classes.thumbnail}>
          <div className={classes.thumbnailContent}>
            <PlusIcon className={classes.icon} />
            <Typography variant="body2" noWrap={true} color="primary">
              {t(I18N.main.LINK_ADD_LOCATION)}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
