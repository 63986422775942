import { Button } from '@material-ui/core'
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab'
import React, { ReactElement } from 'react'
import { useContentStyles } from '../app/styles'
import { NOTIFICATION_TYPE } from '../features/notifications/notificationsSlice'

export interface NotificationAlert {
  title: string
  message: string | ReactElement
  type: NOTIFICATION_TYPE
  actionText?: string
  actionDisabled?: boolean
  actionCallback?: () => void
}

interface VisiusAlertProps extends AlertProps {
  data: NotificationAlert
}

export default function VisiusAlert ({ data, ...rest }: VisiusAlertProps): ReactElement {
  const classes = useContentStyles()
  const handleClick = data.actionCallback
  return (
    <Alert
      className={classes.alert}
      severity={data.type}
      action={
        data.actionText !== undefined
          ? (
            <Button
              disabled={data?.actionDisabled}
              color='inherit'
              size='small'
              onClick={handleClick}
            >
              {data.actionText}
            </Button>
          )
          : null
      }
      {...rest}
    >
      <AlertTitle>{data.title}</AlertTitle>
      {data.message}
    </Alert>
  )
}
