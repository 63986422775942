/* eslint no-void: ["error", { "allowAsStatement": true }] */
import * as signalR from "@microsoft/signalr";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import I18N from "../../app/i18n/strings";
import { RootState } from "../../app/store";
import { ResultKeys } from "../../components/charts/bar/types";
import { RawResult } from "../dashboard/charts/RawResult";
import { ISODateString } from "../groups/data-types";
import {
  StreamId,
  VideoId,
  VIDEO_PREPROCESSING_STATE,
} from "../location/data-mapper";
import {
  loadAnalytics,
  reloadDataById,
  reloadStreamData,
  reloadVideoData,
} from "../location/locationSlice";
import { RawAnalyticsData } from "../location/selectors";
import { actions as notificationActions } from "../notifications/notificationsSlice";
import { useWorker } from "../dashboard/worker/useAnalyticsWorker";

const DEFAULT_SIGNALR_URL = "/notifying";
const DEFAULT_CONNECTION_TIMEOUT = 10000;
const MSG_EVENT_NAME = "SendMessage";

interface VideoFileProcessed {
  VideoFileId: VideoId;
  VideoFileName: string;
  Type: number;
  TimeStamp: ISODateString;
}

interface VideoFileProcessedError {
  VideoFileId: VideoId;
  VideoFileName: string;
  Type: number;
  TimeStamp: ISODateString;
  ErrorMessage: string;
}

interface VideoStreamProcessed {
  VideoStreamId: StreamId;
  VideoStreamIpAddress: string;
  Type: number;
  TimeStamp: ISODateString;
}

interface VideoStreamProcessedError {
  VideoStreamId: StreamId;
  VideoStreamIpAddress: string;
  Type: number;
  TimeStamp: ISODateString;
  ErrorMessage: string;
}

interface VideoProcessing {
  StreamId: VideoId | StreamId;
  Type: number;
  TimeStamp: ISODateString;
}

interface VideoFilePreprocessing {
  VideoFileId: VideoId;
  VideoFileName: string;
  Type: number;
  TimeStamp: ISODateString;
  State: VIDEO_PREPROCESSING_STATE;
  Progress: number;
}

export interface StreamObjectsAtTimeData {
  Objects: Array<{
    StreamId: VideoId | StreamId;
    ClassId: string;
    Time: ISODateString;
    Count: number; // number of repeating intersections
    Age: number | null;
    Gender: number | null;
    AdditionalCountInfo: {
      ObjectsCount: number; // who made number of repeating intersections
      UniqueCount: number; // uniqCount = count - repeatingCount
      RepeatingCount: number; // repeating intersections
      RepeatingObjectsCount: number; // objects, who made this repeating intersections
    } | null;
  }>;
  TimeStamp: ISODateString;
}

type InputResultClassId =
  | "bikes"
  | "bicycle"
  | "car"
  | "cars"
  | "men"
  | "person"
  | "people";
const CLASS_ID_RESULT_KEY_MAP: Record<
  InputResultClassId,
  ResultKeys<RawResult>
> = {
  bicycle: "bikes",
  bikes: "bikes",
  car: "cars",
  cars: "cars",
  men: "men",
  people: "men",
  person: "men",
};

/**
 * Initializes signalR connection.
 * Calls async thunks as callbacks on received messages.
 *
 * @param dispatch - redux thunkDispatch
 */
export default function initSignalR(
  dispatch: ThunkDispatch<RootState, void, AnyAction>,
  worker: ReturnType<typeof useWorker>
): () => void {
  const hubSessionId = sessionStorage.getItem("HUB_SESSION_ID") ?? "";
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${DEFAULT_SIGNALR_URL}?HubSessionId=${hubSessionId}`)
    .build();

  function connect(): void {
    void (async (): Promise<void> => {
      try {
        await connection.start();
      } catch (err) {
        console.log(err);
        setTimeout(connect, DEFAULT_CONNECTION_TIMEOUT);
      }
    })();
  }

  connection.on(MSG_EVENT_NAME, (type, args) => {
    switch (type) {
      case "VideoFilePreprocessingState": {
        const options = JSON.parse(args) as VideoFilePreprocessing;
        return dispatch(
          reloadVideoData(options.VideoFileId, options.State, options.Progress)
        );
      }
      case "VideoFileProcessed": {
        const options = JSON.parse(args) as VideoFileProcessed;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_VIDEO_PREPARED,
            args: {
              file: options.VideoFileName,
            },
          })
        );
        return dispatch(reloadVideoData(options.VideoFileId));
      }
      case "VideoFileProcessedError": {
        const options = JSON.parse(args) as VideoFileProcessedError;
        dispatch(
          notificationActions.error({
            template: I18N.location.NOTIFY_VIDEO_PREPARED_ERROR,
            args: {
              file: options.VideoFileName,
              errorMessage: options.ErrorMessage,
            },
          })
        );
        return dispatch(reloadVideoData(options.VideoFileId));
      }
      case "VideoStreamProcessed": {
        const options = JSON.parse(args) as VideoStreamProcessed;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_STREAM_PREPARED,
            args: {
              url: options.VideoStreamIpAddress,
            },
          })
        );
        return dispatch(reloadStreamData(options.VideoStreamId));
      }
      case "VideoStreamProcessedError": {
        const options = JSON.parse(args) as VideoStreamProcessedError;
        console.log(options.ErrorMessage);
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_STREAM_PREPARED_ERROR,
            args: {
              url: options.VideoStreamIpAddress,
            },
          })
        );
        return dispatch(reloadStreamData(options.VideoStreamId));
      }
      case "VideoFileRemoved": {
        const options = JSON.parse(args) as VideoFileProcessed;
        return dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_VIDEO_REMOVED,
            args: {
              file: options.VideoFileName,
            },
          })
        );
      }
      case "VideoStreamRemoved": {
        const options = JSON.parse(args) as VideoStreamProcessed;
        return dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_STREAM_REMOVED,
            args: {
              url: options.VideoStreamIpAddress,
            },
          })
        );
      }
      case "StreamObjectsAtTimeData": {
        console.log(args);
        console.log(type);

        const msgData = JSON.parse(args) as StreamObjectsAtTimeData;
        const analyticsEntries = msgData.Objects.reduce(
          (data: RawAnalyticsData[], entry) => {
            if (
              ![
                "bikes",
                "men",
                "car",
                "cars",
                "bicycle",
                "person",
                "people",
              ].includes(entry.ClassId)
            ) {
              return data;
            }

            return [
              ...data,
              {
                videoOrStreamId: entry.StreamId,
                data: {
                  resultKey:
                    CLASS_ID_RESULT_KEY_MAP[
                      entry.ClassId as InputResultClassId
                    ],
                  count: entry.Count,
                  time: entry.Time,
                  gender: entry.Gender,
                  age: entry.Age,
                  additionalInfo: entry.AdditionalCountInfo
                    ? {
                        objectsCount: entry.AdditionalCountInfo?.ObjectsCount,
                        repeatingCount:
                          entry.AdditionalCountInfo?.RepeatingCount,
                        repeatingObjectsCount:
                          entry.AdditionalCountInfo?.RepeatingObjectsCount,
                        uniqueCount: entry.AdditionalCountInfo?.UniqueCount,
                      }
                    : null,
                },
              },
            ];
          },
          []
        );

        void (async () => {
          await worker.saveAnalyticsData(analyticsEntries);
          const sourceIds = analyticsEntries.reduce(
            (res: Set<StreamId | VideoId>, entry) => {
              res.add(entry.videoOrStreamId);
              return res;
            },
            new Set<StreamId | VideoId>()
          );
          dispatch(
            loadAnalytics({
              sourceIds: Array.from(sourceIds),
              time: new Date(),
            })
          );
        })();
        return;
      }
      case "VideoProcessingRequested": {
        return dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_PROCESSING_REQUESTED,
          })
        );
      }
      case "VideoProcessingPending": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_PROCESSING_PENDING,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingPreparingInfrastructure": {
        const options = JSON.parse(args) as VideoProcessing;
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingPreparingInfrastructureError": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.error({
            template:
              I18N.location.NOTIFY_PROCESSING_PREPARING_INFRASTRUCTURE_ERROR,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessing": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_PROCESSING_ANALYSIS,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingInfrastructurePrepared": {
        const options = JSON.parse(args) as VideoProcessing;
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingStoppingError": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_PROCESSING_STOPPING_ERROR,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingStopped": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_PROCESSING_STOPPED,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoProcessingFinished": {
        const options = JSON.parse(args) as VideoProcessing;
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoStartProcessingSuccess": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_START_PROCESSING_SUCCESS,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "VideoStartProcessingError": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.location.NOTIFY_START_PROCESSING_ERROR,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      case "TariffTimeOver": {
        const options = JSON.parse(args) as VideoProcessing;
        dispatch(
          notificationActions.info({
            template: I18N.tariff.NOTIFY_TIME_OVER,
          })
        );
        return dispatch(reloadDataById(options.StreamId));
      }
      default: {
        console.log(type, args);
      }
    }
  });

  connection.on("close", connect);
  connect();

  return () => {
    connection.off("close", connect);
    connection.stop().catch((err) => console.error(err));
  };
}
