import React, { ReactElement } from "react";

import { RawResult } from "./RawResult";
import ResultGroupChart from "./ResultAggregation";
import { AggregationResult } from "../chart-types";

import { ChartProps } from "../../../components/charts/Chart";

export default function MaximumNumberChart(
  props: ChartProps<RawResult>
): ReactElement {
  return (
    <ResultGroupChart
      {...props}
      aggregator={(key, values): AggregationResult => ({
        key,
        men: Math.max(...values.map(({ men }) => men)),
        cars: Math.max(...values.map(({ cars }) => cars)),
        bikes: Math.max(...values.map(({ bikes }) => bikes)),
        oldMen: Math.max(...values.map(({ oldMen }) => oldMen)),
        middleMen: Math.max(...values.map(({ middleMen }) => middleMen)),
        youngMen: Math.max(...values.map(({ youngMen }) => youngMen)),
        unknownMen: Math.max(...values.map(({ unknownMen }) => unknownMen)),
        oldWomen: Math.max(...values.map(({ oldWomen }) => oldWomen)),
        middleWomen: Math.max(...values.map(({ middleWomen }) => middleWomen)),
        youngWomen: Math.max(...values.map(({ youngWomen }) => youngWomen)),
        unknownWomen: Math.max(
          ...values.map(({ unknownWomen }) => unknownWomen)
        ),
        oldUnknown: Math.max(...values.map(({ oldUnknown }) => oldUnknown)),
        middleUnknown: Math.max(
          ...values.map(({ middleUnknown }) => middleUnknown)
        ),
        youngUnknown: Math.max(
          ...values.map(({ youngUnknown }) => youngUnknown)
        ),
        unknownUnknown: Math.max(
          ...values.map(({ unknownUnknown }) => unknownUnknown)
        ),
      })}
    />
  );
}
