import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Error, InsertInvitationOutlined } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateCalendar } from "@mui/x-date-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import I18N from "../app/i18n/strings";

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    "& .MuiInput-underline:before": {
      border: 0,
    },
    "& .MuiInput-underline:not(.Mui-error):after": {
      border: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "none",
    },

    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      paddingBottom: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),

      "& .Mui-focused": {
        border: "none",
      },
    },
  },

  dialogPaper: {
    maxWidth: 325,
    borderRadius: theme.spacing(3),

    "& .MuiDialogContent-root": {
      padding: 0,
    },

    "& .MuiDialogContent-root .MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-labelContainer .MuiPickersFadeTransitionGroup-root":
      {
        fontWeight: 400,
        fontSize: 14,
        fontFamily: "Inter",

        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
  },

  dialogDateTimeContainer: {
    display: "flex",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiFormControl-root .MuiInputBase-root input": {
      fontSize: 18,
      fontWeight: 400,
    },
    "& .MuiFormControl-root:first-of-type .MuiInputBase-root input": {
      textAlign: "right",
    },
  },

  timePicker: {
    marginLeft: theme.spacing(2),
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "40%",
  },

  dialogBtn: {
    marginLeft: "40px !important",
    textTransform: "none",
  },
}));

interface DateTimePickerProps {
  date: MaterialUiPickersDate;
  onChange: (date: MaterialUiPickersDate) => void;
}

export default function DateTimePicker(
  props: DateTimePickerProps
): ReactElement {
  const { onChange, date } = props;
  const DEFAULT_DIALOG_DATE = date ?? moment("00:00", "HH:mm");
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState<MaterialUiPickersDate>(date);
  const [dialogDate, setDialogDate] =
    useState<MaterialUiPickersDate>(DEFAULT_DIALOG_DATE);
  const [dialogTime, setDialogTime] =
    useState<MaterialUiPickersDate>(DEFAULT_DIALOG_DATE);
  const [open, setOpen] = useState(false);

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      const defaultDate = date ?? moment("00:00", "HH:mm");
      const dateByUTC =
        date !== null
          ? moment.utc(defaultDate.format("YYYY.MM.DD HH:mm"))
          : null;
      setCurrentDate(dateByUTC);
      onChange(dateByUTC);
    },
    [onChange, setCurrentDate]
  );

  const handleOpenDatePicker = useCallback(() => {
    setDialogDate(DEFAULT_DIALOG_DATE);
    setDialogTime(DEFAULT_DIALOG_DATE);
    setOpen(true);
  }, [setDialogDate, setOpen, DEFAULT_DIALOG_DATE]);

  const handleCloseDatePicker = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSelectDate = useCallback(() => {
    const resultDate =
      dialogDate !== null && dialogTime !== null
        ? moment.utc(
            `${dialogDate.format("YYYY.MM.DD")} ${dialogTime.format("HH:mm")}`,
            "YYYY.MM.DD HH:mm"
          )
        : null;
    handleDateChange(resultDate);
    setOpen(false);
  }, [handleDateChange, setOpen, dialogDate, dialogTime]);

  const hasError = currentDate === null || !currentDate.isValid();
  const isDialogDateValid = dialogDate?.isValid() ?? false;
  const isDialogTimeValid = dialogTime?.isValid() ?? false;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        className={classes.datePicker}
        disableFuture
        error={currentDate === null || !currentDate.isValid()}
        format="DD.MM.YYYY / HH:mm"
        InputAdornmentProps={{
          position: "end",
          onClick: handleOpenDatePicker,
        }}
        invalidDateMessage={t(I18N.common.VALIDATION_DATE_INVALID)}
        keyboardIcon={
          hasError ? (
            <InsertInvitationOutlined color="error" />
          ) : (
            <InsertInvitationOutlined />
          )
        }
        KeyboardButtonProps={{ size: "small" }}
        maxDateMessage={t(I18N.common.VALIDATION_DATE_TOO_FAR)}
        minDateMessage={t(I18N.common.VALIDATION_DATE_TOO_EARLY)}
        onChange={handleDateChange}
        value={currentDate}
        okLabel={t(I18N.common.OK)}
        cancelLabel={t(I18N.common.CANCEL)}
        DialogProps={{
          open: false,
          style: { display: "none" },
        }}
      />
      <Dialog open={open} PaperProps={{ className: classes.dialogPaper }}>
        <DialogContent>
          <DateCalendar
            value={dialogDate}
            onChange={setDialogDate}
            disableFuture
          />
          <div className={classes.dialogDateTimeContainer}>
            <KeyboardDatePicker
              className={classes.datePicker}
              format="DD.MM.YYYY"
              value={dialogDate}
              error={!isDialogDateValid}
              onChange={setDialogDate}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: "none" },
              }}
              invalidDateMessage=""
              InputProps={{
                endAdornment: !isDialogDateValid && <Error color="error" />,
              }}
            />
            <KeyboardTimePicker
              className={[classes.datePicker, classes.timePicker].join(" ")}
              ampm={false}
              value={dialogTime}
              onChange={setDialogTime}
              invalidDateMessage=""
              error={!isDialogTimeValid}
              InputProps={{
                endAdornment: !isDialogTimeValid && <Error color="error" />,
              }}
              InputAdornmentProps={{
                position: "start",
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: "none" },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px" }}>
          <Button
            className={classes.dialogBtn}
            color="primary"
            onClick={handleCloseDatePicker}
          >
            Назад
          </Button>
          <Button
            color="primary"
            className={classes.dialogBtn}
            onClick={handleSelectDate}
            disabled={!isDialogDateValid || !isDialogTimeValid}
          >
            Выбрать
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}
