import { CircularProgress, CircularProgressProps, Fade } from '@material-ui/core'
import React, { ReactElement } from 'react'

export default function ProgressIndicator ({
  size,
  ...props
}: CircularProgressProps): ReactElement {
  return (
    <Fade in>
      <CircularProgress size={size ?? 16} {...props} />
    </Fade>
  )
}
