/* eslint no-void: ["error", { "allowAsStatement": true }] */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { useDispatch } from 'react-redux'
import { groupToGroupMap } from '../data-mapper'
import { GroupData } from '../data-types'
import { renameGroup } from '../main/asyncActions'
import { actions } from '../main/videoSlice'
import useGroupStyles from './groupStyles'
import InlineTextEdit from '../../../components/InlineTextEdit'

interface RenameGroupProps {
  group: GroupData
  onCancel: () => void
}

export default function GroupRenameTextField (props: RenameGroupProps): ReactElement {
  const {
    group,
    onCancel
  } = props

  const classes = useGroupStyles()
  const renameInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  // Edit mode effects: rename logic
  const handleCancel = useCallback(() => {
    if (group.isTmp === true) {
      return dispatch(actions.deleteGroup(group.id))
    }

    onCancel()
  }, [dispatch, onCancel, group])

  const handleRename = useCallback((title: string) => {
    if (title === group.title && group.isTmp !== true) {
      return onCancel()
    }

    const groupMap = groupToGroupMap(group)
    dispatch(renameGroup({
      group: groupMap,
      update: { title }
    }))
  }, [dispatch, onCancel, group])

  const handleResetError = useCallback(() => {
    const newGroup = groupToGroupMap({
      ...group,
      error: undefined
    })
    dispatch(actions.addGroup(newGroup))
  }, [dispatch, group])

  // Autofocus on the text field in edit mode
  useEffect(() => {
    // Focus on the title field when in Edit mode
    const inputElem = (renameInputRef.current as HTMLInputElement)
    inputElem.focus()
    inputElem.select()
  }, [])

  return (
    <>
      <div className={classes.editModeClickHandler} />
      <InlineTextEdit
        className={classes.textField}
        defaultValue={group.title}
        error={group.error}
        inputRef={renameInputRef}
        onCancel={handleCancel}
        onRename={handleRename}
        onResetError={handleResetError}
      />
    </>
  )
}
