import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";

import I18N from "../../../app/i18n/strings";

import ProgressIndicator from "../../../components/ProgressIndicator";
import BaseLocationCard, {
  MainLocationCardProps,
} from "../components/BaseLocationCard";
import LocationContentSummary from "../components/LocationSummary";
import LocationContentTitle from "../components/LocationTitle";

import { archiveLocation, moveLocationToTrash } from "./asyncActions";
import LocationRenameTextField from "./LocationRenameTextField";

import { ReactComponent as PenIcon } from "../../../app/assets/pen.svg";
import { ReactComponent as ArchiveIcon } from "../../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../../app/assets/trashIcon.svg";

const useStyles = makeStyles((theme: Theme) => ({
  locationTitle: {
    display: "flex",
    alignItems: "flex-start",
  },
  busyIndicator: {
    position: "relative",
    width: theme.spacing(4),
    height: 16,
    paddingLeft: theme.spacing(1),
    paddingTop: 3,
  },
}));

export default function VideosLocationCard(
  props: MainLocationCardProps
): ReactElement {
  const { groupId, data, prohibitedTitles } = props;
  const customClasses = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // activate rename mode when creating a new location
  const [renameMode, setRenameMode] = useState<boolean>(Boolean(data.isTmp));

  // Move to Trash/Archive logic
  const handleMoveToTrash = useCallback(() => {
    dispatch(moveLocationToTrash({ groupId, location: data }));
  }, [dispatch, data, groupId]);

  const handleMoveToArchive = useCallback(() => {
    dispatch(archiveLocation({ groupId, location: data }));
  }, [dispatch, data, groupId]);

  // Rename logic
  const handleRenameCancel = useCallback(() => {
    setRenameMode(false);
  }, [setRenameMode]);

  const handleRenameStart = useCallback(
    () => setRenameMode(true),
    [setRenameMode]
  );

  const handleLocationClick = useCallback(() => {
    history.push(`/location/${data.id}`);
  }, [history, data.id]);

  const [title, setTitle] = useState(data.title);
  useEffect(() => {
    if (data.error !== undefined) {
      setRenameMode(true);
    }

    if (title !== data.title) {
      setRenameMode(false);
      setTitle(data.title);
    }
  }, [setRenameMode, setTitle, title, data.error, data.title]);

  return (
    <BaseLocationCard
      groupId={groupId}
      data={data}
      renameMode={data.isTmp ?? renameMode}
      onClick={renameMode ? undefined : handleLocationClick}
      menuItems={
        renameMode
          ? []
          : [
              <MenuItem key="rename" onClick={handleRenameStart}>
                <ListItemIcon>
                  <PenIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="caption">
                      {t(I18N.main.BTN_RENAME_LOCATION)}
                    </Typography>
                  }
                />
              </MenuItem>,
              <MenuItem key="archive" onClick={handleMoveToArchive}>
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="caption">
                      {t(I18N.main.BTN_ARCHIVE_LOCATION)}
                    </Typography>
                  }
                />
              </MenuItem>,
              <MenuItem key="delete" onClick={handleMoveToTrash}>
                <ListItemIcon>
                  <TrashIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="caption">
                      {t(I18N.main.BTN_DELETE_LOCATION)}
                    </Typography>
                  }
                />
              </MenuItem>,
            ]
      }
    >
      {renameMode ? (
        <div className={customClasses.locationTitle}>
          <LocationRenameTextField
            data={data}
            groupId={groupId}
            onCancel={handleRenameCancel}
            prohibitedTitles={prohibitedTitles ?? []}
          />
          <div className={customClasses.busyIndicator}>
            {data.isBusy === true && <ProgressIndicator />}
          </div>
        </div>
      ) : (
        <>
          <LocationContentTitle title={data.title} />
        </>
      )}
      <LocationContentSummary data={data} />
    </BaseLocationCard>
  );
}
