import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Card, CardContent, makeStyles, Theme } from "@material-ui/core";

import { selectIsMenMode, selectIsMenUniteMode } from "../dashboardSlice";

import { ParentSize } from "@visx/responsive";
import { ScaleOrdinal } from "d3-scale";

import { ResultKeys } from "../../../components/charts/bar/types";
import {
  ChartProps,
  ChartVariant,
  SortOrder,
} from "../../../components/charts/Chart";
import { isSortable } from "../../../components/charts/chart-utils";
import VisiusLegend from "../../../components/charts/Legend";

import { COLORS } from "../../../theme";
import ChartCardHeader from "./ChartCardHeader";

interface ChartCardProps<T> {
  title: string | JSX.Element;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
  chart: React.FC<ChartProps<T>>;
  data: T[];
  resultKeys: Array<ResultKeys<T>>;
  defaultVariant: ChartVariant;
  tooltip?: ReactElement;

  height?: number;
  variants?: ChartVariant[];
  defaultSortOrder?: SortOrder;
  sortable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardWrap: {
    boxShadow: "none",
    borderRadius: theme.spacing(1),
    border: `1px solid ${COLORS.GREY2}`,
    position: "relative",
    overflow: "hidden",
  },
  cardContent: {
    padding: theme.spacing(4, 3),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: COLORS.GREY7,
  },
  controls: {
    display: "flex",
    marginLeft: "auto",
    paddingLeft: theme.spacing(2),
  },
  control: {
    marginRight: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    alignItems: "flex-start",

    "& .MuiTypography-subtitle1": {
      lineHeight: 1.15,
      marginTop: "0.15em",
    },

    "& > div": {
      display: "flex",
      minHeight: 30, // roughly equal to the button group height
      alignItems: "center",
    },
  },
  legend: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3.5),
  },
}));

export default function ChartCard<T>(props: ChartCardProps<T>): ReactElement {
  const [height, setHeight] = useState(props.height);
  const [chartVariant, setChartVariant] = useState<ChartVariant>(
    props.defaultVariant
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    props.defaultSortOrder ?? "none"
  );
  const classes = useStyles();
  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);

  useEffect(() => {
    setChartVariant(props.defaultVariant);
  }, [isMenUniteMode, isMenMode]);

  const handleChangeSortOrder = useCallback(() => {
    setSortOrder(sortOrder === "desc" ? "none" : "desc");
  }, [setSortOrder, sortOrder]);

  return (
    <Card className={classes.cardWrap}>
      <CardContent className={classes.cardContent}>
        {!isMenMode || isMenUniteMode ? (
          <ChartCardHeader
            chartVariant={chartVariant}
            sortOrder={sortOrder}
            onSortOrderChange={handleChangeSortOrder}
            onChartVariantChange={setChartVariant}
            title={props.title}
            tooltip={props.tooltip}
            variants={props.variants}
            sortable={props.sortable}
          />
        ) : (
          <ChartCardHeader
            chartVariant={chartVariant}
            sortOrder={sortOrder}
            onSortOrderChange={handleChangeSortOrder}
            onChartVariantChange={setChartVariant}
            title={props.title}
            tooltip={props.tooltip}
            sortable={props.sortable}
          />
        )}

        <div style={{ height }}>
          <ParentSize>
            {(parent): ReactElement | null => {
              if (parent.width === 0) {
                return null;
              }

              return (
                <props.chart
                  colorScale={props.colorScale}
                  data={props.data}
                  width={parent.width}
                  height={props.height}
                  onHeightChange={setHeight}
                  variant={chartVariant}
                  resultKeys={props.resultKeys}
                  sortOrder={isSortable(chartVariant) ? sortOrder : "none"}
                />
              );
            }}
          </ParentSize>
        </div>
        {isMenMode &&
          (props.title === "Среднее кол-во появлений за день" ||
            props.title === "Максимальное кол-во появлений за день" ||
            props.title === "Минимальное кол-во появлений за день") && (
            <div className={classes.legend}>
              <VisiusLegend
                colorScale={props.colorScale}
                resultKeys={props.resultKeys}
                hideLabels={isMenMode && !isMenUniteMode}
              />
            </div>
          )}
        {props.title !== "Среднее кол-во появлений за день" &&
          props.title !== "Максимальное кол-во появлений за день" &&
          props.title !== "Минимальное кол-во появлений за день" && (
            <div className={classes.legend}>
              <VisiusLegend
                colorScale={props.colorScale}
                resultKeys={props.resultKeys}
              />
            </div>
          )}
      </CardContent>
    </Card>
  );
}
