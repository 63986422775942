import {
  ActionReducerMapBuilder,
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
  ThunkDispatch
} from '@reduxjs/toolkit'
import { ERROR_CODE } from '../../app/errors'
import I18N from '../../app/i18n/strings'
import { AppThunk, RootState } from '../../app/store'
import { actions as authActions } from '../auth/authSlice'
import { getLocationFilter, loadGroup as apiLoadGroup, loadGroups as apiLoadGroups } from './api'
import { groupsArrayToMap, groupsMapToArray, groupToGroupMap } from './data-mapper'
import {
  GroupData,
  GroupId,
  GroupMap,
  GroupsMap,
  LocationData,
  LocationId,
  SliceName
} from './data-types'

export enum DATA_LOAD_STATE {
  NONE,
  STARTED,
  FAILED,
  LOADED
}

export interface VideoDataState {
  state: DATA_LOAD_STATE
  data: GroupsMap
  error?: string
}

export interface LocationArgs {
  groupId: GroupId
  location: LocationData
}

export interface LocationUpdateArgs {
  groupId: GroupId
  location: LocationData
  update: {
    [propName in keyof LocationData]?: LocationData[propName]
  }
}
export interface GroupUpdateArgs {
  group: GroupMap
  update: {
    [propName in keyof GroupMap]?: GroupMap[propName]
  }
}

export const initialState: VideoDataState = {
  state: DATA_LOAD_STATE.NONE,
  data: new Map()
}

export const MAIN_SLICE_NAME: SliceName = 'videoData'
export const ARCHIVE_SLICE_NAME: SliceName = 'archive'
export const TRASH_SLICE_NAME: SliceName = 'trash'

interface LocationAction {
  meta: {
    arg: LocationArgs
  }
}

export const deleteLocationState = (state: VideoDataState, action: LocationAction): void => {
  const groupId = action.meta.arg.groupId
  const locationId = action.meta.arg.location.id
  withGroup(state, groupId, group => {
    group.locations.delete(locationId)
  })
}

interface GroupAction {
  meta: {
    arg: GroupMap
  }
}

export const withGroup = (state: VideoDataState, groupId: GroupId, callback: (group: GroupMap) => void): void => {
  if (state.state !== DATA_LOAD_STATE.LOADED) {
    return
  }

  if (!state.data.has(groupId)) {
    return
  }

  // eslint-disable-next-line
  callback(state.data.get(groupId) as GroupMap)
}

export const withLocation = (
  state: VideoDataState,
  groupId: GroupId,
  location: LocationData,
  callback: (location: LocationData) => void
): void => {
  if (state.state !== DATA_LOAD_STATE.LOADED) {
    return
  }
  if (!state.data.has(groupId) || !(state.data.get(groupId) as GroupMap).locations.has(location.id)) {
    return
  }

  // eslint-disable-next-line
  callback((state.data.get(groupId) as GroupMap).locations.get(location.id) as LocationData)
}

export const setGroupBusy = (state: VideoDataState, action: { meta: { arg: GroupMap }}): void => {
  const group = action.meta.arg
  withGroup(state, group.id, (group) => {
    group.isBusy = true
  })
}

export const setGroupIdle = (state: VideoDataState, action: { meta: { arg: GroupMap }}): void => {
  const group = action.meta.arg
  withGroup(state, group.id, (group) => {
    group.isBusy = false
  })
}

export const setLocationBusy = (state: VideoDataState, action: { meta: { arg: LocationArgs }}): void => {
  const { groupId, location } = action.meta.arg
  withLocation(state, groupId, location, (location) => {
    location.isBusy = true
  })
}

export const setLocationIdle = (state: VideoDataState, action: { meta: { arg: LocationArgs }}): void => {
  const { groupId, location } = action.meta.arg
  withLocation(state, groupId, location, (location) => {
    location.isBusy = false
  })
}

export const deleteGroupState = (state: VideoDataState, action: GroupAction): void => {
  const groupId = action.meta.arg.id
  state.data.delete(groupId)
}

export const reloadGroupData = async (groupId: GroupId, sliceNames: SliceName[], rootState: RootState): Promise<GroupData|undefined> => {
  const outdatedSlices = sliceNames
    .map(sliceName => rootState[sliceName])
    .filter(({ state }) => state === DATA_LOAD_STATE.LOADED)

  if (outdatedSlices.length > 0) {
    const updatedGroup = await apiLoadGroup(groupId) as GroupData
    return updatedGroup
  }
}

export const setGroupState = (state: VideoDataState, sliceName: SliceName, group?: GroupData): void => {
  if (group === undefined || state.state !== DATA_LOAD_STATE.LOADED) {
    return
  }

  state.data.set(group.id, groupToGroupMap({
    ...group,
    locations: group.locations.filter(getLocationFilter(sliceName))
  }))
}

interface VideoSlice extends Slice<VideoDataState, {
  addGroup: (state: VideoDataState, action: PayloadAction<GroupMap>) => void
  addLocation: (state: VideoDataState, action: PayloadAction<LocationArgs>) => void
  deleteGroup: (state: VideoDataState, action: PayloadAction<GroupId>) => void
  deleteLocation: (state: VideoDataState, action: PayloadAction<{ groupId: GroupId, locationId: LocationId }>) => void
  loadData: (state: VideoDataState, action: PayloadAction<GroupsMap>) => void
  reset: () => void
}> {
  loadGroup: (groupId: GroupId) => AppThunk
  loadGroups: AsyncThunk<void, void, { dispatch: ThunkDispatch<RootState, void, AnyAction>}>
  selectData: (rootState: RootState) => GroupData[]
  selectLoaded: (rootState: RootState) => boolean
  selectLoading: (rootState: RootState) => boolean
  selectLocationIds: (rootState: RootState) => LocationId[]
  selectNotInitialized: (rootState: RootState) => boolean
  selectLoadError: (rootState: RootState) => string|undefined
  selectIsValidLocation: (locationId?: LocationId) => (rootState: RootState) => boolean|undefined
}

export const createVideoSlice = (sliceName: SliceName, extraReducers?: (builder: ActionReducerMapBuilder<VideoDataState>) => void): VideoSlice => {
  /**
   * Fetches and saves data for all available Groups
   */
  const loadGroups = createAsyncThunk(
    `${sliceName}/loadGroups`,
    async (_, thunkApi) => {
      for await (const dataChunk of apiLoadGroups(sliceName)) {
        const groupsMap = groupsArrayToMap(dataChunk)
        thunkApi.dispatch(slice.actions.loadData(groupsMap))
      }
    }
  )

  /**
   * Loads and saves data for a single Group
   */
  const loadGroup = createAsyncThunk(
    `${sliceName}/loadGroup`,
    async (groupId: GroupId, { getState }) => {
      const { [sliceName]: sliceState } = getState() as RootState
      const { state } = sliceState
      if (state === DATA_LOAD_STATE.NONE) {
        return
      }

      const group = await apiLoadGroup(groupId, sliceName)
      if (group === undefined) {
        return
      }

      return groupToGroupMap(group)
    }
  )

  const slice = createSlice({
    initialState,
    name: sliceName,
    reducers: {
      /**
       * Adds Group to the groups list
       */
      addGroup (state: VideoDataState, action: PayloadAction<GroupMap>) {
        if (state.state === DATA_LOAD_STATE.NONE) {
          return
        }

        state.data.set(action.payload.id, action.payload)
      },

      /**
       * Sets Location data
       */
      addLocation (state: VideoDataState, action: PayloadAction<LocationArgs>) {
        if (state.state === DATA_LOAD_STATE.NONE) {
          return
        }

        const {
          groupId,
          location
        } = action.payload
        const group = state.data.get(groupId) as GroupMap
        group.locations.set(location.id, location)
      },

      deleteGroup (state: VideoDataState, action: PayloadAction<GroupId>) {
        const groupId = action.payload
        state.data.delete(groupId)
      },

      deleteLocation (state: VideoDataState, action: PayloadAction<{ groupId: GroupId, locationId: LocationId }>) {
        const { groupId, locationId } = action.payload
        state.data.get(groupId)?.locations.delete(locationId)
      },

      /**
       * Appends given Groups data to the stored list of groups
       */
      loadData (state: VideoDataState, action: PayloadAction<GroupsMap>) {
        state.data = new Map([
          ...state.data,
          ...action.payload
        ])
      },

      reset () {
        return initialState
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(authActions.reset, () => {
          return initialState
        })
        .addCase(loadGroups.pending, () => {
          return {
            state: DATA_LOAD_STATE.STARTED,
            data: new Map()
          }
        })
        .addCase(loadGroups.fulfilled, (state) => {
          /*
          * Sets the slice's state to LOADED
          *  (means the data has been successfully fetched from the backend and saved to the store)
          */
          state.state = DATA_LOAD_STATE.LOADED
        })
        .addCase(loadGroups.rejected, (state, action) => {
          state.state = DATA_LOAD_STATE.FAILED
          if (action.error.code === undefined) {
            return
          }

          switch (action.error.code) {
            case ERROR_CODE.INVALID_INPUT_DATA:
            default:
            {
              state.error = I18N.main.ERROR_INVALID_DATA
            }
          }
        })
        .addCase(loadGroup.fulfilled, (state, action) => {
          if (action.payload !== undefined) {
            state.data.set(action.payload.id, action.payload)
          }
        })

      if (extraReducers !== undefined) {
        extraReducers(builder)
      }
    }
  })

  return {
    ...slice,

    loadGroup,
    loadGroups,

    selectData: (rootState: RootState): GroupData[] => {
      return groupsMapToArray(rootState[sliceName].data)
    },
    selectLoaded: (rootState: RootState): boolean => {
      return rootState[sliceName].state === DATA_LOAD_STATE.LOADED
    },
    selectLoading: (rootState: RootState): boolean => {
      return rootState[sliceName].state === DATA_LOAD_STATE.STARTED
    },
    selectLocationIds: (rootState: RootState): LocationId[] => {
      const groups = rootState[sliceName].data as GroupsMap
      const locationIds: LocationId[] = []
      for (const { locations } of groups.values()) {
        for (const { id } of locations.values()) {
          locationIds.push(id)
        }
      }
      return locationIds
    },

    selectNotInitialized: (rootState: RootState): boolean => {
      return rootState[sliceName].state === DATA_LOAD_STATE.NONE
    },
    selectLoadError: (rootState: RootState): string|undefined => {
      if (rootState[sliceName].state !== DATA_LOAD_STATE.FAILED) {
        return
      }

      return rootState[sliceName].error
    },
    selectIsValidLocation: (locationId?: LocationId) => (rootState: RootState): boolean|undefined => {
      if (locationId === undefined) {
        return false
      }

      if (rootState[sliceName].state !== DATA_LOAD_STATE.LOADED) {
        return undefined
      }

      return Array
        .from((rootState[sliceName].data as GroupsMap).values())
        .findIndex(({ locations }) => locations.has(locationId)) !== -1
    }
  }
}
