import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Typography, withStyles } from "@material-ui/core";

import I18N from "../app/i18n/strings";

interface ProgressProps {
  variant?: "text" | "line";
}

const SlimLinearProgress = withStyles(() => ({
  root: {
    position: "absolute",
    height: 3,
    left: 0,
    right: 0,
  },
}))(LinearProgress);

export default function DelayedProgress(props: ProgressProps): ReactElement {
  const variant = props.variant ?? "line";
  const { t } = useTranslation();
  return (
    <div style={{ position: "relative" }}>
      <Fade in style={{ transitionDelay: "800ms" }}>
        {variant === "line" ? (
          <SlimLinearProgress />
        ) : (
          <Typography variant="body2">
            {t(I18N.main.common.MSG_LOADING_PROGRESS)}
          </Typography>
        )}
      </Fade>
    </div>
  );
}
