import I18N from './strings'

const STRINGS = {
  translation: {
    // Charts/Dashboard
    [I18N.chart.DATE_RANGE_LABEL_DAYS_OF_WEEK]: 'Дни недели',
    [I18N.chart.DATE_RANGE_LABEL_DATE_FROM]: 'Период (от)',
    [I18N.chart.DATE_RANGE_LABEL_DATE_TO]: 'Период (до)',
    [I18N.chart.DATE_RANGE_TITLE]: 'Анализируемый период',
    [I18N.chart.LEGEND_BIKES]: 'Велосипедисты',
    [I18N.chart.LEGEND_CARS]: 'Автомобили',
    [I18N.chart.LEGEND_MEN]: 'Пешеходы',
    
    [I18N.chart.LEGEND_YOUNG]: '0-15 лет',
    [I18N.chart.LEGEND_MIDDLE]: '15-45 лет',
    [I18N.chart.LEGEND_OLD]: '45+ лет',
    [I18N.chart.LEGEND_UNKNOWN_AGE]: 'Не определён',

    [I18N.chart.LEGEND_FEMALE]: 'Женщины',
    [I18N.chart.LEGEND_MALE]: 'Мужчины',
    [I18N.chart.LEGEND_UNKNOWN_GENDER]: 'Не определён',

    [I18N.chart.LEGEND_YOUNGMEN]: 'Мужчины (0-15)',
    [I18N.chart.LEGEND_MIDDLEMEN]: 'Мужчины (15-45)',
    [I18N.chart.LEGEND_OLDMEN]: 'Мужчины (45+)',
    [I18N.chart.LEGEND_UNKNOWNMEN]: 'Мужчины (Н/О)',

    [I18N.chart.LEGEND_YOUNGWOMEN]: 'Женщины (0-15)',
    [I18N.chart.LEGEND_MIDDLEWOMEN]: 'Женщины (15-45)',
    [I18N.chart.LEGEND_OLDWOMEN]: 'Женщины (45+)',
    [I18N.chart.LEGEND_UNKNOWNWOMEN]: 'Женщины (Н/О)',
    
    [I18N.chart.LEGEND_YOUNGUNKNOWN]: 'Н/О (0-15)',
    [I18N.chart.LEGEND_MIDDLEUNKNOWN]: 'Н/О (15-45)',
    [I18N.chart.LEGEND_OLDUNKNOWN]: 'Н/О (45+)',
    [I18N.chart.LEGEND_UNKNOWNUNKNOWN]: 'Н/О (45+)',


    [I18N.chart.LEGEND_ETC]: 'Остальные',
    [I18N.chart.LEGEND_TITLE]: 'Тип анализируемых объектов',
    [I18N.chart.LOCATION_PICKER_CHECKBOX_LABEL]: 'Сравнительный анализ',
    [I18N.chart.OPTIONS_PANEL_TITLE]: 'Настройки',
    [I18N.chart.OPTIONS_PANEL_SHOW_BUTTON]: 'Показать результат',
    [I18N.chart.OPTIONS_PANEL_BUTTON]: 'Сбросить фильтры',
    [I18N.chart.OPTIONS_PANEL_TOGGLE_BUTTON]: 'Свернуть',
    [I18N.chart.MSG_EMPTY_QUERY_RESULT]: 'Не найдено данных, удовлетворяющих заданным фильтрам. Возможно, аналитика еще не готова',
    [I18N.chart.MSG_LOADING]: 'Загрузка данных...',
    [I18N.chart.MSG_SELECT_LOCATION]: 'Выберите Локацию для отображения аналитики',
    [I18N.chart.TIME_RANGE_LABEL_TIME_FROM]: 'Время (от)',
    [I18N.chart.TIME_RANGE_LABEL_TIME_TO]: 'Время (до)',
    [I18N.chart.TIME_RANGE_LABEL_SELECT]: 'Разрез анализа',
    [I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_MINUTE]: 'Минута',
    [I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_HOUR]: 'Час',
    [I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_DAY]: 'День',
    [I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_WEEK]: 'Неделя',
    [I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_MONTH]: 'Месяц',
    [I18N.chart.TITLE]: 'Аналитика с локации',
    [I18N.chart.TITLE_RAW_RESULT_CHART_TITLE]: 'Кол-во появлений в час за период с {{from}} — {{to}}',
    [I18N.chart.TITLE_RELATIVE_CHART]: 'Соотношение по типу',
    [I18N.chart.TITLE_RELATIVE_WALKERS_CHART]: 'Соотношение пешеходов по типу',
    [I18N.chart.TITLE_AVERAGE_CHART]: 'Среднее кол-во появлений за день',
    [I18N.chart.TITLE_MAX_CHART]: 'Максимальное кол-во появлений за день',
    [I18N.chart.TITLE_MIN_CHART]: 'Минимальное кол-во появлений за день',
    [I18N.chart.TITLE_SUM_CHART]: 'Суммарное кол-во появлений за день',
    [I18N.chart.TOOLTIP_AVERAGE[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», а временной период поставили «01.01.2020 — 07.01.2020» (7 дней).',
    [I18N.chart.TOOLTIP_AVERAGE[1]]: 'Это значит, что в результатах анализа будет 7 значений, где каждое значение — это среднее количество появлений за 1 час (среднее из 24-х часов) в каждый конкретный день.',
    [I18N.chart.TOOLTIP_AVERAGE_SUMMARY]: 'Сколько появлений было зафиксировано в среднем за час в конкретный день. Анализ отображает весь выбранный период и группируется по дням.',
    [I18N.chart.TOOLTIP_MAX[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», а временной период поставили «01.01.2020 — 07.01.2020» (7 дней).',
    [I18N.chart.TOOLTIP_MAX[1]]: 'Это значит, что в результатах анализа будет 7 значений, где каждое значение — это максимальное количество появлений за 1 час (выбирается час с максимальным количеством появлений) в каждый конкретный день.',
    [I18N.chart.TOOLTIP_MAX_SUMMARY]: 'Какое максимальное количество появлений было зафиксировано за час в конкретный день. Анализ отображает весь выбранный период и группируется по дням.',
    [I18N.chart.TOOLTIP_MIN[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», а временной период поставили «01.01.2020 — 07.01.2020» (7 дней).',
    [I18N.chart.TOOLTIP_MIN[1]]: 'Это значит, что в результатах анализа будет 7 значений, где каждое значение — это минимальное количество появлений за 1 час (выбирается час с минимальным количеством появлений) в каждый конкретный день.',
    [I18N.chart.TOOLTIP_MIN_SUMMARY]: 'Какое минимальное количество появлений было зафиксировано за час в конкретный день. Анализ отображает весь выбранный период и группируется по дням.',
    [I18N.chart.TOOLTIP_RAW[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», ' +
      'а временной период поставили «01.01.2020 — 07.01.2020» (7 дней). ',
    [I18N.chart.TOOLTIP_RAW[1]]: 'Это значит, что в результатах анализа будет 168 значений (24х7), ' +
      'где каждое значение — это количество появлений за 1 час.',
    [I18N.chart.TOOLTIP_RAW_SUMMARY]: 'Сколько появлений за час было зафиксировано в конкретный день. Анализ отображает весь выбранный период и группируется по часам.',
    [I18N.chart.TOOLTIP_TOTAL_PIE[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», ' +
    'а временной период поставили «01.01.2020 — 07.01.2020» (7 дней).',
    [I18N.chart.TOOLTIP_TOTAL_PIE[1]]: 'Это значит, что в результатах анализа будет 168 значений (24х7), где каждое значение — это количество объектов за 1 час.',
    [I18N.chart.TOOLTIP_TOTAL_PIE_SUMMARY]: 'Сколько объектов за час было зафиксировано в конкретный день. Анализ отображает весь выбранный период и группируется по часам.',
    [I18N.chart.TOOLTIP_RELATIVE[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «Час», ' +
      'а временной период поставили «01.01.2020 — 07.01.2020» (7 дней). ' +
      'Также вы выбрали несколько объектов для анализа - «Велосипедисты», «Пешеходы» и «Автомобили».',
    [I18N.chart.TOOLTIP_RELATIVE[1]]: 'Это значит, что в результатах анализа будет 3 значения, где каждое значение ' +
      '- это процент или отношение количества конкретного типа объектов к общему количеству всех 3-х типов объектов, ' +
      'суммарно за все 7 дней по 24 часа.',
    [I18N.chart.TOOLTIP_RELATIVE_SUMMARY]: 'Это процентное соотношение объектов, зафиксированных за выбранный период (если выбрано несколько объектов для анализа).',
    [I18N.chart.TOOLTIP_SUM[0]]: 'Например, в фильтрах анализа в пункте «Разрез анализа» вы выбрали «День» ' +
      '(это означает, что результат будет группироваться по «Дням»), а временной период поставили «01.01.2020 — 07.01.2020» (7 дней).',
    [I18N.chart.TOOLTIP_SUM[1]]: 'Это значит, что в результатах анализа будет 7 значений, где каждое значение — это сумма появлений за 1 день по всем часам.',
    [I18N.chart.TOOLTIP_SUM_SUMMARY]: 'Сколько суммарно появлений было зафиксировано в конкретный день. Анализ отображает весь выбранный период и группируется по дням.',

    // Common
    [I18N.common.ERROR_FIELD_REQUIRED]: 'Это обязательное поле',
    [I18N.common.ERROR_UNKNOWN]: 'Неизвестная ошибка',
    [I18N.common.LINK_ANALYTICS]: 'Аналитика',
    [I18N.common.LINK_CREATE_ACCOUNT]: 'Нет аккаунта Visius? Создать',
    [I18N.common.LINK_FORGOT_PASSWORD]: 'Забыли пароль?',
    [I18N.common.LINK_HOME]: 'Главная',
    [I18N.common.LINK_LOCATION]: 'Данные с локации',
    [I18N.common.LINK_LOGIN]: 'Вход в личный кабинет',
    [I18N.common.LINK_LOGOUT]: 'Выход',
    [I18N.common.LINK_MAIN]: 'На главную',
    [I18N.common.LINK_PAYMENTS]: 'Настройки тарифа',
    [I18N.common.LINK_PROFILE]: 'Настройки профиля',
    [I18N.common.LINK_HELP]: 'Помощь',
    [I18N.common.LINK_SUPPORT]: 'Поддержка',
    [I18N.common.OK]: 'OK',
    [I18N.common.CANCEL]: 'Отмена',
    [I18N.common.PLEASE_WAIT]: 'Пожалуйста, подождите...',
    [I18N.common.SELECT_ADMIN_VIEW]: 'Пользователь:',
    [I18N.common.TITLE_MY_ACCOUNT]: 'Мой аккаунт',
    [I18N.common.TITLE_OTHER_ACCOUNTS]: 'Другие пользователи',
    [I18N.common.VALIDATION_DATE_INVALID]: 'Некорректная дата',
    [I18N.common.VALIDATION_DATE_TOO_EARLY]: 'Дата далеко в прошлом',
    [I18N.common.VALIDATION_DATE_TOO_FAR]: 'Дата в будущем',
    [I18N.common.BTN_BACK]: 'Назад',
    [I18N.common.BTN_CONTINUE]: 'Продолжить',
    [I18N.common.BTN_CHANGE]: 'Изменить',
    [I18N.common.BTN_SENT]: 'Отправить',

    // Confirm email
    [I18N.confirmEmail.BTN_RESEND]: 'Повторить',
    [I18N.confirmEmail.LINK_GO_HOME]: 'Перейти на главную страницу',
    [I18N.confirmEmail.MSG_INVALID_LINK]: 'Ссылка для подтверждения email недействительна. Попробуйте запросить ссылку еще раз',
    [I18N.confirmEmail.MSG_RESEND_COMPLETED]: 'Повторное письмо для подтверждения email-адреса отправлено на {{email}}',
    [I18N.confirmEmail.MSG_RESEND_FAILED]: 'Не удалось отправить ссылку для подтверждения email: {{ message }}',
    [I18N.confirmEmail.MSG_SEND_COMPLETED]: 'Письмо для подтверждения email-адреса отправлено на {{ email }}',
    [I18N.confirmEmail.TITLE_BASIC]: 'Подтверждение email',
    [I18N.confirmEmail.TITLE_ERROR]: 'Не удалось подтвердить email',
    [I18N.confirmEmail.TITLE_PLEASE_CONFIRM]: 'Подтвердите email',
    [I18N.confirmEmail.TITLE_SUCCESS]: 'Email-адрес подтвержден',

    // Reset password
    [I18N.passwordReset.BTN_BACK]: 'Назад',
    [I18N.passwordReset.BTN_CHANGE_PASSWORD]: 'Сменить пароль',
    [I18N.passwordReset.BTN_RESET_REQUESTED]: 'Понятно',
    [I18N.passwordReset.BTN_RESET]: 'Сбросить пароль',
    [I18N.passwordReset.BTN_RETRY]: 'Повторить',
    [I18N.passwordReset.ERROR_PASSWORDS_MISMATCH]: 'Введенные пароли не совпадают',
    [I18N.passwordReset.ERROR_PHONE_NUMBER_INCORRECT]: 'Неверный формат номера. Пожалуйста, используйте формат +Х ХХХ ХХХ ХХХХ',
    [I18N.passwordReset.ERROR_RESET_FAILED_NOT_FOUND]: 'Пользователь с указанным email не найден',
    [I18N.passwordReset.ERROR_RESET_FAILED]: 'Не удалось сбросить пароль',
    [I18N.passwordReset.LABEL_CONFIRMATION]: 'Повторите новый пароль',
    [I18N.passwordReset.LABEL_NEW_PASSWORD]: 'Новый пароль',
    [I18N.passwordReset.LINK_RESET]: 'Забыли пароль?',
    [I18N.passwordReset.MSG_CHECK_EMAIL]: 'Если ваш email зарегистрирован в системе, то в ближайшее время вам поступит письмо со ссылкой для изменение пароля. Перейдите по ссылке и создайте новый пароль',
    [I18N.passwordReset.MSG_COMPLETED]: 'Теперь у вас новый пароль. Не забывайте его!',
    [I18N.passwordReset.MSG_NEW_PASSWORD]: 'Придумайте новый пароль для входа в аккаунт',
    [I18N.passwordReset.MSG_WELCOME]: 'Укажите email, и мы пришлем Вам ссылку для смены пароля',
    [I18N.passwordReset.TITLE_BASIC]: 'Сброс пароля',
    [I18N.passwordReset.TITLE_CHECK_EMAIL]: 'Проверьте почту',
    [I18N.passwordReset.TITLE_COMPLETED]: 'Ваш пароль изменен',
    [I18N.passwordReset.TITLE_RESET]: 'Смена пароля',
    [I18N.passwordReset.ERROR_INVALID_PASSWORD_LENGTH]: 'Минимальная длина пароля - 6 символов',
    [I18N.passwordReset.ERROR_INVALID_PASSWORD_SYMBOL]: 'Требуется хотя бы 1 символ из списка:!"#$%&\'()*+,-./:;><=?@[\\]^_`{|}~',
    [I18N.passwordReset.ERROR_INVALID_PASSWORD_UPPERCASE]: 'Пароль должен содержать хотя бы 1 заглавную букву',
    [I18N.passwordReset.ERROR_INVALID_PASSWORD_NUMBER]: 'Пароль должен содержать хотя бы 1 цифру (0-9)',

    // Sign In
    [I18N.signIn.BTN_LOGIN]: 'Войти в аккаунт',
    [I18N.signIn.ERROR_INVALID_INPUT_DATA]: 'Введён неверный email или пароль',
    [I18N.signIn.ERROR_UNKNOWN]: 'Неизвестная ошибка входа в систему',
    [I18N.signIn.LABEL_PASSWORD]: 'Пароль',
    [I18N.signIn.LINK_SIGN_UP]: 'Создать',
    [I18N.signIn.MSG_NO_ACCOUNT]: 'У вас еще нет аккаунта?',
    [I18N.signIn.TITLE_SIGN_IN]: 'Вход в аккаунт',

    // Profile
    [I18N.profile.BTN_BACK]: 'Назад',
    [I18N.profile.BTN_CONFIRM_EMAIL]: 'Подтвердить электронную почту',
    [I18N.profile.BTN_CHANGE_PASSWORD]: 'Сменить пароль',
    [I18N.profile.BTN_EDIT_PROFILE]: 'Сохранить',
    [I18N.profile.LABEL_COMPANY]: 'Компания',
    [I18N.profile.LABEL_CONFIRMATION]: 'Подтверждение пароля',
    [I18N.profile.LABEL_EMAIL]: 'Эл. почта',
    [I18N.profile.LABEL_NAME]: 'Имя',
    [I18N.profile.LABEL_NEW_PASSWORD]: 'Новый пароль',
    [I18N.profile.LABEL_OLD_PASSWORD]: 'Старый пароль',
    [I18N.profile.LABEL_PASSWORD]: 'Пароль',
    [I18N.profile.LABEL_PHONE]: 'Телефон',
    [I18N.profile.LINK_NOTIFICATIONS]: 'Уведомления',
    [I18N.profile.LINK_PAYMENTS]: 'Тарифы и платежи',

    [I18N.profile.LINK_PROFILE]: 'Мой профиль',
    [I18N.profile.MSG_NOT_IMPLEMENTED]: 'Страница находится в разработке',
    [I18N.profile.NOTIFY_PROFILE_SAVED]: 'Изменения успешно сохранены',
    [I18N.profile.NOTIFY_PROFILE_SAVE_ERROR]: 'Не удалось сохранить изменения',
    [I18N.profile.PLACEHOLDER_COMPANY]: 'Название вашей компании',
    [I18N.profile.PLACEHOLDER_CONFIRMATION]: 'Повторите новый пароль',
    [I18N.profile.PLACEHOLDER_EMAIL]: 'user@visius.com',
    [I18N.profile.PLACEHOLDER_NAME]: 'Ваше имя',
    [I18N.profile.PLACEHOLDER_PASSWORD]: 'Ваш пароль',
    [I18N.profile.PLACEHOLDER_NEW_PASSWORD]: 'Введите новый пароль',
    [I18N.profile.PLACEHOLDER_OLD_PASSWORD]: 'Введите старый пароль',
    [I18N.profile.PLACEHOLDER_PHONE]: 'Номер вашего телефона',
    [I18N.profile.ERROR_INCORRECT_PASSWORD]: 'Введён неверный пароль',
    [I18N.profile.TITLE_CHANGE_PASSWORD]: 'Смена пароля',
    [I18N.profile.TITLE_NOTIFICATIONS]: 'Уведомления',
    [I18N.profile.TITLE_PAYMENTS]: 'Тарифы и платежи',
    [I18N.profile.TITLE_PROFILE]: 'Мой профиль',
    [I18N.profile.TITLE_TARIFF_SELECT]: 'Выбор тарифа',

    // Sign Up
    [I18N.signUp.BTN_SIGNUP]: 'Создать аккаунт',
    [I18N.signUp.ERROR_DUPLICATE_USER_EMAIL]: 'Email уже зарегистрирован в системе',
    [I18N.signUp.ERROR_INVALID_USER_EMAIL]: 'Проверьте правильность email-адреса',
    [I18N.signUp.ERROR_INVALID_INPUT_DATA]: 'Проверьте правильность email-адреса и сложность пароля',
    [I18N.signUp.ERROR_AUTH_FAILED]: 'Не удалось выполнить вход в систему',
    [I18N.signUp.LABEL_CONFIRMATION]: 'Подтвердите пароль',
    [I18N.signUp.LABEL_NAME]: 'Имя',
    [I18N.signUp.LABEL_PASSWORD]: 'Придумайте пароль',
    [I18N.signUp.MSG_HAS_ACCOUNT]: 'Уже есть аккаунт Visius?',
    [I18N.signUp.MSG_LICENSE_AGREEMENT]: 'Нажимая кнопку, Вы принимаете условия',
    [I18N.signUp.LINK_SIGN_IN]: 'Войти',
    [I18N.signUp.LINK_LICENSE_AGREEMENT]: 'Пользовательского соглашения',
    [I18N.signUp.TITLE_BASIC]: 'Новый аккаунт',

    // Location
    [I18N.location.BTN_ADD_FILES]: 'Добавить видеофайлы',
    [I18N.location.BTN_ADD_STREAM]: 'Подключить веб-камеру',
    [I18N.location.BTN_DELETE_VIDEO]: 'Удалить навсегда',
    [I18N.location.BTN_MOVE_VIDEO]: 'Переместить',
    [I18N.location.BTN_SAVE_LINE]: 'Сохранить линию',
    [I18N.location.BTN_STEP_BACK]: 'Назад',
    [I18N.location.BTN_STEP_CONNECT_STREAM]: 'Подключить',
    [I18N.location.BTN_STEP_DRAW_LINE]: 'Добавить линию',
    [I18N.location.BTN_STEP_START_ANALYSIS]: 'Начать анализ',
    [I18N.location.BTN_STEP_CONNECT_CAMERA]: 'Включить веб-камеру',
    [I18N.location.BTN_STEP_DISABLE_CAMERA]: 'Отключить веб-камеру',
    [I18N.location.BTN_STEP_UPLOAD]: 'Загрузить',
    [I18N.location.BTN_WATCH]: 'Смотреть',
    [I18N.location.BTN_VIEW_ANALYTICS]: 'Аналитика по локации',
    [I18N.location.MSG_DELETE_VIDEOS_1]: 'Вы уверены, что хотите безвозвратно удалить выбранные объекты?',
    [I18N.location.MSG_DELETE_VIDEOS_2]: 'После удаления их невозможно будет восстановить, но данные с результатами анализа останутся в системе.',
    [I18N.location.MSG_DELETE_VIDEOS_TITLE]: 'Подтверждение удаления',
    [I18N.location.MSG_DELETE_VIDEOS]: 'Файл будет безвозвратно удален',
    [`${I18N.location.MSG_DELETE_VIDEOS}_1`]: 'Файлы будут безвозвратно удалены',
    [`${I18N.location.MSG_DELETE_VIDEOS}_2`]: 'Файлы будут безвозвратно удалены',
    [I18N.location.DLG_DELETE_VIDEOS]: 'Удалить {{count}} видеофайл?',
    [I18N.location.DLG_REPLACE_VIDEOS]: 'Выбор локации, куда переместить',
    [`${I18N.location.DLG_DELETE_VIDEOS}_1`]: 'Удалить {{count}} видеофайла?',
    [`${I18N.location.DLG_DELETE_VIDEOS}_2`]: 'Удалить {{count}} видеофайлов?',
    [I18N.location.MSG_CREATE_GROUP]: 'создать группу',
    [I18N.location.MSG_CREATE_LOCATION]: 'создать локацию',
    [I18N.location.MSG_EDIT_LINE]: 'Начертите линию разметки для подсчета количества объектов, пересекающих ее.',
    [I18N.location.MSG_EDIT_LINE_TWO]: 'Данная линия скопируется на все уже загруженные видео этой локации, а также на добавляемые в будущем.',
    [I18N.location.MSG_EMPTY]: 'Пока ничего нет. Загрузите хотя бы одно видео. Все анализируемые видеофайлы должны быть сняты с одного места и ракурса.',
    [I18N.location.MSG_ENTER_STREAM_URL]: 'Введите RTSP-ссылку для подключения к камере:',
    [I18N.location.MSG_LOCATION_REQUIRED]: 'Прежде, чем загрузить видеофайлы или подключить веб-камеру, необходимо указать Группу и Локацию.',
    [I18N.location.MSG_NUM_FILES]: '{{count}} видеофайл',
    [`${I18N.location.MSG_NUM_FILES}_1`]: '{{count}} видеофайла',
    [`${I18N.location.MSG_NUM_FILES}_2`]: '{{count}} видеофайлов',
    [I18N.location.MSG_SELECT_EMPTY_LOCATIONS]: 'Нет локаций. Введите имя, чтобы создать',
    [I18N.location.MSG_SELECT_EMPTY_GROUPS]: 'Нет групп. Введите название, чтобы создать',
    [I18N.location.MSG_SELECT_GROUP]: 'Выберите Группу локаций',
    [I18N.location.MSG_SELECT_LOCATION]: 'Выберите Локацию',
    [I18N.location.MSG_SELECT_TZ]: 'Выберите часовой пояс, в котором идет онлайн-трансляция:',
    [I18N.location.MSG_SET_DATE_TIME]: 'Перед загрузкой видеофайлов выставите точную дату и время начала съёмки каждого видео',
    [I18N.location.MSG_STREAM_URL_SAMPLES]: 'Примеры ссылок:',
    [I18N.location.MSG_STREAM_IS_CONNECTED]: 'Веб-камера ещё подключена',
    [I18N.location.MSG_STREAM_TIME]: '{{hours}}ч.',
    [I18N.location.MSG_TIMEZONE_WARNING_L1]: 'Перед подключением веб-камеры убедитесь, что часовой пояс выставлен корректно. ',
    [I18N.location.MSG_TIMEZONE_WARNING_L2]: 'Это необходимо для корректной аналитики. В дальнейшем поменять часовой пояс будет невозможно.',
    [I18N.location.MSG_UPLOADING]: 'Загрузка файла...',
    [I18N.location.MSG_UPLOAD_NOT_VALID_FILE]: 'Этот файл имеет неподходящий формат',
    [I18N.location.MSG_UPLOAD_PROCESSING]: 'Предварительная обработка файла...',
    [I18N.location.MSG_UPLOAD_COMPLETED]: 'Файл загружен',
    [I18N.location.MSG_UPLOAD_FAILED]: 'Загрузка файла завершилась с ошибкой',
    [I18N.location.MSG_BILLING_FAILED]: 'Загрузка файла невозможна: израсходован пакет времени',
    [I18N.location.MSG_VIDEO_BUSY]: 'Пожалуйста, дождитесь окончания обработки видео',
    [I18N.location.MSG_VIDEO_NOT_FOUND]: 'Видеофайл не найден',
    [I18N.location.MSG_WAIT_CREATION]: 'Загрузка данных...',
    [I18N.location.MSG_WAIT_UPLOADS]: 'Пожалуйста, дождитесь окончания загрузки и обработки видео',
    [I18N.location.NOTIFY_PROCESSING_REQUESTED]: 'Запрос отправлен',
    [I18N.location.NOTIFY_PROCESSING_PENDING]: 'Видео находится в очереди, процесс займёт чуть больше времени',
    [I18N.location.NOTIFY_PROCESSING_PREPARING_INFRASTRUCTURE]: 'Подготовка к анализу...',
    [I18N.location.NOTIFY_PROCESSING_PREPARING_INFRASTRUCTURE_ERROR]: 'Произошла ошибка. Попробуйте загрузить видео ещё раз...',
    [I18N.location.NOTIFY_PROCESSING_ANALYSIS]: 'Видео анализируются',
    [I18N.location.NOTIFY_PROCESSING_INFRASTRUCTURE_PREPARED]: 'Подготовка к анализу закончена',
    [I18N.location.NOTIFY_PROCESSING_STOPPING]: 'Анализ видеопотока\\видеофайла завершается',
    [I18N.location.NOTIFY_PROCESSING_STOPPING_ERROR]: 'Произошла ошибка. Попробуйте загрузить видео ещё раз...',
    [I18N.location.NOTIFY_PROCESSING_STOPPED]: 'Анализ видео завершён',
    [I18N.location.NOTIFY_PROCESSING_FINISHED]: 'Анализ видеопотока\\видеофайла полностью завершён',
    [I18N.location.NOTIFY_START_PROCESSING_SUCCESS]: 'Обработка видео начата. Результаты будут доступны на странице "Аналитика"',
    [I18N.location.NOTIFY_START_PROCESSING_ERROR]: 'Не удалось запустить обработку видео',
    [I18N.location.NOTIFY_STREAM_REMOVED]: 'Веб-камера {{url}} успешно удалена',
    [I18N.location.NOTIFY_STREAM_PREPARED]: 'Веб-камера {{url}} успешно подключена',
    [I18N.location.NOTIFY_STREAM_PREPARED_ERROR]: 'Не удалось подключить веб-камеру {{url}}',
    [I18N.location.NOTIFY_STREAM_SAVED]: 'Параметры веб-камеры успешно сохранены',
    [I18N.location.NOTIFY_STREAM_SAVE_ERROR]: 'Не удалось сохранить параметры веб-камеры',
    [I18N.location.NOTIFY_VIDEO_PREPARED]: 'Файл {{file}} успешно обработан',
    [I18N.location.NOTIFY_VIDEO_PREPARED_ERROR]: 'Файл {{file}} не обработан: \'{{errorMessage}}\'',
    [I18N.location.NOTIFY_VIDEO_REMOVED]: 'Файл {{file}} успешно удалён',
    [I18N.location.NOTIFY_VIDEO_SAVED]: 'Параметры видео успешно сохранены',
    [I18N.location.NOTIFY_VIDEO_SAVE_ERROR]: 'Не удалось сохранить параметры видео',
    [I18N.location.SELECT_GROUPS]: 'Группа локаций',
    [I18N.location.SELECT_LOCATION]: 'Локация',
    [I18N.location.TAB_FILES]: 'Загрузить видеофайлы',
    [I18N.location.TAB_STREAMS]: 'Подключить веб-камеру',
    [I18N.location.TABLE_FILE_NAME]: 'Название видеофайла',
    [I18N.location.TABLE_DATE]: 'Дата и время начала видео',
    [I18N.location.TABLE_DURATION]: 'Продолжительность видео',
    [I18N.location.TABLE_SIZE]: 'Размер видеофайла',
    [I18N.location.TITLE_LOCATION_PAGE]: 'Данные с локации',
    [I18N.location.TITLE_STREAM]: 'Видео с локации',
    [I18N.location.TITLE_VIDEO]: 'Видео с локации',
    [I18N.location.TITLE_UPLOADS]: 'Загрузка видео в локацию',
    [I18N.location.TABLE_STREAM_CREATED_AT]: 'Дата подключения',
    [I18N.location.TABLE_STREAM_DISCONNECTED_AT]: 'Дата отключения',
    [I18N.location.TABLE_STREAM_URL]: 'IP-адрес камеры',
    [I18N.location.WORKFLOW_PICK_LOCATION]: 'Выбрать группу и локацию',
    [I18N.location.WORKFLOW_ADD_VIDEOS]: 'Добавить видео',
    [I18N.location.WORKFLOW_ADD_VIDEO_LINK]: 'Добавить ссылку',
    [I18N.location.WORKFLOW_PICK_DATE_TIME]: 'Указать дату и время',
    [I18N.location.WORKFLOW_PICK_TIME_ZONE]: 'Указать часовой пояс',
    [I18N.location.WORKFLOW_UPLOAD_TO_SERVER]: 'Загрузить на сервер',
    [I18N.location.WORKFLOW_SEND_TO_SERVER]: 'Отправить на сервер',
    [I18N.location.WORKFLOW_ADD_LINE]: 'Добавить линию',
    [I18N.location.WORKFLOW_START_PROCESSING]: 'Начать анализ видео',

    // Main
    [`${I18N.main.DLG_DELETE_GROUP_LOCATIONS_SUMMARY}_1`]: '{{count}} локации из группы "{{title}}" будут безвозвратно удалены',
    [`${I18N.main.DLG_DELETE_GROUP_LOCATIONS_SUMMARY}_2`]: '{{count}} локаций из группы "{{title}}" будут безвозвратно удалены',
    [`${I18N.main.DLG_EMPTY_TRASH_SUMMARY}_1`]: '{{count}} локации будут безвозвратно удалены',
    [`${I18N.main.DLG_EMPTY_TRASH_SUMMARY}_2`]: '{{count}} локаций будут безвозвратно удалены',
    [`${I18N.main.MSG_VIDEO_FILES_NUMBER}_1`]: '{{count}} видеофайла',
    [`${I18N.main.MSG_VIDEO_FILES_NUMBER}_2`]: '{{count}} видеофайлов',
    [`${I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS}_1`]: '{{count}} локации из группы \'{{group}}\' удалены',
    [`${I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS}_2`]: '{{count}} локаций из группы \'{{group}}\' удалены',
    [`${I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS}_1`]: '{{count}} локации из группы \'{{group}}\' восстановлены',
    [`${I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS}_2`]: '{{count}} локаций из группы \'{{group}}\' восстановлены',
    [`${I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS}_1`]: '{{count}} локации из группы \'{{group}}\' перенесены в корзину',
    [`${I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS}_2`]: '{{count}} локаций из группы \'{{group}}\' перенесены в корзину',
    [I18N.main.BTN_ADD_GROUP]: 'Добавить группу',
    [I18N.main.BTN_ADD_STREAM]: 'Добавить камеру',
    [I18N.main.BTN_ADD_VIDEO]: 'Загрузить видео',
    [I18N.main.BTN_ARCHIVE_GROUP]: 'Архивировать',
    [I18N.main.BTN_ARCHIVE_LOCATION]: 'Архивировать',
    [I18N.main.BTN_DELETE_ALL]: 'Удалить все навсегда',
    [I18N.main.BTN_DELETE_GROUP]: 'Удалить навсегда',
    [I18N.main.BTN_DELETE_LOCATION]: 'Удалить',
    [I18N.main.BTN_DELETE_PERM_LOCATION]: 'Удалить навсегда',
    [I18N.main.BTN_HIDE_GROUP]: 'Скрыть группу',
    [I18N.main.BTN_RENAME_GROUP]: 'Переименовать',
    [I18N.main.BTN_RENAME_LOCATION]: 'Переименовать',
    [I18N.main.BTN_RESTORE_ALL]: 'Восстановить все локации',
    [I18N.main.BTN_RESTORE_FROM_TRASH_ALL]: 'Восстановить все из корзины',
    [I18N.main.BTN_RESTORE_LOCATION]: 'Восстановить',
    [I18N.main.BTN_SHOW_GROUP]: 'Показать группу',
    [I18N.main.BTN_TRASH_ALL]: 'Переместить все в корзину',
    [I18N.main.BTN_TRASH_GROUP]: 'В корзину',
    [I18N.main.common.MSG_LOADING_PROGRESS]: 'Загрузка данных...',
    [I18N.main.DLG_CANCEL]: 'Отмена',
    [I18N.main.DLG_DELETE_GROUP_CONFIRM]: 'Удалить',
    [I18N.main.DLG_DELETE_GROUP_LOCATIONS_SUMMARY]: '{{count}} локация из группы "{{title}}" будет безвозвратно удалена',
    [I18N.main.DLG_DELETE_GROUP_LOCATIONS]: 'Удалить локации из группы?',
    [I18N.main.DLG_DELETE_GROUP_SUMMARY]: 'Группа "{{title}}" и все локации в этой группе будут безвозвратно удалены',
    [I18N.main.DLG_DELETE_GROUP]: 'Удалить группу локаций?',
    [I18N.main.DLG_DELETE_LOCATION_CONFIRM]: 'Удалить',
    [I18N.main.DLG_DELETE_LOCATION_SUMMARY]: 'Локация "{{title}}" будет безвозвратно удалена',
    [I18N.main.DLG_DELETE_LOCATION]: 'Удалить локацию?',
    [I18N.main.DLG_EMPTY_TRASH_SUMMARY]: '{{count}} локация будет безвозвратно удалена',
    [I18N.main.DLG_EMPTY_TRASH]: 'Удалить все локации из корзины?',
    [I18N.main.ERROR_LOCATION_EXISTS]: 'Локация с таким именем уже существует',
    [I18N.main.ERROR_LOCATION_NAME_REQUIRED]: 'Имя локации не может быть пустым',
    [I18N.main.LINK_ADD_LOCATION]: 'Добавить локацию',
    [I18N.main.LINK_ARCHIVE]: 'Архив',
    [I18N.main.LINK_TRASH]: 'Корзина',
    [I18N.main.LINK_VIDEOS]: 'Главная',
    [I18N.main.MSG_EMPTY_GROUP]: 'Нет локаций',
    [I18N.main.MSG_UPLOAD_COMPLETED]: 'Все видео загружены',
    [I18N.main.MSG_UPLOAD_FAILED]: '{{count}} видео не загружено',
    [I18N.main.MSG_PREPROCESSING_FAILED]: '{{count}} видео не обработано',
    [I18N.main.MSG_UPLOAD_PROGRESS]: '{{uploaded}} из {{total}}',
    [I18N.main.MSG_VIDEO_FILES_NUMBER]: '{{count}} видеофайл',
    [I18N.main.MSG_VIDEO_NONE]: 'нет видеоданных для анализа',
    [I18N.main.MSG_VIDEO_STREAM]: 'веб-камера',
    [I18N.main.MSG_PREPROCESSING_PROGRESS]: '{{count}} видео',
    [I18N.main.NOTIFY_ARCHIVE_GROUP_FAILED]: 'Не удалось перенести группу \'{{group}}\' в Архив',
    [I18N.main.NOTIFY_ARCHIVE_GROUP]: 'Группа \'{{group}}\' перенесена в Архив',
    [I18N.main.NOTIFY_ARCHIVE_LOCATION_FAILED]: 'Не удалось перенести локацию \'{{location}}\' в Архив',
    [I18N.main.NOTIFY_ARCHIVE_LOCATION]: 'Локация \'{{location}}\' перенесена в Архив',
    [I18N.main.NOTIFY_DELETE_GROUP_FAILED]: 'Ошибка при удалении группы \'{{group}}\'',
    [I18N.main.NOTIFY_DELETE_GROUP]: 'Группа \'{{group}}\' удалена',
    [I18N.main.NOTIFY_DELETE_LOCATION_FAILED]: 'Не удалось удалить локацию \'{{location}}\'',
    [I18N.main.NOTIFY_DELETE_LOCATION]: 'Локация \'{{location}}\' удалена',
    [I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS_FAILED]: 'Ошибка при удалении локаций из группы \'{{group}}\'',
    [I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS]: '{{count}} локация из группы \'{{group}}\' удалена',
    [I18N.main.NOTIFY_ERROR_CREATE]: 'Не удалось создать группу \'{{group}}\'',
    [I18N.main.NOTIFY_ERROR_RENAME]: 'Не удалось переименовать группу {{group}}',
    [I18N.main.NOTIFY_LOCATION_SAVE_FAILED]: 'Не удалось сохранить локацию \'{{location}}\'',
    [I18N.main.NOTIFY_MOVE_VIDEOS]: 'Видеофайлы успешно перенесены в локацию \'{{location}}\'',
    [I18N.main.NOTIFY_MOVE_VIDEOS_FAILED]: 'Не удалось переместить видеофайлы в локацию \'{{location}}\'',
    [I18N.main.NOTIFY_RESTORE_GROUP_FAILED]: 'Не удалось восстановить группу \'{{group}}\'',
    [I18N.main.NOTIFY_RESTORE_GROUP]: 'Группа \'{{group}}\' восстановлена',
    [I18N.main.NOTIFY_RESTORE_LOCATION_FAILED]: 'не удалось восстановить локацию \'{{location}}\'',
    [I18N.main.NOTIFY_RESTORE_LOCATION]: 'Локация \'{{location}}\' восстановлена',
    [I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS_FAILED]: 'Не удалось восстановить локации из группы \'{{group}}\'',
    [I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS]: '{{count}} локация из группы \'{{group}}\' восстановлена',
    [I18N.main.NOTIFY_TRASH_GROUP_FAILED]: 'Не удалось перенести Группу \'{{group}}\' в Корзину',
    [I18N.main.NOTIFY_TRASH_GROUP]: 'Группа \'{{group}}\' перенесена в Корзину',
    [I18N.main.NOTIFY_TRASH_LOCATION_FAILED]: 'Не удалось перенести Локации \'{{location}}\' в Корзину',
    [I18N.main.NOTIFY_TRASH_LOCATION]: 'Локация \'{{location}}\' перенесена в Корзину',
    [I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS_FAILED]: 'Не удалось перенести в Корзину локации из группы \'{{group}}\' перенесена в корзину',
    [I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS]: '{{count}} локация из группы \'{{group}}\' перенесена в корзину',
    [I18N.main.SUBTITLE_ARCHIVE]: 'Архив',
    [I18N.main.SUBTITLE_COMPLETED_UPLOADS]: 'Загружено {{count}} видео',
    [I18N.main.SUBTITLE_FAILED_UPLOADS]: 'Не загружено {{count}} видео',
    [I18N.main.SUBTITLE_PROGRESS_UPLOADS]: 'Загружается {{count}} видео',
    [I18N.main.SUBTITLE_TRASH]: 'Корзина',
    [I18N.main.SUBTITLE_VIDEO]: 'Группы и локации',
    [I18N.main.TITLE_ARCHIVE]: 'Архив',
    [I18N.main.TITLE_BELONGS_TO]: 'Из группы',
    [I18N.main.TITLE_PREPROCESSING_PROGRESS]: 'Обработка',
    [I18N.main.TITLE_WAIT_START_PREPROCESSING]: 'Ожидает обработки',
    [I18N.main.TITLE_TRASH]: 'Корзина',
    [I18N.main.TITLE_UPLOAD_PROGRESS]: 'Загрузка видео',
    [I18N.main.TITLE_VIDEO]: 'Главная страница',
    [I18N.main.TMP_GROUP_TITLE]: 'Название группы',
    [I18N.main.TMP_LOCATION_TITLE]: 'Название локации',
    [I18N.main.videoData.MSG_EMPTY]: 'Пока ничего нет. Добавьте группу локаций, чтобы начать работу',

    // Tariff
    [I18N.tariff.CURRENT_TITLE]: 'Текущий тариф',
    [I18N.tariff.CURRENT_STRING]: 'Текущий',
    [I18N.tariff.PAYMENT_DATE]: 'Дата подключения',
    [I18N.tariff.HOUR_LIMIT]: 'Лимит по тарифу',
    [I18N.tariff.HOUR_BALANCE]: 'Доступно часов',
    [I18N.tariff.CHANGE_TARIFF]: 'Сменить тариф',
    [I18N.tariff.RESUME_SUBSCRIBE]: 'Продлить подписку',
    [I18N.tariff.CHOOSE_TARIFF]: 'Выбор тарифа',
    [I18N.tariff.SELECT_TARIFF]: 'Перейти на тариф',
    [I18N.tariff.RESUME_TARIFF]: 'Продлить тариф',
    [I18N.tariff.COMMON_FEATURE1]: 'Загрузка видеофайлов',
    [I18N.tariff.COMMON_FEATURE2]: 'Подключение IP-камер',
    [I18N.tariff.COMMON_FEATURE3]: 'Аналитика по видеопотоку',
    [I18N.tariff.PROCESSING_HOURS]: 'часов обработки',
    [I18N.tariff.NOTIFY_TIME_OVER]: 'Пакет часов по тарифу израсходован',
    [I18N.tariff.BUY_MORE]: 'Купить ещё часы',
    [I18N.tariff.HOUR_BALANCE_TITLE]: 'Остаток часов',
    [I18N.tariff.HOUR_BALANCE_SUBTITLE]: 'Доступно {{uploaded}} из {{total}}',
    // Payments
    [I18N.payments.CONFIRM_TITLE]: 'Подтверждение перед оплатой',
    [I18N.payments.SELECTED_STRING]: 'Выбрано:',
    [I18N.payments.BY_TARIFF]: 'по тарифу',
    [I18N.payments.TO_PAYMENTS]: 'К оплате:',
    [I18N.payments.SELECT_PAYMENTS_TYPE]: 'Способ оплаты:',
    [I18N.payments.CARD_TYPE]: 'Банковская карта',
    [I18N.payments.PAYMENT_INVOICE_TYPE]: 'Счёт на оплату',
    [I18N.payments.OPERATION_CANCELLED]: 'Операция прервана',

    // Invoice form
    [I18N.invoice.LABEL_DESCRIPTION]: 'Введите контактные данные для заявки на формирование счёта',
    [I18N.invoice.LABEL_NAME]: 'Имя:',
    [I18N.invoice.LABEL_PHONE]: 'Телефон',
    [I18N.invoice.TITLE_SUCCESS]: 'Заявка на формирование счёта на оплату принята',
    [I18N.invoice.LABEL_SUCCESS]: 'В ближайшее время наш сотрудник свяжется с Вами для уточнения деталей счёта',
    [I18N.invoice.UNDERSTAND_BTN]: 'Понятно'
  }
}

export default STRINGS
