import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AUTH_URL } from '../../app/api-url'
import { ERROR_CODE, ErrorPayload, getErrorPayload } from '../../app/errors'
import I18N from '../../app/i18n/strings'
import { AppThunk, RootState } from '../../app/store'
import { HTTP_METHOD, request } from '../../app/utils'
import { loadUserData } from '../auth/authSlice'
import { AuthState } from '../auth/authTypes'
import { actions as notificationActions } from '../notifications/notificationsSlice'

export enum CONFIRM_EMAIL_STATE {
  NONE,
  STARTED,
  FAILED,
  COMPLETED,
  RESEND_STARTED,
  RESEND_COMPLETED
}

export interface TokenInfo {
  token: string
  userEmail: string
}

export interface ConfirmEmailState {
  state: CONFIRM_EMAIL_STATE
  error?: string
  tokenInfo?: TokenInfo
}

export const initialState: ConfirmEmailState = {
  state: CONFIRM_EMAIL_STATE.NONE
}

const confirmEmailSlice = createSlice({
  name: 'confirmEmail',
  initialState,
  reducers: {
    reset () {
      return initialState
    },
    started (state: ConfirmEmailState, action: PayloadAction<TokenInfo>) {
      state.state = CONFIRM_EMAIL_STATE.STARTED
      state.tokenInfo = action.payload
    },
    resendFailed (state: ConfirmEmailState, action: PayloadAction<ErrorPayload>) {
      state.state = CONFIRM_EMAIL_STATE.FAILED
      switch (action.payload.code) {
        case ERROR_CODE.INVALID_INPUT_DATA:
        default: {
          state.error = I18N.confirmEmail.MSG_RESEND_FAILED
        }
      }
    },
    resendStarted (state: ConfirmEmailState) {
      state.state = CONFIRM_EMAIL_STATE.RESEND_STARTED
    },
    resendCompleted (state: ConfirmEmailState) {
      state.state = CONFIRM_EMAIL_STATE.RESEND_COMPLETED
    },
    completed (state: ConfirmEmailState) {
      state.state = CONFIRM_EMAIL_STATE.COMPLETED
    },
    failed (state: ConfirmEmailState, action: PayloadAction<ErrorPayload>) {
      state.state = CONFIRM_EMAIL_STATE.FAILED
      switch (action.payload.code) {
        case ERROR_CODE.INVALID_INPUT_DATA:
        default: {
          state.error = I18N.confirmEmail.MSG_INVALID_LINK
        }
      }
    }
  }
})

export const {
  actions,
  reducer
} = confirmEmailSlice

export const requestConfirmation = (): AppThunk => async (dispatch, getState) => {
  try {
    const authState = getState().auth as AuthState
    dispatch(actions.resendStarted())
    await request(`${AUTH_URL.RESEND_EMAIL_CONFIRMATION}`, { method: HTTP_METHOD.GET })
    dispatch(actions.resendCompleted())
    dispatch(notificationActions.info({
      template: I18N.confirmEmail.MSG_RESEND_COMPLETED,
      args: { email: authState.user?.email as string }
    }))
  } catch (err) {
    const errorPayload = getErrorPayload(err)
    dispatch(actions.resendFailed(errorPayload))
    dispatch(notificationActions.error({
      template: I18N.confirmEmail.MSG_RESEND_FAILED,
      args: { message: errorPayload.message }
    }))
  }
}

export const confirmEmail = (tokenInfo: TokenInfo): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.started(tokenInfo))
    await request(AUTH_URL.CONFIRM_EMAIL, {
      method: HTTP_METHOD.PUT,
      data: {
        email: tokenInfo.userEmail,
        token: tokenInfo.token
      }
    })
    await dispatch(loadUserData())
    dispatch(actions.completed())
  } catch (err) {
    const errorPayload = getErrorPayload(err)
    dispatch(actions.failed(errorPayload))
  }
}

export const selectIsConfirmationStarted = ({ confirmEmail }: RootState): boolean => {
  return confirmEmail.state === CONFIRM_EMAIL_STATE.STARTED
}
export const selectIsConfirmationCompleted = ({ confirmEmail }: RootState): boolean => {
  return confirmEmail.state === CONFIRM_EMAIL_STATE.COMPLETED
}
export const selectResendStarted = ({ confirmEmail }: RootState): boolean => {
  return confirmEmail.state === CONFIRM_EMAIL_STATE.RESEND_STARTED
}
export const selectResendCompleted = ({ confirmEmail }: RootState): boolean => {
  return confirmEmail.state === CONFIRM_EMAIL_STATE.RESEND_COMPLETED
}
export const selectConfirmationDetails = ({ confirmEmail }: RootState): TokenInfo|undefined => {
  return confirmEmail.tokenInfo
}
export const selectConfirmationError = ({ confirmEmail }: RootState): string|undefined => {
  if (confirmEmail.state !== CONFIRM_EMAIL_STATE.FAILED) {
    return
  }

  return confirmEmail.error
}
