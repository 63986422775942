/* eslint no-void: ["error", { "allowAsStatement": true }] */
import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";

import clsx from "clsx";

import I18N from "../../../app/i18n/strings";
import { sortByDateAsc } from "../../../app/utils";

import ProgressIndicator from "../../../components/ProgressIndicator";
import { unarchiveGroup } from "../archive/asyncActions";

import { groupToGroupMap } from "../data-mapper";
import { GroupData, SliceName } from "../data-types";

import AddLocationCard from "../main/AddLocationCard";
import {
  archiveGroup,
  moveGroupToTrash,
  moveLocationsToTrash,
} from "../main/asyncActions";
import { deleteGroup, restoreGroup } from "../trash/asyncActions";
import { LocationCardProps, MainLocationCardProps } from "./BaseLocationCard";
import GroupContextMenu from "./GroupContextMenu";
import GroupRenameTextField from "./GroupRenameTextField";
import useGroupStyles from "./groupStyles";

import { ReactComponent as FolderIcon } from "../../../app/assets/folderIcon.svg";

interface MouseState {
  mouseX?: number;
  mouseY?: number;
}

interface GroupProps {
  data: GroupData;
  locationComponent:
    | React.FC<LocationCardProps>
    | React.FC<MainLocationCardProps>;
  full: boolean;
  variant: SliceName;
  editable?: boolean;
  editMode?: boolean;
  onRenameCancel?: () => void;
}

const initialMouseState: MouseState = {};

export default function LocationGroup(props: GroupProps): ReactElement {
  const classes = useGroupStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    data: group,
    locationComponent: LocationComponent,
    onRenameCancel,
  } = props;

  const [editMode, setEditMode] = useState<boolean>(Boolean(group.isTmp));
  const [expanded, setExpanded] = useState<boolean>(true);
  const [mouseState, setMouseState] = useState<MouseState>(initialMouseState);
  const [title, setTitle] = useState(group.title);

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  // Context menu lifecycle
  const handleMenuOpen = useCallback(
    (event: MouseEvent): void => {
      setMouseState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    },
    [setMouseState]
  );
  const handleMenuClose = useCallback(() => {
    setMouseState(initialMouseState);
  }, [setMouseState]);

  // Context menu actions
  // Toggle Expanded
  const handleExpanded = useCallback(() => {
    setExpanded(!expanded);
    handleMenuClose();
  }, [setExpanded, handleMenuClose, expanded]);

  // Move to Archive
  const handleMoveToArchive = useCallback(() => {
    handleMenuClose();
    const groupMap = groupToGroupMap(group);
    return dispatch(archiveGroup(groupMap));
  }, [dispatch, handleMenuClose, group]);

  // Move to Trash
  const handleMoveToTrash = useCallback(() => {
    handleMenuClose();
    const groupMap = groupToGroupMap(group);
    if (props.full) {
      return dispatch(moveGroupToTrash(groupMap));
    }

    return dispatch(moveLocationsToTrash(groupMap));
  }, [dispatch, handleMenuClose, group, props.full]);

  // Delete
  const handleDeleteGroup = useCallback(() => {
    setDeleteDialogOpen(false);
    const groupMap = groupToGroupMap(group);
    return dispatch(deleteGroup(groupMap));
  }, [dispatch, group]);
  const handleDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
    handleMenuClose();
  }, [setDeleteDialogOpen, handleMenuClose]);
  const handleDeleteCancel = useCallback(
    () => setDeleteDialogOpen(false),
    [setDeleteDialogOpen]
  );

  // Edit Mode
  const handleEditMode = useCallback(() => {
    setEditMode(true);
    handleMenuClose();
  }, [setEditMode, handleMenuClose]);

  const handleRestoreFromArchive = useCallback(() => {
    handleMenuClose();
    const groupMap = groupToGroupMap(group);
    return dispatch(unarchiveGroup(groupMap));
  }, [dispatch, handleMenuClose, group]);

  const handleRestoreFromTrash = useCallback(() => {
    handleMenuClose();
    const groupMap = groupToGroupMap(group);
    return dispatch(restoreGroup(groupMap));
  }, [dispatch, handleMenuClose, group]);

  useEffect(() => {
    if (group.error !== undefined) {
      setEditMode(true);
    }

    if (title !== group.title) {
      setEditMode(false);
      setTitle(group.title);
    }
  }, [setEditMode, setTitle, group.error, title, group.title]);

  const contextMenu = useMemo(() => {
    const commonProps = {
      variant: props.variant,
      expanded: expanded,
      mouseState: mouseState,
      onClose: handleMenuClose,
      onExpand: handleExpanded,
    };
    switch (props.variant) {
      case "videoData": {
        return (
          <GroupContextMenu
            {...commonProps}
            onArchive={handleMoveToArchive}
            onDelete={handleMoveToTrash}
            onRename={handleEditMode}
          />
        );
      }
      case "archive": {
        return (
          <GroupContextMenu
            {...commonProps}
            onDelete={handleMoveToTrash}
            onRestore={handleRestoreFromArchive}
          />
        );
      }
      case "trash": {
        return (
          <GroupContextMenu
            {...commonProps}
            onDelete={handleDeleteDialog}
            onRestore={handleRestoreFromTrash}
          />
        );
      }
    }
  }, [
    handleDeleteDialog,
    handleEditMode,
    handleRestoreFromTrash,
    handleMoveToTrash,
    handleRestoreFromArchive,
    handleMoveToArchive,
    handleMenuClose,
    handleExpanded,
    props.variant,
    expanded,
    mouseState,
  ]);

  const locationCards = useMemo(() => {
    return group.locations.sort(sortByDateAsc).map((locationData) => {
      return (
        <LocationComponent
          key={locationData.id}
          groupId={group.id}
          data={locationData}
          prohibitedTitles={group.locations
            .filter(({ id }) => id !== locationData.id)
            .map(({ title }) => title)}
        />
      );
    });
  }, [group, LocationComponent]);

  return (
    <>
      {contextMenu}
      <Accordion
        expanded={expanded}
        variant="outlined"
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: false }}
      >
        <AccordionSummary
          onClick={handleMenuOpen}
          className={clsx(classes.accordionTitle, {
            [classes.textIsGrey]: !expanded,
          })}
          IconButtonProps={{
            size: "small",
          }}
          expandIcon={
            <ExpandLess
              className={clsx({ [classes.rotateIcon]: !expanded })}
              style={{ transform: "scale(1.2)" }}
            />
          }
        >
          {props.full ? (
            <>
              <div className={classes.locationIcon}>
                {group.isBusy === true ? (
                  <ProgressIndicator />
                ) : expanded ? (
                  <FolderIcon />
                ) : (
                  <FolderIcon />
                )}
              </div>
              {editMode ? (
                <GroupRenameTextField
                  group={group}
                  onCancel={() => {
                    if (onRenameCancel !== undefined) {
                      onRenameCancel();
                    }
                    setEditMode(false);
                  }}
                />
              ) : (
                <Typography variant="h5">{group.title}</Typography>
              )}
            </>
          ) : (
            <>
              {group.isBusy === true && (
                <ProgressIndicator className={classes.locationIcon} />
              )}
              <Typography variant="body2">
                {t(I18N.main.TITLE_BELONGS_TO)}:
              </Typography>
              &nbsp;
              <Typography
                variant="body1"
                style={{ fontSize: "15px", fontWeight: 700 }}
              >
                {group.title}
              </Typography>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.locationsContainer}>
          <div className={classes.locationsList}>
            {locationCards}
            {props.editable === true && (
              <AddLocationCard disabled={group.isTmp} groupId={group.id} />
            )}
            {props.editable !== true && group.locations.length === 0 && (
              <Typography>{t(I18N.main.MSG_EMPTY_GROUP)}</Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {expanded && (
        <Divider variant="middle" style={{ margin: "0 0 32px 0" }} />
      )}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle>
          <Typography style={{ fontWeight: 600 }} variant="h4">
            {t(
              props.full
                ? I18N.main.DLG_DELETE_GROUP
                : I18N.main.DLG_DELETE_GROUP_LOCATIONS
            )}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              {t(
                props.full
                  ? I18N.main.DLG_DELETE_GROUP_SUMMARY
                  : I18N.main.DLG_DELETE_GROUP_LOCATIONS_SUMMARY,
                {
                  title: group.title,
                  count: group.locations.length,
                }
              )}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            <Typography
              style={{ textTransform: "none" }}
              color="primary"
              variant="body2"
            >
              {t(I18N.main.DLG_CANCEL)}
            </Typography>
          </Button>

          <Button
            onClick={handleDeleteGroup}
            variant="contained"
            style={{
              background: "linear-gradient(90deg, #FF5F49 0%, #FF3D00 99.94%)",
              borderRadius: 12,
            }}
            autoFocus
          >
            <Typography
              style={{ textTransform: "none", color: "white" }}
              variant="body2"
            >
              {t(I18N.main.DLG_DELETE_GROUP_CONFIRM)}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
