
export enum PASSWORD_RESET_STATE {
  NONE, // Interaction hasn't been initialized
  FAILED,
  REQUEST_CHECK_EMAIL,
  REQUEST_STARTED,
  RESET_STARTED,
  RESET_COMPLETED,
  RESET_REQUESTING
}

export interface PasswordResetState {
  state: PASSWORD_RESET_STATE
  email?: string
  error?: string
}
