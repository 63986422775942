import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { History } from 'history'
import { AUTH_URL } from '../../app/api-url'
import { deleteDatabase } from '../../app/db'
import { ERROR_CODE, getErrorPayload, RequestError } from '../../app/errors'
import I18N from '../../app/i18n/strings'
import { AppThunk, RootState } from '../../app/store'
import { HTTP_METHOD, request } from '../../app/utils'
import {
  AUTH_STATE,
  AuthState,
  FormError,
  UserProfile,
  UserDto
} from './authTypes'

export const ADMIN_VIEW_STORAGE_KEY = 'ADMIN_VIEW_USER_ID'

export const getUserData = async (): Promise<UserDto> => {
  const data = await request(AUTH_URL.ACCOUNT)
  return data as UserDto
}

export const getUsers = async (): Promise<UserDto[]> => {
  const data = await request(AUTH_URL.USER_LIST) as UserDto[]
  return data
}

interface LoginOptions {
  email: string
  password: string
}

/**
 * Try to login using given email/password
 * @param {string} email - email
 * @param {string} password - email
 */
export const login = createAsyncThunk<UserProfile, LoginOptions>(
  'auth/login',
  async ({ email, password }) => {
    try {
      await request(AUTH_URL.LOGIN, { method: HTTP_METHOD.POST, data: { email, password } })
      const user = await getUserData()
      const availableUsers = user.isAdmin
        ? await getUsers()
        : [user]

      return { ...user, availableUsers }
    } catch (err) {
      const errorPayload = getErrorPayload(err)
      throw errorPayload
    }
  }
)

interface LogoutOptions {
  redirectTo: string
  history: History
}

/**
 * Ends current user session.
 * Resets app state, clears IndexedDB, redirects to a given page
 */
export const logout = ({ history, redirectTo }: LogoutOptions): AppThunk => async (dispatch) => {
  try {
    try {
      await request(AUTH_URL.LOGOUT, { raw: true })
    } catch (err) {
      if ((err as RequestError).code !== ERROR_CODE.GENERIC_ACCESS_DENIED) {
        throw err
      }
    }

    await deleteDatabase()
    sessionStorage.removeItem(ADMIN_VIEW_STORAGE_KEY)
    dispatch(actions.reset())
    history.push(redirectTo)
  } catch (err) {
    console.error(err)
  }
}

/**
 * Try to get user profile data.
 * Succeeds if user is authenticated, fails and throws error otherwise.
 *
 * @param {boolean} throwOnFailure - set to `true` in order to throw login error on request failure
 */
export const loadUserData = createAsyncThunk<UserProfile, boolean|undefined>(
  'auth/loadUserProfile',
  async (throwOnFailure?: boolean) => {
    try {
      const user = await getUserData()
      const availableUsers = user.isAdmin
        ? await getUsers()
        : [user]

      return { ...user, availableUsers }
    } catch (err) {
      if (throwOnFailure === true) {
        const errorPayload = getErrorPayload(err)
        throw errorPayload
      }

      throw new Error('Not authorized')
    }
  }
)

export const initialState: AuthState = {
  authState: AUTH_STATE.NONE
}

type AuthRejectedAction = PayloadAction<
unknown,
string,
{ requestId: string },
SerializedError>

const handleAuthFailed = (
  state: AuthState,
  action: AuthRejectedAction,
  authErrorMsg = I18N.signIn.ERROR_INVALID_INPUT_DATA
): void => {
  state.authState = AUTH_STATE.LOGIN_FAILED
  switch (action.error.code) {
    case ERROR_CODE.NOT_FOUND:
    case ERROR_CODE.INVALID_INPUT_DATA:
    case ERROR_CODE.INVALID_PASSWORD:
    case ERROR_CODE.AUTH_INCORRECT_PASSWORD:
    case ERROR_CODE.AUTH_USER_NOT_FOUND:
    case ERROR_CODE.GENERIC_ACCESS_DENIED: {
      state.error = {
        email: [authErrorMsg]
      }
      break
    }

    case ERROR_CODE.UNKNOWN_ERROR:
    default: {
      state.error = {
        email: [I18N.signIn.ERROR_UNKNOWN]
      }
    }
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset () { return initialState },
    clearError (state: AuthState) {
      delete state.error
      state.authState = AUTH_STATE.NOT_AUTHENTICATED
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadUserData.pending, (state, action) => {
        state.authState = AUTH_STATE.AUTHENTICATING
      })
      .addCase(loadUserData.fulfilled, (state, action) => {
        state.authState = AUTH_STATE.AUTHENTICATION_COMPLETED
        state.user = action.payload
      })
      .addCase(loadUserData.rejected, (state, action) => {
        if (action.meta.arg !== true) {
          state.authState = AUTH_STATE.NOT_AUTHENTICATED
          return
        }

        // TODO: supply error message as a #loadUserData argument?
        handleAuthFailed(state, action, I18N.signUp.ERROR_AUTH_FAILED)
      })
      .addCase(login.pending, (state, action) => {
        state.authState = AUTH_STATE.LOGIN_STARTED
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authState = AUTH_STATE.AUTHENTICATION_COMPLETED
        state.user = action.payload
      })
      .addCase(login.rejected, handleAuthFailed)
  }
})

export const {
  actions,
  reducer
} = authSlice

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthNotInitialized = (state: RootState): boolean => state.auth.authState === AUTH_STATE.NONE
export const selectAuthenticating = (state: RootState): boolean => state.auth.authState === AUTH_STATE.AUTHENTICATING
export const selectLoginStarted = (state: RootState): boolean => state.auth.authState === AUTH_STATE.LOGIN_STARTED
export const selectAuthenticated = (state: RootState): boolean => Boolean(state.auth.user)
export const selectAuthFinished = (state: RootState): boolean => {
  return state.auth.authState === AUTH_STATE.AUTHENTICATION_COMPLETED ||
    state.auth.authState === AUTH_STATE.NOT_AUTHENTICATED ||
    state.auth.authState === AUTH_STATE.LOGIN_STARTED ||
    state.auth.authState === AUTH_STATE.LOGIN_FAILED
}
export const selectUserData = (state: RootState): UserProfile|undefined => {
  return state.auth.user
}
export const selectLoginError = (state: RootState): FormError|undefined => {
  if (state.auth.authState !== AUTH_STATE.LOGIN_FAILED) {
    return
  }

  return state.auth.error
}
