import React, {
  ReactElement, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { actions, getSelectedTariff, loadTariffs, selectIsTariffsLoading, selectTariffs, selectUserTariff } from '../tariffSlice'

import { Cached } from '@material-ui/icons'
import checkIcon from '../../../../app/assets/checkIcon.svg'
import { Button, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core'

import pageStyles from '../../../groups/components/pageStyles'
import I18N from '../../../../app/i18n/strings'
import { COLORS } from '../../../../theme'
import { useHistory } from 'react-router'
import tariffStyles from '../tariffStyles'

const useStyles = makeStyles((theme: Theme) => ({
  featureRow: {
    display: 'flex',
    marginBottom: theme.spacing(3)
  },
  feature: {
    marginLeft: theme.spacing(2)
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  currentBox: {
    width: 94,
    height: 25,
    backgroundColor: COLORS.BLUE5,
    color: COLORS.WHITE,
    textAlign: 'center',
    marginTop: -12,
    marginLeft: '62%'
  },
  col: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    border: '1px solid',
    borderColor: COLORS.GREY2,
    borderRadius: 8
  },
  selected: {
    borderColor: theme.palette.info.light
  },
  tariffRow: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(6.5),
    marginTop: theme.spacing(4),
    '&:first-child': {
      marginTop: theme.spacing(6),
      alignItems: 'flex-end'
    },
    '&:last-child': {
      marginTop: theme.spacing(11.5),
      marginBottom: theme.spacing(4)
    }
  },
  tariffPurchase: {
    fontSize: 22,
    marginBottom: 39
  },
  tariffName: {
    fontSize: 22
  }
}))

export default function TariffsList (): ReactElement {
  const { t } = useTranslation()
  const classes = pageStyles()
  const cardClasses = useStyles()
  const tariffClasses = tariffStyles()

  const dispatch = useDispatch()
  const history = useHistory()

  const selectedTariff = useSelector(getSelectedTariff)
  const currentTariff = useSelector(selectUserTariff)
  const tariffs = useSelector(selectTariffs)
  const isLoading = useSelector(selectIsTariffsLoading)

  const [selectedTariffId, setSelectedTariffId] = useState<number | undefined>(selectedTariff?.id)

  // Вызов как componentDidMount пока нет обновления тарифов по SignalR
  useEffect(() => {
    dispatch(loadTariffs())
  }, [dispatch])

  const navigateBack = (): void => {
    history.goBack()
  }

  const onTariffSelectClick = (): void => {
    const selectedTariff = tariffs?.find(t => t.id === selectedTariffId)
    dispatch(actions.setSelectedTariff(selectedTariff))
    history.push('?tab=payments&inner=confirmPayment')
  }
  return (
    <>
      <div className={tariffClasses.card}>
        <Card className={tariffClasses.cardWrap}>
          <CardContent className={clsx(tariffClasses.innerCard, tariffClasses.flexContent)}>
            <div>
              <Typography variant='h6' className={tariffClasses.title}>
                {t(I18N.profile.TITLE_TARIFF_SELECT)}
              </Typography>

              {isLoading
                ? (<Typography>{t(I18N.main.common.MSG_LOADING_PROGRESS)}</Typography>)
                : (
                  <div className={cardClasses.grid}>
                    {tariffs?.map(tariff => {
                      return (
                        <div
                          key={tariff.id}
                          className={clsx(cardClasses.col, tariff.id === selectedTariffId
                            ? cardClasses.selected
                            : '')}
                        >
                          {tariff.tariffSnapshotId === (currentTariff?.id ?? '') && (
                            <div className={cardClasses.currentBox}>
                              <Typography>
                                {t(I18N.tariff.CURRENT_STRING)}
                              </Typography>
                            </div>)}

                          <div className={cardClasses.tariffRow}>
                            <Typography variant='h2' className={cardClasses.tariffName}>
                              {tariff.name}
                            </Typography>
                          </div>

                          <div className={cardClasses.tariffRow}>
                            <Typography variant='h3'>
                              {tariff.maxHours} {t(I18N.tariff.PROCESSING_HOURS)}
                            </Typography>
                          </div>

                          <div className={cardClasses.tariffRow}>
                            <Typography variant='h2' className={cardClasses.tariffPurchase}>
                              {`${tariff.purchase} р`}
                            </Typography>
                          </div>

                          <div>
                            <div className={cardClasses.featureRow}>
                              <img src={checkIcon} alt='' />
                              <Typography variant='h3' className={cardClasses.feature}>
                                {t(I18N.tariff.COMMON_FEATURE1)}
                              </Typography>
                            </div>

                            <div className={cardClasses.featureRow}>
                              <img src={checkIcon} alt='' />
                              <Typography variant='h3' className={cardClasses.feature}>
                                {t(I18N.tariff.COMMON_FEATURE2)}
                              </Typography>
                            </div>

                            <div className={cardClasses.featureRow}>
                              <img src={checkIcon} alt='' />
                              <Typography variant='h3' className={cardClasses.feature}>
                                {t(I18N.tariff.COMMON_FEATURE3)}
                              </Typography>
                            </div>
                          </div>

                          <div className={cardClasses.tariffRow}>
                            <Button
                              color='primary'
                              className={classes.btn}
                              variant='contained'
                              disabled={tariff.isSystem}
                              onMouseEnter={() => setSelectedTariffId(tariff.id)}
                              onMouseLeave={() => setSelectedTariffId(undefined)}
                              onClick={onTariffSelectClick}
                            >
                              {tariff.tariffSnapshotId === (currentTariff?.id ?? '')
                                ? t(I18N.tariff.RESUME_TARIFF)
                                : t(I18N.tariff.SELECT_TARIFF)}
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
            </div>

            <div>
              <Button
                color='primary'
                className={classes.btn}
                variant='outlined'
                startIcon={<Cached />}
                onClick={navigateBack}
              >
                {t(I18N.common.BTN_BACK)}
              </Button>
            </div>

          </CardContent>
        </Card>
      </div>
    </>
  )
}
