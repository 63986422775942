import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  Button,
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { ParentSize } from "@visx/responsive";

import { startProcessing } from "../locationSlice";
import {
  getLoadedSelector,
  getStreamSelector,
  getVideoSelector,
} from "../selectors";
import deepEqual from "deep-equal";

import I18N from "../../../app/i18n/strings";
import { useAppLayoutStyles } from "../../../app/styles";

import WorkFlow from "../workflow/WorkFlow";
import { LocationId } from "../../groups/data-types";
import VisiusLink from "../../../components/Link";
import DrawLine, { Line } from "./components/DrawLine";

import useStyles from "../styles";
import clsx from "clsx";

/* Line coords for Videos must be converted to the following dimansions */
const VIDEO_LINE_TARGET_WIDTH = 1280;
const VIDEO_LINE_TARGET_HEIGHT = 720;

interface TabProps {
  locationId?: LocationId;
}

const useCustomStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  workFlowWrap: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  message: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  submitBtn: {
    marginTop: theme.spacing(2),
  },
}));

interface LineEditorProps {
  url: string;
  onChange: (line: Line) => void;

  line?: Line;
  targetWidth?: number;
  targetHeight?: number;
  locationId?: string;
}

function LineEditor(props: LineEditorProps): ReactElement {
  const classes = useCustomStyles();
  const { t } = useTranslation();
  const { line, url, targetWidth, targetHeight, locationId, onChange } = props;

  return (
    <ParentSize>
      {(parent) => (
        <>
          <div className={classes.workFlowWrap}>
            <WorkFlow addLineStep={true} locationId={locationId} />
          </div>
          <Container className={classes.container}>
            <Typography variant="body2" className={classes.message}>
              {t(I18N.location.MSG_EDIT_LINE)}
              <br />
              {t(I18N.location.MSG_EDIT_LINE_TWO)}
            </Typography>
            <DrawLine
              width={Math.ceil(parent.width)}
              points={line}
              imgUrl={url}
              onChange={onChange}
              targetWidth={targetWidth}
              targetHeight={targetHeight}
            />
          </Container>
        </>
      )}
    </ParentSize>
  );
}

export default function LineEditorTab(props: TabProps): ReactElement {
  const { locationId } = props;
  const appClasses = useAppLayoutStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const videoId = query.get("video") ?? undefined;
  const streamId = query.get("stream") ?? undefined;
  const dispatch = useDispatch();
  const [line, setLine] = useState<Line>();
  const history = useHistory();

  const selectLoaded = useMemo(
    () => getLoadedSelector(locationId),
    [locationId]
  );
  const selectStream = useMemo(
    () => getStreamSelector(locationId, streamId),
    [locationId, streamId]
  );
  const selectVideo = useMemo(
    () => getVideoSelector(locationId, videoId),
    [locationId, videoId]
  );

  const isLoaded = useSelector(selectLoaded) ?? false;
  const stream = useSelector(selectStream);
  const video = useSelector(selectVideo);

  useEffect(() => {
    const savedLine = (video ?? stream)?.line;
    if (savedLine !== undefined && !deepEqual(savedLine, line)) {
      setLine(savedLine);
    }
  }, [setLine, video, stream, line]);

  /**
   * Save tmp line changes to the Store
   */
  const handleLineChange = useCallback(
    (line: Line) => {
      setLine(line);
    },
    [setLine]
  );

  /**
   * Save line to the backend
   */
  const handleLineSave = useCallback(() => {
    dispatch(
      startProcessing({
        locationId: locationId as LocationId,
        line: line as Line,
        streamId: stream?.id,
        videoId: video?.id,
        redirectTo: `/location/${locationId as LocationId}`,
        history: history,
      })
    );
  }, [dispatch, line, stream, video, locationId, history]);

  const lineTarget = video ?? stream;

  if (!isLoaded) {
    return <Typography>{t(I18N.main.common.MSG_LOADING_PROGRESS)}</Typography>;
  }

  if (lineTarget === undefined) {
    return <Typography>{t(I18N.location.MSG_VIDEO_NOT_FOUND)}</Typography>;
  }

  return (
    <>
      <LineEditor
        line={line}
        url={lineTarget.thumbnailUrl as string}
        onChange={handleLineChange}
        locationId={locationId}
        targetWidth={video !== undefined ? VIDEO_LINE_TARGET_WIDTH : undefined}
        targetHeight={
          video !== undefined ? VIDEO_LINE_TARGET_HEIGHT : undefined
        }
      />
      <div className={classes.steps}>
        <div className={classes.contentBlock}>
          <VisiusLink
            to={`/location/${locationId as LocationId}`}
            className={clsx({
              [appClasses.linkButton]: true,
              [classes.backButton]: true,
            })}
          >
            <Button
              color="primary"
              variant="outlined"
              disabled={streamId !== undefined && videoId !== undefined}
            >
              {t(I18N.location.BTN_STEP_BACK)}
            </Button>
          </VisiusLink>

          <Button
            color="primary"
            variant="contained"
            disabled={
              line === undefined || line.length < 2 || lineTarget.isBusy
            }
            onClick={handleLineSave}
            className={classes.mainBtn}
          >
            {t(I18N.location.BTN_STEP_START_ANALYSIS)}
          </Button>
        </div>
      </div>
    </>
  );
}
