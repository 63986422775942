import { Theme, makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../theme";

const drawerWidth = 368;

export const usePanelStyle = makeStyles<Theme>((theme: Theme) => ({
  root: {
    position: "relative",
    height: 0,
    zIndex: theme.zIndex.appBar,
    overflowX: "hidden",
    flexShrink: 0,
  },
  rootClose: {
    width: theme.spacing(8),
  },
  drawer: {
    width: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    height: `calc(100% - 128px)`,
    width: drawerWidth,
    padding: theme.spacing(1, 3, 1, 3),
    margin: theme.spacing(14, 2, 0, 0),
    borderColor: COLORS.GREY2,
    borderRadius: theme.spacing(3),
    boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
    background: theme.palette.grey[400],
    border: `1px solid ${theme.palette.primary.light}`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),

    "& $button:last-of-type": {
      marginBottom: "8px",
    },
  },
  drawerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  button: {
    marginTop: "auto",
  },
  panelElement: {
    width: "100%",
    marginTop: "auto",
  },
  title: {
    fontSize: "18px",
  },
  titleWrap: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),

    "& .MuiButton-label": {
      alignItems: "center",
    },
  },
  toggleButtonOpen: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: 6,
  },
  toggleButtonClose: {
    textTransform: "none",
    fontWeight: 600,
  },
  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    height: `calc(100vh - ${theme.spacing(15)}px)`,

    "& .MuiDrawer-paper": {
      width: "64px",
    },
  },
  drawerPaperClose: {
    padding: 0,
    cursor: "pointer",
  },
}));
