import I18N from "../../app/i18n/strings";
import { ResultKeys } from "../../components/charts/bar/types";
import { RawResult } from "./charts/RawResult";
import { LegendEntry } from "./components/Legend";

export interface DataForTotalPie {
  index: number;
  label: ResultKeys<RawResult>;
  value: number;
  color: string;
}

export const resultFields: Record<any, LegendEntry> = {
  men: { title: I18N.chart.LEGEND_MEN, color: "#579EDD" },
  cars: { title: I18N.chart.LEGEND_CARS, color: "#AA54B2" },
  bikes: { title: I18N.chart.LEGEND_BIKES, color: "#F8981D" },
  oldMen: { title: I18N.chart.LEGEND_OLDMEN, color: "#11B0C7" },
  middleMen: { title: I18N.chart.LEGEND_MIDDLEMEN, color: "#59C8D8" },
  youngMen: { title: I18N.chart.LEGEND_YOUNGMEN, color: "#ACE3EB" },
  unknownMen: { title: I18N.chart.LEGEND_UNKNOWNMEN, color: "#DBF3F7" },
  oldWomen: { title: I18N.chart.LEGEND_OLDWOMEN, color: "#EE645A" },
  middleWomen: { title: I18N.chart.LEGEND_MIDDLEWOMEN, color: "#F3938B" },
  youngWomen: { title: I18N.chart.LEGEND_YOUNGWOMEN, color: "#F9C9C5" },
  unknownWomen: { title: I18N.chart.LEGEND_UNKNOWNWOMEN, color: "#FCE8E6" },
  oldUnknown: { title: I18N.chart.LEGEND_OLDUNKNOWN, color: "#888888" },
  middleUnknown: { title: I18N.chart.LEGEND_MIDDLEUNKNOWN, color: "#BBBBBB" },
  youngUnknown: { title: I18N.chart.LEGEND_YOUNGUNKNOWN, color: "#E2E0E0" },
  unknownUnknown: { title: I18N.chart.LEGEND_UNKNOWNUNKNOWN, color: "#F0F0F0" },
  ageJun: { title: I18N.chart.LEGEND_YOUNG, color: "#179EDD" },
  ageMiddle: { title: I18N.chart.LEGEND_MIDDLE, color: "#22BD66" },
  ageSenior: { title: I18N.chart.LEGEND_OLD, color: "#32BD66" },
  ageUnknown: { title: I18N.chart.LEGEND_UNKNOWN_AGE, color: "#42BD66" },
  gMale: { title: I18N.chart.LEGEND_MALE, color: "#52BD66" },
  gFemale: { title: I18N.chart.LEGEND_FEMALE, color: "#62BD66" },
  gUnknown: { title: I18N.chart.LEGEND_UNKNOWN_GENDER, color: "#71BD66" },
};

export const allkindOfPeople: ResultKeys<RawResult>[] = [
  "oldMen",
  "oldMenObject",
  "oldMenUniq",
  "oldMenRepeating",
  "oldMenRepeatingObject",
  "middleMen",
  "middleMenObject",
  "middleMenUniq",
  "middleMenRepeating",
  "middleMenRepeatingObject",
  "youngMen",
  "youngMenObject",
  "youngMenUniq",
  "youngMenRepeating",
  "youngMenRepeatingObject",
  "unknownMen",
  "unknownMenObject",
  "unknownMenUniq",
  "unknownMenRepeating",
  "unknownMenRepeatingObject",
  "oldWomen",
  "oldWomenObject",
  "oldWomenUniq",
  "oldWomenRepeating",
  "oldWomenRepeatingObject",
  "middleWomen",
  "middleWomenObject",
  "middleWomenUniq",
  "middleWomenRepeating",
  "middleWomenRepeatingObject",
  "youngWomen",
  "youngWomenObject",
  "youngWomenUniq",
  "youngWomenRepeating",
  "youngWomenRepeatingObject",
  "unknownWomen",
  "unknownWomenObject",
  "unknownWomenUniq",
  "unknownWomenRepeating",
  "unknownWomenRepeatingObject",
  "oldUnknown",
  "oldUnknownObject",
  "oldUnknownUniq",
  "oldUnknownRepeating",
  "oldUnknownRepeatingObject",
  "middleUnknown",
  "middleUnknownObject",
  "middleUnknownUniq",
  "middleUnknownRepeating",
  "middleUnknownRepeatingObject",
  "youngUnknown",
  "youngUnknownObject",
  "youngUnknownUniq",
  "youngUnknownRepeating",
  "youngUnknownRepeatingObject",
  "unknownUnknown",
  "unknownUnknownObject",
  "unknownUnknownUniq",
  "unknownUnknownRepeating",
  "unknownUnknownRepeatingObject",
];

export const menContainer = (value?: string): Partial<keyof RawResult>[] => {
  {
    switch (value) {
      case "uniq":
        return [
          "oldMenUniq",
          "middleMenUniq",
          "youngMenUniq",
          "unknownMenUniq",
          "oldWomenUniq",
          "middleWomenUniq",
          "youngWomenUniq",
          "unknownWomenUniq",
          "oldUnknownUniq",
          "middleUnknownUniq",
          "youngUnknownUniq",
          "unknownUnknownUniq",
        ];
      case "repeating":
        return [
          "oldMenRepeating",
          "middleMenRepeating",
          "youngMenRepeating",
          "unknownMenRepeating",
          "oldWomenRepeating",
          "middleWomenRepeating",
          "youngWomenRepeating",
          "unknownWomenRepeating",
          "oldUnknownRepeating",
          "middleUnknownRepeating",
          "youngUnknownRepeating",
          "unknownUnknownRepeating",
        ];
      case "object":
        return [
          "oldMenObject",
          "middleMenObject",
          "youngMenObject",
          "unknownMenObject",
          "oldWomenObject",
          "middleWomenObject",
          "youngWomenObject",
          "unknownWomenObject",
          "oldUnknownObject",
          "middleUnknownObject",
          "youngUnknownObject",
          "unknownUnknownObject",
        ];
      case "repeatingObject":
        return [
          "oldMenRepeatingObject",
          "middleMenRepeatingObject",
          "youngMenRepeatingObject",
          "unknownMenRepeatingObject",
          "oldWomenRepeatingObject",
          "middleWomenRepeatingObject",
          "youngWomenRepeatingObject",
          "unknownWomenRepeatingObject",
          "oldUnknownRepeatingObject",
          "middleUnknownRepeatingObject",
          "youngUnknownRepeatingObject",
          "unknownUnknownRepeatingObject",
        ];
      default:
        return [
          "oldMen",
          "middleMen",
          "youngMen",
          "unknownMen",
          "oldWomen",
          "middleWomen",
          "youngWomen",
          "unknownWomen",
          "oldUnknown",
          "middleUnknown",
          "youngUnknown",
          "unknownUnknown",
        ];
    }
  }
};

export const nullData = (getDate: number, hour: number) => ({
  time: new Date(getDate + hour),
  cars: 0,
  bikes: 0,
  men: 0,

  oldMen: 0,
  middleMen: 0,
  youngMen: 0,
  unknownMen: 0,
  oldWomen: 0,
  middleWomen: 0,
  youngWomen: 0,
  unknownWomen: 0,
  oldUnknown: 0,
  middleUnknown: 0,
  youngUnknown: 0,
  unknownUnknown: 0,

  oldMenObject: 0,
  middleMenObject: 0,
  youngMenObject: 0,
  unknownMenObject: 0,
  oldWomenObject: 0,
  middleWomenObject: 0,
  youngWomenObject: 0,
  unknownWomenObject: 0,
  oldUnknownObject: 0,
  middleUnknownObject: 0,
  youngUnknownObject: 0,
  unknownUnknownObject: 0,

  oldMenUniq: 0,
  middleMenUniq: 0,
  youngMenUniq: 0,
  unknownMenUniq: 0,
  oldWomenUniq: 0,
  middleWomenUniq: 0,
  youngWomenUniq: 0,
  unknownWomenUniq: 0,
  oldUnknownUniq: 0,
  middleUnknownUniq: 0,
  youngUnknownUniq: 0,
  unknownUnknownUniq: 0,

  oldMenRepeating: 0,
  middleMenRepeating: 0,
  youngMenRepeating: 0,
  unknownMenRepeating: 0,
  oldWomenRepeating: 0,
  middleWomenRepeating: 0,
  youngWomenRepeating: 0,
  unknownWomenRepeating: 0,
  oldUnknownRepeating: 0,
  middleUnknownRepeating: 0,
  youngUnknownRepeating: 0,
  unknownUnknownRepeating: 0,

  oldMenRepeatingObject: 0,
  middleMenRepeatingObject: 0,
  youngMenRepeatingObject: 0,
  unknownMenRepeatingObject: 0,
  oldWomenRepeatingObject: 0,
  middleWomenRepeatingObject: 0,
  youngWomenRepeatingObject: 0,
  unknownWomenRepeatingObject: 0,
  oldUnknownRepeatingObject: 0,
  middleUnknownRepeatingObject: 0,
  youngUnknownRepeatingObject: 0,
  unknownUnknownRepeatingObject: 0,

  ageJun: 0,
  ageMiddle: 0,
  ageSenior: 0,
  ageUnknown: 0,
  gMale: 0,
  gFemale: 0,
  gUnknown: 0,
});

export const itemData: Partial<RawResult> = {
  men: 0,
  oldMen: 0,
  middleMen: 0,
  youngMen: 0,
  unknownMen: 0,
  oldWomen: 0,
  middleWomen: 0,
  youngWomen: 0,
  unknownWomen: 0,
  oldUnknown: 0,
  middleUnknown: 0,
  youngUnknown: 0,
  unknownUnknown: 0,
  cars: 0,
  bikes: 0,
};

export const dataForTotalPie = (
  getValue: (label: string) => number,
  totalMen: number,
  totalCars: number,
  totalBikes: number,
  totalObjects: number,
  isMenMode: boolean,
  isMenUniteMode: boolean
): DataForTotalPie[] =>
  isMenMode && !isMenUniteMode
    ? [
        {
          label: "oldMen",
          value: getValue("oldMen"),
          color: "#11B0C7",
          index: 4,
        },
        {
          label: "middleMen",
          value: getValue("middleMen"),
          color: "#59C8D8",
          index: 5,
        },
        {
          label: "youngMen",
          value: getValue("youngMen"),
          color: "#ACE3EB",
          index: 6,
        },
        {
          label: "unknownMen",
          value: getValue("unknownMen"),
          color: "#DBF3F7",
          index: 7,
        },
        {
          label: "oldWomen",
          value: getValue("oldWomen"),
          color: "#EE645A",
          index: 8,
        },
        {
          label: "middleWomen",
          value: getValue("middleWomen"),
          color: "#F3938B",
          index: 9,
        },
        {
          label: "youngWomen",
          value: getValue("youngWomen"),
          color: "#F9C9C5",
          index: 10,
        },
        {
          label: "unknownWomen",
          value: getValue("unknownWomen"),
          color: "#FCE8E6",
          index: 11,
        },
        {
          label: "oldUnknown",
          value: getValue("oldUnknown"),
          color: "#888888",
          index: 12,
        },
        {
          label: "middleUnknown",
          value: getValue("middleUnknown"),
          color: "#BBBBBB",
          index: 13,
        },
        {
          label: "youngUnknown",
          value: getValue("youngUnknown"),
          color: "#E2E0E0",
          index: 14,
        },
        {
          label: "unknownUnknown",
          value: getValue("unknownUnknown"),
          color: "#F0F0F0",
          index: 15,
        },
      ]
    : isMenMode && isMenUniteMode
    ? [{ label: "men", value: totalMen, color: "#579EDD", index: 3 }]
    : [
        {
          label: "men",
          value: totalMen / totalObjects,
          color: "#579EDD",
          index: 0,
        },
        {
          label: "cars",
          value: totalCars / totalObjects,
          color: "#AA54B2",
          index: 1,
        },
        {
          label: "bikes",
          value: totalBikes / totalObjects,
          color: "#F8981D",
          index: 2,
        },
      ];

export function getDetailData(
  gender: string,
  totals: RawResult,
  peopleMode: any
) {
  const data = {
    old: peopleMode.common
      ? totals[`old${gender}Object` as ResultKeys<RawResult>]
      : peopleMode.uniq
      ? totals[`old${gender}Uniq` as ResultKeys<RawResult>]
      : totals[`old${gender}RepeatingObject` as ResultKeys<RawResult>],
    middle: peopleMode.common
      ? totals[`middle${gender}Object` as ResultKeys<RawResult>]
      : peopleMode.uniq
      ? totals[`middle${gender}Uniq` as ResultKeys<RawResult>]
      : totals[`middle${gender}RepeatingObject` as ResultKeys<RawResult>],
    young: peopleMode.common
      ? totals[`young${gender}Object` as ResultKeys<RawResult>]
      : peopleMode.uniq
      ? totals[`young${gender}Uniq` as ResultKeys<RawResult>]
      : totals[`young${gender}RepeatingObject` as ResultKeys<RawResult>],
    unknown: peopleMode.common
      ? totals[`unknown${gender}Object` as ResultKeys<RawResult>]
      : peopleMode.uniq
      ? totals[`unknown${gender}Uniq` as ResultKeys<RawResult>]
      : totals[`unknown${gender}RepeatingObject` as ResultKeys<RawResult>],
  };
  return data;
}

