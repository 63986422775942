import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import deepEqual from "deep-equal";
import {
  actions,
  createTmpGroup,
  loadGroups,
  selectData,
  selectLoading,
  selectNotInitialized,
} from "./videoSlice";

import { Add } from "@material-ui/icons";
import { Button, Divider, Typography } from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import { declOfNum, sortByDateDesc } from "../../../app/utils";

import Group from "../components/Group";
import pageStyles from "../components/pageStyles";
import DelayedProgress from "../../../components/DelayedProgress";

import { GroupData } from "../data-types";
import GroupsLocationCard from "./MainLocationCard";

import { ReactComponent as FolderIcon } from "../../../app/assets/folderIcon.svg";
import { ReactComponent as LocationIcon } from "../../../app/assets/locationIcon.svg";
import { ReactComponent as MovieIcon } from "../../../app/assets/movieIcon.svg";

export default function MainTab({
  leftPanelIsOpen,
}: {
  leftPanelIsOpen?: boolean;
}): ReactElement {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const groups: GroupData[] = useSelector(selectData, deepEqual);
  const isLoading = useSelector(selectLoading);
  const isNotInitialized = useSelector(selectNotInitialized);
  const declensionsOfGroup = ["группа", "группы", "групп"];
  const declensionsOfLocation = ["локация", "локации", "локаций"];
  const declensionsOfVideo = ["видеофайл", "видеофайла", "видеофайлов"];
  const classes = pageStyles({ leftPanelIsOpen });

  const numOfLocations: number = groups.reduce((total, currentItem) => {
    total += currentItem.locations.length;
    return total;
  }, 0);

  const numOfVideos = groups
    .map((item) => item.locations)
    .flat()
    .reduce((total, currentItem) => {
      total += currentItem.videos ?? 0;
      return total;
    }, 0);

  const handleAdd = useCallback(() => {
    dispatch(createTmpGroup(t(I18N.main.TMP_GROUP_TITLE)));
  }, [dispatch, t]);

  useEffect(() => {
    if (isNotInitialized) {
      dispatch(loadGroups());
    }
  }, [dispatch, isNotInitialized]);

  const groupList = useMemo(() => {
    if (isLoading || isNotInitialized) {
      return <DelayedProgress variant="text" />;
    }
    if (groups.length === 0) {
      return (
        <Typography variant="body1">
          {t(I18N.main.videoData.MSG_EMPTY)}
        </Typography>
      );
    }
    return groups.sort(sortByDateDesc).map((group) => {
      return (
        <Group
          editable
          key={group.id}
          data={group}
          locationComponent={GroupsLocationCard}
          onRenameCancel={() => {
            if (group.isTmp === true) {
              dispatch(actions.deleteGroup(group.id));
            }
          }}
          variant="videoData"
          // Main page only could have Groups in "full" control mode
          full
        />
      );
    });
  }, [dispatch, t, isLoading, groups, isNotInitialized]);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerWrapper}>
          <div className={classes.generalInfo}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="h3"
            >
              {t(I18N.main.SUBTITLE_VIDEO)}
            </Typography>
            <div className={classes.infoItems}>
              <Button startIcon={<FolderIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${groups.length} ${declOfNum(
                    groups.length,
                    declensionsOfGroup
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<LocationIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfLocations} ${declOfNum(
                    numOfLocations,
                    declensionsOfLocation
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<MovieIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfVideos} ${declOfNum(
                    numOfVideos,
                    declensionsOfVideo
                  )}`}
                </Typography>
              </Button>
            </div>
          </div>
          <div>
            <Button
              color="primary"
              className={classes.btn}
              variant="outlined"
              startIcon={<Add />}
              onClick={handleAdd}
            >
              {t(I18N.main.BTN_ADD_GROUP)}
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div>{groupList}</div>
    </>
  );
}
