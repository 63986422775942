import React from "react";
import { DetailData } from "./GenderStats";

export type BarStackHorizontalProps = {
  detailData?: DetailData;
  colorOld: string;
  colorMiddle: string;
  colorYoung: string;
  colorUnknown: string;
};

export default function DetailStatsCard(props: BarStackHorizontalProps) {
  const { detailData, colorOld, colorMiddle, colorUnknown, colorYoung } = props;

  const old = detailData?.old ?? 0;
  const middle = detailData?.middle ?? 0;
  const young = detailData?.young ?? 0;
  const unknown = detailData?.unknown ?? 0;

  const totalCount = old + middle + young + unknown;

  const oldPercent = (old / totalCount) * 100;
  const middlePercent = (middle / totalCount) * 100;
  const youngPercent = (young / totalCount) * 100;
  const unknownPercent = (unknown / totalCount) * 100;

  return (
    <div style={{ width: "100%", height: "8px", display: "flex" }}>
      <div
        style={{
          width: `${oldPercent}%`,
          height: "100%",
          background: `${colorOld}`,
        }}
      />
      <div
        style={{
          width: `${middlePercent}%`,
          height: "100%",
          background: `${colorMiddle}`,
        }}
      />
      <div
        style={{
          width: `${youngPercent}%`,
          height: "100%",
          background: `${colorYoung}`,
        }}
      />
      <div
        style={{
          width: `${unknownPercent}%`,
          height: "100%",
          background: `${colorUnknown}`,
        }}
      />
    </div>
  );
}
