import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme: Theme) => ({
  formControl: {
    marginTop: theme.spacing(1.5),
    minWidth: 120,
    width: "100%",

    "& .MuiAutocomplete-popupIndicator span": {
      "& svg": {
        "& path": {
          d: "path('M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z')",
          transform: "translate(4px, 8px)",
        },
      },
    },

    "& .MuiFormLabel-root": {
      fontSize: 14,

      "&.Mui-disabled": {
        color: "#DFE6FB",
      },

      "&.Mui-focused": {
        display: "none",
      },
    },

    "& .MuiInputBase-root": {
      color: "none",
      background: theme.palette.common.white,
      borderRadius: 12,
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",

      "& .MuiChip-root": {
        background: "white",
        border: "1px solid #DFE6FB",
        boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      },

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&.Mui-disabled .MuiInputBase-input": {
        color: "#DFE6FB",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "& fieldset": {
        border: "none ",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid #2C67FF",
      },

      "&.Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },
    },

    "& .MuiInputBase-root .Mui-disabled": {
      boxShadow: "none",
      border: "none",
    },
  },
  select: {
    "& svg": {
      "& path": {
        d: "path('M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z')",
        transform: "translate(4px, 8px)",
      },
    },

    "& .MuiList-root": {
      borderRadius: "12px",
    },
  },
  dropDownList: {
    borderRadius: "16px",

    "& .MuiListItem-root": {
      fontSize: "14px",
    },

    "& .MuiList-padding": {
      padding: 0,
    },
  },
  title: {
    textTransform: "none",
    marginBottom: 16,
    marginTop: 24,
  },

  additionalOptions: {
    height: "115px",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "#f5f5f5",
      borderRadius: "10px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "5px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  childTitle: {
    display: "flex",
    marginBottom: 8,
    marginTop: 12,
  },
  labelOfLegend: {
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
    },
  },
}));
