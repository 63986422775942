import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import {
  loadUserData,
  selectAuthenticated,
  selectAuthNotInitialized,
  selectUserData,
} from "./features/auth/authSlice";
import {
  loadGroups,
  selectNotInitialized,
} from "./features/groups/main/videoSlice";
import {
  selectAppLoading,
  selectAppReady,
} from "./features/notifications/notificationsSlice";
import {
  loadBillingUrl,
  loadCurrentTariff,
  selectBillingUrl,
  selectIsCurrentTariffError,
  selectIsLoadingError,
} from "./features/profile/tariffs/tariffSlice";

import initSignalR from "./features/notifications/signalR";

import DelayedProgress from "./components/DelayedProgress";
import SignIn from "./features/auth/SignIn";
import ConfirmEmail from "./features/confirm-email/ConfirmEmail";
import Chart from "./features/dashboard/Page";
import { useWorker as useAnalyticsWorker } from "./features/dashboard/worker/useAnalyticsWorker";
import GroupsPage from "./features/groups/Page";
import Location from "./features/location/Page";
import Notification from "./features/notifications/Notification";
import ResetPassword from "./features/password-reset/PasswordReset";
import Profile from "./features/profile/Page";
import SignUp from "./features/signup/SignUp";
import theme from "./theme";
/* eslint no-void: ["error", { "allowAsStatement": true }] */

const TARIFF_UPDATING_PERIOD_MS = 60000;

function App(): ReactElement {
  const dispatch = useDispatch();

  // leftPanelIsOpen => true -- left panel of page is open
  const [leftPanelIsOpen, setLeftPaneIsOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setLeftPaneIsOpen((prev) => !prev);
  };

  // isAuthNotInitialized => true -- authentication hasn't been started yet
  const isAuthNotInitialized = useSelector(selectAuthNotInitialized);
  const isAuthCompleted = useSelector(selectAuthenticated);
  // isAppReady => true -- an attempt to authenticate user using Cookies has been finished
  //                       regardless of whether authentication succeeded or failed
  const isAppReady = useSelector(selectAppReady);
  // isLoading => true -- some continuous async action (e.g data loading) is underway
  const isLoading = useSelector(selectAppLoading);
  const analyticsWorker = useAnalyticsWorker();

  const userData = useSelector(selectUserData);
  const billingUrl = useSelector(selectBillingUrl);
  const isTariffError = useSelector(selectIsLoadingError);
  const isCurrentTariffError = useSelector(selectIsCurrentTariffError);

  useEffect(() => {
    dispatch(loadBillingUrl());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthNotInitialized) {
      dispatch(loadUserData());
    }
  }, [dispatch, isAuthNotInitialized]);

  useEffect(() => {
    if (isAuthCompleted) {
      // #initSignalR returns cleanup function to stop listening to messages
      return initSignalR(dispatch, analyticsWorker);
    }
  }, [dispatch, isAuthCompleted, analyticsWorker]);

  // Load Groups/Locations data (it is used on various app pages)
  // if the corresponding slice hasn't been initialized yet
  const isNotInitialized = useSelector(selectNotInitialized);
  useEffect(() => {
    if (isAuthCompleted && isNotInitialized) {
      dispatch(loadGroups());
    }
  }, [dispatch, isAuthCompleted, isNotInitialized]);

  useEffect(() => {
    const callback = (): void => {
      if (
        userData !== undefined &&
        billingUrl !== undefined &&
        !isCurrentTariffError
      ) {
        dispatch(loadCurrentTariff(userData.id));
      }
    };

    callback();
    const tariffUpdateInterval = setInterval(
      callback,
      TARIFF_UPDATING_PERIOD_MS
    );
    return () => clearInterval(tariffUpdateInterval);
  }, [dispatch, userData, isCurrentTariffError]);

  useEffect(() => {
    if (isTariffError) {
      // повторный запрос url сервера биллинга
      dispatch(loadBillingUrl());
    }
  }, [dispatch, isTariffError]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isAppReady && (
        <Router>
          <Switch>
            <Route
              exact
              path="/main"
              render={(props) =>
                isAuthCompleted ? (
                  <GroupsPage
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              exact
              path="/location"
              render={(props) =>
                isAuthCompleted ? (
                  <Location
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              exact
              path="/profile"
              render={(props) =>
                isAuthCompleted ? (
                  <Profile
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              exact
              path="/location/dashboard"
              render={(props) =>
                isAuthCompleted ? (
                  <Chart
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              exact
              path="/location/:id"
              render={(props) =>
                isAuthCompleted ? (
                  <Location
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route
              exact
              path="/location/:id/dashboard"
              render={(props) =>
                isAuthCompleted ? (
                  <Chart
                    leftPanelIsOpen={leftPanelIsOpen}
                    handleDrawerToggle={handleDrawerToggle}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { referrer: props.location },
                    }}
                  />
                )
              }
            />

            <Route exact path="/confirm-email" component={ConfirmEmail} />
            <Route exact path="/login" component={SignIn} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/signup" component={SignUp} />

            <Redirect exact from="/" to="/main" />
          </Switch>
        </Router>
      )}
      {
        // if the app is 'ready' progress bar is shown as a part of VisiusAppBar
        !isAppReady && isLoading && <DelayedProgress />
      }
      <Notification />
    </ThemeProvider>
  );
}

export default App;
