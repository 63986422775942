import { formatLocale } from 'd3-format'

export const d3locale = formatLocale({
  decimal: ',',
  thousands: '\xa0',
  grouping: [3],
  currency: ['', ' руб.']
})

export const d3format = d3locale.format(',.2~f')
