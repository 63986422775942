import { MAIN_URL } from "../../app/api-url";
import { HTTP_METHOD, request } from "../../app/utils";
import { groupDtoToGroup, locationDtoToLocation } from "./data-mapper";
import { LocationGroupsResponseDto } from "./data-mock";
import {
  ARCHIVE_STATE,
  GroupData,
  GroupDto,
  LocationGroupCreateDto,
  UpdateGroupRequestDto,
  GroupId,
  GroupMap,
  LocationData,
  LocationDto,
  LocationCreateDto,
  UpdateLocationRequestDto,
  LocationId,
  SliceName,
} from "./data-types";
import { LocationArgs } from "./slice-utils";

const getLocationGroupsUrl = (
  sliceName: SliceName,
  offset: number,
  count: number
): string => {
  const query = new URLSearchParams({
    offset: offset.toString(),
    count: count.toString(),
  });

  switch (sliceName) {
    case "archive": {
      query.set("archive", "true");
      break;
    }
    case "trash": {
      query.set("trash", "true");
      break;
    }
    case "videoData":
    default: {
      query.set("active", "true");
    }
  }

  return `${MAIN_URL.GROUPS}?${query.toString()}`;
};

export const getLocationFilter =
  (sliceName: SliceName) =>
  (groupOrLocation: LocationData | GroupData): boolean => {
    const { isRemoved = false, isArchived = false } = groupOrLocation;
    switch (sliceName) {
      case "archive":
        return !isRemoved && isArchived;
      case "trash":
        return isRemoved;
      case "videoData":
      default:
        return !isArchived && !isRemoved;
    }
  };

/* Groups API */

/**
 * Loads data for all groups within the given sliceName (archive|trash|videoData)
 */
export async function* loadGroups(
  sliceName: SliceName,
  chunkSize = 25
): AsyncGenerator<GroupData[]> {
  let offset = 0;
  do {
    const url = getLocationGroupsUrl(sliceName, offset, chunkSize);
    const {
      entities: groupsDomain,
      count,
      total,
    } = (await request(url)) as LocationGroupsResponseDto;

    // 2. Ensure that only data of the slice's type is actually loaded
    const groups = await groupsDomain.map((group) =>
      groupDtoToGroup(group, getLocationFilter(sliceName))
    );

    // 3. Transform data from Array to Map (for the ease of access)
    yield groups;
    if (count + offset >= total) {
      return;
    }

    offset += count;
  } while (true);
}

/**
 * Saves (adds!) group with given title to the backend
 */
export async function addGroup(title: string): Promise<GroupDto> {
  const data: LocationGroupCreateDto = { title };

  const newGroup = (await request(MAIN_URL.GROUPS, {
    method: HTTP_METHOD.POST,
    data: data as unknown as Record<string, unknown>,
  })) as GroupDto;

  return newGroup;
}

/**
 * Load data for a group with the given ID within the given sliceName (archive|trash|videoData)
 */
export async function loadGroup(
  groupId: GroupId,
  sliceName?: SliceName
): Promise<GroupData | undefined> {
  const groupDto = (await request(
    MAIN_URL.GROUP.replace("{id}", groupId.toString())
  )) as GroupDto;
  const group = groupDtoToGroup(
    groupDto,
    sliceName !== undefined ? getLocationFilter(sliceName) : undefined
  );
  const { isArchived = false, isRemoved = false } = group;

  if (sliceName === "videoData" && (isArchived || isRemoved)) {
    return;
  }

  if (sliceName === "archive" && isRemoved) {
    return;
  }

  return group;
}

/**
 * Load data for a location with the given ID
 * @param locationId - location ID
 */
export async function loadLocation(
  locationId: LocationId
): Promise<LocationData> {
  const url = MAIN_URL.LOCATION.replace("{id}", locationId.toString());
  const locationDto = (await request(url)) as LocationDto;
  return locationDtoToLocation(locationDto);
}

/**
 * Saves (updates!) groups to the backend
 */
export async function updateGroup(group: GroupMap): Promise<void> {
  const data: UpdateGroupRequestDto = {
    title: group.title,
    isRemoved: group.isRemoved !== undefined ? group.isRemoved : null,
    state: null,
  };

  if (group.isArchived !== undefined) {
    data.state = group.isArchived
      ? ARCHIVE_STATE.ARCHIVED
      : ARCHIVE_STATE.NOT_ARCHIVED;
  }

  await request(MAIN_URL.GROUP.replace("{id}", group.id.toString()), {
    method: HTTP_METHOD.PUT,
    data: data as unknown as Record<string, unknown>,
  });
}

/**
 * Permanently deletes group with the given ID
 */
export async function deleteGroup(groupId: GroupId): Promise<void> {
  const query = new URLSearchParams({ id: groupId });
  await request(`${MAIN_URL.GROUP_BASE}?${query.toString()}`, {
    method: HTTP_METHOD.DELETE,
  });
}

/* Locations API */
/**
 * Saves (adds!) location to the backend
 */
export async function addLocation(
  groupId: GroupId,
  title: string
): Promise<LocationDto> {
  const data: LocationCreateDto = {
    locationGroupId: groupId,
    title: title,
  };

  const newLocation = (await request(MAIN_URL.LOCATION.replace("{id}", ""), {
    method: HTTP_METHOD.POST,
    data: data as unknown as Record<string, unknown>,
  })) as LocationDto;
  return {
    ...newLocation,
    isCameraConnected: null,
    completedCount: 0,
    processingCount: 0,
    videoFilesSize: 0
  };
}

/**
 * Saves (updates!) location to the backend
 */
export async function updateLocation({
  groupId,
  location,
}: LocationArgs): Promise<void> {
  const data: UpdateLocationRequestDto = {
    title: location.title,
    locationGroupId: groupId,
    state:
      location.isArchived !== undefined
        ? location.isArchived
          ? ARCHIVE_STATE.ARCHIVED
          : ARCHIVE_STATE.NOT_ARCHIVED
        : null,
    isRemoved: Boolean(location.isRemoved),
  };

  await request(MAIN_URL.LOCATION.replace("{id}", location.id.toString()), {
    method: HTTP_METHOD.PUT,
    data: data as unknown as Record<string, unknown>,
  });
}

/**
 * Permanently deletes location
 */
export async function deleteLocation(locationId: LocationId): Promise<void> {
  const query = new URLSearchParams({ id: locationId });
  await request(`${MAIN_URL.LOCATION_BASE}?${query.toString()}`, {
    method: HTTP_METHOD.DELETE,
  });
}
