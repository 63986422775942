import { grey } from '@material-ui/core/colors'
import { Brush } from '@visx/brush'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { Group } from '@visx/group'
import { PatternLines } from '@visx/pattern'
import { scaleOrdinal } from '@visx/scale'
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDebounce } from 'react-use'
import { MaxYGetter } from '../../../features/dashboard/charts/utils'
import { COLORS } from '../../../theme'
import VisiusBarGroup from '../bar/GroupView'
import VisiusBarStack from '../bar/StackView'
import { ResultKeys } from '../bar/types'
import { ChartVariant } from '../Chart'
import { getScaleBand, getScaleLinear } from '../chart-utils'
import LinearChartView from '../linear/LinearView'

const BRUSH_PATTER_ID = 'pattern-lines'
const BRUSH_RESIZE_DEBOUNCE_MS = 50
const BRUSH_DEBOUNCE_MS = 50
const SELECTED_BRUSH_STYLE = {
  fill: `url(#${BRUSH_PATTER_ID})`,
  stroke: COLORS.BLUE5,
  strokeWidth: 2
}

export interface BrushExtent {
  x0: string
  x1: string
}

export interface BrushPosition {
  x0?: number
  x1?: number
}

interface BrushProps<T> {
  left: number
  top: number
  width: number
  height: number
  data: T[]
  resultKeys: Array<ResultKeys<T>>
  variant: ChartVariant
  extent?: BrushExtent

  getX: (datum: T) => string
  getMaxY: MaxYGetter<T>
  onChange: (extent?: BrushExtent) => void
}

export default function VisiusBrush<T> (props: BrushProps<T>): ReactElement {
  // bounds
  const {
    left,
    top,
    height,
    data,
    resultKeys,
    variant,
    extent,

    getX,
    getMaxY,
    onChange
  } = props
  const baseBrush = useRef<BaseBrush>(null)
  const [width, setWidth] = useState(props.width)
  const xScale = useMemo(
    () => getScaleBand({ data, size: width, getter: getX, padding: 0 }),
    [getX, data, width]
  )
  const yScale = useMemo(
    () => getScaleLinear({ data, size: height, getter: getMaxY }),
    [getMaxY, data, height]
  )
  const colorScale = useMemo(() => scaleOrdinal({
    domain: resultKeys,
    range: [grey[500]]
  }), [resultKeys])

  /* uncomment and use `setFilteredData` instead of props.onChange
     in order to debounce onChange events */
  const [brushPosition, setBrushPosition] = useState<BrushPosition>({})
  useDebounce(() => {
    const brush = baseBrush.current
    if (brush === null || width === props.width) {
      return
    }

    const extent = brush.getExtent(
      { x: brush.state.extent.x0 * props.width / width },
      { x: brush.state.extent.x1 * props.width / width })
    brush.setState({
      extent,
      start: {
        x: extent.x0,
        y: extent.y0
      },
      end: {
        x: extent.x1,
        y: extent.y1
      }
    })
    setBrushPosition({
      x0: extent.x0,
      x1: extent.x1
    })
    setWidth(props.width)
  }, BRUSH_RESIZE_DEBOUNCE_MS, [props.width])

  useDebounce(() => {
    const { x0, x1 } = brushPosition
    if (x0 === undefined || x1 === undefined || x0 === x1) {
      return onChange()
    }

    let minX = Infinity
    let minXValue = ''
    let maxX = -Infinity
    let maxXValue = ''
    for (const datum of data) {
      const xValue = getX(datum)
      const x = xScale(xValue)

      if (x === undefined) {
        continue
      }

      if (x < minX && x > x0) {
        minX = x
        minXValue = xValue
      }

      if (x > maxX && x < x1) {
        maxX = x
        maxXValue = xValue
      }
    }

    if (minXValue === '' || maxXValue === '') {
      return onChange()
    }

    onChange({
      x0: minXValue,
      x1: maxXValue
    })
  }, BRUSH_DEBOUNCE_MS, [onChange, xScale, getX, brushPosition, width, props.width])

  const handleBrushPorisionChange = useCallback(() => {
    const x0 = baseBrush.current?.state.extent.x0
    const x1 = baseBrush.current?.state.extent.x1
    setBrushPosition({ x0, x1 })
  }, [setBrushPosition])

  const brushWidth = baseBrush.current?.getBrushWidth() ?? 0
  const brushHeight = baseBrush.current?.getBrushHeight() ?? 0
  const initialBrushPosition = useMemo(() => {
    if (extent?.x0 === undefined || extent?.x1 === undefined) {
      return undefined
    }

    return {
      start: { x: xScale(extent.x0) },
      end: { x: xScale(extent.x1) }
    }
  }, [xScale, extent])

  const chart = useMemo(() => {
    switch (variant) {
      case 'stack': {
        return (
          <VisiusBarStack
            colorScale={colorScale}
            opacity={0.5}
            height={height}
            data={data}
            groupBy={getX}
            xScale={xScale}
            yScale={yScale}
            resultKeys={resultKeys}
            hideLabels
          />
        )
      }
      case 'group': {
        return (
          <VisiusBarGroup
            colorScale={colorScale}
            opacity={0.5}
            height={height}
            data={data}
            groupBy={getX}
            resultKeys={resultKeys}
            xScale={xScale}
            yScale={yScale}
            hideLabels
          />
        )
      }
      case 'linear': {
        return (
          <LinearChartView
            colorScale={colorScale}
            opacity={0.5}
            height={height}
            data={data}
            groupBy={getX}
            resultKeys={resultKeys}
            xScale={xScale}
            yScale={yScale}
            hideLabels
          />
        )
      }
    }
  }, [getX, variant, colorScale, data, resultKeys, xScale, yScale, height])

  return (
    <Group left={left} top={top}>
      {chart}
      <PatternLines
        id='pattern-lines'
        height={8}
        width={8}
        stroke={COLORS.GREY3}
        strokeWidth={1}
        orientation={['diagonal']}
      />
      <Brush
        key={`brush-${data.length}`}
        initialBrushPosition={initialBrushPosition}
        xScale={xScale}
        yScale={yScale}
        width={width}
        height={height}
        handleSize={8}
        resizeTriggerAreas={['left', 'right']}
        brushDirection='horizontal'
        innerRef={baseBrush}
        onChange={handleBrushPorisionChange}
        selectedBoxStyle={{
          ...SELECTED_BRUSH_STYLE,
          strokeDasharray: `0, ${brushWidth}, ${brushHeight}, ${brushWidth}, ${brushHeight}`
        }}
        margin={{ left }}
      />
    </Group>
  )
}
