import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  updateGroup as apiUpdateGroup,
  updateLocation as apiUpdateLocation
} from '../api'
import { GroupMap } from '../data-types'
import { ARCHIVE_SLICE_NAME, LocationArgs, MAIN_SLICE_NAME, reloadGroupData, TRASH_SLICE_NAME } from '../slice-utils'

/* ########### ARCHIVE ###########

  # Supported Actions

  Source\Dest.|       Main          |           Trash
  ------------|---------------------|--------------------------
  Archive     |  unarchiveLocation  | main:moveLocationToTrash
              |  unarchiveGroup     | main:moveLocationsToTrash
              |                     | main:moveGroupToTrash

  use #updateGroup to edit group attributes without moving the data between Main/Archive/Trash
*/

/**
 * Moves the given Group (with all archived locations) into the Main from the Archive
 */
export const unarchiveGroup = createAsyncThunk(
  `${TRASH_SLICE_NAME}/unarchiveGroup`,
  async (group: GroupMap, { getState }) => {
    if (group.isArchived ?? false) {
      await apiUpdateGroup({
        ...group,
        isArchived: false,
        isRemoved: undefined
      })
    } else {
      const locations = Array.from(group.locations.values())
      await Promise.all(locations.map(async location => await apiUpdateLocation({
        groupId: group.id,
        location: {
          ...location,
          isArchived: false,
          isRemoved: false
        }
      })))
    }

    return await reloadGroupData(group.id, [ARCHIVE_SLICE_NAME, MAIN_SLICE_NAME], getState())
  }
)

/**
 * Moves given Location into the Main tab from Archive
 */
export const unarchiveLocation = createAsyncThunk(
    `${TRASH_SLICE_NAME}/unarchiveLocation`,
    async ({ groupId, location }: LocationArgs, { getState }) => {
      await apiUpdateLocation({
        groupId,
        location: {
          ...location,
          isArchived: false
        }
      })

      return await reloadGroupData(groupId, [ARCHIVE_SLICE_NAME, MAIN_SLICE_NAME], getState())
    }
)
