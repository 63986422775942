import React, { PropsWithChildren, ReactElement } from 'react'
import Menu from '@material-ui/core/Menu'

interface MenuState {
  x?: number
  y?: number
  className?: string
}

export const initialState: MenuState = {}

interface MenuProps extends MenuState {
  onClose: () => void
}

export default function ContextMenu (props: PropsWithChildren<MenuProps>): ReactElement {
  const { onClose, x, y, className } = props

  return (
    <Menu
      keepMounted
      open={x !== undefined}
      onClose={onClose}
      className={className}
      anchorReference='anchorPosition'
      anchorPosition={
        y !== undefined && x !== undefined
          ? { left: x, top: y }
          : undefined
      }
    >
      {props.children}
    </Menu>
  )
}
