import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { makeStyles, Theme } from "@material-ui/core/styles";

import { renameLocation } from "./asyncActions";
import { actions } from "./videoSlice";

import { GroupId, LocationData } from "../data-types";
import InlineTextEdit from "../../../components/InlineTextEdit";

export const useLocationCardStyles = makeStyles<Theme>((theme: Theme) => ({
  /* "Rename" field */
  textInput: {
    // place <input> above the clickAwayHandler
    position: "relative",
    zIndex: 2,

    "& .MuiInputBase-root": {
      background: theme.palette.common.white,
      padding: theme.spacing(0.25, 1),
      borderRadius: 0,

      "& .MuiInputBase-input": {
        fontWeight: 500,
        fontSize: 14,
      },

      "& .MuiOutlinedInput-inputMarginDense": {
        padding: 0,
        fontSize: 14,
      },
    },

    "& .MuiFormHelperText-root": {
      lineHeight: 1.15,
      marginLeft: theme.spacing(0),
      color: theme.palette.common.white,
      position: "absolute",
      transform: "translateY(-100%)",
      marginTop: theme.spacing(-0.5),
    },
  },
}));

export interface LocationCardProps {
  groupId: GroupId;
  data: LocationData;
  prohibitedTitles: string[];
  onCancel: () => void;
}

export default function LocationRenameTextField(
  props: LocationCardProps
): ReactElement {
  const { data, groupId, prohibitedTitles, onCancel } = props;

  const classes = useLocationCardStyles();
  const dispatch = useDispatch();
  const renameInputRef = useRef<HTMLInputElement>(null);
  const isBusy = props.data.isBusy ?? false;

  const handleCancel = useCallback(() => {
    if (data.isTmp === true) {
      return dispatch(
        actions.deleteLocation({
          groupId,
          locationId: data.id,
        })
      );
    }

    onCancel();
  }, [dispatch, onCancel, data, groupId]);

  const handleRename = useCallback(
    (title: string) => {
      if (title === data.title && data.isTmp !== true) {
        return onCancel();
      }

      dispatch(
        renameLocation({
          groupId,
          location: data,
          update: { title },
        })
      );
    },
    [dispatch, onCancel, data, groupId]
  );

  const handleResetError = useCallback(() => {
    dispatch(
      actions.addLocation({
        groupId,
        location: {
          ...data,
          error: undefined,
        },
      })
    );
  }, [dispatch, data, groupId]);

  // Focus on the title text field when in Edit mode
  useEffect(() => {
    if (isBusy) {
      return;
    }

    const inputElem = renameInputRef.current as HTMLInputElement;
    inputElem.focus();
    inputElem.select();
  }, [isBusy]);

  return (
    <InlineTextEdit
      className={classes.textInput}
      defaultValue={props.data.title}
      error={data.error}
      inputRef={renameInputRef}
      onCancel={handleCancel}
      onRename={handleRename}
      onResetError={handleResetError}
      prohibitedValues={prohibitedTitles}
    />
  );
}
