import countries from 'i18n-iso-countries'
import countriesRu from 'i18n-iso-countries/langs/ru.json'
import { tz } from 'moment-timezone'
import { getLocale } from './i18n/locale'
import { sortBy } from './utils'
countries.registerLocale(countriesRu)

export type TimeZoneName = string

export interface TimeZone {
  name: TimeZoneName
  offset: string
}

export interface Country {
  key: string
  title: string
}

export const DEFAULT_COUNTRY = 'RU'
export const DEFAULT_TIMEZONE = 'Europe/Moscow'

export const COUNTRY_LIST: Country[] = tz
  .countries()
  .map(key => ({
    key,
    title: countries.getName(key, getLocale(), { select: 'official' })
  }))
  .sort(sortBy(['title']))

export const getTimeZoneList = (country: string): TimeZone[] => {
  return tz.zonesForCountry(country)
    .map(timezone => ({
      name: timezone,
      offset: tz(Date.now(), timezone).format('Z')
    }))
    .sort(sortBy(['offset', 'name']))
}

export const getDefaultTimeZone = (country?: string): TimeZoneName|undefined => {
  const tzList = getTimeZoneList(country ?? DEFAULT_COUNTRY)
  if (tzList.length === 0) {
    return
  }

  const defaultOffset = tz(Date.now(), tz.guess()).format('Z')
  const guessedTimeZone = tzList.find(({ offset }) => offset === defaultOffset) ?? tzList[0]
  return guessedTimeZone.name
}
