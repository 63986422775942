import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Group } from "@visx/group";
import { MarkerCircle } from "@visx/marker";
import { LinePath } from "@visx/shape";
import { curveMonotoneX as curve } from "@visx/curve";
import { localPoint } from "@visx/event";

import { ChartViewProps } from "../bar/types";
import { grey } from "@material-ui/core/colors";

export default function LinearChartView<T>(
  props: ChartViewProps<T, string>
): ReactElement {
  const {
    resultKeys,
    data,
    xScale,
    yScale,
    colorScale,
    offset,
    hideLabels = false,

    groupBy,
    showTooltip,
    hideTooltip,
  } = props;

  const xScaleOffset = xScale.bandwidth() / 2;
  const leftOffset = offset?.left ?? 0;
  // const topOffset = offset?.top || 0
  const [highlightedDatum, setHighlightedDatum] = useState<T>();
  useEffect(() => {
    setHighlightedDatum(undefined);
  }, [data]);
  const getTooltipHandler = useCallback(() => {
    if (showTooltip === undefined) {
      return;
    }

    const getNearestDatum = (x: number): T | undefined => {
      if (data.length === 0) {
        return;
      }

      for (let i = 0; i < data.length - 1; i++) {
        const a = data[i];
        const b = data[i + 1];
        const ax = xScale(groupBy(a));
        const bx = xScale(groupBy(b));
        if (ax === undefined || bx === undefined) {
          continue;
        }

        if (x < ax) {
          return a;
        }
        if (x > ax && x < bx) {
          return Math.abs(x - ax) < Math.abs(x - bx) ? a : b;
        }
      }

      return data[data.length - 1];
    };

    return (event: MouseEvent): void => {
      const coords = localPoint(
        (event.target as SVGElement).ownerSVGElement as Element,
        event
      );
      if (coords?.x === undefined || coords?.y === undefined) {
        return;
      }
      const x = coords.x - leftOffset - xScaleOffset;
      const datum = getNearestDatum(x);
      setHighlightedDatum(datum);

      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum,
      });
    };
  }, [
    setHighlightedDatum,
    showTooltip,
    groupBy,
    data,
    leftOffset,
    xScale,
    xScaleOffset,
  ]);

  const handleMouseLeave = useCallback(() => {
    if (hideTooltip === undefined) {
      return;
    }

    setHighlightedDatum(undefined);
    hideTooltip();
  }, [hideTooltip, setHighlightedDatum]);

  const getDatumX = useCallback(
    (datum: T): number => {
      const x = xScale(groupBy(datum)) ?? 0;
      const xWithOffset = x + xScaleOffset;
      return xWithOffset;
    },
    [groupBy, xScale, xScaleOffset]
  );
  const getYGetter = useCallback(
    (key: keyof T) =>
      (datum: T): number => {
        const value = datum[key];
        if (typeof value !== "number") {
          return 0;
        }

        return yScale(value) ?? 0;
      },
    [yScale]
  );

  return (
    <>
      {highlightedDatum !== undefined && (
        <LinePath<number>
          data={yScale.range()}
          x={() => (xScale(groupBy(highlightedDatum)) ?? 0) + xScaleOffset}
          y={(y) => y}
          stroke={grey[500]}
          strokeWidth={1}
          strokeOpacity={0.6}
          strokeDasharray="6, 3"
        />
      )}
      {resultKeys.map((key) => {
        return (
          <Group key={`line-${key}`}>
            <MarkerCircle
              id={`marker-circle-${key}`}
              fill={colorScale(key)}
              size={1.5}
              refX={2}
            />
            <LinePath<T>
              curve={curve}
              data={data}
              x={getDatumX}
              y={getYGetter(key as keyof T)}
              stroke={colorScale(key)}
              strokeWidth={2}
              strokeOpacity={0.6}
              markerMid={hideLabels ? undefined : `url(#marker-circle-${key})`}
              markerStart={
                hideLabels ? undefined : `url(#marker-circle-${key})`
              }
              markerEnd={hideLabels ? undefined : `url(#marker-circle-${key})`}
            />
            {highlightedDatum !== undefined &&
              resultKeys.map((key) => {
                const y = highlightedDatum[key as keyof T];
                if (typeof y !== "number") {
                  return null;
                }

                return (
                  <circle
                    key={key as string}
                    r={4}
                    cx={(xScale(groupBy(highlightedDatum)) ?? 0) + xScaleOffset}
                    cy={yScale(y)}
                    stroke={colorScale(key)}
                    strokeWidth={2}
                    fill="white"
                  />
                );
              })}
          </Group>
        );
      })}
      <rect
        fill="transparent"
        height={yScale.range()[0]}
        width={xScale.range()[1]}
        onMouseMove={getTooltipHandler()}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
}
