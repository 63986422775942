import { Moment } from 'moment'

export type LocationId = string
export type GroupId = string
export type ISODateString = string

export enum ARCHIVE_STATE {
  NOT_ARCHIVED = 0,
  ARCHIVED = 1
}

export type SliceName = 'videoData'|'archive'|'trash'

// API response formats
export interface LocationPreviewDto {
  archivedAt: ISODateString|null
  createAt: ISODateString
  id: LocationId
  imagePreviewUrl: string|null
  isRemoved: boolean
  removedAt: ISODateString|null
  state: ARCHIVE_STATE
  title: string|null
  isCameraConnected: boolean|null
  processingCount: number
  completedCount: number
  videoFilesSize: number
  videoFilesCount: number
  videoStreamCount: number
}

export interface LocationDto extends LocationPreviewDto {
  locationGroupId: GroupId
}

export interface GroupDto {
  archivedAt: ISODateString|null
  createAt: ISODateString
  id: GroupId
  isRemoved: boolean
  locations: LocationPreviewDto[]
  removedAt: ISODateString|null
  state: ARCHIVE_STATE
  title: string
}

// API request formats
// DTO: Resource creation
export interface LocationGroupCreateDto {
  title: string
}

export interface LocationCreateDto {
  title: string
  locationGroupId: GroupId
}

// DTO: Resource updates
export interface UpdateGroupRequestDto {
  title: string
  state: ARCHIVE_STATE|null
  isRemoved: boolean|null
}

export interface UpdateLocationRequestDto {
  title: string
  locationGroupId: GroupId
  state: ARCHIVE_STATE|null
  isRemoved: boolean
}

/*
 * FRONTEND data formats.
 *
 * Location groups data is represented in two ways across the App:
 * - Array of groups with each group containing an array of locations (to render UI)
 * - HashMap of groups with each groups containing a HashMap of locations (to use in Redux Store)
*/
export interface LocationData {
  id: LocationId
  title: string
  videos: number
  streams: number
  createdAt: Moment
  
  videoFilesSize?: number
  isCameraConnected?: boolean|null
  processingCount?: number
  completedCount?: number
  error?: string
  archivedAt?: Moment
  removedAt?: Moment
  thumbnail?: string
  isRemoved?: boolean
  isArchived?: boolean
  isTmp?: boolean
  isBusy?: boolean
}

export interface PureGroup {
  id: GroupId
  createdAt: Moment
  title: string

  error?: string
  archivedAt?: Moment
  removedAt?: Moment
  isArchived?: boolean
  isRemoved?: boolean
  isTmp?: boolean
  isBusy?: boolean
}

export interface GroupData extends PureGroup {
  locations: LocationData[]
}

export interface GroupMap extends PureGroup {
  locations: Map<LocationId, LocationData>
}

export type GroupsMap = Map<GroupId, GroupMap>
