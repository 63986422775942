import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import deepEqual from "deep-equal";

import I18N from "../../../app/i18n/strings";
import { declOfNum, sortByDateDesc } from "../../../app/utils";

import DelayedProgress from "../../../components/DelayedProgress";
import Group from "../components/Group";
import pageStyles from "../components/pageStyles";

import { groupToGroupMap } from "../data-mapper";
import { GroupData } from "../data-types";

import { moveGroupToTrash, moveLocationsToTrash } from "../main/asyncActions";
import ArchiveLocationCard from "./ArchiveLocationCard";
import {
  loadGroups,
  selectData,
  selectLoading,
  selectNotInitialized,
} from "./archiveSlice";
import { unarchiveGroup } from "./asyncActions";

import { ReactComponent as FolderIcon } from "../../../app/assets/folderIcon.svg";
import { ReactComponent as LocationIcon } from "../../../app/assets/locationIcon.svg";
import { ReactComponent as MovieIcon } from "../../../app/assets/movieIcon.svg";
import { ReactComponent as TrashIcon } from "../../../app/assets/trashIcon.svg";
import { ReactComponent as RecoverIcon } from "../../../app/assets/recoverDataIcon.svg";

function filterGroups(group: GroupData): boolean {
  return Boolean(group.isArchived) || group.locations.length > 0;
}

export const useStyle = makeStyles<Theme>((theme: Theme) => ({
  generalInfo: {
    display: "flex",

    [theme.breakpoints.down(1580)]: {
      marginRight: "auto",
      marginBottom: theme.spacing(1.5),
    },
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down(1580)]: {
      flexDirection: "column",
      alignItems: "flex-end",
      marginBottom: theme.spacing(1),
    },
  },
  recoverIcon: {
    margin: "0 7px 0 7px",

    "& path": {
      fill: theme.palette.common.white,
    },
  },
  deleteIcon: {
    margin: "0 7px 0 7px",

    "& path": {
      fill: theme.palette.primary.main,
    },
  },
}));

export default function ArchiveTab({
  leftPanelIsOpen,
}: {
  leftPanelIsOpen?: boolean;
}): ReactElement {
  const groups: GroupData[] = useSelector(selectData, deepEqual);
  const { t } = useTranslation();
  const isLoading = useSelector(selectLoading);
  const isNotInitialized = useSelector(selectNotInitialized);
  const archiveClasses = useStyle();
  const classes = pageStyles({ leftPanelIsOpen });
  const dispatch = useDispatch();

  const declensionsOfGroup = ["группа", "группы", "групп"];
  const declensionsOfLocation = ["локация", "локации", "локаций"];
  const declensionsOfVideo = ["видеофайл", "видеофайла", "видеофайлов"];

  const handleRestore = useCallback(() => {
    for (const group of groups) {
      const groupMap = groupToGroupMap(group);
      dispatch(unarchiveGroup(groupMap));
    }
  }, [dispatch, groups]);

  const handleTrash = useCallback(() => {
    for (const group of groups) {
      const groupMap = groupToGroupMap(group);
      if (group.isArchived === true) {
        dispatch(moveGroupToTrash(groupMap));
      } else {
        dispatch(moveLocationsToTrash(groupMap));
      }
    }
  }, [dispatch, groups]);

  useEffect(() => {
    if (isNotInitialized) {
      dispatch(loadGroups());
    }
  }, [dispatch, isNotInitialized]);

  const data = useMemo(() => groups.filter(filterGroups), [groups]);
  const groupList = useMemo(() => {
    if (isLoading || isNotInitialized) {
      return <DelayedProgress variant="text" />;
    }
    if (data.length === 0) {
      return <Typography variant="body2">{t("Архив пуст")}</Typography>;
    }
    return data.sort(sortByDateDesc).map((group) => {
      return (
        <Group
          key={group.id}
          data={group}
          locationComponent={ArchiveLocationCard}
          full={Boolean(group.isArchived)}
          variant="archive"
        />
      );
    });
  }, [t, data, isLoading, isNotInitialized]);

  const numOfLocations: number = data.reduce((total, currentItem) => {
    total += currentItem.locations.length;
    return total;
  }, 0);

  const numOfVideos: number = data
    .map((item) => item.locations)
    .flat()
    .reduce((total, currentItem) => {
      total += currentItem.videos ?? 0;
      return total;
    }, 0);

  return (
    <>
      <div className={classes.header}>
        <div className={archiveClasses.headerWrapper}>
          <div className={archiveClasses.generalInfo}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="h3"
            >
              {t(I18N.main.SUBTITLE_ARCHIVE)}
            </Typography>

            <div className={classes.infoItems}>
              <Button startIcon={<FolderIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${data.length} ${declOfNum(
                    data.length,
                    declensionsOfGroup
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<LocationIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfLocations} ${declOfNum(
                    numOfLocations,
                    declensionsOfLocation
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<MovieIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfVideos} ${declOfNum(
                    numOfVideos,
                    declensionsOfVideo
                  )}`}
                </Typography>
              </Button>
            </div>
          </div>

          <div>
            <Button
              className={classes.btn}
              color="primary"
              variant="outlined"
              startIcon={<TrashIcon className={archiveClasses.deleteIcon} />}
              disabled={data.length === 0}
              onClick={handleTrash}
            >
              {t(I18N.main.BTN_TRASH_ALL)}
            </Button>

            <Button
              color="primary"
              className={classes.btn}
              variant="contained"
              startIcon={<RecoverIcon className={archiveClasses.recoverIcon} />}
              disabled={data.length === 0}
              onClick={handleRestore}
            >
              {t(I18N.main.BTN_RESTORE_ALL)}
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
      </div>
      <div>{groupList}</div>
    </>
  );
}
