import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import moment from "moment";
import { ScaleOrdinal } from "d3-scale";

import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

import clsx from "clsx";

import I18N from "../../../app/i18n/strings";

import { ResultKeys } from "../../../components/charts/bar/types";
import { TIME_RANGE_FORMAT } from "../../../components/charts/chart-utils";
import { DateRange } from "../../../components/DateRangePicker";

import DateRangePicker from "./DateRange";
import Legend, { LegendEntry } from "./Legend";
import LocationPicker from "./LocationPicker";
import { usePanelStyle } from "./optionsPanelStyles";
import TimeRange from "./TimeRange";

import { LocationId } from "../../groups/data-types";
import { RawResult } from "../charts/RawResult";

interface OptionsPanelProps {
  colorScale: ScaleOrdinal<ResultKeys<RawResult>, string>;
  daysOfWeek: number[];
  timeRanges: DateRange[];
  resultFields: Record<ResultKeys<RawResult>, LegendEntry>;
  resultKeys: Array<ResultKeys<RawResult>>;
  openRightBar: boolean;
  handleDrawerToggle: () => void;
  dateRange?: DateRange;
  initialDateRange?: DateRange;
  locationId?: LocationId;
}

enum DASHBOARD_URL {
  PAGE_MAIN = "/location/dashboard/",
  PAGE_LOCATION = "/location/{id}/dashboard/",
}

interface ICategories {
  name: string;
  age: string[];
  gender: string[];
  checkboxes: (string | null)[];
}

const DATE_FORMAT = "YYYY-MM-DD";

export default function OptionsPanel(props: OptionsPanelProps): ReactElement {
  const [menIsChecked, setMenIsChecked] = React.useState<boolean>(false);

  const categories: ICategories[] = [
    { name: "oldMen", age: ["ageSenior"], gender: ["gMale"], checkboxes: [] },
    {
      name: "middleMen",
      age: ["ageMiddle"],
      gender: ["gMale"],
      checkboxes: [],
    },
    { name: "youngMen", age: ["ageJun"], gender: ["gMale"], checkboxes: [] },
    {
      name: "unknownMen",
      age: ["ageUnknown"],
      gender: ["gMale"],
      checkboxes: [],
    },
    {
      name: "oldWomen",
      age: ["ageSenior"],
      gender: ["gFemale"],
      checkboxes: [],
    },
    {
      name: "middleWomen",
      age: ["ageMiddle"],
      gender: ["gFemale"],
      checkboxes: [],
    },
    {
      name: "youngWomen",
      age: ["ageJun"],
      gender: ["gFemale"],
      checkboxes: [],
    },
    {
      name: "unknownWomen",
      age: ["ageUnknown"],
      gender: ["gFemale"],
      checkboxes: [],
    },
    {
      name: "oldUnknown",
      age: ["ageSenior", "ageUnknown"],
      gender: ["gUnknown"],
      checkboxes: [],
    },
    {
      name: "middleUnknown",
      age: ["ageMiddle", "ageUnknown"],
      gender: ["gUnknown"],
      checkboxes: [],
    },
    {
      name: "youngUnknown",
      age: ["ageJun", "ageUnknown"],
      gender: ["gUnknown"],
      checkboxes: [],
    },
    {
      name: "unknownUnknown",
      age: ["ageUnknown"],
      gender: ["gUnknown"],
      checkboxes: [],
    },
  ];

  const classes = usePanelStyle();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const getQuery = useCallback(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const {
    colorScale,
    daysOfWeek,
    dateRange,
    timeRanges,
    locationId,
    resultFields,
    resultKeys,
    initialDateRange,
    openRightBar,
    handleDrawerToggle,
  } = props;

  React.useEffect(() => {
    if (
      !resultKeys.includes("cars") &&
      resultKeys.includes("men") &&
      !resultKeys.includes("bikes")
    ) {
      setMenIsChecked(true);
    } else {
      setMenIsChecked(false);
    }
  }, [resultKeys]);

  // Change string location when use Location picker
  const handleLocationChange = useCallback(
    (newLocationId?: LocationId) => {
      newLocationId === undefined
        ? history.replace(DASHBOARD_URL.PAGE_MAIN)
        : history.replace(
            DASHBOARD_URL.PAGE_LOCATION.replace("{id}", newLocationId)
          );
    },
    [history]
  );

  // Date range change handlers
  const handleDateChange = useCallback(
    (range?: DateRange): void => {
      const rangeStart = range?.start ?? initialDateRange?.start ?? undefined;
      const rangeEnd = range?.end ?? initialDateRange?.end ?? undefined;
      const query = getQuery();
      query.set(
        "startDate",
        rangeStart !== undefined ? moment(rangeStart).format(DATE_FORMAT) : ""
      );
      query.set(
        "endDate",
        rangeEnd !== undefined ? moment(rangeEnd).format(DATE_FORMAT) : ""
      );

      history.replace(`?${query.toString()}`);
    },
    [getQuery, history, initialDateRange]
  );

  // DayOfWeek change handlers
  const handleDayChange = useCallback(
    (dayNumber: number[]): void => {
      const query = getQuery();
      query.set("day", JSON.stringify(dayNumber));
      history.replace(`?${query.toString()}`);
    },
    [getQuery, history]
  );

  // Time range change handlers
  const handleTimeChange = useCallback(
    (ranges: DateRange[]): void => {
      const query = getQuery();
      const timeQueryObj = ranges.map(({ start, end }) => ({
        start: moment(start).format(TIME_RANGE_FORMAT),
        end: moment(end).format(TIME_RANGE_FORMAT),
      }));
      query.set("time", JSON.stringify(timeQueryObj));
      history.replace(`?${query.toString()}`);
    },
    [getQuery, history]
  );

  const handleLegendChange = useCallback(
    (resultKeys: Array<ResultKeys<RawResult>>): void => {
      const query = getQuery();

      if (!resultKeys.includes("cars") && !resultKeys.includes("bikes")) {
        const selectedAges = [
          resultKeys.includes("ageJun") ? "ageJun" : null,
          resultKeys.includes("ageMiddle") ? "ageMiddle" : null,
          resultKeys.includes("ageSenior") ? "ageSenior" : null,
          resultKeys.includes("ageUnknown") ? "ageUnknown" : null,
        ].filter(Boolean);

        const selectedGenders = [
          resultKeys.includes("gMale") ? "gMale" : null,
          resultKeys.includes("gFemale") ? "gFemale" : null,
          resultKeys.includes("gUnknown") ? "gUnknown" : null,
        ].filter(Boolean);

        const filteredCategories: ICategories[] = categories.reduce(
          (acc: ICategories[], category: ICategories) => {
            if (
              category.age.some((age) => selectedAges.includes(age)) &&
              category.gender.some((gender) => selectedGenders.includes(gender))
            ) {
              acc.push({
                ...category,
                checkboxes: [...selectedAges, ...selectedGenders],
              });
            }
            return acc;
          },
          []
        );

        const selectedCategories = [
          ...filteredCategories.map((i) => i.name),
          ...(filteredCategories.map((i) => i.checkboxes)[0] ?? ""),
          "men",
        ];

        if (selectedCategories.length > 0) {
          query.set("objects", JSON.stringify(selectedCategories));
        } else {
          query.set("objects", JSON.stringify([]));
        }
      } else {
        query.set("objects", JSON.stringify(resultKeys));
      }

      history.replace(`?${query.toString()}`);
    },
    [getQuery, history, categories]
  );

  // Clear panel state = clear URL
  const handleClearURL = useCallback(() => {
    history.push(location.pathname);
  }, [history, location]);

  const minDate =
    initialDateRange !== undefined
      ? moment(new Date(initialDateRange.start).setHours(0, 0, 0))
      : undefined;
  const maxDate =
    initialDateRange !== undefined
      ? moment(new Date(initialDateRange.end).setHours(23, 59, 59))
      : undefined;
  return (
    <div
      className={clsx(classes.root, {
        [classes.rootClose]: !openRightBar,
      })}
    >
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openRightBar,
          [classes.drawerClose]: !openRightBar,
        })}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerPaperClose]: !openRightBar,
          }),
        }}
        anchor="right"
      >
        <div
          className={clsx({
            [classes.hide]: openRightBar,
          })}
        >
          <IconButton
            color="primary"
            onClick={handleDrawerToggle}
            className={classes.toggleButtonOpen}
          >
            <NavigateBefore />
          </IconButton>
        </div>
        <div
          className={clsx(classes.drawerWrapper, {
            [classes.hide]: !openRightBar,
          })}
        >
          <div>
            <div className={classes.titleWrap}>
              <Typography variant="h4" className={classes.title}>
                {t(I18N.chart.OPTIONS_PANEL_TITLE)}
              </Typography>
              <Button
                className={clsx(classes.toggleButtonClose, {
                  [classes.hide]: !openRightBar,
                })}
                endIcon={<NavigateNext />}
                color="primary"
                onClick={handleDrawerToggle}
              >
                {t(I18N.chart.OPTIONS_PANEL_TOGGLE_BUTTON)}
              </Button>
            </div>
            <Divider />
            <LocationPicker
              locationId={locationId}
              onChange={handleLocationChange}
            />
            <DateRangePicker
              range={dateRange}
              onChange={handleDateChange}
              minDate={minDate}
              maxDate={maxDate}
              daysOfWeek={daysOfWeek}
              onDayChange={handleDayChange}
              disabled={initialDateRange === undefined}
            />
            <TimeRange ranges={timeRanges} onChange={handleTimeChange} />
            <Legend<RawResult>
              colorScale={colorScale}
              entries={resultFields}
              value={resultKeys}
              onChange={handleLegendChange}
            />
            {menIsChecked && (
              <Legend<RawResult>
                colorScale={colorScale}
                entries={resultFields}
                value={resultKeys}
                isMen={menIsChecked}
                onChange={handleLegendChange}
              />
            )}
          </div>

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={handleClearURL}
          >
            {t(I18N.chart.OPTIONS_PANEL_BUTTON)}
          </Button>
        </div>
      </Drawer>
    </div>
  );
}
