import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Theme, Typography } from "@material-ui/core";
import { ScaleOrdinal } from "d3-scale";
import { d3format, d3locale } from "../../../app/i18n/d3";
import { ResultKeys } from "../../../components/charts/bar/types";
import { AggregationResult } from "../chart-types";
import { selectIsMenMode, selectIsMenUniteMode } from "../dashboardSlice";
import { RawResult } from "./RawResult";

import { ReactComponent as WalkerIcon } from "../../../app/assets/walkerIcon.svg";
import { ReactComponent as CarIcon } from "../../../app/assets/carIcon.svg";
import { ReactComponent as BicycleIcon } from "../../../app/assets/bicycleIcon.svg";

import { ReactComponent as MenIcon } from "../../../app/assets/menIcon.svg";
import { ReactComponent as WomenIcon } from "../../../app/assets/womenIcon.svg";
import { ReactComponent as UnknownIcon } from "../../../app/assets/unknownIcon.svg";

const useStyles = makeStyles((theme: Theme) => ({
  tooltipRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.25),
    marginRight: "10px",
    width: "max-content",

    "& .MuiTypography-root": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
  },
  square: {
    height: 10,
    width: 10,
    borderRadius: 2,
  },
  tooltipValue: {
    fontWeight: 600,
    fontSize: 12,
    fontHeight: 15,
    marginLeft: theme.spacing(1),
  },

  tooltipIcon: {
    maxHeight: 13,
    maxWidth: 13,
    marginLeft: theme.spacing(0.5),
  },

  summaryRow: {
    marginTop: theme.spacing(1),
    fontSize: 12,
    fontHeight: 15,
    fontWeight: 500,
    textAlign: "center",
    color: "#122045",
  },
}));

interface ChartTooltipProps<T> {
  data: T;
  resultKeys: Array<ResultKeys<T>>;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;

  tooltipDataGetter: (data: T) => string[];
}

export default function ChartTooltip(
  props: ChartTooltipProps<RawResult | AggregationResult>
): ReactElement {
  const { colorScale, data, resultKeys, tooltipDataGetter } = props;
  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const classes = useStyles();

  return (
    <div>
      {!isMenMode && !data.value && resultKeys.includes("men") && (
        <div key="men" className={classes.tooltipRow}>
          <WalkerIcon
            className={classes.tooltipIcon}
            style={{ color: colorScale("men") }}
          />
          <Typography className={classes.tooltipValue}>
            {d3format(data.men)}
          </Typography>
        </div>
      )}
      {!isMenMode && !data.value && resultKeys.includes("bikes") && (
        <div key="bikes" className={classes.tooltipRow}>
          <BicycleIcon
            className={classes.tooltipIcon}
            style={{ color: colorScale("bikes") }}
          />
          <Typography className={classes.tooltipValue}>
            {d3format(data.bikes)}
          </Typography>
        </div>
      )}
      {!isMenMode && !data.value && resultKeys.includes("cars") && (
        <div key="cars" className={classes.tooltipRow}>
          <CarIcon
            className={classes.tooltipIcon}
            style={{ color: colorScale("cars") }}
          />
          <Typography className={classes.tooltipValue}>
            {d3format(data.cars)}
          </Typography>
        </div>
      )}
      {!isMenMode &&
        data.label === "men" &&
        data.value &&
        resultKeys.includes("men") && (
          <div key="men" className={classes.tooltipRow}>
            <WalkerIcon
              className={classes.tooltipIcon}
              style={{ color: colorScale("men") }}
            />
            <Typography className={classes.tooltipValue}>
              {d3locale.format(".2~%")(data.value)}
            </Typography>
          </div>
        )}
      {!isMenMode &&
        data.label === "bikes" &&
        data.value &&
        resultKeys.includes("bikes") && (
          <div key="bikes" className={classes.tooltipRow}>
            <BicycleIcon
              className={classes.tooltipIcon}
              style={{ color: colorScale("bikes") }}
            />
            <Typography className={classes.tooltipValue}>
              {d3locale.format(".2~%")(data.value)}
            </Typography>
          </div>
        )}
      {!isMenMode &&
        data.label === "cars" &&
        data.value &&
        resultKeys.includes("cars") && (
          <div key="cars" className={classes.tooltipRow}>
            <CarIcon
              className={classes.tooltipIcon}
              style={{ color: colorScale("cars") }}
            />
            <Typography className={classes.tooltipValue}>
              {d3locale.format(".2~%")(data.value)}
            </Typography>
          </div>
        )}
      {data.label === "etc" && data.value && (
        <div key="etc" className={classes.tooltipRow}>
          <Typography className={classes.tooltipValue}>
            {d3locale.format(".2~%")(data.value)}
          </Typography>
        </div>
      )}
      {isMenMode && !isMenUniteMode && !data.value && (
        <div style={{ display: "flex" }}>
          <div className={classes.tooltipContainer}>
            <div className={classes.tooltipRow} style={{ opacity: "0" }}>
              <Typography className={classes.tooltipValue}>M</Typography>

              <MenIcon className={classes.tooltipIcon} />
            </div>

            <div className={classes.tooltipRow}>
              <Typography
                style={{ color: "#888888" }}
                className={classes.tooltipValue}
              >
                45+
              </Typography>
            </div>
            <div className={classes.tooltipRow}>
              <Typography
                style={{ color: "#888888" }}
                className={classes.tooltipValue}
              >
                15-45
              </Typography>
            </div>
            <div className={classes.tooltipRow}>
              <Typography
                style={{ color: "#888888" }}
                className={classes.tooltipValue}
              >
                0-15
              </Typography>
            </div>
            <div className={classes.tooltipRow}>
              <Typography
                style={{ color: "#888888" }}
                className={classes.tooltipValue}
              >
                Н/О
              </Typography>
            </div>
          </div>
          <div className={classes.tooltipContainer}>
            {
              <div
                style={{ position: "relative", right: 9 }}
                className={classes.tooltipRow}
              >
                <Typography
                  style={{
                    marginRight: 4,
                    color: "#11B0C7",
                  }}
                  className={classes.tooltipValue}
                >
                  M
                </Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>
            }
            {resultKeys.includes("oldMen") && (
              <div key="oldMen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#11B0C7",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.oldMen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("middleMen") && (
              <div key="middleMen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#59C8D8",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.middleMen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("youngMen") && (
              <div key="youngMen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#ACE3EB",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.youngMen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("unknownMen") && (
              <div key="unknownMen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#DBF3F7",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.unknownMen)}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.tooltipContainer}>
            {
              <div
                style={{ position: "relative", right: 9 }}
                className={classes.tooltipRow}
              >
                <Typography
                  style={{ marginRight: 4, color: "#EE645A" }}
                  className={classes.tooltipValue}
                >
                  Ж
                </Typography>

                <WomenIcon
                  style={{ height: 13 }}
                  className={classes.tooltipIcon}
                />
              </div>
            }
            {resultKeys.includes("oldWomen") && (
              <div key="oldWomen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#EE645A",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.oldWomen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("middleWomen") && (
              <div key="middleWomen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#F3938B",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.middleWomen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("youngWomen") && (
              <div key="youngWomen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#F9C9C5",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.youngWomen)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("unknownWomen") && (
              <div key="unknownWomen" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#FCE8E6",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.unknownWomen)}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.tooltipContainer}>
            {
              <div
                style={{ position: "relative", right: 9 }}
                className={classes.tooltipRow}
              >
                <Typography
                  style={{ marginRight: 4, color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  Н/О
                </Typography>

                <UnknownIcon className={classes.tooltipIcon} />
              </div>
            }
            {resultKeys.includes("oldUnknown") && (
              <div key="oldUnknown" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#888888",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.oldUnknown)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("middleUnknown") && (
              <div key="middleUnknown" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#BBBBBB",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.middleUnknown)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("youngUnknown") && (
              <div key="youngUnknown" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#E2E0E0",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.youngUnknown)}
                </Typography>
              </div>
            )}
            {resultKeys.includes("unknownUnknown") && (
              <div key="unknownUnknown" className={classes.tooltipRow}>
                <div
                  className={classes.square}
                  style={{
                    background: "#F0F0F0",
                  }}
                ></div>
                <Typography className={classes.tooltipValue}>
                  {d3format(data.unknownUnknown)}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "oldMen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  45+
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#11B0C7",
                    }}
                    className={classes.tooltipValue}
                  >
                    M
                  </Typography>

                  <MenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("oldMen") && (
                <div key="oldMen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#11B0C7",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "middleMen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  15-45
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#11B0C7",
                    }}
                    className={classes.tooltipValue}
                  >
                    M
                  </Typography>

                  <MenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("middleMen") && (
                <div key="middleMen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#59C8D8",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "youngMen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  0-15
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#11B0C7",
                    }}
                    className={classes.tooltipValue}
                  >
                    M
                  </Typography>

                  <MenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("youngMen") && (
                <div key="youngMen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#ACE3EB",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "unknownMen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  Н/О
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#11B0C7",
                    }}
                    className={classes.tooltipValue}
                  >
                    M
                  </Typography>

                  <MenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("unknownMen") && (
                <div key="unknownMen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#DBF3F7",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "oldWomen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  45+
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#EE645A",
                    }}
                    className={classes.tooltipValue}
                  >
                    Ж
                  </Typography>

                  <WomenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("oldWomen") && (
                <div key="oldWomen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#EE645A",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "middleWomen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  15-45
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#EE645A",
                    }}
                    className={classes.tooltipValue}
                  >
                    Ж
                  </Typography>

                  <WomenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("middleWomen") && (
                <div key="middleWomen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#F3938B",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "youngWomen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  0-15
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#EE645A",
                    }}
                    className={classes.tooltipValue}
                  >
                    Ж
                  </Typography>

                  <WomenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("youngWomen") && (
                <div key="youngWomen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#F9C9C5",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "unknownWomen" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  Н/О
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#EE645A",
                    }}
                    className={classes.tooltipValue}
                  >
                    Ж
                  </Typography>

                  <WomenIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("unknownWomen") && (
                <div key="unknownWomen" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#FCE8E6",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "oldUnknown" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  45+
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#888888",
                    }}
                    className={classes.tooltipValue}
                  >
                    Н/О
                  </Typography>

                  <UnknownIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("oldUnknown") && (
                <div key="oldUnknown" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#888888",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "middleUnknown" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  15-45
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#888888",
                    }}
                    className={classes.tooltipValue}
                  >
                    Н/О
                  </Typography>

                  <UnknownIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("middleUnknown") && (
                <div key="middleUnknown" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#BBBBBB",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "youngUnknown" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  0-15
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#888888",
                    }}
                    className={classes.tooltipValue}
                  >
                    Н/О
                  </Typography>

                  <UnknownIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("youngUnknown") && (
                <div key="youngUnknown" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#E2E0E0",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      {isMenMode &&
        !isMenUniteMode &&
        data?.value &&
        data?.label === "unknownUnknown" && (
          <div style={{ display: "flex" }}>
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipRow} style={{ opacity: "0" }}>
                <Typography className={classes.tooltipValue}>M</Typography>

                <MenIcon className={classes.tooltipIcon} />
              </div>

              <div className={classes.tooltipRow}>
                <Typography
                  style={{ color: "#888888" }}
                  className={classes.tooltipValue}
                >
                  Н/О
                </Typography>
              </div>
            </div>

            <div className={classes.tooltipContainer}>
              {
                <div
                  style={{ position: "relative", right: 9 }}
                  className={classes.tooltipRow}
                >
                  <Typography
                    style={{
                      marginRight: 4,
                      color: "#888888",
                    }}
                    className={classes.tooltipValue}
                  >
                    Н/О
                  </Typography>

                  <UnknownIcon className={classes.tooltipIcon} />
                </div>
              }
              {resultKeys.includes("unknownUnknown") && (
                <div key="unknownUnknown" className={classes.tooltipRow}>
                  <div
                    className={classes.square}
                    style={{
                      background: "#F0F0F0",
                    }}
                  ></div>
                  <Typography className={classes.tooltipValue}>
                    {d3locale.format(".2~%")(data.value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}

      {isMenMode && isMenUniteMode && (
        <div className={classes.tooltipRow}>
          <WalkerIcon
            className={classes.tooltipIcon}
            style={{ color: colorScale("men") }}
          />
          <Typography className={classes.tooltipValue}>
            {data.value ? `100%` : d3format(data.men)}
          </Typography>
        </div>
      )}
      <Typography
        color="textSecondary"
        variant="body1"
        className={classes.summaryRow}
      >
        {!data.value &&
          tooltipDataGetter(data).map((part) => (
            <span key={part}>
              {part}
              <br />
            </span>
          ))}
      </Typography>
    </div>
  );
}
