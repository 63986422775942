import { RootState } from "../../app/store";
import { ResultKeys } from "../../components/charts/bar/types";
import { RawResult } from "../dashboard/charts/RawResult";
import {
  GroupId,
  GroupMap,
  ISODateString,
  LocationData,
  LocationId,
} from "../groups/data-types";
import { DATA_LOAD_STATE, VideoDataState } from "../groups/slice-utils";
import {
  StreamData,
  StreamId,
  UploadData,
  VideoData,
  VideoId,
} from "./data-mapper";
import { LocationsState, LocationState, withLocation } from "./locationHepers";
import { Moment } from "moment";

interface LocationSelectValue {
  id: LocationId;
  title: string;
  videoFilesSize?: number
  videos?: number
}

export interface GroupSelectValue {
  id?: GroupId;
  title: string;
  locations?: LocationData[]
}

// Data for <SELECT> components

/**
 * Returns Group list (to use as <Select> data source)
 * @param rootState - Root redux state
 */
export const selectGroupValues = (rootState: RootState): GroupSelectValue[] => {
  return Array.from((rootState.videoData as VideoDataState).data.values()).map(
    ({ id, title, locations }) => ({
      id,
      title,
      locations: Object.values(Object.fromEntries(locations)),
    })
  );
};

/**
 * Returns Locations list (to use as <Select> data source)
 * @param [groupId]: group ID
 */
export const getLocationValuesSelector =
  (groupId?: GroupId) =>
  (rootState: RootState): LocationSelectValue[] => {
    if (groupId === undefined) {
      return [];
    }

    const group = rootState.videoData.data.get(groupId);
    if (group === undefined) {
      return [];
    }

    return Array.from(group.locations.values());
  };

/**
 * Returns selector to find group by location
 * @param [locationId] - location ID
 */
export const getGroupByLocationSelector =
  (locationId: LocationId) =>
  (rootState: RootState): GroupMap | undefined => {
    return Array.from(
      (rootState.videoData as VideoDataState).data.values()
    ).find(({ locations }) => locations.has(locationId));
  };

// ANALYTICS

/**
 * Returns true if analytics for the given location hasn't been requested yet
 * @param locationId
 */
export const getAnalyticsNotInitializedSelector =
  (locationId?: LocationId) =>
  (state: RootState): boolean | undefined => {
    return withLocation(state.location, locationId, (location) => {
      return (
        location.analyticsState === DATA_LOAD_STATE.NONE &&
        location.state === DATA_LOAD_STATE.LOADED
      );
    });
  };

/**
 * Returns:
 *  `undefined` if location is not yet initialized
 *  `true` if the given Location has analytics data
 *  `false` if the given Location doesn't have analytics data
 *
 * @param [locationId] - location ID
 */
export const getLocationHasAnalyticsSelector =
  (locationId?: LocationId) =>
  (state: RootState): boolean | undefined => {
    return withLocation(state.location, locationId, (location) => {
      if (location.state !== DATA_LOAD_STATE.LOADED) {
        return undefined;
      }

      return (
        Array.from([...location.videos.values(), ...location.streams.values()])
          .length > 0
      );
    });
  };

export const getCountOfLocationWithAnalyticsSelector =
  (locationId?: LocationId) =>
  (state: RootState): (VideoData | StreamData)[] | undefined => {
    return withLocation(state.location, locationId, (location) => {
      if (location.state !== DATA_LOAD_STATE.LOADED) {
        return undefined;
      }

      return Array.from([
        ...location.videos.values(),
        ...location.streams.values(),
      ]);
    });
  };

export const getAnalyticsUpdatedAtSelector =
  (locationId?: LocationId) =>
  (state: RootState): ISODateString | undefined => {
    return withLocation(state.location, locationId, (location) => {
      let updatedAt: Moment | undefined;
      for (const { analyticsDate } of [
        ...location.videos.values(),
        ...location.streams.values(),
      ]) {
        if (analyticsDate === undefined) {
          continue;
        }

        if (
          updatedAt === undefined ||
          updatedAt.valueOf() < analyticsDate.valueOf()
        ) {
          updatedAt = analyticsDate;
        }
      }

      return updatedAt?.format();
    });
  };

/**
 * Returns selector to select a single Stream by location ID, stream ID
 * @param [locationId] - location ID
 * @param [videoId] - stream ID
 */
export const getStreamSelector =
  (locationId?: LocationId, streamId?: StreamId) =>
  ({ location }: RootState): StreamData | undefined => {
    const state = location as LocationsState;
    if (
      locationId === undefined ||
      streamId === undefined ||
      !state.data.has(locationId)
    ) {
      return;
    }

    const locationState = state.data.get(locationId) as LocationState;
    return locationState.streams.get(streamId);
  };

/**
 * Returns selector to select Streams by location ID
 * @param locationId - location ID
 */
export const getStreamsSelector =
  (locationId?: LocationId) =>
  ({ location }: RootState): StreamData[] => {
    const state = location as LocationsState;
    if (locationId === undefined || !state.data.has(locationId)) {
      return [];
    }

    const locationState = state.data.get(locationId) as LocationState;
    return Array.from(locationState.streams.values());
  };

/**
 * Returns selector to select Streams by location ID
 * @param locationId - location ID
 */
export const getSourcesSelector =
  (locationId?: LocationId) =>
  ({ location }: RootState): Array<StreamData | VideoData> => {
    const state = location as LocationsState;
    if (locationId === undefined || !state.data.has(locationId)) {
      return [];
    }

    const locationState = state.data.get(locationId) as LocationState;
    return [
      ...locationState.videos.values(),
      ...locationState.streams.values(),
    ];
  };

/**
 * Returns selector to select Streams by location ID
 * @param locationId - location ID
 */
export const getSourceIdsSelector =
  (locationId?: LocationId) =>
  (state: RootState): Array<StreamId | VideoId> => {
    const dataSource = getSourcesSelector(locationId)(state);
    return dataSource.map(({ id }) => id);
  };

/**
 * Returns selector to select Streams by location ID for demolocation
 * @param locationId - location ID
 */
export const getSourceIdsForAnalyticsSelector =
  (locationId?: LocationId) =>
  (state: RootState): VideoId[] => {
    const dataSource = getSourcesSelector(locationId)(state) as VideoData[];
    const dataHasVideosClone =
      dataSource.find(({ isClone }) => isClone) !== undefined;
    if (dataHasVideosClone) {
      return dataSource.map(({ originalVideoFileId }) => originalVideoFileId);
    }
    return dataSource.map(({ id }) => id);
  };

/**
 * Returns selector to select a single Video by location ID, video ID
 * @param [locationId] - location ID
 * @param [videoId] - video ID
 */
export const getVideoSelector =
  (locationId?: LocationId, videoId?: VideoId) =>
  ({ location }: RootState): VideoData | undefined => {
    const state = location as LocationsState;
    if (
      locationId === undefined ||
      videoId === undefined ||
      !state.data.has(locationId)
    ) {
      return;
    }

    const locationState = state.data.get(locationId) as LocationState;
    return locationState.videos.get(videoId);
  };

/**
 * Returns selector to select Videos by location ID
 * @param [locationId] - location ID
 */
export const getVideosSelector =
  (locationIds?: LocationId | LocationId[]) =>
  ({ location }: RootState): VideoData[] => {
    if (locationIds === undefined) {
      return [];
    }

    if (!Array.isArray(locationIds)) {
      locationIds = [locationIds];
    }

    return locationIds
      .filter((locationId) => location.data.has(locationId))
      .reduce((res: VideoData[], locationId) => {
        const locationState = location.data.get(locationId) as LocationState;

        return [...res, ...Array.from(locationState.videos.values())];
      }, []);
  };

/**
 * Returns selector to select Uploads by location ID
 * @param [locationIds] - list of location IDs
 */
export const getUploadsSelector =
  (locationIds?: LocationId | LocationId[]) =>
  ({ location }: RootState): UploadData[] => {
    if (locationIds === undefined) {
      return [];
    }

    if (!Array.isArray(locationIds)) {
      locationIds = [locationIds];
    }

    return locationIds
      .filter((locationId) => location.data.has(locationId))
      .reduce((res: UploadData[], locationId) => {
        const locationState = location.data.get(locationId) as LocationState;

        return [...res, ...Array.from(locationState.uploads.values())];
      }, []);
  };

/**
 * Returns selector to select true if location is not loaded yet
 * @param [locationId] - location ID
 */
export const getNotInitializedSelector =
  (locationId?: LocationId) =>
  ({ location }: RootState): boolean | undefined => {
    if (locationId === undefined) {
      return undefined;
    }

    const state = location as LocationsState;
    return (
      !state.data.has(locationId) ||
      state.data.get(locationId)?.state === DATA_LOAD_STATE.NONE
    );
  };

/**
 * Returns selector to select true if location has been loaded
 * @param [locationId] - location ID
 */
export const getLoadedSelector =
  (locationId?: LocationId) =>
  ({ location }: RootState): boolean | undefined => {
    if (locationId === undefined) {
      return undefined;
    }

    const state = location as LocationsState;
    return (
      state.data.has(locationId) &&
      state.data.get(locationId)?.state === DATA_LOAD_STATE.LOADED
    );
  };

export interface RawAnalyticsData {
  videoOrStreamId: VideoId | StreamId;
  data: {
    resultKey: ResultKeys<RawResult>;
    count: number;
    time: ISODateString;
    gender: number | null;
    age: number | null;
    additionalInfo: {
      objectsCount: number;
      uniqueCount: number;
      repeatingCount: number;
      repeatingObjectsCount: number;
    } | null;
  };
}

/**
 * Returns selector to select a location title
 * @param [locationId] - location ID
 */
export const getLocationSelector =
  (locationId?: LocationId) =>
  ({ videoData }: RootState): LocationData | undefined => {
    if (locationId === undefined) {
      return undefined;
    }

    const state = videoData as VideoDataState;
    const arrayLocations = Array.from(state.data.values()).find(
      ({ locations }) => locations.has(locationId)
    ) as GroupMap;
    return arrayLocations.locations.get(locationId) as LocationData;
  };
