import React, { useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear } from "@visx/scale";
import { GridRows } from "@visx/visx";
import { COLORS } from "../../../theme";
import { ScaleOrdinal } from "d3-scale";

import { ResultKeys } from "../../../components/charts/bar/types";
import { DataForTotalPie } from "../constants";

export type BarsProps = {
  width: number;
  height: number;
  events?: boolean;
  data: Array<DataForTotalPie>;
};

interface IProps<T> extends BarsProps {
  getTooltipHandler: (
    datumIndex: string
  ) => ((event: MouseEvent) => void) | undefined;
  tTip: false | JSX.Element | undefined;
  hideTooltip: () => void;
  containerRef: (element: HTMLElement | SVGElement | null) => void;
  resultKeys: Array<ResultKeys<T>>;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
}

const black = "#000000";

export default function PeopleCategoryPercentage<T>(props: IProps<T>) {
  const {
    width,
    height,
    data,
    tTip,
    resultKeys,
    containerRef,
    hideTooltip,
    getTooltipHandler,
  } = props;
  const verticalMargin = 120;

  const offset = useMemo(
    () => ({
      left: 30,
      top: 60,
    }),
    [data]
  );

  const chartWidth = width;
  const chartHeight = height;

  // accessors
  const getLabel = (d: DataForTotalPie) => d.label;
  const getValue = (d: DataForTotalPie) => d.value;

  // bounds
  const xMax = width - 25;
  const yMax = height - verticalMargin;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map(getLabel),
        padding: 0.4,
      }),
    [xMax, data]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...data.map(getValue))],
      }),
    [yMax, data]
  );

  const valueOfFilteredData: number = data
    .filter((i) => i.value !== 0)
    .reduce((sum, { value }) => sum + value, 0);

  const leftAxis = scaleLinear<number>({
    range: [yMax, 0],
    round: true,
    domain: [0, (Math.max(...data.map(getValue)) / valueOfFilteredData) * 100],
  });

  const filteredData: DataForTotalPie[] = data.filter(({ label }) =>
    resultKeys.includes(label as ResultKeys<T>)
  );

  return width < 10 ? null : (
    <svg ref={containerRef} width={width} height={height / 1.2}>
      <GridRows
        top={offset.top}
        left={offset.left}
        scale={yScale}
        width={chartWidth}
        height={chartHeight}
        stroke={COLORS.GREY1}
        pointerEvents="none"
      />
      <Group top={verticalMargin / 2} left={30}>
        {filteredData.map((d, i) => {
          const label = getLabel(d);
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - (yScale(getValue(d)) ?? 0);
          const barX = xScale(label);
          const barY = yMax - barHeight;
          return (
            <Bar
              key={`bar-${label}`}
              x={data.length === 1 ? barWidth : barX}
              y={barY}
              width={data.length === 1 ? barWidth / 4 : barWidth}
              onMouseOver={getTooltipHandler(d.label)}
              onMouseLeave={hideTooltip}
              height={barHeight}
              fill={`${d.color}`}
              rx={4}
            />
          );
        })}
        <AxisLeft
          hideAxisLine
          hideTicks
          top={-5}
          left={5}
          scale={leftAxis}
          tickFormat={(value) => `${value}%`}
          tickValues={
            data.length === 1 ? [0, 25, 50, 75, 100] : leftAxis.ticks(4)
          }
          stroke={black}
          tickStroke={black}
        />
        {tTip}
      </Group>
    </svg>
  );
}
