import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";

import {
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";

import { scaleOrdinal } from "@visx/scale";
import deepEqual from "deep-equal";
import moment from "moment";

import I18N from "../../app/i18n/strings";
import { useAppLayoutStyles } from "../../app/styles";

import VisiusAppBar from "../../components/AppBar";
import { ResultKeys } from "../../components/charts/bar/types";
import { ChartVariant } from "../../components/charts/Chart";
import {
  readDate,
  readDay,
  readResultKey,
  readTimeRanges,
} from "../../components/charts/chart-utils";
import { DateRange } from "../../components/DateRangePicker";
import VisiusDrawer from "../../components/Drawer";

import { LocationId } from "../groups/data-types";
import { selectIsValidLocation } from "../groups/main/videoSlice";

import { unsubscribeFromDashboard as apiUnsubscribeFromDashboard } from "../location/api";
import { StreamId, VideoId } from "../location/data-mapper";

import {
  loadLocation,
  subscribeToDashboard,
  unsubscribeFromDashboard,
} from "../location/locationSlice";
import {
  actions,
  selectIsMenMode,
  selectIsMenUniteMode,
  selectPeopleMode,
} from "../dashboard/dashboardSlice";

import AverageNumberBarChart from "./charts/AverageNumber";
import MaximumNumberBarChart from "./charts/MaximumNumber";
import MinimumNumberBarChart from "./charts/MinimumNumber";
import RawResultBarChart, { RawResult } from "./charts/RawResult";
import TotalNumberBarChart from "./charts/TotalNumber";

import { useWorker } from "./worker/useAnalyticsWorker";

import ChartCard from "./components/ChartCard";
import PanelDrawer from "./components/OptionsPanel";
import EmptyMsgCard from "./components/EmptyMsg";
import TotalPieCard from "./components/TotalPieChart";
import StatsContainer from "./components/StatsContainer";

import {
  getAnalyticsUpdatedAtSelector,
  getLocationHasAnalyticsSelector,
  getNotInitializedSelector,
  getSourceIdsSelector,
  getSourceIdsForAnalyticsSelector,
} from "../location/selectors";

import { PagesProps } from "../groups/Page";

import { ReactComponent as CameraIcon } from "../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../app/assets/webcamIcon.svg";
import { ReactComponent as HomeIcon } from "../../app/assets/homeIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../app/assets/trashIcon.svg";
import { ReactComponent as MovieIcon } from "../../app/assets/movieIcon.svg";
import { ReactComponent as AnalyticIcon } from "../../app/assets/analyticIconSideBar.svg";

import clsx from "clsx";
import {
  itemData,
  menContainer,
  nullData,
  allkindOfPeople,
  resultFields,
  dataForTotalPie,
} from "./constants";
import { useStyles } from "./styles";

/* eslint no-void: ["error", { "allowAsStatement": true }] */

export const MAX_CARD_WIDTH = 390;

const colorScale = scaleOrdinal({
  domain: Object.keys(resultFields) as Array<ResultKeys<RawResult>>,
  range: Object.values(resultFields).map(({ color }) => color),
});

export const defaultResultKeys = Object.keys(resultFields).sort((a, b) =>
  a.localeCompare(b)
) as Array<ResultKeys<RawResult>>;
const chartVariants: ChartVariant[] = ["linear", "group"];

export default function DashboardPage(props: PagesProps): ReactElement {
  const [openRightBar, setOpenRightBar] = React.useState(true);
  const classes = useAppLayoutStyles();
  const isMenMode = useSelector(selectIsMenMode);
  const dashboardClasses = useStyles({ isMenMode });
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticsWorker = useWorker();
  const peopleMode = useSelector(selectPeopleMode);

  /**
   * Returns new instance of URLSearchParams for the current URL
   */
  const getQuery = useCallback(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  // Read and validate Location ID
  const { id } = useParams<{ id?: string }>();
  const isValidLocation = useSelector(selectIsValidLocation(id));
  const locationId = isValidLocation === true ? (id as LocationId) : undefined;
  const hasAnalytics = useSelector(getLocationHasAnalyticsSelector(locationId));

  // `isDataLoaded = false` corresponds to the 'Loading...' message in the UI
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [viewData, setViewData] = useState<RawResult[]>([]);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);

  const handleSwitchToggle = () => {
    dispatch(actions.setOnlyMenUniteMode(!isMenUniteMode));
  };

  const handleDrawerToggle = useCallback(
    () => setOpenRightBar(!openRightBar),
    [openRightBar]
  );

  const [datasetDateRange, setDatasetDateRange] = useState<DateRange>();
  const initialDateRange: DateRange | undefined = useMemo(() => {
    if (datasetDateRange === undefined) {
      return;
    }

    const endMoment = moment(datasetDateRange.end);
    return {
      end: datasetDateRange.end,
      start:
        endMoment.diff(moment(datasetDateRange.start), "d") > 7
          ? moment(datasetDateRange.end).add(-7, "d").toDate()
          : datasetDateRange.start,
    };
  }, [datasetDateRange]);

  function useGetPropertyTotals<T extends Record<string, any>>(viewData: T[]) {
    return useMemo(() => {
      const totals = {} as RawResult;

      allkindOfPeople.forEach((property: ResultKeys<RawResult>) => {
        totals[property] = viewData.reduce(
          (sum, item) => sum + item[property],
          0
        );
      });

      return totals;
    }, [viewData]);
  }

  const totals = useGetPropertyTotals(viewData);

  const totalMen = useMemo(() => {
    return menContainer().reduce((sum, cur) => {
      return sum + Number(totals[cur]);
    }, 0);
  }, [viewData]);

  const totalBikes = useMemo(
    () => viewData.reduce((sum, { bikes }) => sum + bikes, 0),
    [viewData]
  );
  const totalCars = useMemo(
    () => viewData.reduce((sum, { cars }) => sum + cars, 0),
    [viewData]
  );

  const totalObjects = useMemo(
    () =>
      viewData.reduce(
        (
          sum,
          {
            oldMen,
            middleMen,
            youngMen,
            unknownMen,
            oldWomen,
            middleWomen,
            youngWomen,
            unknownWomen,
            oldUnknown,
            middleUnknown,
            youngUnknown,
            unknownUnknown,
            cars,
            bikes,
          }
        ) => {
          return (
            sum +
            oldMen +
            middleMen +
            youngMen +
            unknownMen +
            oldWomen +
            middleWomen +
            youngWomen +
            unknownWomen +
            oldUnknown +
            middleUnknown +
            youngUnknown +
            unknownUnknown +
            bikes +
            cars
          );
        },
        0
      ),
    [viewData]
  );

  const totalCount = useMemo(() => {
    switch (true) {
      case peopleMode.repeating:
        return menContainer("repeating").reduce((sum, cur) => {
          return sum + Number(totals[cur]);
        }, 0);
      case peopleMode.uniq:
        return menContainer("uniq").reduce((sum, cur) => {
          return sum + Number(totals[cur]);
        }, 0);
      default:
        return menContainer().reduce((sum, cur) => {
          return sum + Number(totals[cur]);
        }, 0);
    }
  }, [totals, peopleMode, menContainer]);

  const totalObjectCount = useMemo(() => {
    return menContainer("object").reduce((sum, cur) => {
      return sum + Number(totals[cur]);
    }, 0);
  }, [viewData]);

  const totalUniqCount = useMemo(() => {
    return menContainer("uniq").reduce((sum, cur) => {
      return sum + Number(totals[cur]);
    }, 0);
  }, [viewData]);

  const totalRepeatingCount = useMemo(() => {
    return menContainer("repeating").reduce((sum, cur) => {
      return sum + Number(totals[cur]);
    }, 0);
  }, [viewData]);

  const totalRepeatingObjectCount = useMemo(() => {
    return menContainer("repeatingObject").reduce((sum, cur) => {
      return sum + Number(totals[cur]);
    }, 0);
  }, [viewData]);

  const resultKeysURL: Array<ResultKeys<RawResult>> = useMemo(
    () => readResultKey(getQuery()),
    [getQuery]
  ).filter(
    (i) =>
      i !== "ageJun" &&
      i !== "ageMiddle" &&
      i !== "ageSenior" &&
      i !== "ageUnknown" &&
      i !== "gMale" &&
      i !== "gUnknown" &&
      i !== "gFemale"
  );

  const getValue = useCallback(
    (label: string) => {
      switch (true) {
        case peopleMode.repeating:
          return (
            totals[(label + "RepeatingObject") as ResultKeys<RawResult>] /
            totalRepeatingObjectCount
          );
        case peopleMode.uniq:
          return (
            totals[(label + "Uniq") as ResultKeys<RawResult>] / totalUniqCount
          );
        default:
          return (
            totals[(label + "Object") as ResultKeys<RawResult>] /
            totalObjectCount
          );
      }
    },
    [peopleMode, totals]
  );

  const sourceIdsSelector = useMemo(
    () => getSourceIdsSelector(locationId),
    [locationId]
  );
  const sourceIds = useSelector(sourceIdsSelector, deepEqual);

  const sourceIdsForAnalyticsSelector = useMemo(
    () => getSourceIdsForAnalyticsSelector(locationId),
    [locationId]
  );
  const sourceIdsAnalytics = useSelector(
    sourceIdsForAnalyticsSelector,
    deepEqual
  );

  const [subscriptions, setSubscriptions] = useState<Array<StreamId | VideoId>>(
    []
  );
  const updatedAt = useSelector(getAnalyticsUpdatedAtSelector(locationId));

  // Read necessary location data
  const isLocationNotInitialized = useSelector(
    getNotInitializedSelector(locationId)
  );

  // Read Analytics options from the URL
  const dateRange: DateRange | undefined = useMemo(() => {
    const query = getQuery();
    if (datasetDateRange === undefined || initialDateRange === undefined) {
      return undefined;
    }
    const start = readDate(query, "startDate") ?? initialDateRange.start;
    const end = readDate(query, "endDate") ?? initialDateRange.end;

    const dateRangeStartRound = moment(datasetDateRange.start)
      .startOf("day")
      .toDate();

    if (
      start < dateRangeStartRound ||
      start > datasetDateRange.end ||
      start > end
    ) {
      return initialDateRange;
    }

    return { start, end };
  }, [getQuery, datasetDateRange, initialDateRange]);
  const daysOfWeek: number[] = useMemo(() => readDay(getQuery()), [getQuery]);

  const resultKeysForLegendURL: Array<ResultKeys<RawResult>> = useMemo(
    () => readResultKey(getQuery()),
    [getQuery]
  );

  const timeRanges: DateRange[] = useMemo(
    () => readTimeRanges(getQuery()),
    [getQuery]
  );

  const [lastLocationId, setLastLocationId] = useState<LocationId>();

  useEffect(() => {
    if (!isMenMode) dispatch(actions.setPeopleMode("common"));
  }, [isMenMode, dispatch]);

  useEffect(() => {
    if (locationId !== undefined && isLocationNotInitialized === true) {
      dispatch(loadLocation(locationId));
    }
  }, [dispatch, locationId, isLocationNotInitialized]);

  // Subscribe (Unsubscribe) to dahsboard dataset when location has been changed
  useEffect(() => {
    if (deepEqual(sourceIds, subscriptions)) {
      return;
    }
    dispatch(subscribeToDashboard(sourceIds));
    setSubscriptions(sourceIds);
  }, [dispatch, lastLocationId, sourceIds, subscriptions]);

  useEffect(() => {
    const callback = (): void => {
      void apiUnsubscribeFromDashboard(subscriptions);
    };
    window.addEventListener("unload", callback);
    return () => window.removeEventListener("unload", callback);
  }, [subscriptions]);
  useEffect(() => {
    if (
      subscriptions.length === 0 ||
      lastLocationId === undefined ||
      locationId === undefined ||
      lastLocationId === locationId
    ) {
      return;
    }
    dispatch(unsubscribeFromDashboard(subscriptions));
  }, [dispatch, locationId, lastLocationId, subscriptions]);

  useEffect(() => {
    dispatch(
      actions.setOnlyMenMode(
        !resultKeysURL.includes("cars") && !resultKeysURL.includes("bikes")
      )
    );
    if (resultKeysURL.includes("cars") || resultKeysURL.includes("bikes")) {
      dispatch(actions.setOnlyMenUniteMode(false));
    }
  }, [dispatch, resultKeysURL]);

  // Filter dashboard data when filters changed or new data received
  useEffect(() => {
    if (
      lastLocationId !== locationId ||
      hasAnalytics === false ||
      isValidLocation === false
    ) {
      // Set data loading status when location changed
      setIsDataLoaded(hasAnalytics === false || isValidLocation === false);
      setViewData([]);
      setLastLocationId(locationId);
    }

    if (hasAnalytics !== true) {
      return;
    }

    void (async () => {
      console.log(`Load analytics (${updatedAt ?? "initial dataset"})`);
      const dataset = await analyticsWorker.queryViewData({
        sourceIds: sourceIdsAnalytics,
        dateRange,
        timeRanges,
        daysOfWeek,
        view: "hour",
      });

      setViewData(dataset);
      setIsDataLoaded(true);
    })();
  }, [
    sourceIdsAnalytics,
    peopleMode,
    analyticsWorker,
    dateRange,
    daysOfWeek,
    isValidLocation,
    hasAnalytics,
    lastLocationId,
    locationId,
    sourceIds,
    timeRanges,
    updatedAt,
  ]);

  // Load dashboard data when filters changed or new data received
  useEffect(() => {
    void (async () => {
      const currentDatasetRange = await analyticsWorker.queryDateRange({
        sourceIds: sourceIdsAnalytics,
      });

      if (deepEqual(currentDatasetRange, datasetDateRange)) {
        return;
      }
      console.log(
        `Load analytics date range (${updatedAt ?? "initial dataset"})`
      );
      setDatasetDateRange(currentDatasetRange);
    })();
  }, [
    sourceIdsAnalytics,
    analyticsWorker,
    datasetDateRange,
    updatedAt,
    sourceIds,
  ]);

  const dataWithZeroValues = useMemo(() => {
    const data: RawResult[] = [];

    for (let i = 0; i < viewData.length; i++) {
      if (viewData.length - 1 === i) {
        data.push(viewData[i]);
        break;
      }
      data.push(viewData[i]);

      let previousDate = new Date(viewData[i].time).valueOf();
      const lastDate = new Date(viewData[i + 1].time).valueOf();

      const hour = 60 * 60 * 1000;
      while (lastDate - previousDate > hour) {
        const getDate = new Date(data[data.length - 1].time).valueOf();
        const newData: RawResult = nullData(getDate, hour);

        data.push(newData);
        previousDate += hour;
      }
    }

    return data;
  }, [viewData]);

  const dataForMenMode = useMemo(() => {
    const data: RawResult[] = [];

    if (peopleMode.common) {
      dataWithZeroValues.forEach((i) => {
        const newItemData: Partial<RawResult> = {
          time: i.time,
          ...itemData,
        };
        menContainer().forEach((key) => {
          if (key === "time") return;
          newItemData[key] = i[key];
        });

        data.push(newItemData as RawResult);
      });
    }
    if (peopleMode.uniq) {
      dataWithZeroValues.forEach((i) => {
        const newItemData: Partial<RawResult> = {
          time: i.time,
          ...itemData,
        };
        menContainer().forEach((key) => {
          if (key === "time") return;
          newItemData[key] = i[`${key}Uniq` as keyof Omit<RawResult, "time">];
        });

        data.push(newItemData as RawResult);
      });
    }
    if (peopleMode.repeating) {
      dataWithZeroValues.forEach((i) => {
        const newItemData: Partial<RawResult> = {
          time: i.time,
          ...itemData,
        };
        menContainer().forEach((key) => {
          if (key === "time") return;
          newItemData[key] =
            i[`${key}Repeating` as keyof Omit<RawResult, "time">];
        });

        data.push(newItemData as RawResult);
      });
    }
    return data;
  }, [viewData, peopleMode, menContainer]);

  const dataForUniteMenMode = useMemo(() => {
    const data: RawResult[] = [];

    if (peopleMode.common) {
      dataWithZeroValues.forEach((i) => {
        const newItemData = {
          time: i.time,
          ...itemData,
        };

        newItemData.men =
          i.oldMen +
          i.middleMen +
          i.youngMen +
          i.unknownMen +
          i.oldWomen +
          i.middleWomen +
          i.youngWomen +
          i.unknownWomen +
          i.oldUnknown +
          i.middleUnknown +
          i.youngUnknown +
          i.unknownUnknown;

        data.push(newItemData as RawResult);
      });
    }
    if (peopleMode.uniq) {
      dataWithZeroValues.forEach((i) => {
        const newItemData = {
          time: i.time,
          ...itemData,
        };

        newItemData.men =
          i.oldMenUniq +
          i.middleMenUniq +
          i.youngMenUniq +
          i.unknownMenUniq +
          i.oldWomenUniq +
          i.middleWomenUniq +
          i.youngWomenUniq +
          i.unknownWomenUniq +
          i.oldUnknownUniq +
          i.middleUnknownUniq +
          i.youngUnknownUniq +
          i.unknownUnknownUniq;

        data.push(newItemData as RawResult);
      });
    }
    if (peopleMode.repeating) {
      dataWithZeroValues.forEach((i) => {
        const newItemData = {
          time: i.time,
          ...itemData,
        };

        newItemData.men =
          i.oldMenRepeating +
          i.middleMenRepeating +
          i.youngMenRepeating +
          i.unknownMenRepeating +
          i.oldWomenRepeating +
          i.middleWomenRepeating +
          i.youngWomenRepeating +
          i.unknownWomenRepeating +
          i.oldUnknownRepeating +
          i.middleUnknownRepeating +
          i.youngUnknownRepeating +
          i.unknownUnknownRepeating;

        data.push(newItemData as RawResult);
      });
    }
    return data;
  }, [viewData]);

  const defaultData = useMemo(() => {
    const data: RawResult[] = [];

    dataWithZeroValues.forEach((i) => {
      const newItemData = {
        time: i.time,
        ...itemData,
      };

      newItemData.cars = i.cars;
      newItemData.bikes = i.bikes;

      newItemData.men =
        i.oldMen +
        i.middleMen +
        i.youngMen +
        i.unknownMen +
        i.oldWomen +
        i.middleWomen +
        i.youngWomen +
        i.unknownWomen +
        i.oldUnknown +
        i.middleUnknown +
        i.youngUnknown +
        i.unknownUnknown;

      data.push(newItemData as RawResult);
    });
    return data;
  }, [viewData]);

  // Tooltips
  const rawResultTooltip = useMemo(
    () => (
      <div>
        <Typography className="text2">
          {t(I18N.chart.TOOLTIP_RAW_SUMMARY)}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_RAW[0])}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_RAW[1])}
        </Typography>
      </div>
    ),
    [t]
  );
  const totalTooltip = useMemo(
    () => (
      <div>
        <Typography className="text2">
          {t(I18N.chart.TOOLTIP_SUM_SUMMARY)}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_SUM[0])}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_SUM[1])}
        </Typography>
      </div>
    ),
    [t]
  );
  const averageTooltip = useMemo(
    () => (
      <div>
        <Typography className="text2">
          {t(I18N.chart.TOOLTIP_AVERAGE_SUMMARY)}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_AVERAGE[0])}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_AVERAGE[1])}
        </Typography>
      </div>
    ),
    [t]
  );
  const maxTooltip = useMemo(
    () => (
      <div>
        <Typography className="text2">
          {t(I18N.chart.TOOLTIP_MAX_SUMMARY)}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_MAX[0])}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_MAX[1])}
        </Typography>
      </div>
    ),
    [t]
  );
  const minTooltip = useMemo(
    () => (
      <div>
        <Typography className="text2">
          {t(I18N.chart.TOOLTIP_MIN_SUMMARY)}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_MIN[0])}
        </Typography>
        <Typography className="text1">
          {t(I18N.chart.TOOLTIP_MIN[1])}
        </Typography>
      </div>
    ),
    [t]
  );

  const dataForChart =
    isMenMode && !isMenUniteMode
      ? dataForMenMode
      : isMenMode && isMenUniteMode
      ? dataForUniteMenMode
      : defaultData;

  return (
    <div className={classes.root}>
      <VisiusAppBar
        title={t(I18N.chart.TITLE)}
        leftPanelIsOpen={props.leftPanelIsOpen}
      />
      <VisiusDrawer
        items={[
          { title: I18N.main.LINK_VIDEOS, link: "/", icon: <HomeIcon /> },
          {
            title: I18N.common.LINK_LOCATION,
            link:
              locationId !== undefined
                ? `/location/${locationId}`
                : "/location",
            icon: <MovieIcon />,
          },
          {
            title: I18N.common.LINK_ANALYTICS,
            link: "#",
            icon: <AnalyticIcon />,
            selected: true,
          },
          {
            title: I18N.main.LINK_ARCHIVE,
            link: "/main?tab=archive",
            icon: <ArchiveIcon />,
          },
          {
            title: I18N.main.LINK_TRASH,
            link: "/main?tab=trash",
            icon: <TrashIcon />,
          },
        ]}
        open={props.leftPanelIsOpen}
        handleDrawerToggle={props.handleDrawerToggle}
        buttons={[
          {
            title: I18N.main.BTN_ADD_VIDEO,
            link: "/location?tab=video",
            icon: <CameraIcon />,
          },
          {
            title: I18N.main.BTN_ADD_STREAM,
            link: "/location?tab=stream",
            icon: <WebcamIcon />,
          },
        ]}
      />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <main className="hasMarginOffset">
          <div className={dashboardClasses.header}>
            <div className={dashboardClasses.headerWrapper}>
              <div className={dashboardClasses.generalInfo}>
                <Typography
                  className={dashboardClasses.title}
                  color="textPrimary"
                  variant="h3"
                >
                  {t(I18N.chart.TITLE)}
                </Typography>

                {isMenMode && (
                  <div className={dashboardClasses.infoItems}>
                    <Button
                      className={clsx(dashboardClasses.button, {
                        [dashboardClasses.buttonActive]: peopleMode.common,
                      })}
                      onClick={() => dispatch(actions.setPeopleMode("common"))}
                      variant="text"
                    >
                      <Typography color="inherit" variant="body2">
                        {`Все появления: ${totalMen}/${totalObjectCount} пешеходов`}
                      </Typography>
                    </Button>

                    <Button
                      className={clsx(dashboardClasses.button, {
                        [dashboardClasses.buttonActive]: peopleMode.uniq,
                      })}
                      onClick={() => dispatch(actions.setPeopleMode("uniq"))}
                      variant="text"
                    >
                      <Typography color="inherit" variant="body2">
                        {`Единственные ${totalUniqCount}/${totalUniqCount} пешеходов`}
                      </Typography>
                    </Button>

                    <Button
                      className={clsx(dashboardClasses.button, {
                        [dashboardClasses.buttonActive]: peopleMode.repeating,
                      })}
                      onClick={() =>
                        dispatch(actions.setPeopleMode("repeating"))
                      }
                      variant="text"
                    >
                      <Typography color="inherit" variant="body2">
                        {`Множественные ${totalRepeatingCount}/${totalRepeatingObjectCount} пешеходов`}
                      </Typography>
                    </Button>
                  </div>
                )}
              </div>
              {isMenMode && (
                <FormGroup className={dashboardClasses.switcher}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isMenUniteMode}
                        onChange={handleSwitchToggle}
                        disableRipple
                      />
                    }
                    label={
                      <Typography
                        style={{ fontSize: 12, marginLeft: 8 }}
                        variant="body2"
                      >
                        Объединить
                      </Typography>
                    }
                  />
                </FormGroup>
              )}
            </div>
          </div>
          {viewData.length > 0 ? (
            <div className={`${dashboardClasses.cardContainer} full-width`}>
              <Divider variant="middle" className={dashboardClasses.divider} />

              <div
                className={`${dashboardClasses.card} ${dashboardClasses.nestedCardContainer}`}
              >
                <StatsContainer
                  colorScale={colorScale}
                  totalCars={totalCars}
                  totalBikes={totalBikes}
                  totalUniqCount={totalUniqCount}
                  totalRepeatingObjectCount={totalRepeatingObjectCount}
                  totalObjectCount={totalObjectCount}
                  dateRange={dateRange}
                  totals={totals}
                  totalCount={totalCount}
                  resultKeysURL={resultKeysURL}
                  totalMen={totalMen}
                  totalObjects={totalObjects}
                />
              </div>

              <div className={`${dashboardClasses.card} full-width`}>
                <ChartCard
                  title={
                    <Typography variant="h5">
                      Кол-во появлений в&nbsp;
                      <span style={{ color: "#2C67FF" }}>час</span>
                      &nbsp;за период с&nbsp;
                      <span style={{ color: "#2C67FF" }}>
                        {moment(dateRange?.start).format("DD.MM.YYYY")}
                      </span>
                      &nbsp;по&nbsp;
                      <span style={{ color: "#2C67FF" }}>
                        {moment(dateRange?.end).format("DD.MM.YYYY")}
                      </span>
                    </Typography>
                  }
                  tooltip={rawResultTooltip}
                  chart={RawResultBarChart}
                  colorScale={colorScale}
                  data={dataForChart}
                  height={350}
                  variants={chartVariants}
                  defaultVariant="group"
                  resultKeys={resultKeysURL}
                />
              </div>

              <div className={`${dashboardClasses.card} fit wide`}>
                <ChartCard
                  title={t(I18N.chart.TITLE_SUM_CHART)}
                  chart={TotalNumberBarChart}
                  colorScale={colorScale}
                  data={dataForChart}
                  defaultVariant="group"
                  variants={chartVariants}
                  resultKeys={resultKeysURL}
                  sortable
                  tooltip={totalTooltip}
                />
              </div>

              <div className={`${dashboardClasses.card} fixed`}>
                <TotalPieCard
                  resultKeys={resultKeysURL}
                  colorScale={colorScale}
                  data={dataForTotalPie(
                    getValue,
                    totalMen,
                    totalCars,
                    totalBikes,
                    totalObjects,
                    isMenMode,
                    isMenUniteMode
                  )}
                  variants={["group", "pie"] as ChartVariant[]}
                />
              </div>

              <div className={`${dashboardClasses.card}`}>
                <ChartCard
                  title={t(I18N.chart.TITLE_AVERAGE_CHART)}
                  chart={AverageNumberBarChart}
                  colorScale={colorScale}
                  data={dataForChart}
                  defaultVariant="group"
                  variants={chartVariants}
                  resultKeys={resultKeysURL}
                  sortable
                  tooltip={averageTooltip}
                />
              </div>

              <div className={`${dashboardClasses.card}`}>
                <ChartCard
                  title={t(I18N.chart.TITLE_MAX_CHART)}
                  chart={MaximumNumberBarChart}
                  colorScale={colorScale}
                  data={dataForChart}
                  defaultVariant="group"
                  variants={chartVariants}
                  resultKeys={resultKeysURL}
                  sortable
                  tooltip={maxTooltip}
                />
              </div>

              <div className={`${dashboardClasses.card}`}>
                <ChartCard
                  title={t(I18N.chart.TITLE_MIN_CHART)}
                  chart={MinimumNumberBarChart}
                  colorScale={colorScale}
                  data={dataForChart}
                  defaultVariant="group"
                  variants={chartVariants}
                  resultKeys={resultKeysURL}
                  sortable
                  tooltip={minTooltip}
                />
              </div>
            </div>
          ) : (
            <div className={dashboardClasses.emptyMsgCard}>
              <Divider variant="middle" className={dashboardClasses.divider} />
              <EmptyMsgCard
                message={t(
                  isDataLoaded
                    ? locationId === undefined
                      ? I18N.chart.MSG_SELECT_LOCATION
                      : I18N.chart.MSG_EMPTY_QUERY_RESULT
                    : I18N.chart.MSG_LOADING
                )}
              />
            </div>
          )}
        </main>
      </div>
      <PanelDrawer
        locationId={locationId}
        initialDateRange={datasetDateRange}
        dateRange={dateRange}
        daysOfWeek={daysOfWeek}
        timeRanges={timeRanges}
        colorScale={colorScale}
        resultFields={resultFields}
        handleDrawerToggle={handleDrawerToggle}
        openRightBar={openRightBar}
        resultKeys={resultKeysForLegendURL}
      />
    </div>
  );
}
