import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { History } from "history";
import moment, { Moment } from "moment";
import { v4 as uuid } from "uuid";
import { deleteAnalytics } from "../../app/db";
import { AppThunk, RootState } from "../../app/store";
import { getDefaultTimeZone, TimeZoneName } from "../../app/timezone";
import { actions as authActions } from "../auth/authSlice";
import { LocationId } from "../groups/data-types";
import { loadGroup } from "../groups/main/videoSlice";
import { DATA_LOAD_STATE } from "../groups/slice-utils";
import {
  createStream as apiCreateStream,
  createVideo,
  deleteVideos as apiDeleteVideos,
  deleteStream as apiDeleteStream,
  loadStream,
  loadStreams,
  loadVideo,
  loadVideos,
  moveVideos as apiMoveVideos,
  startProcessing as apiStartProcessing,
  stopProcessing as apiStopProcessing,
  StreamUpdateOptions,
  subscribeToDashboard as apiSubscribeToDashboard,
  unsubscribeFromDashboard as apiUnsubscribeFromDashboard,
  updateStream as apiUpdateStream,
  updateVideo as apiUpdateVideo,
  VideoFileUpdateOptions,
} from "./api";
import { uploadFile as apiUploadFile } from "./blobStorage";
import {
  StreamData,
  StreamDto,
  streamDtoToStream,
  StreamId,
  UPLOAD_STATE,
  UploadData,
  UploadId,
  VIDEO_STATE,
  VideoData,
  videoDtoToData,
  VideoId,
  VIDEO_PROCESSING_STATE,
  VIDEO_PREPROCESSING_STATE,
} from "./data-mapper";
import { Line } from "./line-editor/components/DrawLine";
import {
  ensureLocation,
  LocationsState,
  LocationState,
  withLocation,
  withStream,
  withVideo,
} from "./locationHepers";
import {
  getGroupByLocationSelector,
  getStreamsSelector,
  getVideosSelector,
} from "./selectors";

interface LineUpdateOptions {
  locationId: LocationId;
  forceProcessing?: boolean;
  line?: Line;
  redirectTo?: string;
  history?: History;

  videoId?: VideoId;
  streamId?: StreamId;
  videoIds?: VideoId[];
}

interface UploadUpdate {
  key: UploadId;
  locationId: LocationId;
  update: {
    [propName in keyof UploadData]?: UploadData[propName];
  };
}

interface StreamCreateOptions {
  locationId: LocationId;
  streamId: StreamId;
}

interface VideosDeleteOptions {
  locationId: LocationId;
  videoIds: VideoId[];
}

interface VideosMoveOptions {
  oldLocationId: LocationId;
  locationId: LocationId;
  videoIds: VideoId[];
  locationName: string;
}

export type AnalyticsPayload = Array<{
  locationId: LocationId;
  sourceId: VideoId | StreamId;
  time: Date;
}>;

export const initialState: LocationsState = {
  data: new Map(),
};

interface ThunkAPI {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  getState: () => RootState;
}

export const saveLine = async (
  options: LineUpdateOptions,
  { dispatch, getState }: ThunkAPI
): Promise<void> => {
  const { locationId, videoId, streamId } = options;
  if (options.line === undefined) {
    throw new Error("Line is required but missing");
  }

  const state = getState();
  const videos = getVideosSelector(locationId)(state);
  const streams = getStreamsSelector(locationId)(state);
  const promises = [];

  for (const { id, line } of videos) {
    if (line === undefined || id === videoId) {
      const updateOptions = { locationId, line: options.line, videoId: id };
      promises.push(apiUpdateVideo(updateOptions));
      dispatch(actions.updateVideoLine(updateOptions));
    }
  }
  for (const { id, line, timeZone } of streams) {
    if (line === undefined || id === streamId) {
      const updateOptions = {
        locationId,
        line: options.line,
        streamId: id,
        timeZone,
      };
      promises.push(apiUpdateStream(updateOptions));
      dispatch(actions.updateStreamLine(updateOptions));
    }
  }
  await Promise.all(promises);
};

/**
 * Updates line for a given stream/video and all streams/videos without lines within the same location
 */
export const startProcessing = createAsyncThunk(
  "location/startProcessing",
  async (options: LineUpdateOptions, thunkApi) => {
    const {
      locationId,
      videoId,
      streamId,
      redirectTo,
      history,
      forceProcessing = false,
    } = options;
    const state: RootState = thunkApi.getState();
    const videos = getVideosSelector(locationId)(state);
    const streams = getStreamsSelector(locationId)(state);

    if (options.line !== undefined) {
      await saveLine(options, thunkApi);
    }

    // TODO: we need to handle failed requests properly in order to make it possible
    // to start processing later
    const videoProcessingPromises = videos.reduce(
      (promises: Array<Promise<void>>, { id, line, state: videoState }) => {
        if (!forceProcessing && line !== undefined && id !== videoId) {
          return promises;
        }

        if (videoState === VIDEO_STATE.UPLOADED) {
          return [...promises, apiStartProcessing(id)];
        }

        if (options.line !== undefined) {
          return [
            ...promises,
            autoStartProcessing({ id, locationId, line: options.line }),
          ];
        }

        return promises;
      },
      []
    );
    const streamProcessingPromises = streams.reduce(
      (promises: Array<Promise<void>>, { id, line }) => {
        if (!forceProcessing && line !== undefined && id !== streamId) {
          return promises;
        }

        return [...promises, apiStartProcessing(id)];
      },
      []
    );
    await Promise.all([
      ...videoProcessingPromises,
      ...streamProcessingPromises,
    ]);

    if (history !== undefined && redirectTo !== undefined) {
      history.push(redirectTo);
    }
  }
);

/**
 * Restarts the processing of the stream at the location
 */
export const restartProcessing = createAsyncThunk(
  "location/restartProcessing",
  async (options: LineUpdateOptions) => {
    const { streamId } = options;

    if (streamId === undefined) {
      return;
    }

    await apiStartProcessing(streamId);
  }
);

/**
 * Stops video processing
 */
export const stopProcessing = createAsyncThunk(
  "location/stopProcessing",
  async (locationId: LocationId, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const videos = getVideosSelector(locationId)(state);
    const streams = getStreamsSelector(locationId)(state);

    // TODO: we need to handle failed requests properly to make it possible
    // to start processing later
    const stopProcessingPromises = [];
    for (const { id } of [...videos, ...streams]) {
      stopProcessingPromises.push(apiStopProcessing(id));
    }
    await Promise.all(stopProcessingPromises);
  }
);

/**
 * Deletes videos from the backend, deletes cached analytics data
 */
export const deleteVideos = createAsyncThunk(
  "location/deleteVideos",
  async (options: VideosDeleteOptions) => {
    await Promise.all([
      apiDeleteVideos(options.videoIds),
      deleteAnalytics(options.videoIds),
    ]);
  }
);

/**
 * Replace videos in another location, deletes cached analytics data(?)
 */
export const moveVideos = createAsyncThunk(
  "location/replaceVideos",
  async (options: VideosMoveOptions) => {
    await apiMoveVideos(options.videoIds, options.locationId);
  }
);

/**
 * Creates a new stream at the backend
 */
export const createStream = createAsyncThunk(
  "location/createStream",
  async ({ locationId, streamId }: StreamCreateOptions, { getState }) => {
    const rootState = getState();
    const locationsState = (rootState as RootState).location as LocationsState;
    const stream = locationsState.data.get(locationId)?.streams.get(streamId);
    if (stream === undefined) {
      throw new Error("Stream not found");
    }

    const streamDto = await apiCreateStream({
      locationId,
      url: stream.url,
      timeZone: stream.timeZone,
    });

    return streamDto;
  }
);

/**
 * Ensures signalR subscription on the analytics data for the given media sources
 */
export const subscribeToDashboard = createAsyncThunk(
  "location/subscribeToDashboard",
  async (sourceIds: Array<VideoId | StreamId>) => {
    for (const id of sourceIds) {
      await apiSubscribeToDashboard(id);
    }
  }
);

/**
 * Deletes signalR subscription on the analytics data
 */
export const unsubscribeFromDashboard = createAsyncThunk(
  "location/unsubscribeFromDashboard",
  async (sourceIds: Array<StreamId | VideoId>) => {
    await apiUnsubscribeFromDashboard(sourceIds);
  }
);

/**
 * Loads (and saves to the state) data on videos and streams within a given location
 */
export const loadLocation = createAsyncThunk(
  "location/loadLocation",
  async (locationId: LocationId, thunkApi) => {
    for await (const dataChunk of loadVideos(locationId)) {
      thunkApi.dispatch(actions.loadVideos({ locationId, data: dataChunk }));
    }
    for await (const dataChunk of loadStreams(locationId)) {
      thunkApi.dispatch(actions.loadStreams({ locationId, data: dataChunk }));
    }
  }
);

const slice = createSlice({
  name: "location",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },

    createStream(state: LocationsState, action: PayloadAction<LocationId>) {
      const locationId = action.payload;
      withLocation(state, locationId, (location) => {
        const streamId = uuid();
        location.streams.set(streamId, {
          id: streamId,
          url: "",
          isConnected: false,
          isTmp: true,
          timeZone: getDefaultTimeZone() as TimeZoneName,
          videoProcessingState: VIDEO_PROCESSING_STATE.NOT_STARTED,
        });
      });
    },

    loadAnalytics(
      state: LocationsState,
      action: PayloadAction<AnalyticsPayload>
    ) {
      for (const { locationId, sourceId, time } of action.payload) {
        withVideo(state, locationId, sourceId, (video) => {
          video.analyticsDate = moment(time);
        });
        withStream(state, locationId, sourceId, (stream) => {
          stream.analyticsDate = moment(time);
        });
      }
    },

    loadStreams(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; data: StreamData[] }>
    ) {
      const { locationId, data } = action.payload;
      withLocation(state, locationId, (location) => {
        data.forEach((stream) => location.streams.set(stream.id, stream));
      });
    },

    loadVideos(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; data: VideoData[] }>
    ) {
      const { locationId, data } = action.payload;
      withLocation(state, locationId, (location) => {
        data.forEach((video) => location.videos.set(video.id, video));
      });
    },

    removeStream(
      state: LocationsState,
      actions: PayloadAction<{ locationId: LocationId; streamId: StreamId }>
    ) {
      const { locationId, streamId } = actions.payload;
      withLocation(state, locationId, (location) => {
        location.streams.delete(streamId);
      });
    },

    removeUpload(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; key: UploadId }>
    ) {
      withLocation(state, action.payload.locationId, (location) => {
        location.uploads.delete(action.payload.key);
      });
    },

    setStream(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; data: StreamData }>
    ) {
      const { locationId, data } = action.payload;
      withLocation(state, locationId, (location) => {
        location.streams.set(data.id, data);
      });
    },

    setVideo(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; data: VideoData }>
    ) {
      const { locationId, data } = action.payload;
      withLocation(state, locationId, (location) => {
        location.videos.set(data.id, data);
      });
    },

    setUploads(
      state: LocationsState,
      action: PayloadAction<{ locationId: LocationId; files: File[] }>
    ) {
      const { locationId, files } = action.payload;
      withLocation(state, locationId, (location) => {
        for (const file of files) {
          location.uploads.set(getUploadId(file), {
            file,
            date: null,
            progress: 0,
            state: UPLOAD_STATE.NONE,
            locationId,
          });
        }
      });
    },

    updateUpload(state: LocationsState, action: PayloadAction<UploadUpdate>) {
      const { key, locationId, update } = action.payload;
      withLocation(state, locationId, (location) => {
        if (!location.uploads.has(key)) {
          return;
        }

        const newUploadState = {
          ...(location.uploads.get(key) as UploadData),
          ...update,
        };

        if (update.fileId !== undefined && update.fileId !== key) {
          location.uploads.delete(key);
        }
        location.uploads.set(update.fileId ?? key, newUploadState);
      });
    },

    updateVideoLine(
      state: LocationsState,
      action: PayloadAction<VideoFileUpdateOptions>
    ) {
      const { locationId, videoId, line } = action.payload;
      withVideo(state, locationId, videoId, (video) => {
        video.line = line;
      });
    },

    updateStreamLine(
      state: LocationsState,
      action: PayloadAction<StreamUpdateOptions>
    ) {
      const { locationId, streamId, line } = action.payload;
      withStream(state, locationId, streamId, (stream) => {
        stream.line = line;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(moveVideos.fulfilled, (state, action) => {
        const { oldLocationId, locationId, videoIds } = action.meta.arg;
        withLocation(state, oldLocationId, (location) => {
          videoIds.forEach((videoId) => {
            location.videos.delete(videoId);
          });
        });
        withLocation(state, locationId, (location) => {
          location.state = DATA_LOAD_STATE.NONE;
        });
      })
      .addCase(authActions.reset, () => {
        return initialState;
      })
      .addCase(deleteVideos.fulfilled, (state, action) => {
        const { locationId, videoIds } = action.meta.arg;
        withLocation(state, locationId, (location) => {
          videoIds.forEach((videoId) => {
            location.videos.delete(videoId);
          });
        });
      })
      .addCase(startProcessing.pending, (state, action) => {
        const { locationId, streamId, videoId } = action.meta.arg;
        if (streamId !== undefined) {
          withStream(state, locationId, streamId, (stream) => {
            stream.isBusy = true;
          });
        }
        if (videoId !== undefined) {
          withVideo(state, locationId, videoId, (video) => {
            video.isBusy = true;
          });
        }
      })
      .addCase(startProcessing.fulfilled, (state, action) => {
        const { locationId, streamId, videoId } = action.meta.arg;
        if (streamId !== undefined) {
          withStream(state, locationId, streamId, (stream) => {
            stream.isBusy = false;
          });
        }
        if (videoId !== undefined) {
          withVideo(state, locationId, videoId, (video) => {
            video.isBusy = false;
          });
        }
      })
      .addCase(startProcessing.rejected, (state, action) => {
        const { locationId, streamId, videoId } = action.meta.arg;
        if (streamId !== undefined) {
          withStream(state, locationId, streamId, (stream) => {
            stream.isBusy = false;
          });
        }
        if (videoId !== undefined) {
          withVideo(state, locationId, videoId, (video) => {
            video.isBusy = false;
          });
        }
      })
      .addCase(loadLocation.pending, (state, action) => {
        const locationId = action.meta.arg;
        ensureLocation(state, locationId, (location) => {
          location.state = DATA_LOAD_STATE.STARTED;
          location.videos = new Map();
        });
      })
      .addCase(loadLocation.fulfilled, (state, action) => {
        const locationId = action.meta.arg;
        withLocation(state, locationId, (location) => {
          location.state = DATA_LOAD_STATE.LOADED;
        });
      })
      .addCase(createStream.fulfilled, (state, action) => {
        const streamDto: StreamDto = action.payload;
        const { locationId, streamId } = action.meta.arg;
        withLocation(state, locationId, (location) => {
          const stream = streamDtoToStream(streamDto);
          const isTmpStream = location.streams.get(streamId)?.isTmp ?? false;

          if (isTmpStream) {
            location.streams.delete(streamId);
          }
          location.streams.set(stream.id, stream);
        });
      });
  },
});

export const { actions, reducer } = slice;

export function getUploadId(file: File): UploadId {
  return file.name;
}

interface VideoProcessingOptions {
  id: VideoId;
  line: Line;
  locationId: LocationId;
}

/**
 * Sets `isAutoAnalysisEnabled` field to true (if there are any labeled videos within the location)
 * so that video processing is started automatically once the backend is ready
 * @param id {VideoId} - Video file DTO
 * @param locationId {LocationId} - Location ID
 * @param line {Line} - redux store root state
 * @returns {Promise<void>}
 */
const autoStartProcessing = async ({
  id,
  locationId,
  line,
}: VideoProcessingOptions): Promise<void> => {
  await apiUpdateVideo({
    locationId,
    line,
    videoId: id,
    autoStart: true,
  });
};

const uploadFile =
  (locationId: LocationId, { date, file, width, height }: UploadData): AppThunk =>
  async (dispatch, getState) => {
    let uploadId = getUploadId(file);
    try {
      // 1. Set upload state to indicate progress
      setUploadState(UPLOAD_STATE.CREATING, uploadId);

      // 2. Create VideoFile entry at the backend
      const { sasUrl, data: fileDto } = await createVideo({
        file,
        date: date as Moment,
        locationId,
        width,
        height
      });
      dispatch(actions.setVideo({ locationId, data: videoDtoToData(fileDto) }));

      // 3. TODO: Autostart video processing in case videos in location already have a "line"
      const videos = getVideosSelector(locationId)(getState());
      const videoWithLine = videos.find(({ line }) => line !== undefined);
      if (videoWithLine !== undefined) {
        await autoStartProcessing({
          id: fileDto.id,
          locationId,
          line: videoWithLine.line as Line,
        });
      }

      // 4. Update location data in the store, so it contains correct number of files/streams/uploads
      updateLocation();

      // 5. Set upload state to indicate started upload
      dispatch(
        actions.updateUpload({
          key: getUploadId(file),
          locationId,
          update: {
            fileId: fileDto.id,
            state: UPLOAD_STATE.UPLOADING,
          },
        })
      );
      uploadId = fileDto.id;

      // 6. Upload the file
      await apiUploadFile({
        url: sasUrl,
        file: file,
        onProgress: (evt) => {
          dispatch(
            actions.updateUpload({
              key: uploadId,
              locationId,
              update: {
                progress: evt.loadedBytes / file.size,
              },
            })
          );
        },
      });

      // 7. Complete upload
      setUploadState(UPLOAD_STATE.UPLOADED, uploadId);
    } catch (err) {
      setUploadState(UPLOAD_STATE.FAILED, uploadId);
    }

    function setUploadState(state: UPLOAD_STATE, uploadId: string): void {
      dispatch(
        actions.updateUpload({
          key: uploadId,
          locationId,
          update: {
            state,
          },
        })
      );
    }

    function updateLocation(): void {
      const state: RootState = getState();
      const group = getGroupByLocationSelector(locationId)(state);
      if (group === undefined) {
        return;
      }

      // TODO: replace loadGroup with loadLocation once the APIs are compatible
      dispatch(loadGroup(group.id));
      // dispatch(reloadLocation({ groupId: group.id, locationId }))
    }
  };

export const uploadFiles =
  (locationId: LocationId): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().location as LocationsState;
    const uploads = (state.data.get(locationId) as LocationState).uploads;
    
    const creationPromises = Array.from(uploads.values())
      .filter(
        ({ date, state }) =>
          (state === UPLOAD_STATE.NONE || state === UPLOAD_STATE.FAILED) &&
          date &&
          date.isValid()
      )
      .map((uploadInfo) => dispatch(uploadFile(locationId, uploadInfo)));

    await Promise.all(creationPromises);
  };

interface AnalyticsStats {
  sourceIds: Array<StreamId | VideoId>;
  time: Date;
}

export const loadAnalytics =
  (stats: AnalyticsStats): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().location as LocationsState;
    const streamLocations: Record<VideoId | StreamId, LocationId | false> = {};

    const cloneIds = stats.sourceIds.reduce((res: VideoId[], sourceId) => {
      for (const { videos } of state.data.values()) {
        for (const video of videos.values()) {
          if (video.originalVideoFileId === sourceId) {
            res.push(video.id);
          }
        }
      }
      return res;
    }, []);
    const payload: AnalyticsPayload = [];

    for (const sourceId of [...cloneIds, ...stats.sourceIds]) {
      if (streamLocations[sourceId] === undefined) {
        const location = Array.from(state.data.values()).find(
          ({ videos, streams }) => videos.has(sourceId) || streams.has(sourceId)
        );

        streamLocations[sourceId] = location?.id ?? false;
      }

      if (streamLocations[sourceId] === false) {
        continue;
      }

      payload.push({
        locationId: streamLocations[sourceId] as LocationId,
        sourceId,
        time: stats.time,
      });
    }

    if (payload.length === 0) {
      return;
    }

    dispatch(actions.loadAnalytics(payload));
  };

export const reloadVideoData =
  (
    videoId: VideoId,
    jobState = VIDEO_PREPROCESSING_STATE.CANCELED,
    progress = 0
  ): AppThunk =>
  async (dispatch, getState) => {
    const { locationId, video } = await loadVideo(videoId);
    const group = getGroupByLocationSelector(locationId)(getState());

    dispatch(
      actions.setVideo({
        locationId,
        data: {
          ...video,
          filePreprocessing: {
            jobState: jobState,
            progress: progress,
          },
        },
      })
    );
    if (group === undefined) {
      return;
    }

    // TODO: replace loadGroup with loadLocation once the APIs are compatible
    await dispatch(loadGroup(group.id));
    // dispatch(reloadLocation({ groupId: group.id, locationId }))
  };

export const reloadStreamData =
  (streamId: StreamId): AppThunk =>
  async (dispatch, getState) => {
    const { locationId, stream } = await loadStream(streamId);
    const group = getGroupByLocationSelector(locationId)(getState());

    await dispatch(actions.setStream({ locationId, data: stream }));
    if (stream.thumbnailUrl === undefined) {
      dispatch(
        actions.removeStream({
          locationId: locationId,
          streamId: streamId,
        })
      );
      return await apiDeleteStream([streamId]);
    }
    if (group === undefined) {
      return;
    }
    // TODO: replace loadGroup with loadLocation once the APIs are compatible
    await dispatch(loadGroup(group.id));
  };

export const reloadDataById =
  (streamOrVideoId: StreamId | VideoId): AppThunk =>
  async (dispatch, getState) => {
    // We find what came to us - stream or video
    const rootState = getState();
    const locationState = rootState.location as LocationsState;
    const isVideoId = Array.from(locationState.data.values()).some(
      ({ videos }) => {
        return Array.from(videos.values()).some(
          ({ id }) => id === streamOrVideoId
        );
      }
    );

    if (isVideoId) {
      return dispatch(reloadVideoData(streamOrVideoId));
    }

    const isStreamId = Array.from(locationState.data.values()).some(
      ({ streams }) => {
        return Array.from(streams.values()).some(
          ({ id }) => id === streamOrVideoId
        );
      }
    );

    if (isStreamId) {
      return dispatch(reloadStreamData(streamOrVideoId));
    }
  };
