import { PayloadAction } from '@reduxjs/toolkit'
import { GroupData } from '../data-types'
import {
  archiveGroup,
  archiveLocation,
  moveGroupToTrash,
  moveLocationsToTrash,
  moveLocationToTrash,
  renameGroup
} from '../main/asyncActions'
import {
  ARCHIVE_SLICE_NAME,
  createVideoSlice,
  deleteGroupState,
  deleteLocationState,
  setGroupBusy,
  setGroupIdle,
  setGroupState,
  setLocationBusy,
  setLocationIdle,
  VideoDataState,
  withGroup
} from '../slice-utils'
import { restoreGroup, restoreLocation } from '../trash/asyncActions'
import { unarchiveGroup, unarchiveLocation } from './asyncActions'

const updateGroupState = (state: VideoDataState, action: PayloadAction<GroupData|undefined>): void => {
  setGroupState(state, ARCHIVE_SLICE_NAME, action.payload)
}

export const {
  actions,
  reducer,

  loadGroup,
  loadGroups,
  selectData,
  selectNotInitialized,
  selectLoadError,
  selectLoading
} = createVideoSlice(ARCHIVE_SLICE_NAME, builder => {
  builder
    // rename group
    .addCase(renameGroup.fulfilled, (state, action) => {
      const { group, update } = action.meta.arg
      withGroup(state, group.id, groupState => {
        groupState.title = update.title as string
      })
    })
    // add to archive
    .addCase(archiveGroup.fulfilled, updateGroupState)
    .addCase(archiveLocation.fulfilled, updateGroupState)
    // move group to Trash
    .addCase(moveGroupToTrash.pending, setGroupBusy)
    .addCase(moveGroupToTrash.fulfilled, deleteGroupState)
    .addCase(moveGroupToTrash.rejected, setGroupIdle)
    // move location to Trash
    .addCase(moveLocationToTrash.pending, setLocationBusy)
    .addCase(moveLocationToTrash.fulfilled, deleteLocationState)
    .addCase(moveLocationToTrash.rejected, setLocationIdle)
    // move multiple locations to Trash
    .addCase(moveLocationsToTrash.pending, setGroupBusy)
    .addCase(moveLocationsToTrash.fulfilled, deleteGroupState)
    .addCase(moveLocationsToTrash.rejected, setGroupIdle)
    // unarchive Group
    .addCase(unarchiveGroup.pending, setGroupBusy)
    .addCase(unarchiveGroup.fulfilled, deleteGroupState)
    .addCase(unarchiveGroup.rejected, setGroupIdle)
    // unarchive Location
    .addCase(unarchiveLocation.pending, setLocationBusy)
    .addCase(unarchiveLocation.fulfilled, deleteLocationState)
    .addCase(unarchiveLocation.rejected, setLocationIdle)
    // restore from Trash
    .addCase(restoreGroup.fulfilled, updateGroupState)
    .addCase(restoreLocation.fulfilled, updateGroupState)
})
