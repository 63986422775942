import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk, RootState } from '../../app/store'
import { HTTP_METHOD, request } from '../../app/utils'
import { AUTH_URL } from '../../app/api-url'
import { PASSWORD_RESET_STATE, PasswordResetState } from './passwordResetTypes'
import { ErrorPayload, ERROR_CODE, getErrorPayload } from '../../app/errors'
import I18N from '../../app/i18n/strings'

export const initialState: PasswordResetState = {
  state: PASSWORD_RESET_STATE.NONE
}

const authSlice = createSlice({
  name: 'passwordReset',
  initialState,
  reducers: {
    reset () {
      return initialState
    },
    requestStarted (state: PasswordResetState) {
      state.state = PASSWORD_RESET_STATE.REQUEST_STARTED
    },
    requestCheckEmail (state: PasswordResetState, action: PayloadAction<string>) {
      state.state = PASSWORD_RESET_STATE.REQUEST_CHECK_EMAIL
      state.email = action.payload
    },
    resetStarted (state: PasswordResetState) {
      state.state = PASSWORD_RESET_STATE.RESET_STARTED
    },
    resetCompleted (state: PasswordResetState) {
      state.state = PASSWORD_RESET_STATE.RESET_COMPLETED
    },
    resetFailed (state: PasswordResetState, action: PayloadAction<ErrorPayload>) {
      state.state = PASSWORD_RESET_STATE.FAILED
      switch (action.payload.code) {
        case ERROR_CODE.NOT_FOUND: {
          state.error = I18N.passwordReset.ERROR_RESET_FAILED_NOT_FOUND
          return
        }
        case ERROR_CODE.INVALID_INPUT_DATA:
        default: {
          state.error = I18N.passwordReset.ERROR_RESET_FAILED
        }
      }
    }
  }
})

export const {
  actions,
  reducer
} = authSlice

export const requestReset = (email: string): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.requestStarted())
    await request(`${AUTH_URL.FORGOT_PASSWORD}?userEmail=${email}`,
      { method: HTTP_METHOD.GET })
    dispatch(actions.requestCheckEmail(email))
  } catch (err) {
    const errorPayload = getErrorPayload(err)
    dispatch(actions.resetFailed(errorPayload))
  }
}

export const resetPassword = (email: string, token: string, password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(actions.resetStarted())
    await request(AUTH_URL.RESET_PASSWORD, {
      method: HTTP_METHOD.PUT,
      data: {
        email: email,
        resetToken: token,
        newPassword: password
      }
    })
    dispatch(actions.resetCompleted())
  } catch (err) {
    const errorPayload = getErrorPayload(err)
    dispatch(actions.resetFailed(errorPayload))
  }
}

export const selectIsPasswordResetRequested = (state: RootState): boolean => state.passwordReset.state === PASSWORD_RESET_STATE.REQUEST_CHECK_EMAIL
export const selectIsPasswordResetStarted = (state: RootState): boolean => state.passwordReset.state === PASSWORD_RESET_STATE.RESET_STARTED
export const selectPasswordResetError = (state: RootState): string|undefined => {
  if (state.passwordReset.state !== PASSWORD_RESET_STATE.FAILED) {
    return
  }

  return state.passwordReset.error
}
export const selectEmail = (state: RootState): string|undefined => {
  if (state.passwordReset.state !== PASSWORD_RESET_STATE.REQUEST_CHECK_EMAIL) {
    return
  }

  return state.passwordReset.email
}
export const selectIsResetCompleted = (state: RootState): boolean => state.passwordReset.state === PASSWORD_RESET_STATE.RESET_COMPLETED
