import { PayloadAction } from '@reduxjs/toolkit'
import { unarchiveGroup } from '../archive/asyncActions'
import { GroupData } from '../data-types'
import {
  archiveGroup,
  moveGroupToTrash,
  moveLocationsToTrash,
  moveLocationToTrash,
  renameGroup
} from '../main/asyncActions'
import {
  createVideoSlice,
  deleteGroupState,
  deleteLocationState,
  setGroupBusy,
  setGroupIdle,
  setGroupState,
  setLocationBusy,
  setLocationIdle,
  TRASH_SLICE_NAME,
  VideoDataState,
  withGroup
} from '../slice-utils'
import {
  deleteGroup,
  deleteLocation,
  restoreGroup,
  restoreLocation
} from './asyncActions'

const updateGroupState = (state: VideoDataState, action: PayloadAction<GroupData|undefined>): void => {
  setGroupState(state, TRASH_SLICE_NAME, action.payload)
}

export const {
  actions,
  reducer,

  loadGroup,
  loadGroups,
  selectData,
  selectNotInitialized,
  selectLoadError,
  selectLoading
} = createVideoSlice(TRASH_SLICE_NAME, builder => {
  builder
    // rename/archive/unarchive: update Group when attributes changes
    .addCase(renameGroup.fulfilled, (state, action) => {
      const { group, update } = action.meta.arg
      if (update.title === undefined) {
        return
      }

      withGroup(state, group.id, (groupState) => {
        groupState.title = update.title as string
      })
    })
    .addCase(archiveGroup.fulfilled, updateGroupState)
    .addCase(unarchiveGroup.fulfilled, updateGroupState)
    // move to Trash: reload Group with changed removed Locations
    .addCase(moveGroupToTrash.fulfilled, updateGroupState)
    .addCase(moveLocationToTrash.fulfilled, updateGroupState)
    .addCase(moveLocationsToTrash.fulfilled, updateGroupState)
    // delete permanently
    .addCase(deleteGroup.pending, setGroupBusy)
    .addCase(deleteGroup.fulfilled, deleteGroupState)
    .addCase(deleteGroup.rejected, setGroupIdle)
    .addCase(deleteLocation.pending, setLocationBusy)
    .addCase(deleteLocation.fulfilled, deleteLocationState)
    .addCase(deleteLocation.rejected, setLocationIdle)
    // restore from Trash
    .addCase(restoreLocation.pending, setLocationBusy)
    .addCase(restoreLocation.fulfilled, deleteLocationState)
    .addCase(restoreLocation.rejected, setLocationIdle)
    .addCase(restoreGroup.pending, setGroupBusy)
    .addCase(restoreGroup.fulfilled, deleteGroupState)
    .addCase(restoreGroup.rejected, setGroupIdle)
})
