import { SerializedError } from '@reduxjs/toolkit'

export enum ERROR_CODE {
  // userName === email
  INVALID_INPUT_DATA = '100000000',
  NOT_FOUND = '100000001',
  UNKNOWN_ERROR = '100000002',
  INVALID_PASSWORD = '100000003',
  DUPLICATE_USER_NAME = '1010',
  DUPLICATE_USER_EMAIL = '1011',
  INVALID_USER_NAME = ' 1008',
  INVALID_EMAIL = '1009',

  AUTH_USER_NOT_FOUND = '1',
  AUTH_INCORRECT_PASSWORD = '5',

  PROFILE_INCORRECT_PASSWORD = '1025',

  GENERIC_ACCESS_DENIED = 'GENERIC_ACCESS_DENIED'
}

export class ErrorPayload extends Error implements SerializedError {
  code: string

  constructor (code: ERROR_CODE, message: string) {
    super(message) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain

    this.code = code.toString()
    this.message = message
  }
}

export class RequestError extends Error {
  code: ERROR_CODE

  constructor (code: ERROR_CODE, message?: string) {
    super(message) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain

    this.code = code
  }
}

export function getErrorPayload (err: RequestError|Error): ErrorPayload {
  return new ErrorPayload(
    ('code' in err) ? err.code : ERROR_CODE.UNKNOWN_ERROR,
    err.message
  )
}
