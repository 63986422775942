import React, { ReactElement, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { ScaleOrdinal } from "d3-scale";

import I18N from "../../app/i18n/strings";
import { selectIsMenUniteMode } from "../../features/dashboard/dashboardSlice";
import { COLORS } from "../../theme";
import { ResultKeys } from "./bar/types";

const LEGEND_GLYPH_SIZE = 10;

interface LegendProps<T> {
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
  resultKeys: Array<ResultKeys<T>>;
  showEtc?: boolean;
  hideLabels?: boolean;
}

export default function VisiusLegend<T>(
  props: LegendProps<T>
): ReactElement | null {
  const { colorScale, resultKeys, showEtc = false, hideLabels = false } = props;
  const { t } = useTranslation();
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const [showMore, setShowMore] = useState(hideLabels);

  const handleShowMore = useCallback(() => {
    setShowMore((prev) => !prev);
  }, [setShowMore]);

  return (
    <LegendOrdinal
      scale={colorScale}
      labelFormat={(label) => {
        const i18nKey =
          `LEGEND_${label.toUpperCase()}` as keyof typeof I18N.chart;
        const i18nTpl = I18N.chart[i18nKey];
        const itemLabel = t(i18nTpl);
        return itemLabel;
      }}
    >
      {(labels) => {
        const visibleLabels = showMore ? labels.slice(0, 5) : labels;
        return (
          <>
            {visibleLabels
              .filter(({ datum }) => resultKeys.includes(datum))
              .filter((i) => {
                if (
                  !resultKeys.includes("cars" as ResultKeys<T>) &&
                  !resultKeys.includes("bikes" as ResultKeys<T>) &&
                  !isMenUniteMode
                ) {
                  return i.datum !== "men";
                }

                if (isMenUniteMode) {
                  return i.datum === "men";
                }

                return (
                  i.datum === "men" || i.datum === "cars" || i.datum === "bikes"
                );
              })
              .map((label, i) => {
                return (
                  <LegendItem key={`legend-quantile-${i}`} margin="2px 6px">
                    <svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
                      <rect
                        rx={2}
                        ry={2}
                        fill={label.value}
                        width={LEGEND_GLYPH_SIZE}
                        height={LEGEND_GLYPH_SIZE}
                      />
                    </svg>
                    <LegendLabel
                      style={{
                        fontSize: 12,
                        lineHeight: "14.52px",
                        margin: "0 0 0 8px",
                      }}
                      align="left"
                    >
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            {hideLabels && (
              <Typography
                onClick={handleShowMore}
                style={{
                  fontWeight: 600,
                  cursor: "pointer",
                  marginLeft: 14,
                  position: "relative",
                  bottom: "1px",
                }}
                color="primary"
                variant="body2"
              >
                {showMore ? "Ещё" : "Скрыть"}
              </Typography>
            )}
            {showEtc && (
              <LegendItem key="legend-quantile-etc" margin="0 6px">
                <svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
                  <rect
                    rx={2}
                    ry={2}
                    fill={COLORS.BLACK}
                    width={LEGEND_GLYPH_SIZE}
                    height={LEGEND_GLYPH_SIZE}
                  />
                </svg>
                <LegendLabel align="left" margin="0 0 0 4px">
                  {t(I18N.chart.LEGEND_ETC)}
                </LegendLabel>
              </LegendItem>
            )}
          </>
        );
      }}
    </LegendOrdinal>
  );
}
