export enum AUTH_STATE {
  NONE, // App is loading
  AUTHENTICATING, // Querying the current user from the API server
  NOT_AUTHENTICATED, // Not authenticated at the API server
  AUTHENTICATION_COMPLETED, // Successfully authenticated at the API server

  LOGIN_STARTED, // Currently trying to log in using email/password
  LOGIN_FAILED, // Log in attempt failed
}

export interface UserDto {
  id: string
  email: string
  emailConfirmed: boolean
  name: string
  isAdmin: boolean
  phoneNumber?: string
  companyName?: string
}

export interface UserProfile extends UserDto {
  availableUsers: UserDto[]
}

export interface FormError {
  email?: string[]
  password?: string[]
}

export interface AuthState {
  authState: AUTH_STATE
  error?: FormError
  user?: UserProfile
  users?: UserProfile[]
}
