import React, { ReactElement, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Toolbar from "@material-ui/core/Toolbar";
import { styled, Theme } from "@mui/material/styles";
import {
  Button,
  IconButton,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import MuiAppBar, {
  AppBarProps as MuiAppBarProps,
} from "@material-ui/core/AppBar";

import clsx from "clsx";
import I18N from "../app/i18n/strings";

import { logout } from "../features/auth/authSlice";
import { selectAppLoading } from "../features/notifications/notificationsSlice";
import { selectUserTariff } from "../features/profile/tariffs/tariffSlice";

import Link from "./Link";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import DelayedProgress from "./DelayedProgress";

import { COLORS } from "../theme";

import { ReactComponent as RubleIcon } from "../app/assets/rubleIcon.svg";
import { ReactComponent as CardIcon } from "../app/assets/cardIcon.svg";
import { ReactComponent as BellIcon } from "../app/assets/bellIcon.svg";
import { ReactComponent as SettingsIcon } from "../app/assets/settingsIcon.svg";
import { ReactComponent as ExitIcon } from "../app/assets/exitIcon.svg";
import { selectIsMenMode } from "../features/dashboard/dashboardSlice";

interface AppBarProps extends MuiAppBarProps {
  title?: string;
  className?: string;
  noDrawer?: boolean;
  leftPanelIsOpen?: boolean;
}

const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    margin: `16px 16px 0 ${drawerWidth + 32}px`,
    backgroundColor: "transparent",
    boxShadow: "none",
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.spacing(3),

    "&.no-drawer": {
      marginLeft: 0,
      width: "100%",
    },

    "& .MuiToolbar-root": {
      position: "relative",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2),
      zIndex: theme.zIndex.drawer,
      backgroundColor: COLORS.ALMOST_WHITE,
      borderRadius: theme.spacing(3),
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
    },
  },
  appBarBackground: {
    position: "absolute",
    zIndex: 10,
    top: "-20px",
    left: "-8px",
    width: "101%",
    height: theme.spacing(22.5),
    backgroundColor: COLORS.DEFAULT_BACKGROUND,

    [theme.breakpoints.down(1580)]: {
      height: (props: {
        isDashboardTab: boolean;
        isMenMode: boolean;
        isTrashOrArchiveTab: boolean;
      }) =>
        (props.isMenMode && props.isDashboardTab) || props.isTrashOrArchiveTab
          ? theme.spacing(28)
          : theme.spacing(22.5),
    },
  },
  adminUserSelect: {
    alignContent: "center",
    alignItems: "center",
    background: COLORS.WHITE,
    display: "flex",
    height: 64,
    width: 350,
    padding: theme.spacing(0, 2, 0, 2),
    marginRight: theme.spacing(1),
  },
  buyMoreBtn: {
    textDecoration: "none !important",
    padding: "16px 0",
  },
  rightPanel: {
    display: "flex",
    flexDirection: "row",

    "& .MuiSvgIcon-root": {
      color: COLORS.GREY8,
    },

    "& .MuiIconButton-root": {
      width: theme.spacing(5),
      height: theme.spacing(5),
      background: theme.palette.common.white,
      border: `1px solid ${COLORS.GREY0}`,
      borderRadius: "16px",
    },
  },
  progress: {
    marginRight: theme.spacing(4),
    padding: theme.spacing(1, 3, 1, 2),
    background: theme.palette.common.white,
    border: "1px solid rgba(44, 103, 255, 0.1)",
    borderRadius: "16px",
  },
  progressTitle: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  progressSubtitle: {
    color: theme.palette.secondary.dark,
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",

    "& svg": {
      transform: "scale(1.3)",
    },

    "& .MuiLink-root:nth-of-type(-n+2) .MuiButtonBase-root:hover": {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },

    "& > button:hover": {
      "& path": {
        fill: theme.palette.primary.main,
      },
    },

    "& .MuiLink-root:nth-of-type(n+3) .MuiButtonBase-root:hover": {
      "& path:nth-of-type(2)": {
        stroke: theme.palette.primary.main,
      },

      "& path:nth-of-type(3)": {
        fill: theme.palette.primary.main,
      },
    },
  },
  activeTab: {
    "& path:nth-of-type(2)": {
      stroke: theme.palette.primary.main,
    },

    "& path:nth-of-type(3)": {
      fill: theme.palette.primary.main,
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "leftPanelIsOpen",
})<AppBarProps>(({ theme, leftPanelIsOpen }) => ({
  width: `calc(100% - ${drawerWidth}px + 104px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(leftPanelIsOpen && {
    width: `calc(100% - ${drawerWidth}px - 48px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ColorButton = withStyles(() => ({
  root: {
    padding: "10px 30px",
    color: COLORS.WHITE,
    boxShadow: "none",
    background: "linear-gradient(90deg, #27AE60 0%, #119B05 99.94%)",
    borderRadius: "16px",
    fontFamily: "Inter, san-serif",
    fontSize: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "linear-gradient(90deg, #1FAB5A 0%, #0F9004 99.94%)",
      boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",
    },

    "&.Mui-disabled": {
      background: "rgba(19, 157, 12, 0.2)",
      color: "white",
    },

    "& .MuiButton-startIcon": {
      marginRight: "18px",
    },
  },
}))(Button);

export default function VisiusAppBar({
  title,
  noDrawer = false,
  ...props
}: AppBarProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isTrashOrArchiveTab =
    query.get("tab") === "trash" || query.get("tab") === "archive";
  const isDashboardTab = location.pathname.includes("dashboard");
  const isMenMode = useSelector(selectIsMenMode);
  const classes = useStyles({ isDashboardTab, isTrashOrArchiveTab, isMenMode });
  const isProfileTab =
    query.get("tab") === "main" || query.get("tab") === "chpassw";

  const currentTariff = useSelector(selectUserTariff);
  const isLoading = useSelector(selectAppLoading);

  const handleLogout = useCallback((): void => {
    dispatch(
      logout({
        redirectTo: "/",
        history,
      })
    );
  }, [dispatch, history]);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, { "no-drawer": noDrawer })}
      {...props}
    >
      <div className={classes.appBarBackground}></div>
      <Toolbar>
        <Link
          // to='profile?tab=payments&inner=tariffs'
          to="#"
          className={classes.buyMoreBtn}
        >
          <ColorButton variant="contained" startIcon={<RubleIcon />} disabled>
            {t(I18N.tariff.BUY_MORE)}
          </ColorButton>
        </Link>
        <div className={classes.rightPanel}>
          <div className={classes.progress}>
            <CircularProgressWithLabel
              variant="determinate"
              viewValue={currentTariff?.timeBalanceHour ?? 0}
              value={currentTariff?.timeBalancePercantage ?? 0}
              title={t(I18N.tariff.HOUR_BALANCE_TITLE)}
              subtitle={
                currentTariff?.timeBalanceHour
                  ? t(I18N.tariff.HOUR_BALANCE_SUBTITLE, {
                      uploaded: currentTariff?.timeBalanceHour ?? 0,
                      total: currentTariff?.maxHours ?? 0,
                    })
                  : "У вас закончился пакет ГБ"
              }
              type="billing"
              titleClass={classes.progressTitle}
              subtitleClass={classes.progressSubtitle}
            />
          </div>

          <div className={classes.iconsWrapper}>
            <Link color="inherit" to="#">
              <IconButton color="inherit">
                <BellIcon />
              </IconButton>
            </Link>

            <Link color="inherit" to="#">
              <IconButton color="inherit">
                <CardIcon />
              </IconButton>
            </Link>

            <Link color="inherit" to="/profile?tab=main">
              <IconButton color="inherit">
                <SettingsIcon
                  className={isProfileTab ? classes.activeTab : ""}
                />
              </IconButton>
            </Link>

            <IconButton
              style={{ marginLeft: 16 }}
              color="inherit"
              onClick={handleLogout}
            >
              <ExitIcon />
            </IconButton>
          </div>
        </div>
      </Toolbar>
      {isLoading && <DelayedProgress />}
    </AppBar>
  );
}
