import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Error } from "@material-ui/icons";
import { TextField, Typography } from "@material-ui/core";

import I18N from "../app/i18n/strings";
import Tooltip from "./Tooltip";
/* eslint no-void: ["error", { "allowAsStatement": true }] */

interface InlineTextFieldProps {
  defaultValue: string;
  onResetError: () => void;
  onRename: (value: string) => void;
  onCancel: () => void;

  className?: string;
  error?: string;
  inputRef?: RefObject<HTMLInputElement>;
  prohibitedValues?: string[];
}

const stopPropagation = (evt: MouseEvent): void => evt.stopPropagation();
const validate = (
  title: string,
  prohibitedValues?: string[]
): string | undefined => {
  if (title === "") {
    return I18N.main.ERROR_LOCATION_NAME_REQUIRED;
  }

  return prohibitedValues?.includes(title) === true
    ? I18N.main.ERROR_LOCATION_EXISTS
    : undefined;
};

export default function InlineTextField(
  props: InlineTextFieldProps
): ReactElement {
  const ref = useRef<HTMLInputElement>();
  const {
    className,
    defaultValue,
    error,
    inputRef = ref,
    prohibitedValues = [],
    onResetError,
    onRename,
    onCancel,
  } = props;

  const { t } = useTranslation();
  const [renameError, setRenameError] = useState<string>();

  // Apply changes when focus lost on Enter pressed
  const handleInputBlur = useCallback(
    (evt: FocusEvent) => {
      if (error !== undefined || renameError !== undefined) {
        return;
      }

      const target = evt.target as HTMLInputElement;
      onRename(target.value);
    },
    [onRename, error, renameError]
  );

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      switch (evt.key) {
        case "Escape": {
          return onCancel();
        }
        case "Enter": {
          if (renameError !== undefined) {
            return;
          }
          const inputElem = inputRef.current as HTMLInputElement;
          return onRename(inputElem.value ?? defaultValue);
        }
      }
    },
    [onRename, onCancel, defaultValue, inputRef, renameError]
  );

  // reset rename error when value changes
  const handleResetError = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setRenameError(validate(evt.target.value, prohibitedValues));

      if (error === undefined) {
        return;
      }

      onResetError();
    },
    [onResetError, error, prohibitedValues]
  );

  // Autofocus on the text field
  useEffect(() => {
    const inputElem = inputRef.current as HTMLInputElement;
    inputElem.focus();
    inputElem.select();
  }, [inputRef]);

  const hasError = error !== undefined || renameError !== undefined;
  const tooltipTitle = t(error ?? (renameError as string));
  return (
    <Tooltip
      open={hasError}
      placement="bottom-start"
      title={
        <>
          <Error style={{ marginRight: 8 }} color="error" />
          <Typography variant="body2">{tooltipTitle}</Typography>
        </>
      }
    >
      <TextField
        variant="outlined"
        size="small"
        className={className}
        inputRef={inputRef}
        defaultValue={defaultValue}
        onBlur={handleInputBlur}
        onClick={stopPropagation}
        onKeyDown={handleKeyDown}
        onChange={handleResetError}
        error={hasError}
      />
    </Tooltip>
  );
}
