import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { ReactComponent as CloseIcon } from "../../src/app/assets/closeIcon.svg";

const useStyles = makeStyles((theme: Theme) => ({
  noticeHidden: {
    display: "none",
  },
  btnClose: {
    margin: "6px 6px 0 auto",
    cursor: "pointer",
    position: "absolute",
    right: 0,

    "& path": {
      fill: theme.palette.primary.dark,
    },
  },
  notice: {
    "& .MuiPaper-root": {
      borderRadius: 12,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

export default function Notice() {
  const classes = useStyles();
  const [isHidden, setHidden] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("isNoticeShown")) {
      setHidden(false);
    }
  }, []);

  const hideNotice = () => {
    setHidden(!isHidden);
    localStorage.setItem("isNoticeShown", "true");
  };

  return (
    <Dialog className={classes.notice} open={isHidden}>
      <CloseIcon className={classes.btnClose} onClick={hideNotice} />

      <DialogTitle>
        <Typography component="p" variant="h5">
          Сервис находится в режиме бета-тестирования, возможны ошибки. Напишите
          нам на почту <a href="mailto:info@visius.ai">info@visius.ai</a>, и мы
          поможем вам.
        </Typography>
      </DialogTitle>
    </Dialog>
  );
}
