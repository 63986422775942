import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-dates";
import moment, { Moment } from "moment";

import { Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { DateRange } from "../../../components/DateRangePicker";
import usePanelStyles from "./componentsPanelStyles";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "../../../app/css/customStyle.css";
import I18N from "../../../app/i18n/strings";
import { v4 as uuid } from "uuid";

export interface DateRangeProps {
  minDate?: Moment;
  maxDate?: Moment;
  range?: DateRange;
  disabled?: boolean;
  daysOfWeek: number[];
  onDayChange: (dayOf: number[]) => void;
  onChange: (range?: DateRange) => void;
}

export interface DayObj {
  title: string;
  value: number;
}
export const dayWeekArr: DayObj[] = [
  { title: "Пн", value: 1 },
  { title: "Вт", value: 2 },
  { title: "Ср", value: 3 },
  { title: "Чт", value: 4 },
  { title: "Пт", value: 5 },
  { title: "Сб", value: 6 },
  { title: "Вс", value: 0 },
];

export default function DateRangeRender(props: DateRangeProps): ReactElement {
  const { minDate, maxDate, onChange, onDayChange, daysOfWeek } = props;
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  const classes = usePanelStyles();
  const { t } = useTranslation();
  const startDate = props.range?.start ?? null;
  const endDate = props.range?.end ?? null;

  // Compare URL with array Day of the week
  const dayTagFilter: DayObj[] = dayWeekArr.filter((day) =>
    daysOfWeek.includes(day.value)
  );

  const handleDateChange = useCallback(
    ({ startDate: start, endDate: end }) => {
      if (start === undefined || end === undefined) {
        return;
      }
      onChange({ start, end });
    },
    [onChange]
  );

  const handleDayChange = useCallback(
    (event, dayObj: DayObj[]) => {
      if (dayObj === undefined) {
        return;
      }
      const dayNumber = dayObj.map((day) => {
        return day.value;
      });
      onDayChange(dayNumber);
    },
    [onDayChange]
  );

  const isDateInvalid = useCallback(
    (day: Moment) => {
      return (
        (minDate !== undefined && day < minDate) ||
        (maxDate !== undefined && day > maxDate)
      );
    },
    [minDate, maxDate]
  );
  const uniqueId = useMemo(() => uuid(), []);

  // It's code add labels for DateRange
  useEffect(() => {
    const createLabel = function (
      parentElem: HTMLElement,
      className: string
    ): HTMLLabelElement {
      const labelFirst = document.createElement("label");
      labelFirst.classList.add(className);
      parentElem.appendChild(labelFirst);

      return labelFirst;
    };

    const inputDateFirst = document.querySelector(
      `#range-card--start-${uniqueId}`
    );
    const inputDateLast = document.querySelector(
      `#range-card--end-${uniqueId}`
    );

    if (inputDateFirst !== null) {
      const parentFirst = inputDateFirst.parentElement as HTMLElement;
      const labelElem =
        (parentFirst.querySelector(
          ".DateRangePickerInput_first_child"
        ) as HTMLElement) ??
        createLabel(parentFirst, "DateRangePickerInput_first_child");
      labelElem.textContent = t(I18N.chart.DATE_RANGE_LABEL_DATE_FROM);
      labelElem.style.display = startDate === null ? "none" : "";
    }

    if (inputDateLast !== null) {
      const parentLast = inputDateLast.parentElement as HTMLElement;
      const labelElem =
        (parentLast.querySelector(
          ".DateRangePickerInput_last_child"
        ) as HTMLElement) ??
        createLabel(parentLast, "DateRangePickerInput_last_child");
      labelElem.textContent = t(I18N.chart.DATE_RANGE_LABEL_DATE_TO);
      labelElem.style.display = endDate === null ? "none" : "";
    }
  }, [t, uniqueId, startDate, endDate]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5" className={classes.title}>
        {t(I18N.chart.DATE_RANGE_TITLE)}
      </Typography>
      <DateRangePicker
        disabled={props.disabled}
        numberOfMonths={1}
        startDate={startDate !== null ? moment(startDate) : startDate} // momentPropTypes.momentObj or null,
        startDateId={`range-card--start-${uniqueId}`} // PropTypes.string.isRequired,
        endDate={endDate !== null ? moment(endDate) : null} // momentPropTypes.momentObj or null,
        endDateId={`range-card--end-${uniqueId}`} // PropTypes.string.isRequired,
        onDatesChange={handleDateChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={setFocusedInput}
        isOutsideRange={isDateInvalid}
        minimumNights={0}
        customArrowIcon=" "
        hideKeyboardShortcutsPanel
        disableScroll={false}
        startDatePlaceholderText={t(I18N.chart.DATE_RANGE_LABEL_DATE_FROM)}
        endDatePlaceholderText={t(I18N.chart.DATE_RANGE_LABEL_DATE_TO)}
      />
      <Autocomplete
        disabled={props.disabled}
        className={classes.formControl}
        multiple
        options={dayWeekArr}
        defaultValue={
          dayTagFilter.length === dayWeekArr.length ? undefined : dayTagFilter
        }
        getOptionLabel={(option) => option.title}
        filterSelectedOptions
        PaperComponent={({ children }) => (
          <Paper className={classes.dropDownList}>{children}</Paper>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              dayTagFilter.length === dayWeekArr.length
                ? t(I18N.chart.DATE_RANGE_LABEL_DAYS_OF_WEEK)
                : ""
            }
            variant="outlined"
            InputLabelProps={{
              shrink: false,
            }}
          />
        )}
        value={dayTagFilter.length === dayWeekArr.length ? [] : dayTagFilter}
        onChange={handleDayChange}
      />
    </div>
  );
}
