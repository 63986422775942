import {
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { ErrorOutline } from '@material-ui/icons'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from './Tooltip'

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiFormLabel-root, & .MuiInputBase-root .MuiInput-input, & label + .MuiInput-formControl': {
      fontSize: 18
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 20px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.75)'
    }
  },
  endAdornmentError: {
    marginRight: theme.spacing(0.5)
  },
  errorIcon: {
    marginRight: 8
  },
  errorMassages: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 300
  },
  errorTag: {
    display: 'flex'
  }
}))

export interface TextFieldValdationProps {
  errors?: string[]
  errorPosition?: 'bottom'|'right'|'left'|'top'
}

export default function VisiusTextField ({
  inputRef: externalInputRef,
  type,
  onChange,
  errors,
  errorPosition,
  ...props
}: React.PropsWithoutRef<TextFieldValdationProps & TextFieldProps>): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  let inputRef = useRef<HTMLInputElement>(null)
  if (externalInputRef !== undefined) {
    inputRef = externalInputRef as React.RefObject<HTMLInputElement>
  }

  const errorsNumber = errors?.length ?? 0
  const hasErrors = errorsNumber > 0
  const [isPasswordVisible, _setPasswordVisibility] = useState(false)
  const [value, setValue] = useState(props.defaultValue ?? '')

  // `errors` - contains current text field errors if any or undefined if there are no current errors
  // `lastErrors` - contains current text field errors if any, or last errors available for the field
  //              if there are no current errors
  // This way we can use `last errors` to make animated transitions for error tooltips
  const [lastErrors, setLastErrors] = useState(errors)

  const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement>): void => {
    setValue(evt.target.value)
    if (onChange !== undefined) {
      onChange(evt)
    }
  }, [setValue, onChange])

  useEffect(() => {
    if (errors !== undefined && errors !== []) {
      setLastErrors(errors)
    }
  }, [setLastErrors, errors])

  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.errorMassages }}
      open={hasErrors}
      // NOTE: There must not be multiple text fields with errors number > 1
      placement={errorPosition ?? (
        isSmallScreen ? 'bottom' : (errorsNumber > 1 ? 'right' : 'left'))}
      title={lastErrors?.map((error) => {
        return (
          <div
            key={`errors-tags-${error}`}
            className={classes.errorTag}
          >
            {errorsNumber > 1
              ? <ErrorOutline className={classes.errorIcon} color='primary' />
              : null}
            <Typography
              variant='body2'
            >{t(error)}
            </Typography>
          </div>
        )
      }) ?? ''}
    >
      <TextField
        variant='standard'
        margin='normal'
        className={classes.textField}
        error={hasErrors}
        fullWidth
        onChange={handleChange}
        inputRef={inputRef}
        type={type === 'password' && isPasswordVisible ? 'text' : type}
        InputProps={{
          endAdornment: (
            <>
              {hasErrors && <ErrorOutline className={classes.endAdornmentError} color='error' />}
              {
                type === 'password' && value !== '' && props.disabled !== true && (
                  <IconButton
                    size='small'
                    onClick={() => _setPasswordVisibility(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )
              }
            </>
          )
        }}
        {...props}
      />
    </Tooltip>
  )
}
