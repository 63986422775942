import { Theme, makeStyles } from "@material-ui/core";
import { MAX_CARD_WIDTH } from "./Page";

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(12, 2, 1, 3),

    [theme.breakpoints.down(1580)]: {
      padding: (props: { isMenMode: boolean }) =>
        props.isMenMode ? "140px 16px 8px 24px" : "96px 16px 8px 24px",
    },
  },
  header: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: theme.spacing(16),
    width: `calc(100% - 240px - 368px - 32px - 56px)`,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    height: 38,
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(0.4),
    position: "relative",
    bottom: "6px",

    [theme.breakpoints.down(1580)]: {
      height: (props: { isMenMode: boolean }) =>
        props.isMenMode ? "auto" : "38px",
    },
  },
  generalInfo: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down(1580)]: {
      flexDirection: (props: { isMenMode: boolean }) =>
        props.isMenMode ? "column" : "row",
      alignItems: "flex-start",
    },
  },
  infoItems: {
    display: "flex",
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: "none",
    padding: theme.spacing(1, 1.5),
    border: `1.5px solid transparent`,

    "& .MuiTypography-root": {
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: 600,
      whiteSpace: "nowrap",
    },
  },
  buttonActive: {
    color: theme.palette.primary.main,
    background: "#eaf0ff",
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
  },
  title: {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(3),

    [theme.breakpoints.down(1580)]: {
      marginBottom: "18px",
    },
  },
  divider: {
    position: "sticky",
    zIndex: theme.zIndex.appBar,
    top: "177px",
    width: `calc(100% - 16px)`,
    margin: theme.spacing(0, 1, 3, 1),

    [theme.breakpoints.down(1580)]: {
      top: (props: { isMenMode: boolean }) =>
        props.isMenMode ? "220px" : "177px",

      marginBottom: (props: { isMenMode: boolean }) =>
        props.isMenMode ? theme.spacing(2) : theme.spacing(3),
    },
  },
  nestedCardContainer: {
    display: "flex",

    [theme.breakpoints.down(1920)]: {
      flexWrap: "wrap",
      gap: theme.spacing(2),
    },

    "& $card": {
      margin: 0,
      marginRight: theme.spacing(2),

      [theme.breakpoints.down(1920)]: {
        margin: 0,
      },

      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  card: {
    margin: theme.spacing(1),
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: MAX_CARD_WIDTH,

    [theme.breakpoints.down(1580)]: {
      flexBasis: 270,
    },

    "& .MuiCard-root": {
      border: `1px solid ${theme.palette.grey[50]}`,
      borderRadius: theme.spacing(3),
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
    },

    "&.fixed": {
      flexGrow: 0,
      flexShrink: 0,
    },

    "&.full-width": {
      flexBasis: "100%",
    },

    "&.fit": {
      flexBasis: `calc(100% - ${MAX_CARD_WIDTH + theme.spacing(4)}px)`,

      "&.wide": {
        minWidth: 700,

        [theme.breakpoints.down(1580)]: {
          minWidth: 500,
        },
      },
    },
  },
  switcher: {
    [theme.breakpoints.down(1580)]: {
      alignSelf: "flex-end",
      marginLeft: "8px",
    },

    "& .MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "& .MuiSwitch-root": {
      padding: "6px",
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          background: "linear-gradient(90deg, #0891F2 0%, #055EEA 99.94%)",
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: theme.spacing(2),
      opacity: 1,
      backgroundColor: "#D3D3D3 !important",
    },
    "& .MuiSwitch-thumb": {
      marginTop: "2px",
      marginLeft: "2px",
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: "#fbfbfb",
    },
  },
  emptyMsgCard: {
    display: "flex",
    flexWrap: "wrap",
    padding: "96px 0 8px 24px",
    marginRight: "16px",
  },
}));
