import { LocationId } from '../groups/data-types'
import { DATA_LOAD_STATE } from '../groups/slice-utils'
import {
  StreamData,
  StreamId,
  UploadData,
  UploadId,
  VideoData,
  VideoId
} from './data-mapper'

export interface LocationState {
  id: LocationId
  state: DATA_LOAD_STATE
  analyticsState: DATA_LOAD_STATE
  videos: Map<VideoId, VideoData>
  streams: Map<StreamId, StreamData>
  uploads: Map<UploadId, UploadData>
  error?: string
}

export interface LocationsState {
  data: Map<LocationId, LocationState>
}

const getInitialLocationState = (locationId: LocationId): LocationState => ({
  id: locationId,
  state: DATA_LOAD_STATE.NONE,
  analyticsState: DATA_LOAD_STATE.NONE,
  videos: new Map(),
  streams: new Map(),
  uploads: new Map()
})

export function withLocation<ResultType> (
  state: LocationsState,
  locationId: LocationId|undefined,
  callback: (location: LocationState) => ResultType,
  defaultValue?: ResultType
): ResultType|undefined {
  if (locationId === undefined || !state.data.has(locationId)) {
    return defaultValue
  }

  // eslint-disable-next-line
  return callback(state.data.get(locationId) as LocationState)
}

export const ensureLocation = (
  state: LocationsState,
  locationId: LocationId,
  callback: (location: LocationState) => void
): void => {
  if (!state.data.has(locationId)) {
    state.data.set(locationId, getInitialLocationState(locationId))
  }

  // eslint-disable-next-line
  callback(state.data.get(locationId) as LocationState)
}

export const withVideo = (
  state: LocationsState,
  locationId: LocationId,
  videoId: VideoId,
  callback: (video: VideoData) => void
): void => {
  if (!state.data.has(locationId)) {
    return
  }

  const location = state.data.get(locationId) as LocationState
  if (!location.videos.has(videoId)) {
    return
  }

  // eslint-disable-next-line
  callback(location.videos.get(videoId) as VideoData)
}

export const withStream = (
  state: LocationsState,
  locationId: LocationId,
  streamId: StreamId,
  callback: (video: StreamData) => void
): void => {
  if (!state.data.has(locationId)) {
    return
  }

  const location = state.data.get(locationId) as LocationState
  if (!location.streams.has(streamId)) {
    return
  }

  // eslint-disable-next-line
  callback(location.streams.get(streamId) as StreamData)
}
