
export enum SIGNUP_STATE {
  NONE, // Interaction hasn't been initialized
  SIGNUP_STARTED,
  SIGNUP_FAILED
}

export interface FormError {
  firstName?: string[]
  email?: string[]
  phoneNumber?: string[]
  password?: string[]
}

export interface SignUpState {
  state: SIGNUP_STATE
  error?: FormError
}

export interface ErrorPayload {
  status?: number
  message?: string
}
