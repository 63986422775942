import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  Country,
  COUNTRY_LIST,
  DEFAULT_COUNTRY,
  getDefaultTimeZone,
  getTimeZoneList,
  TimeZone,
  TimeZoneName,
} from "../../../../app/timezone";

interface TzProps {
  timeZone?: TimeZoneName;
  onChange: (timeZone: TimeZoneName) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  control: {
    flexShrink: 0,
    flexGrow: 0,
    marginRight: theme.spacing(2),
    minWidth: 350,

    "& button:nth-of-type(2) span": {
      "& svg": {
        "& path": {
          d: "path('M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z')",
          transform: "translate(4px, 8px)",
        },
      },
    },

    "& .MuiFormLabel-root": {
      fontWeight: 400,
      fontSize: "14px",

      "&.Mui-disabled": {
        color: "#DFE6FB",
      },
    },

    "& .MuiInputBase-root": {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      background: "#FFFFFF",
      padding: "20px",

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&.Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid #2C67FF",
      },

      "& fieldset": {
        border: "none ",
      },
    },
  },
}));

export default function TimeZoneSelect(props: TzProps): ReactElement {
  const { timeZone = getDefaultTimeZone(), onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [inputValue, setInputValue] = useState("");
  const [tzInputValue, setTzInputValue] = useState("");

  const tzList = useMemo(() => {
    return getTimeZoneList(country);
  }, [country]);

  const handleCountryChange = useCallback(
    (_, value: Country | null) => {
      if (value === null) {
        return;
      }

      setCountry(value.key);
      onChange(getDefaultTimeZone(value.key) as TimeZoneName);
    },
    [onChange]
  );

  const handleTimeZoneChange = useCallback(
    (_, value: TimeZone | null) => {
      if (value === null) {
        return;
      }

      onChange(value.name);
    },
    [onChange]
  );

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        className={classes.control}
        options={COUNTRY_LIST}
        value={COUNTRY_LIST.find(({ key }) => key === country)}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Страна")}
            variant="outlined"
            size="small"
          />
        )}
        onChange={handleCountryChange}
      />
      <Autocomplete
        className={classes.control}
        options={tzList}
        value={tzList.find(({ name }) => name === timeZone)}
        inputValue={tzInputValue}
        onInputChange={(_, newInputValue) => {
          setTzInputValue(newInputValue);
        }}
        getOptionLabel={(option) => `${t(option.name)} (${option.offset})`}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Часовой пояс")}
            variant="outlined"
            size="small"
          />
        )}
        onChange={handleTimeZoneChange}
      />
    </div>
  );
}
