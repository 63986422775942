import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import I18N from '../../app/i18n/strings'
import { useFormStyles } from '../../app/styles'
import Form, { FormValidatorOptions, getConfirmationValidator, getRequiredValidator } from '../../components/Form'
import TextField from '../../components/TextField'
import {
  actions,
  resetPassword,
  selectIsPasswordResetStarted,
  selectPasswordResetError
} from './passwordResetSlice'

interface FormData {
  password: string
  confirmation: string
}

interface FormProps {
  token: string
  email: string
}

export default function ResetRequestForm (props: FormProps): ReactElement {
  const classes = useFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isResetStarted = useSelector(selectIsPasswordResetStarted)
  const resetError = useSelector(selectPasswordResetError)

  const _onChange = (): void => {
    if (resetError !== undefined) {
      dispatch(actions.reset())
    }
  }

  const _onSubmit = (data: FormData): void => {
    dispatch(resetPassword(props.email, props.token, data.password))
  }

  return (
    <Form
      onSubmit={_onSubmit}
      validate={(options: FormValidatorOptions<FormData>) => ({
        ...getRequiredValidator(['password', 'confirmation'])(options),
        ...getConfirmationValidator<FormData>('password', 'confirmation')(options)
      })}
    >
      <Typography className={classes.formIntro}>
        {t(I18N.passwordReset.MSG_NEW_PASSWORD)}
      </Typography>
      <TextField
        required
        autoComplete='new-password'
        name='password'
        label={t(I18N.passwordReset.LABEL_NEW_PASSWORD)}
        type='password'
        onChange={_onChange}
        error={Boolean(resetError)}
        helperText={t(resetError ?? '')}
      />
      <TextField
        required
        autoComplete='new-password'
        name='confirmation'
        label={t(I18N.passwordReset.LABEL_CONFIRMATION)}
        type='password'
        onChange={_onChange}
      />
      <div className={classes.formFooterActions}>
        <Button
          className={classes.loginBtn}
          type='submit'
          variant='contained'
          color='primary'
          disabled={isResetStarted}
        >
          {t(I18N.passwordReset.BTN_CHANGE_PASSWORD)}
        </Button>
      </div>
    </Form>
  )
}
