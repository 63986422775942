import React, { ReactElement, useCallback, useMemo } from "react";
import moment from "moment";
import ChartTooltip from "./ChartTooltip";
import Chart, { ChartProps } from "../../../components/charts/Chart";
import { generateMaxYGetter } from "./utils";

const TICK_DATE_FORMAT = "DD MMM YYYY";
const TOOLTIP_DATE_FORMAT = "DD MMM YYYY";
const TIME_FORMAT = "HH:00";
const ID_FORMAT = "DD.MM.YYYY HH";

export interface RawResult {
  time: Date;
  oldMen: number;
  middleMen: number;
  youngMen: number;
  unknownMen: number;
  oldWomen: number;
  middleWomen: number;
  youngWomen: number;
  unknownWomen: number;
  oldUnknown: number;
  middleUnknown: number;
  youngUnknown: number;
  unknownUnknown: number;

  oldMenObject: number;
  middleMenObject: number;
  youngMenObject: number;
  unknownMenObject: number;
  oldWomenObject: number;
  middleWomenObject: number;
  youngWomenObject: number;
  unknownWomenObject: number;
  oldUnknownObject: number;
  middleUnknownObject: number;
  youngUnknownObject: number;
  unknownUnknownObject: number;

  oldMenUniq: number;
  middleMenUniq: number;
  youngMenUniq: number;
  unknownMenUniq: number;
  oldWomenUniq: number;
  middleWomenUniq: number;
  youngWomenUniq: number;
  unknownWomenUniq: number;
  oldUnknownUniq: number;
  middleUnknownUniq: number;
  youngUnknownUniq: number;
  unknownUnknownUniq: number;

  oldMenRepeating: number;
  middleMenRepeating: number;
  youngMenRepeating: number;
  unknownMenRepeating: number;
  oldWomenRepeating: number;
  middleWomenRepeating: number;
  youngWomenRepeating: number;
  unknownWomenRepeating: number;
  oldUnknownRepeating: number;
  middleUnknownRepeating: number;
  youngUnknownRepeating: number;
  unknownUnknownRepeating: number;

  oldMenRepeatingObject: number;
  middleMenRepeatingObject: number;
  youngMenRepeatingObject: number;
  unknownMenRepeatingObject: number;
  oldWomenRepeatingObject: number;
  middleWomenRepeatingObject: number;
  youngWomenRepeatingObject: number;
  unknownWomenRepeatingObject: number;
  oldUnknownRepeatingObject: number;
  middleUnknownRepeatingObject: number;
  youngUnknownRepeatingObject: number;
  unknownUnknownRepeatingObject: number;

  men: number;
  cars: number;
  bikes: number;
  ageJun: number;
  ageMiddle: number;
  ageSenior: number;
  ageUnknown: number;
  gMale: number;
  gFemale: number;
  gUnknown: number;
}

// accessors
export const getX = ({ time }: RawResult): string => {
  return moment(time).format(ID_FORMAT);
};

const getComparableX = (x: string): number => {
  return moment(x, ID_FORMAT).toDate().getTime();
};

const getXTickLabel = (xValue: string): string[] => {
  const date = moment(xValue, ID_FORMAT);
  const dateStr = date.format(TICK_DATE_FORMAT);
  const timeStr = `${date.format(TIME_FORMAT)}-${date
    .add(1, "h")
    .format(TIME_FORMAT)}`;

  return [dateStr, timeStr];
};

export const getXTooltipLabel = (xValue: string): string[] => {
  const date = moment(xValue, ID_FORMAT);
  const dateStr = date.format(TOOLTIP_DATE_FORMAT);
  const timeStr = `${date.format(TIME_FORMAT)}-${date
    .add(1, "h")
    .format(TIME_FORMAT)}`;

  return [dateStr, timeStr];
};

export default function ObjectDetectionResultBarChart(
  props: ChartProps<RawResult>
): ReactElement | null {
  const {
    width,
    height,
    colorScale,
    data,
    variant = "group",
    resultKeys,
  } = props;

  const getTooltip = useCallback(
    (tooltipData: RawResult): ReactElement => {
      return (
        <ChartTooltip
          data={tooltipData}
          colorScale={colorScale}
          resultKeys={resultKeys}
          tooltipDataGetter={(data) =>
            getXTooltipLabel(getX(data as RawResult))
          }
        />
      );
    },
    [colorScale, resultKeys]
  );

  const maxYGetter = useMemo(
    () => generateMaxYGetter<RawResult>(resultKeys, variant),
    [resultKeys, variant]
  );

  if (width === 0) {
    return null;
  }

  return (
    <Chart<RawResult>
      brush
      width={width}
      height={height}
      colorScale={colorScale}
      data={data}
      variant={variant}
      resultKeys={resultKeys}
      tooltipRenderer={getTooltip}
      getX={getX}
      getComparableX={getComparableX}
      getMaxY={maxYGetter}
      getXLabel={getXTickLabel}
      tickLabelWidth={80}
    />
  );
}
