import React, {
  ReactElement,
  MouseEvent,
  useCallback,
  useState,
  useMemo,
} from "react";
import { Bar } from "@visx/shape";
import {
  BarGroupBar,
  BarGroup as BarGroupType,
} from "@visx/shape/lib/types/barGroup";
import { Group } from "@visx/group";
import { Text } from "@visx/text";

import { BarGroupProps } from "./types";
import { d3format } from "../../../app/i18n/d3";
import { useSelector } from "react-redux";
import {
  selectIsMenMode,
  selectIsMenUniteMode,
} from "../../../features/dashboard/dashboardSlice";

const MIN_LABELED_BAR_WIDTH = 20;
const LABEL_BOTTOM_OFFSET = 10;

const getBarKey = (
  barGroup: BarGroupType<string>,
  bar: BarGroupBar<string>
): string => {
  return `${barGroup.index}-${bar.index}-${bar.key}-${bar.value}`;
};

export default function VerticalBarGroup(
  props: BarGroupProps<BarGroupType<string>>
): ReactElement {
  const {
    barGroup,
    hideLabels,
    opacity,
    maxBarHeight = 0,
    labels,
    onMouseOver,
    onMouseLeave,
  } = props;
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = useCallback(
    (evt: MouseEvent) => {
      setHovered(true);
      if (onMouseOver === undefined) {
        return;
      }
      onMouseOver(evt);
    },
    [onMouseOver, setHovered]
  );

  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const regexMen = /\w+Men\b/;

  const matchesMen =
    labels
      ?.map((item) => item.match(regexMen))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const regexWomen = /\w+Women\b/;
  const matchesWomen =
    labels
      ?.map((item) => item.match(regexWomen))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const regexUnknown = /\w+Unknown\b/;
  const matchesUnknown =
    labels
      ?.map((item) => item.match(regexUnknown))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
    if (onMouseLeave === undefined) {
      return;
    }
    onMouseLeave();
  }, [onMouseLeave, setHovered]);

  const filteredBarGroup = barGroup.bars.filter(
    (i) => i.key !== "cars" && i.key !== "bikes" && i.key !== "men"
  );

  const averageX =
    barGroup.bars.length !== 0
      ? barGroup.bars.reduce((sum, obj) => sum + obj.x, 0) /
        barGroup.bars.length
      : 0;

  const onlyMenBars = useMemo(() => {
    const onlyMenBars: BarGroupBar<string>[] = [];

    filteredBarGroup.forEach((i) => {
      const newBar: BarGroupBar<string> = {
        index: i.index,
        color: i.color,
        height: i.height,
        key: i.key,
        value: i.value,
        width: i.width,
        x: averageX - i.width,
        y: i.y,
      };

      if (i.key === "oldMen") {
        newBar.height = i.height / matchesMen;
        newBar.y = maxBarHeight - newBar.height;
      }

      if (i.key === "middleMen") {
        newBar.height = i.height / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        newBar.y =
          maxBarHeight -
          (oldMen ? oldMen.height / matchesMen : 0) -
          newBar.height;
      }

      if (i.key === "youngMen") {
        newBar.height = i.height / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        const middleMen = filteredBarGroup.find((i) => i.key === "middleMen");
        newBar.y =
          maxBarHeight -
          (oldMen ? oldMen.height / matchesMen : 0) -
          (middleMen ? middleMen.height / matchesMen : 0) -
          newBar.height;
      }

      if (i.key === "unknownMen") {
        newBar.height = i.height / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        const middleMen = filteredBarGroup.find((i) => i.key === "middleMen");
        const youngMen = filteredBarGroup.find((i) => i.key === "youngMen");

        newBar.y =
          maxBarHeight -
          (oldMen ? oldMen.height / matchesMen : 0) -
          (middleMen ? middleMen.height / matchesMen : 0) -
          (youngMen ? youngMen.height / matchesMen : 0) -
          newBar.height;
      }

      if (i.key === "oldWomen") {
        newBar.height = i.height / matchesWomen;

        newBar.x += i.width;

        newBar.y = maxBarHeight - newBar.height;
      }

      if (i.key === "middleWomen") {
        newBar.height = i.height / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");

        newBar.x += i.width;

        newBar.y =
          maxBarHeight -
          (oldWomen ? oldWomen.height / matchesWomen : 0) -
          newBar.height;
      }

      if (i.key === "youngWomen") {
        newBar.height = i.height / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");

        const middleWomen = filteredBarGroup.find(
          (i) => i.key === "middleWomen"
        );

        newBar.x += i.width;

        newBar.y =
          maxBarHeight -
          (oldWomen ? oldWomen.height / matchesWomen : 0) -
          (middleWomen ? middleWomen.height / matchesWomen : 0) -
          newBar.height;
      }

      if (i.key === "unknownWomen") {
        newBar.height = i.height / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");
        const middleWomen = filteredBarGroup.find(
          (i) => i.key === "middleWomen"
        );
        const youngWomen = filteredBarGroup.find((i) => i.key === "youngWomen");

        newBar.x += i.width;

        newBar.y =
          maxBarHeight -
          (oldWomen ? oldWomen.height / matchesWomen : 0) -
          (middleWomen ? middleWomen.height / matchesWomen : 0) -
          (youngWomen ? youngWomen.height / matchesWomen : 0) -
          newBar.height;
      }

      if (i.key === "oldUnknown") {
        newBar.height = i.height / matchesUnknown;

        newBar.x += i.width * 2;

        newBar.y = maxBarHeight - newBar.height;
      }

      if (i.key === "middleUnknown") {
        newBar.height = i.height / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");

        newBar.x += i.width * 2;

        newBar.y =
          maxBarHeight -
          (oldUnknown ? oldUnknown.height / matchesUnknown : 0) -
          newBar.height;
      }

      if (i.key === "youngUnknown") {
        newBar.height = i.height / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");
        const middleUnknown = filteredBarGroup.find(
          (i) => i.key === "middleUnknown"
        );

        newBar.x += i.width * 2;

        newBar.y =
          maxBarHeight -
          (oldUnknown ? oldUnknown.height / matchesUnknown : 0) -
          (middleUnknown ? middleUnknown.height / matchesUnknown : 0) -
          newBar.height;
      }

      if (i.key === "unknownUnknown") {
        newBar.height = i.height / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");
        const middleUnknown = filteredBarGroup.find(
          (i) => i.key === "middleUnknown"
        );
        const youngUnknown = filteredBarGroup.find(
          (i) => i.key === "youngUnknown"
        );

        newBar.x += i.width * 2;

        newBar.y =
          maxBarHeight -
          (oldUnknown ? oldUnknown.height / matchesUnknown : 0) -
          (middleUnknown ? middleUnknown.height / matchesUnknown : 0) -
          (youngUnknown ? youngUnknown.height / matchesUnknown : 0) -
          newBar.height;
      }

      onlyMenBars.push(newBar);
    });

    return onlyMenBars;
  }, [barGroup, maxBarHeight]);

  const onlyUniteMenBars = useMemo(() => {
    const data: BarGroupBar<string>[] = [];

    barGroup.bars
      .filter((i) => i.key === "men")
      .forEach((i) => {
        const newBar: BarGroupBar<string> = {
          index: i.index,
          color: i.color,
          height: i.height,
          key: i.key,
          value: i.value,
          width: i.width,
          x: averageX,
          y: i.y,
        };

        data.push(newBar);
      });

    return data;
  }, [barGroup, maxBarHeight]);

  const defaultBars = useMemo(() => {
    const data: BarGroupBar<string>[] = [];

    for (let i = 0; i < barGroup.bars.length; i++) {
      const curr = barGroup.bars[i];
      const newBar: BarGroupBar<string> = {
        index: curr.index,
        color: curr.color,
        height: curr.height,
        key: curr.key,
        value: curr.value,
        width: curr.width,
        x: averageX - curr.width,
        y: curr.y,
      };
      if (curr.index === 0) {
        newBar.x += curr.width;
      }

      if (curr.index === 1) {
        newBar.x += curr.width * 2;
      }

      data.push(newBar);
    }

    return data;
  }, [barGroup, maxBarHeight]);

  return (
    <Group
      left={barGroup.x0}
      top={0}
      onMouseMove={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {isMenMode &&
        !isMenUniteMode &&
        onlyMenBars.map((bar) => {
          return (
            <Group key={getBarKey(barGroup, bar)}>
              <Bar
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={bar.color}
                opacity={opacity}
                rx={1}
                ry={1}
              />
              {hovered && (
                <rect
                  x={bar.x}
                  y={bar.y}
                  rx={1}
                  ry={1}
                  width={bar.width}
                  height={bar.height}
                  fill="url(#pattern-hover-bar)"
                />
              )}
              {hideLabels !== true &&
                !isMenMode &&
                bar.width > MIN_LABELED_BAR_WIDTH && (
                  <Text
                    x={bar.x + bar.width / 2}
                    y={bar.y - LABEL_BOTTOM_OFFSET}
                    textAnchor="middle"
                    verticalAnchor="end"
                    width={bar.width}
                    fontSize="small"
                    style={{ userSelect: "none" }}
                  >
                    {d3format(bar.value)}
                  </Text>
                )}
            </Group>
          );
        })}

      {isMenMode &&
        isMenUniteMode &&
        onlyUniteMenBars
          .filter((i) => i.key === "men")
          .map((bar) => {
            return (
              <Group key={getBarKey(barGroup, bar)}>
                <Bar
                  x={bar.x}
                  y={bar.y}
                  width={bar.width}
                  height={bar.height}
                  fill={bar.color}
                  opacity={opacity}
                  rx={1}
                  ry={1}
                />
                {hovered && (
                  <rect
                    x={bar.x}
                    y={bar.y}
                    rx={1}
                    ry={1}
                    width={bar.width}
                    height={bar.height}
                    fill="url(#pattern-hover-bar)"
                  />
                )}
                {hideLabels !== true && bar.width > MIN_LABELED_BAR_WIDTH && (
                  <Text
                    x={bar.x + bar.width / 2}
                    y={bar.y - LABEL_BOTTOM_OFFSET}
                    textAnchor="middle"
                    verticalAnchor="end"
                    width={bar.width}
                    fontSize="small"
                    style={{ userSelect: "none" }}
                  >
                    {d3format(bar.value)}
                  </Text>
                )}
              </Group>
            );
          })}

      {!isMenMode &&
        !isMenUniteMode &&
        defaultBars.map((bar) => {
          return (
            <Group key={getBarKey(barGroup, bar)}>
              <Bar
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={bar.color}
                opacity={opacity}
                rx={1}
                ry={1}
              />
              {hovered && (
                <rect
                  x={bar.x}
                  y={bar.y}
                  rx={1}
                  ry={1}
                  width={bar.width}
                  height={bar.height}
                  fill="url(#pattern-hover-bar)"
                />
              )}
            </Group>
          );
        })}
    </Group>
  );
}
