import { Button, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import I18N from '../../app/i18n/strings'
import { useFormStyles } from '../../app/styles'
import AuthTemplate from '../../components/AuthPage'
import VisiusLink from '../../components/Link'
import {
  actions,
  selectEmail,
  selectIsPasswordResetRequested,
  selectIsResetCompleted
} from './passwordResetSlice'
import RequestForm from './RequestForm'
import ResetForm from './ResetForm'

export default function ResetPassword (): ReactElement {
  const { t } = useTranslation()
  const classes = useFormStyles()
  const location = useLocation()
  const isResetCompleted = useSelector(selectIsResetCompleted)
  const isResetRequested = useSelector(selectIsPasswordResetRequested)
  const resetEmail = useSelector(selectEmail)
  const query = new URLSearchParams(location.search)
  const token = query.get('token')
  const userEmail = query.get('userEmail')

  if (isResetRequested) {
    return (
      <AuthTemplate title={t(I18N.passwordReset.TITLE_CHECK_EMAIL)}>
        <Typography>
          {t(I18N.passwordReset.MSG_CHECK_EMAIL, { email: resetEmail })}
        </Typography>
        <div className={classes.formFooterActions}>
          <VisiusLink
            to='/login'
            className={classes.loginBtnLink}
          >
            <Button
              className={classes.loginBtn}
              variant='contained'
              color='primary'
              onClick={() => actions.reset()}
            >
              {t(I18N.passwordReset.BTN_RESET_REQUESTED)}
            </Button>
          </VisiusLink>
        </div>
      </AuthTemplate>
    )
  }
  if (isResetCompleted) {
    return (
      <AuthTemplate title={t(I18N.passwordReset.TITLE_COMPLETED)}>
        <Typography>
          {t(I18N.passwordReset.MSG_COMPLETED)}
        </Typography>
        <div className={classes.formFooterActions}>
          <VisiusLink
            to='/login'
            className={classes.loginBtnLink}
          >
            <Button
              className={classes.loginBtn}
              type='submit'
              variant='contained'
              color='primary'
            >
              {t(I18N.signIn.BTN_LOGIN)}
            </Button>
          </VisiusLink>
        </div>
      </AuthTemplate>
    )
  }

  if (userEmail !== null && token !== null) {
    return (
      <AuthTemplate title={t(I18N.passwordReset.TITLE_RESET)}>
        <ResetForm email={userEmail} token={token} />
      </AuthTemplate>
    )
  }

  return (
    <AuthTemplate title={t(I18N.passwordReset.TITLE_BASIC)}>
      <RequestForm />
    </AuthTemplate>
  )
}
