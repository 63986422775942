import React, { ReactElement, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import BaseLocationCard, {
  LocationCardProps,
} from "../components/BaseLocationCard";
import LocationSummary from "../components/LocationSummary";
import LocationTitle from "../components/LocationTitle";

import { deleteLocation, restoreLocation } from "./asyncActions";

import { ReactComponent as TrashIcon } from "../../../app/assets/trashIcon.svg";
import { ReactComponent as RecoverIcon } from "../../../app/assets/recoverDataIcon.svg";

export const useStyle = makeStyles<Theme>((theme: Theme) => ({
  dialog: {
    borderRadius: theme.spacing(1.5),

    "& .MuiDialogActions-root": {
      padding: theme.spacing(1, 3, 2),
    },
  },
}));

export default function TrashLocationCard({
  groupId,
  data,
}: LocationCardProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleRestore = useCallback(() => {
    dispatch(restoreLocation({ groupId, location: data }));
  }, [dispatch, data, groupId]);
  const handleDelete = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);
  const handleDeleteCancel = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);
  const doDelete = useCallback(() => {
    setDeleteDialogOpen(false);
    dispatch(deleteLocation({ groupId, location: data }));
  }, [dispatch, groupId, data]);

  return (
    <>
      <BaseLocationCard
        groupId={groupId}
        data={data}
        menuItems={[
          <MenuItem key="restore" onClick={handleRestore}>
            <ListItemIcon>
              <RecoverIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="caption">
                  {t(I18N.main.BTN_RESTORE_LOCATION)}
                </Typography>
              }
            />
          </MenuItem>,
          <MenuItem key="delete" onClick={handleDelete}>
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="caption">
                  {t(I18N.main.BTN_DELETE_PERM_LOCATION)}
                </Typography>
              }
            />
          </MenuItem>,
        ]}
      >
        <LocationTitle title={data.title} />
        <LocationSummary data={data} />
      </BaseLocationCard>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>
          <Typography style={{ fontWeight: 600 }} variant="h4">
            {t(I18N.main.DLG_DELETE_LOCATION)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              {t(I18N.main.DLG_DELETE_LOCATION_SUMMARY, { title: data.title })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            <Typography
              style={{ textTransform: "none" }}
              color="primary"
              variant="body2"
            >
              {t(I18N.main.DLG_CANCEL)}
            </Typography>
          </Button>
          <Button
            onClick={doDelete}
            variant="contained"
            style={{
              background: "linear-gradient(90deg, #FF5F49 0%, #FF3D00 99.94%)",
              borderRadius: 12,
            }}
            autoFocus
          >
            <Typography
              style={{ textTransform: "none", color: "white" }}
              variant="body2"
            >
              {t(I18N.main.DLG_DELETE_LOCATION_CONFIRM)}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
