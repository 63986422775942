import React, { ReactElement } from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
interface LocationTitleProps {
  title: string
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: theme.palette.primary.dark,
    // experimental line clipping
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: 1.25,
    fontWeight: 500
  }
}))

export default function LocationTitle (props: LocationTitleProps): ReactElement {
  const classes = useStyles()
  const { title } = props

  return (
    <Typography
      className={classes.text}
      variant='body2'
      component='h4'
    >
      {title}
    </Typography>
  )
}
