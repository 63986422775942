import React, { ReactElement, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LegendOrdinal, LegendItem } from "@visx/legend";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ScaleOrdinal } from "d3-scale";

import { ResultKeys } from "../../../components/charts/bar/types";
import usePanelStyles from "./componentsPanelStyles";

import I18N from "../../../app/i18n/strings";
import { ReactComponent as CheckedIcon } from "../../../app/assets/checkedCheckbox.svg";
import { ReactComponent as UnCheckedIcon } from "../../../app/assets/unCheckedCheckbox.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  child: {
    marginLeft: "20px",
  },
}));

export interface LegendEntry {
  title: string;
  color: string;
}

export interface LegendProps<T> {
  entries: Record<ResultKeys<T>, LegendEntry>;
  value: Array<ResultKeys<T>>;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
  isMen?: boolean;
  onChange: (values: Array<ResultKeys<T>>) => void;
}

export interface LegendLabel<T> {
  datum: ResultKeys<T>;
  value?: string;
  index: number;
  text: string;
}

export default function Legend<T>({
  colorScale,
  entries,
  value,
  isMen,
  onChange,
}: LegendProps<T>): ReactElement {
  const classes = usePanelStyles();
  const legendClasses = useStyles();
  const { t } = useTranslation();
  const getTitle = useCallback(
    (key: ResultKeys<T>, color?: string): ReactNode => {
      return t(entries[key].title);
    },
    [t, entries]
  );

  const selectedAges = [
    value.includes("ageJun" as ResultKeys<T>) ? "ageJun" : null,
    value.includes("ageMiddle" as ResultKeys<T>) ? "ageMiddle" : null,
    value.includes("ageSenior" as ResultKeys<T>) ? "ageSenior" : null,
    value.includes("ageUnknown" as ResultKeys<T>) ? "ageUnknown" : null,
  ].filter(Boolean);

  const selectedGenders = [
    value.includes("gMale" as ResultKeys<T>) ? "gMale" : null,
    value.includes("gFemale" as ResultKeys<T>) ? "gFemale" : null,
    value.includes("gUnknown" as ResultKeys<T>) ? "gUnknown" : null,
  ].filter(Boolean);

  const handleChange = useCallback(
    (checked: boolean, resultKey: ResultKeys<T>): void => {
      if (!checked) {
        const index = value.indexOf(resultKey);
        if (index === -1) {
          return;
        }

        if (
          selectedAges.length === 1 &&
          (resultKey === "ageJun" ||
            resultKey === "ageMiddle" ||
            resultKey === "ageSenior" ||
            resultKey === "ageUnknown")
        ) {
          return;
        }

        if (
          selectedGenders.length === 1 &&
          (resultKey === "gMale" ||
            resultKey === "gFemale" ||
            resultKey === "gUnknown")
        ) {
          return;
        }

        const newValue = [...value];
        newValue.splice(index, 1);

        return onChange(newValue);
      }

      onChange([...value, resultKey].sort((a, b) => a.localeCompare(b)));
    },
    [onChange, value]
  );

  if (!isMen) {
    return (
      <Grid container>
        <Typography variant="h5" className={classes.title}>
          {t(I18N.chart.LEGEND_TITLE)}
        </Typography>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div
              className={clsx(legendClasses.container, {
                [legendClasses.child]: isMen,
              })}
            >
              {labels
                .sort((a, b) => {
                  if (entries[a.datum].title < entries[b.datum].title) {
                    return -1;
                  }
                  if (entries[a.datum].title > entries[b.datum].title) {
                    return 1;
                  }
                  return 0;
                })
                .map(({ datum: key, value: color }) => {
                  if (key === "cars" || key === "bikes" || key === "men") {
                    return (
                      <LegendItem key={`legend-quantile-${color as string}`}>
                        <FormControlLabel
                          key={key as string}
                          className={classes.labelOfLegend}
                          control={
                            <Checkbox
                              color="primary"
                              icon={<UnCheckedIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={value.includes(key)}
                              onChange={({ target }) =>
                                handleChange(target.checked, key)
                              }
                            />
                          }
                          label={getTitle(key, color)}
                        />
                      </LegendItem>
                    );
                  }
                })}
            </div>
          )}
        </LegendOrdinal>
      </Grid>
    );
  } else {
    return (
      <Grid className={classes.additionalOptions} container>
        <Typography variant="h5" className={classes.childTitle}>
          Пол
        </Typography>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div
              className={clsx(legendClasses.container, {
                [legendClasses.child]: isMen,
              })}
            >
              {labels
                .sort((a, b) => {
                  if (entries[a.datum].title < entries[b.datum].title) {
                    return -1;
                  }
                  if (entries[a.datum].title > entries[b.datum].title) {
                    return 1;
                  }
                  return 0;
                })
                .map(({ datum: key, value: color }) => {
                  if (
                    key === "gMale" ||
                    key === "gFemale" ||
                    key === "gUnknown"
                  ) {
                    return (
                      <LegendItem key={`legend-quantile-${color as string}`}>
                        <FormControlLabel
                          key={key as string}
                          className={classes.labelOfLegend}
                          control={
                            <Checkbox
                              color="primary"
                              icon={<UnCheckedIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={value.includes(key)}
                              onChange={({ target }) =>
                                handleChange(target.checked, key)
                              }
                            />
                          }
                          label={getTitle(key, color)}
                        />
                      </LegendItem>
                    );
                  }
                })}
            </div>
          )}
        </LegendOrdinal>
        <Typography variant="h5" className={classes.childTitle}>
          Возраст
        </Typography>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div
              className={clsx(legendClasses.container, {
                [legendClasses.child]: isMen,
              })}
            >
              {labels
                .sort((a, b) => {
                  if (entries[a.datum].title < entries[b.datum].title) {
                    return -1;
                  }
                  if (entries[a.datum].title > entries[b.datum].title) {
                    return 1;
                  }
                  return 0;
                })
                .map(({ datum: key, value: color }) => {
                  if (
                    key === "ageJun" ||
                    key === "ageMiddle" ||
                    key === "ageSenior" ||
                    key === "ageUnknown"
                  ) {
                    return (
                      <LegendItem key={`legend-quantile-${color as string}`}>
                        <FormControlLabel
                          key={key as string}
                          className={classes.labelOfLegend}
                          control={
                            <Checkbox
                              color="primary"
                              icon={<UnCheckedIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={value.includes(key)}
                              onChange={({ target }) =>
                                handleChange(target.checked, key)
                              }
                            />
                          }
                          label={getTitle(key, color)}
                        />
                      </LegendItem>
                    );
                  }
                })}
            </div>
          )}
        </LegendOrdinal>
      </Grid>
    );
  }
}
