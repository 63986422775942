import { BlockBlobClient } from '@azure/storage-blob'

export interface UploadOptions {
  url: string
  file: File
  onProgress: (
    evt: {
      loadedBytes: number
    }
  ) => void
}

export async function uploadFile (options: UploadOptions): Promise<void> {
  const { file, url, onProgress } = options
  const blockBlobClient = new BlockBlobClient(url)
  await blockBlobClient.uploadData(file, { onProgress })
}
