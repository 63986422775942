import React, { ReactElement, useCallback } from "react";

import {
  IconButton,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { TableChart } from "@material-ui/icons";

import { isSortable } from "../../../components/charts/chart-utils";
import { ChartVariant, SortOrder } from "../../../components/charts/Chart";
import Tooltip from "../../../components/Tooltip";
import { COLORS } from "../../../theme";

import { ReactComponent as TooltipIcon } from "../../../app/assets/tooltipIcon.svg";
import { ReactComponent as BarIcon } from "../../../app/assets/analyticIcon.svg";
import { ReactComponent as LinearIcon } from "../../../app/assets/linearIcon.svg";
import { ReactComponent as PieIcon } from "../../../app/assets/pieIcon.svg";

interface ChartCardHeaderProps {
  title: string | JSX.Element;
  chartVariant: ChartVariant;
  onSortOrderChange?: () => void;
  onChartVariantChange?: (chartVariant: ChartVariant) => void;

  sortOrder?: SortOrder;
  tooltip?: ReactElement;
  variants?: ChartVariant[];
  sortable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    marginLeft: 10,
    maxWidth: 13,
  },
  controls: {
    display: "flex",
    marginLeft: "auto",
    paddingLeft: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),

    "& .MuiTypography-subtitle1": {
      lineHeight: 1.15,
      marginTop: "0.15em",
      fontWeight: 500,
    },

    "& > div": {
      display: "flex",
      minHeight: 30, // roughly equal to the button group height
      alignItems: "center",
    },
  },
  buttonGroup: {
    display: "flex",
    marginLeft: theme.spacing(2),
    borderRadius: 12,

    "& .MuiIconButton-root": {
      border: `solid 1.5px ${COLORS.BLUE_MAIN}`,
      borderRadius: 0,
      padding: "5px",

      "& svg path": {
        fill: `${COLORS.BLUE_MAIN}`,
      },

      "&.checked svg path": {
        fill: "white",
      },

      "&:not(:last-child)": {
        borderRightWidth: 0,
      },
      "&:first-child": {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
      },
      "&:last-child": {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
      },
      "&.checked": {
        background: "linear-gradient(90deg, #0891F2 0%, #055EEA 99.94%)",
      },
    },
  },
}));

const ChartTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 246,
    border: `solid 1px ${COLORS.BLUE_MAIN}`,
    borderTopWidth: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),

    "& .text1, & .text2": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "15px",

      letterSpacing: "0.25px",
    },

    "& .text1": {
      marginBottom: theme.spacing(2),
      color: COLORS.GREY_NEUTRAL,

      "&:last-of-type": {
        marginBottom: 0,
      },
    },

    "& .text2": {
      fontWeight: 600,
      color: COLORS.NEW_BLACK,
      marginBottom: theme.spacing(2),
    },
  },
}))(Tooltip);

function getChartVariantIcon(variant: ChartVariant): ReactElement {
  switch (variant) {
    case "linear": {
      return <LinearIcon style={{ transform: "scale(0.9)" }} />;
    }
    case "group": {
      return <BarIcon style={{ transform: "scale(0.9)" }} />;
    }
    case "stack": {
      return <TableChart />;
    }
    case "pie": {
      return <PieIcon style={{ transform: "scale(0.9)" }} />;
    }
  }
}

interface IconProps {
  width: number;
  height: number;
}

function SortDescIcon({ width, height }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5V7.5H21V5H3ZM3 19.5H9V17H3V19.5ZM15 13.5H3V11H15V13.5Z"
        fill={COLORS.BLUE6}
      />
    </svg>
  );
}

function SortNoneIcon({ width, height }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 22C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19ZM19 8H5V6H19V8ZM19 10H5V20H19V10ZM17 13V19H15V13H17ZM11 19H13V15H11V19ZM9 12H7V19H9V12Z"
        fill={COLORS.BLUE6}
      />
    </svg>
  );
}

export default function ChartCardHeader(
  props: ChartCardHeaderProps
): ReactElement {
  const classes = useStyles();
  const {
    chartVariant,
    title,
    sortOrder,
    sortable,
    tooltip,
    variants,

    onChartVariantChange,
    onSortOrderChange,
  } = props;

  const handleChartVariantChange = useCallback(
    (variant: ChartVariant) => {
      if (onChartVariantChange === undefined) {
        return;
      }

      onChartVariantChange(variant);
    },
    [onChartVariantChange]
  );

  return (
    <div className={classes.cardHeader}>
      <div>
        {typeof title === "string" ? (
          <Typography variant="h5">{title}</Typography>
        ) : (
          title
        )}
        {tooltip !== undefined && (
          <div style={{ display: "flex", alignSelf: "center" }}>
            <ChartTooltip interactive title={tooltip} placement="right-start">
              <TooltipIcon className={classes.infoIcon} />
            </ChartTooltip>
          </div>
        )}
      </div>

      <div className={classes.controls}>
        {sortable === true && isSortable(chartVariant) && (
          <IconButton onClick={onSortOrderChange} color="primary" size="small">
            {sortOrder === "desc" ? (
              <SortNoneIcon width={20} height={20} />
            ) : (
              <SortDescIcon width={20} height={20} />
            )}
          </IconButton>
        )}

        {variants !== undefined && (
          <div className={classes.buttonGroup}>
            {variants.map((variant) => (
              <IconButton
                key={variant}
                className={variant === chartVariant ? "checked" : ""}
                onClick={() => handleChartVariantChange(variant)}
                color="primary"
                size="small"
              >
                {getChartVariantIcon(variant)}
              </IconButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
