import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import I18N from "../../app/i18n/strings";
import { getCurrentTab } from "../../app/utils";
import { useAppLayoutStyles } from "../../app/styles";

import TariffsList from "./tariffs/components/TariffsList";
import ConfirmPayments from "./tariffs/components/ConfirmPayments";
import InvoiceForm from "./tariffs/components/InvoiceForm";
import InvoiceSuccessInfo from "./tariffs/components/InvoiceSuccessInfo";
import TariffTab from "./tariffs/TariffTab";

import MainTab from "./ProfileTab";
import ChangePasswordTab from "./ChangePasswordTab";
import VisiusDrawer from "../../components/Drawer";
import NotYetImplementedTab from "./NotImplementedTab";

import { PagesProps } from "../groups/Page";
import pageStyles from "../groups/components/pageStyles";
import VisiusAppBar from "../../components/AppBar";

import { ReactComponent as CameraIcon } from "../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../app/assets/webcamIcon.svg";
import { ReactComponent as HomeIcon } from "../../app/assets/homeIcon.svg";
import { ReactComponent as MovieIcon } from "../../app/assets/movieIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../app/assets/trashIcon.svg";
import { ReactComponent as AnalyticIcon } from "../../app/assets/analyticIconSideBar.svg";
import { Divider, IconButton, Typography } from "@material-ui/core";

import { ReactComponent as ArrowLeftIcon } from "../../app/assets/arrowLeft.svg";

type TabId = "main" | "payments" | "notifications" | "chpassw";
const tabs: TabId[] = ["main", "payments", "notifications", "chpassw"];

export type InnerId =
  | "current"
  | "tariffs"
  | "confirmPayment"
  | "invoiceForm"
  | "invoiceSuccess"
  | "unknown";

/**
 * Returns inner component in current tab ID based on
 *  - given list of possible tab IDs
 *
 * @param query
 */
const getTabInnerComponent = (query: URLSearchParams): InnerId => {
  const tabInnerQuery = query.get("inner");
  if (tabInnerQuery === null) {
    return "unknown";
  }

  return tabInnerQuery as InnerId;
};

const _getPageTitle = (currentTab: TabId, innerComponent: InnerId): string => {
  switch (innerComponent) {
    case "current":
    case "confirmPayment":
    case "invoiceForm":
    case "invoiceSuccess": {
      return I18N.profile.TITLE_PAYMENTS;
    }
    case "tariffs": {
      return I18N.profile.TITLE_TARIFF_SELECT;
    }
  }

  switch (currentTab) {
    case "main": {
      return I18N.profile.TITLE_PROFILE;
    }
    case "payments": {
      return I18N.profile.TITLE_PAYMENTS;
    }
    case "notifications": {
      return I18N.profile.TITLE_NOTIFICATIONS;
    }
    case "chpassw":
      return I18N.profile.TITLE_CHANGE_PASSWORD;
  }
};

const _getPageComponent = (
  currentTab: TabId,
  innerComponent: InnerId
): ReactElement => {
  switch (innerComponent) {
    case "current":
      return <TariffTab />;
    case "tariffs":
      return <TariffsList />;
    case "confirmPayment":
      return <ConfirmPayments />;
    case "invoiceForm":
      return <InvoiceForm />;
    case "invoiceSuccess":
      return <InvoiceSuccessInfo />;
  }

  switch (currentTab) {
    case "chpassw":
      return <ChangePasswordTab />;
    case "main":
      return <MainTab />;
    default: {
      return <NotYetImplementedTab />;
    }
  }
};

export default function ProfilePage(props: PagesProps): ReactElement {
  const layoutClasses = useAppLayoutStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const classes = pageStyles({ leftPanelIsOpen: props.leftPanelIsOpen });

  const query = new URLSearchParams(location.search);
  const tab = getCurrentTab(tabs, query);
  const innerComponent = getTabInnerComponent(query);
  const history = useHistory();

  const goBack = () => history.goBack();

  return (
    <div className={layoutClasses.root}>
      <VisiusAppBar
        title={t(_getPageTitle(tab, innerComponent))}
        leftPanelIsOpen={props.leftPanelIsOpen}
      />
      <VisiusDrawer
        items={[
          {
            title: I18N.common.LINK_HOME,
            link: "/",
            icon: <HomeIcon />,
          },
          {
            title: I18N.common.LINK_LOCATION,
            link: "/location",
            icon: <MovieIcon />,
          },
          {
            title: I18N.common.LINK_ANALYTICS,
            link: "/location/dashboard",
            icon: <AnalyticIcon />,
          },
          {
            title: I18N.main.LINK_ARCHIVE,
            link: "?tab=archive",
            icon: <ArchiveIcon />,
          },
          {
            title: I18N.main.LINK_TRASH,
            link: "?tab=trash",
            icon: <TrashIcon />,
          },
        ]}
        buttons={[
          {
            title: I18N.main.BTN_ADD_VIDEO,
            link: "/location?tab=video",
            icon: <CameraIcon />,
          },
          {
            title: I18N.main.BTN_ADD_STREAM,
            link: "/location?tab=stream",
            icon: <WebcamIcon />,
          },
        ]}
        open={props.leftPanelIsOpen}
        handleDrawerToggle={props.handleDrawerToggle}
      />
      <div className={layoutClasses.content}>
        <div className={layoutClasses.toolbar} />
        <main className={layoutClasses.profileCardContainer}>
          <div className={classes.header}>
            <div className={classes.headerWrapper} style={{ height: 36.5 }}>
              <IconButton
                onClick={goBack}
                color="primary"
                size="small"
                style={{ marginRight: 28 }}
              >
                <ArrowLeftIcon style={{ transform: "scale(1.25)" }} />
              </IconButton>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="h3"
                style={{ height: 33 }}
              >
                Настройки профиля
              </Typography>
            </div>
            <Divider className={classes.divider} />
          </div>
          {_getPageComponent(tab, innerComponent)}
        </main>
      </div>
    </div>
  );
}
