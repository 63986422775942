import { makeStyles, Theme } from "@material-ui/core";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import I18N from "../../../app/i18n/strings";

import { addGroup, addLocation } from "../../groups/api";
import { GroupId, LocationId } from "../../groups/data-types";
import { loadGroup } from "../../groups/main/videoSlice";
import {
  getGroupByLocationSelector,
  getLocationValuesSelector,
  selectGroupValues,
} from "../selectors";
import CreatableSelect from "../../../components/CreatableSelect";

import { ReactComponent as LocationIcon } from "../../../app/assets/locationIcon.svg";
import { ReactComponent as FolderIcon } from "../../../app/assets/folderIcon.svg";

interface LocationControlProps {
  locationId?: LocationId;
  onChange: (locationId?: LocationId) => void;
  className?: string;
  locationName?: string;
  isReplaceAction?: boolean;
  isLocationDisabled?: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    "& $controlsContainerWrap:first-of-type": {
      "& $icon path": {
        fill: theme.palette.primary.main,
      },
    },
    "& $controlsContainerWrap:last-of-type": {
      "& $icon path": {
        stroke: theme.palette.primary.main,
      },
    },
    "& $controlsContainerWrapFirst": {
      "& $icon path": {
        fill: theme.palette.primary.main,
      },
    },
    "& $controlsContainerWrapSecond": {
      "& $icon path": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  controlsContainerWrap: {
    marginRight: theme.spacing(4),
  },
  controlsContainerWrapFirst: {
    margin: 0,
  },
  controlsContainerWrapSecond: {
    marginTop: theme.spacing(2),
  },
  controlsContainer: {
    display: "flex",
    alignContent: "start",
    alignItems: "flex-start",
    flexGrow: 1,
    maxWidth: 356, // the value is taken from the UI design
  },
  control: {
    flexShrink: 0,
    flexGrow: 1,
    marginRight: theme.spacing(1),
    minWidth: 320, // the value should be enough for a placeholder

    "& .MuiFormLabel-root": {
      fontWeight: 400,
      fontSize: "14px",

      "&.Mui-disabled": {
        color: "#DFE6FB",
      },
    },

    "& .MuiInputBase-root": {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      background: theme.palette.common.white,
      padding: "20px",

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "&.Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid #2C67FF",
      },

      "& fieldset": {
        border: "none ",
      },
    },
  },
  icon: {
    marginTop: theme.spacing(1.5),
    marginRight: 18,
    marginBottom: theme.spacing(0.75),
    fontSize: 24,
    transform: "scale(2)",
    display: "flex",
  },

  listbox: {
    background: "red",
    backgroundRadius: "16px",
  },
}));

export default function LocationControl(
  props: LocationControlProps
): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId, className, onChange, isLocationDisabled } = props;
  const [locationError, setLocationError] = useState<string>();
  const [groupError, setGroupError] = useState<string>();

  // load Groups list
  const groups = useSelector(selectGroupValues);

  // load initial Group
  const selectInitialGroup = useMemo(
    () =>
      locationId !== undefined
        ? getGroupByLocationSelector(locationId)
        : () => undefined,
    [locationId]
  );
  const initialGroup = useSelector(selectInitialGroup);
  const [groupId, setGroupId] = useState<GroupId | undefined>(initialGroup?.id);

  // load current group's Locations
  const selectLocations = useMemo(() => {
    if (groupId === undefined) {
      return () => [];
    }
    return getLocationValuesSelector(groupId);
  }, [groupId]);
  const locations = useSelector(selectLocations);

  // unset Location Select value when Group has been changed
  const verifiedLocationId =
    locations.findIndex(({ id }) => locationId === id) !== -1
      ? locationId
      : undefined;

  const selectedLocation = locations.find((item) => {
    if (verifiedLocationId !== undefined && verifiedLocationId === item.id) {
      return item;
    }
    return false;
  });

  const isDemoLocation =
    selectedLocation?.videos === 10 &&
    selectedLocation?.videoFilesSize === 9328394240;

  useEffect(() => {
    if (isLocationDisabled) {
      isLocationDisabled(isDemoLocation);
    }
  }, [isDemoLocation, verifiedLocationId]);

  const handleGroupCreate = useCallback(
    async (title: string) => {
      try {
        const groupDto = await addGroup(title);
        await dispatch(loadGroup(groupDto.id));
        setGroupId(groupDto.id);
        onChange();
      } catch (err) {
        setGroupError(err.message);
      }
    },
    [dispatch, setGroupId, onChange]
  );

  const handleLocationCreate = useCallback(
    async (title: string) => {
      try {
        const verifiedGroupId = groupId as GroupId;
        const locationDto = await addLocation(verifiedGroupId, title);
        await dispatch(loadGroup(verifiedGroupId));
        onChange(locationDto.id);
      } catch (err) {
        setLocationError(err.message);
      }
    },
    [dispatch, onChange, groupId]
  );

  const handleGroupChange = useCallback(
    (groupId: GroupId) => {
      setGroupId(groupId);
      onChange();
    },
    [onChange, setGroupId]
  );

  const handleGroupErrorReset = useCallback(
    () => setGroupError(undefined),
    [setGroupError]
  );
  const handleLocationErrorReset = useCallback(
    () => setLocationError(undefined),
    [setLocationError]
  );

  // in case Groups data isn't available from start,
  // set group ID once Groups data has been loaded
  useEffect(() => {
    if (groupId === undefined && initialGroup !== undefined) {
      setGroupId(initialGroup.id);
    }
  }, [setGroupId, groupId, initialGroup]);

  return (
    <div
      className={`${className} ${classes.content}`}
      style={{ display: "flex" }}
    >
      <div
        className={
          className !== undefined
            ? classes.controlsContainerWrapFirst
            : classes.controlsContainerWrap
        }
      >
        <div className={classes.controlsContainer}>
          <FolderIcon className={classes.icon} />
          <CreatableSelect
            className={classes.control}
            value={groupId}
            options={groups}
            onChange={handleGroupChange}
            resetError={handleGroupErrorReset}
            onCreate={handleGroupCreate}
            title={t(I18N.location.SELECT_GROUPS)}
            placeHolder={t(I18N.location.MSG_SELECT_GROUP)}
            createText={t(I18N.location.MSG_CREATE_GROUP)}
            noOptionsText={t(I18N.location.MSG_SELECT_EMPTY_GROUPS)}
            error={groupError}
          />
        </div>
      </div>

      <div
        className={
          className !== undefined
            ? classes.controlsContainerWrapSecond
            : classes.controlsContainerWrap
        }
      >
        <div className={classes.controlsContainer}>
          <LocationIcon className={classes.icon} />
          <CreatableSelect
            className={classes.control}
            disabled={groupId === undefined}
            value={verifiedLocationId}
            options={locations}
            onChange={onChange}
            resetError={handleLocationErrorReset}
            onCreate={handleLocationCreate}
            title={t(I18N.location.SELECT_LOCATION)}
            placeHolder={t(I18N.location.MSG_SELECT_LOCATION)}
            createText={t(I18N.location.MSG_CREATE_LOCATION)}
            noOptionsText={t(I18N.location.MSG_SELECT_EMPTY_LOCATIONS)}
            error={locationError}
          />
        </div>
      </div>
    </div>
  );
}
