import { Moment } from 'moment'
import { HTTP_METHOD, request } from '../../app/utils'
import { ISODateString, LocationId } from '../groups/data-types'
import {
  StreamData,
  StreamDto,
  streamDtoToStream,
  StreamId,
  VideoData,
  videoDtoToData,
  VideoFileDto,
  VideoId
} from './data-mapper'
import { Line } from './line-editor/components/DrawLine'

export enum LOCATION_URL {
  PAGE = '/location/{id}',
  LOCATION_VIDEOS = '/api/video-files/by-location-id/{id}',
  LOCATION_STREAMS = '/api/video-streams/by-location-id/{id}',
  DASHBOARD = '/api/dashboard',
  DASHBOARD_SUBSCRIBE = '/api/dashboard/{streamId}',
  START_MEDIA_PROCESSING = '/api/video-processing-request/start/{streamId}',
  STOP_MEDIA_PROCESSING = '/api/video-processing-request/stop/{streamId}'
}

export enum VIDEO_URL {
  FILES = '/api/video-files',
  FILE = '/api/video-files/{id}'
}

export enum STREAM_URL {
  STREAMS = '/api/video-streams',
  STREAM = '/api/video-streams/{id}'
}

export interface VideosResponse {
  count: number
  total: number
  entities: VideoFileDto[]
}

export interface StreamsResponse {
  count: number
  total: number
  entities: StreamDto[]
}

interface VideoFileCreateOptions {
  file: File
  date: Moment
  locationId: LocationId
  height?: number
  width?: number
}

export interface VideoFileCreateDto extends Record<string, unknown> {
  fileName: string
  startVideoDateTime: ISODateString
  locationId: LocationId
  width?: number
  height?: number
}

export interface VideoFileCreateResponse {
  data: VideoFileDto
  sasUrl: string
}

export interface StreamUpdateOptions {
  locationId: LocationId
  streamId: StreamId
  line: Line
  timeZone: string
}

export interface VideoFileUpdateOptions {
  locationId: LocationId
  videoId: VideoId
  line: Line
  autoStart?: boolean
}

export interface StreamUpdateDto extends Record<string, unknown> {
  locationId: LocationId
  countingLine: {
    x1: number
    x2: number
    y1: number
    y2: number
  }
}

export interface VideoFileUpdateDto extends Record<string, unknown> {
  locationId: LocationId
  countingLine: {
    x1: number
    x2: number
    y1: number
    y2: number
  }
  isAutoAnalysisEnabled?: boolean
}

export interface VideoFileMoveDto extends Record<string, unknown> {
  locationId: LocationId
}

export interface StreamCreateDto {
  locationId: LocationId
  url: string
  timeZone: string
}

/**
 * Request video processing to be started
 * @param {VideoId|StreamId} id - source media ID
 */
export async function startProcessing (id: VideoId|StreamId): Promise<void> {
  await request(LOCATION_URL.START_MEDIA_PROCESSING.replace('{streamId}', id), {
    raw: true,
    method: HTTP_METHOD.POST
  })
}

export async function stopProcessing (id: VideoId|StreamId): Promise<void> {
  await request(LOCATION_URL.STOP_MEDIA_PROCESSING.replace('{streamId}', id), {
    raw: true,
    method: HTTP_METHOD.PUT
  })
}

export async function subscribeToDashboard (id: VideoId|StreamId): Promise<void> {
  await request(LOCATION_URL.DASHBOARD_SUBSCRIBE.replace('{streamId}', id), { raw: true })
}

export async function unsubscribeFromDashboard (ids: Array<VideoId|StreamId>): Promise<void> {
  const query = new URLSearchParams()
  for (const id of ids) {
    query.append('streamIds', id)
  }
  await request(`${LOCATION_URL.DASHBOARD}?${query.toString()}`, {
    raw: true,
    method: HTTP_METHOD.DELETE,
    keepalive: true
  })
}

export async function loadVideo (videoId: VideoId): Promise<{ locationId: LocationId, video: VideoData }> {
  const url = VIDEO_URL.FILE.replace('{id}', videoId)
  const fileDto = await request(url) as VideoFileDto
  return { locationId: fileDto.locationId, video: videoDtoToData(fileDto) }
}

export async function loadStream (streamId: StreamId): Promise<{ locationId: LocationId, stream: StreamData }> {
  const url = STREAM_URL.STREAM.replace('{id}', streamId)
  const streamDto = await request(url) as StreamDto
  return { locationId: streamDto.locationId, stream: streamDtoToStream(streamDto) }
}

/**
 * Loads videos by location ID
 */
export async function * loadVideos (locationId: LocationId, chunkSize = 25): AsyncGenerator<VideoData[]> {
  let offset = 0
  do {
    const query = new URLSearchParams({
      offset: offset.toString(),
      count: chunkSize.toString()
    })
    const baseUrl = LOCATION_URL.LOCATION_VIDEOS.replace('{id}', locationId.toString())
    const url = `${baseUrl}?${query.toString()}`
    const { entities: videos, count, total } = await request(url) as VideosResponse

    yield videos.map(videoDtoToData)
    if (count + offset >= total) {
      return
    }

    offset += count
  } while (true)
}

/**
 * Loads streams by location ID
 */
export async function * loadStreams (locationId: LocationId, chunkSize = 25): AsyncGenerator<StreamData[]> {
  let offset = 0
  do {
    const query = new URLSearchParams({
      offset: offset.toString(),
      count: chunkSize.toString()
    })
    const baseUrl = LOCATION_URL.LOCATION_STREAMS.replace('{id}', locationId.toString())
    const url = `${baseUrl}?${query.toString()}`
    const { entities: streams, count, total } = await request(url) as StreamsResponse

    yield streams.map(streamDtoToStream)
    if (count + offset >= total) {
      return
    }

    offset += count
  } while (true)
}

export async function createStream ({ locationId, url, timeZone }: StreamCreateDto): Promise<StreamDto> {
  const response = await request(STREAM_URL.STREAMS, {
    method: HTTP_METHOD.POST,
    data: {
      ipAddress: url,
      locationId,
      dateTimeZoneId: timeZone
    }
  }) as StreamDto

  return response
}

export async function createVideo ({ file, date, locationId, width, height }: VideoFileCreateOptions): Promise<VideoFileCreateResponse> {
  const fileCreateDto: VideoFileCreateDto = {
    fileName: file.name,
    startVideoDateTime: (date).format(),
    locationId: locationId,
    width: width,
    height: height
  }

  const response = await request(VIDEO_URL.FILES, {
    method: HTTP_METHOD.POST,
    data: fileCreateDto
  }) as VideoFileCreateResponse

  return response
}

export async function deleteVideos (videoIds: VideoId[]): Promise<void> {
  const query = videoIds.reduce((query, videoId) => {
    return query + `${query === '' ? '?' : '&'}id=${videoId}`
  }, '')
  await request(`${VIDEO_URL.FILES}${query}`, {
    method: HTTP_METHOD.DELETE
  })
}

export async function deleteStream (streamIds: StreamId[]): Promise<void> {
  const query = streamIds.reduce((query, streamId) => {
    query.append('id', streamId)
    return query
  }, new URLSearchParams())
  await request(`${STREAM_URL.STREAMS}?${query.toString()}`, {
    method: HTTP_METHOD.DELETE
  })
}

export async function moveVideos (videoIds: VideoId[], locationIdReplacing: LocationId): Promise<void> {
  const moveVideos: VideoFileMoveDto = {
    locationId: locationIdReplacing
  }
  for (const id of videoIds) {
    await request(VIDEO_URL.FILE.replace('{id}', id), {
      method: HTTP_METHOD.PUT,
      data: moveVideos
    })
  }
}

/**
 * Returns left/top point in a given line
 *
 * @param line - line (containing two points)
 */
const getLeftTopPoint = (line: Line): number => {
  // return the left most point
  if (line[0].x < line[1].x) {
    return 0
  }

  if (line[0].x > line[1].x) {
    return 1
  }

  // in case points have the same X coordinate
  // return the one with lower Y value
  if (line[0].y < line[1].y) {
    return 0
  }

  return 1
}

export async function updateStream ({ locationId, streamId, line, timeZone }: StreamUpdateOptions): Promise<void> {
  const leftTopPoint = getLeftTopPoint(line)
  const rightBottomPoint = leftTopPoint === 0 ? 1 : 0
  const streamUpdateDto: StreamUpdateDto = {
    locationId: locationId,
    countingLine: {
      x1: line[leftTopPoint].x,
      x2: line[rightBottomPoint].x,
      y1: line[leftTopPoint].y,
      y2: line[rightBottomPoint].y
    },
    DateTimeZoneId: timeZone
  }

  await request(STREAM_URL.STREAM.replace('{id}', streamId), {
    method: HTTP_METHOD.PUT,
    data: streamUpdateDto
  })
}

export async function updateVideo ({ locationId, videoId, line, autoStart }: VideoFileUpdateOptions): Promise<void> {
  const leftTopPoint = getLeftTopPoint(line)
  const rightBottomPoint = leftTopPoint === 0 ? 1 : 0
  const fileUpdateDto: VideoFileUpdateDto = {
    locationId: locationId,
    countingLine: {
      x1: line[leftTopPoint].x,
      x2: line[rightBottomPoint].x,
      y1: line[leftTopPoint].y,
      y2: line[rightBottomPoint].y
    },
    isAutoAnalysisEnabled: autoStart
  }

  await request(VIDEO_URL.FILE.replace('{id}', videoId), {
    method: HTTP_METHOD.PUT,
    data: fileUpdateDto
  })
}
