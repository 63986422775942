import { Moment } from 'moment'

export enum TARIFF_STATE {
  NONE, // Tariffs isn't loading
  LOADING_DATA, // Querying the current user tariff or tariffs from the API server
  DATA_LOADED, // Querying the current user tariff or tariffs from the API server ended
  ERROR // Error in current user tariff or tariffs loading
}

export enum INVOICE_FORM_STATE {
  NONE,
  STARTED,
  FAILED,
  SAVED
}

export type TariffId = string
export type UserId = string

export interface TariffData {
  id: number
  tariffSnapshotId: string
  name: string
  isSystem: boolean
  maxHours: number
  purchase: number
}

export interface UserTariffData {
  id: TariffId
  name: string
  lastPaymentDate: Moment |null
  maxHours: number
  purchase: number
  timeBalanceHour: number
  timeBalanceString: string
  timeBalancePercantage: number
  isSystem: boolean
}

export interface TariffsState {
  billingUrl: string | undefined
  tariffState: TARIFF_STATE
  tariffListState: TARIFF_STATE
  invoiceFormState: INVOICE_FORM_STATE
  currentTariff?: UserTariffData
  tariffs?: TariffData[]
  selectedTariff?: TariffData
  error?: string
}
