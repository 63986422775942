import { makeStyles, Theme } from '@material-ui/core'
import { COLORS } from '../../../theme'
import { MAX_CARD_WIDTH } from '../../dashboard/Page'

export const MAX_TARIFF_CARD_WIDTH = 576

export default makeStyles((theme: Theme) => ({
  card: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: MAX_CARD_WIDTH,

    '&.fixed': {
      flexGrow: 0,
      flexShrink: 0
    },

    '&.full-width': {
      flexBasis: '100%'
    },

    '&.fit': {
      flexBasis: `calc(100% - ${MAX_CARD_WIDTH + theme.spacing(4)}px)`,

      '&.wide': {
        minWidth: 700
      }
    }
  },
  cardWrap: {
    boxShadow: 'none',
    borderRadius: 8,
    border: '1px solid',
    borderColor: COLORS.GREY2,
    height: '100%',

    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(6)
    }
  },
  innerCard: {
    padding: theme.spacing(6)
  },
  grid: {
    display: 'flex'
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  title: {
    marginBottom: theme.spacing(6.5)
  },
  leftBtn: {
    marginLeft: 0
  }
}))
