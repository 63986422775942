import React, { ReactElement, useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { d3locale } from "../app/i18n/d3";
import { VISIUS_COLORS } from "../app/styles";

const formatPercentage = d3locale.format(",.2~r");

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  info: {
    color: VISIUS_COLORS.INFO,
  },
  error: {
    color: VISIUS_COLORS.ERROR,
  },
  success: {
    color: VISIUS_COLORS.SUCCESS,
  },
  warn: {
    color: VISIUS_COLORS.WARN,
  },
  billing: {
    color: theme.palette.primary.main,
  },
  background: {
    position: "absolute",
    "&$info": {
      color: VISIUS_COLORS.INFO_BACKGROUND,
    },
    "&$warn": {
      color: VISIUS_COLORS.WARN_BACKGROUND,
    },
    "&$success": {
      color: VISIUS_COLORS.SUCCESS_BACKGROUND,
    },
    "&$error": {
      color: VISIUS_COLORS.ERROR_BACKGROUND,
    },
    "&$billing": {
      color: VISIUS_COLORS.INFO_BACKGROUND,
    },
  },
  foreground: {},
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: theme.spacing(2),
  },
}));

interface ProgressProps extends CircularProgressProps {
  viewValue?: number;
  title?: string;
  subtitle?: string;
  type?: "error" | "warn" | "success" | "info" | "billing";
  titleClass?: string;
  subtitleClass?: string;
}

const INDETERMINATE_PROGRESS_STEP = 10;
const INDETERMINATE_PROGRESS_TIMEOUT_MS = 1500;
const MAX_PROGRESS = 100;

export default function VisiusCircularProgress(
  props: ProgressProps
): ReactElement {
  const classes = useStyles();
  const {
    title,
    subtitle,
    value,
    viewValue,
    type = "info",
    variant,
    titleClass,
    subtitleClass,
    ...progressProps
  } = props;
  const className = classes[type];

  // Material UI indeterminate progress bars rely on JS-based animations that very CPU-expensive.
  // As a tmp solution we are using determinate progress bar with a value being changed
  // at certain time intervals
  const [progressValue, setProgressValue] = useState(value ?? 0);
  useEffect(() => {
    if (variant === "determinate") {
      return setProgressValue(value ?? 0);
    }

    const indeterminateTimeout = setTimeout(() => {
      const nextProgressValue = progressValue + INDETERMINATE_PROGRESS_STEP;
      setProgressValue(
        nextProgressValue >= MAX_PROGRESS
          ? INDETERMINATE_PROGRESS_STEP
          : nextProgressValue
      );
    }, INDETERMINATE_PROGRESS_TIMEOUT_MS);

    return () => clearTimeout(indeterminateTimeout);
  }, [setProgressValue, progressValue, value, variant]);

  return (
    <div className={classes.container}>
      <Box
        position="relative"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress
          variant="determinate"
          value={100}
          className={`${classes.background} ${className}`}
          {...progressProps}
        />
        <CircularProgress
          variant="determinate"
          className={`${classes.foreground} ${className}`}
          value={progressValue}
          {...progressProps}
        />
        {value !== undefined && (
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="body2"
              component="div"
              className={titleClass}
              style={{
                color: viewValue === 0 ? "#FF3D00" : "",
                fontWeight: 700,
                fontSize: 10,
              }}
            >
              {viewValue ?? `${formatPercentage(value)}%`}
            </Typography>
          </Box>
        )}
      </Box>
      {title !== undefined && (
        <div className={classes.titleContainer}>
          <Typography variant="body2" className={titleClass}>
            {title}
          </Typography>
          <Typography
            style={{ color: viewValue === 0 ? "#FF3D00" : "" }}
            variant="caption"
            color="textSecondary"
            className={subtitleClass}
          >
            {subtitle}
          </Typography>
        </div>
      )}
    </div>
  );
}
