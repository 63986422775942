import { Button, Tab, Tabs, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import I18N from "../../../app/i18n/strings";
import { useAppLayoutStyles } from "../../../app/styles";
import { hasCloneVideo, isValidFileType } from "../../../app/utils";
import Link from "../../../components/Link";
import { LocationId } from "../../groups/data-types";
import { UPLOAD_STATE, VIDEO_STATE, VideoData } from "../data-mapper";
import { Line } from "../line-editor/components/DrawLine";
import {
  actions,
  getUploadId,
  startProcessing,
  uploadFiles,
} from "../locationSlice";
import { getUploadsSelector, getVideosSelector } from "../selectors";
import useStyles from "../styles";
import UploadsList from "./components/UploadsList";
import VideosList from "./components/VideosList";
import WorkFlow from "../workflow/WorkFlow";
import { TabId } from "../Page";

import { ReactComponent as CameraIcon } from "../../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../../app/assets/webcamIcon.svg";

interface VideosTabProps {
  locationId?: LocationId;
  showTitle: boolean;
  isContentLoaded?: boolean;
  currentTab: TabId;
  visibleTabs: TabId[];
  handleTabChange?: (evt: any, tabIndex: number) => void;
}

/**
 * The component provides two lists:
 *  - Videos: files that have already been uploaded or at least registered at the backend
 *  - Uploads: files that have just been selected by the User, the backend doesn't know about these yet
 *
 * Navigation:
 *  - LineEditorTab is available if there are uploaded video files
 */
export default function VideosTab(props: VideosTabProps): ReactElement {
  const {
    locationId,
    showTitle,
    isContentLoaded,
    currentTab,
    visibleTabs,
    handleTabChange,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const appClasses = useAppLayoutStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectVideos = useMemo(
    () => getVideosSelector(locationId),
    [locationId]
  );
  const selectUploads = useMemo(
    () => getUploadsSelector(locationId),
    [locationId]
  );
  const videos = useSelector(selectVideos);
  const uploads = useSelector(selectUploads);

  const isNotDemoLocation = useMemo(() => !hasCloneVideo(videos), [videos]);

  /*
   * UI event handlers
   *
   * It's only possible to attach files when locationId is set,
   * so `locationId as LocationId` is valid here
   */
  const handleAddFiles = useCallback(() => {
    if (inputRef.current === null) {
      return;
    }
    inputRef.current.click();
  }, [inputRef]);

  const handleRemove = useCallback(
    (file: File) => {
      dispatch(
        actions.removeUpload({
          key: getUploadId(file),
          locationId: locationId as LocationId,
        })
      );
    },
    [dispatch, locationId]
  );

  const handleFilesSelected = useCallback(
    (evt: ChangeEvent) => {
      const inputFiles = (evt.target as HTMLInputElement).files;
      if (inputFiles === null || inputFiles.length === 0) {
        return;
      }

      dispatch(
        actions.setUploads({
          locationId: locationId as LocationId,
          files: Array.from(inputFiles),
        })
      );
      // reset <input> value to ensure that file-selected event is triggered next time
      // regardless of the files selected
      (evt.target as HTMLInputElement).value = "";
    },
    [dispatch, locationId]
  );

  const handleUpload = useCallback(async () => {
    dispatch(uploadFiles(locationId as LocationId));
  }, [dispatch, locationId]);

  // Not yet started uploads are displayed in a separate list
  const visibleUploads = uploads.filter(({ fileId }) => fileId === undefined);
  const showUploadsList = visibleUploads.length > 0;

  const nextStepControl = useMemo((): ReactElement | null => {
    if (showUploadsList) {
      const isUploadDisabled =
        uploads.findIndex(({ date, state, file }) => {
          return (
            date === null ||
            !date.isValid() ||
            state === UPLOAD_STATE.CREATING ||
            !isValidFileType(file)
          );
        }) !== -1;
      return (
        <div className={classes.steps}>
          <div className={classes.contentBlock} style={{ display: "flex" }}>
            <Button
              color="primary"
              disabled={isUploadDisabled}
              onClick={handleUpload}
              variant="contained"
              className={classes.mainBtn}
            >
              {t(I18N.location.BTN_STEP_UPLOAD)}
            </Button>
            <Typography
              variant="body2"
              className={`${classes.paragraph} ${classes.nextStepParagraph}`}
            >
              {t(I18N.location.MSG_SET_DATE_TIME)}
            </Typography>
          </div>
        </div>
      );
    }

    const videoWithLine = videos.find(({ line }) => line !== undefined);
    const videoWithoutLine = videos.find(
      ({ state, line }) => state === VIDEO_STATE.UPLOADED && line === undefined
    );
    if (videos.length > 0 && videoWithLine === undefined && isNotDemoLocation) {
      const editLineAvailable =
        videoWithLine === undefined && videoWithoutLine !== undefined;

      const btn = (
        <Button
          color="primary"
          variant="contained"
          disabled={!editLineAvailable}
          className={classes.mainBtn}
        >
          {t(I18N.location.BTN_STEP_DRAW_LINE)}
        </Button>
      );
      return (
        <div className={classes.steps}>
          <div className={classes.contentBlock}>
            {editLineAvailable ? (
              <Link
                to={`?tab=line&video=${(videoWithoutLine as VideoData).id}`}
                className={appClasses.linkButton}
              >
                {btn}
              </Link>
            ) : (
              <>
                <Typography
                  variant="body2"
                  className={`${classes.paragraph} ${classes.nextStepParagraph}`}
                >
                  {showUploadsList
                    ? t(I18N.location.MSG_SET_DATE_TIME)
                    : t(I18N.location.MSG_WAIT_UPLOADS)}
                </Typography>
                {btn}
              </>
            )}
          </div>
        </div>
      );
    }

    // TODO: show button to start processing of videos without lines.
    // once video processing autostart is implemented it would be possible to get rid of this case
    const pendingVideo = videos.find(
      ({ state, line }) => state === VIDEO_STATE.UPLOADING && line === undefined
    );
    if (
      videoWithLine !== undefined &&
      (videoWithoutLine !== undefined || pendingVideo !== undefined)
    ) {
      const handleStartProcessing = (): void => {
        dispatch(
          startProcessing({
            locationId: locationId as LocationId,
            line: videoWithLine.line as Line,
            videoId: (videoWithoutLine as VideoData).id,
          })
        );
      };

      return (
        <div className={classes.steps}>
          <div className={classes.contentBlock}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleStartProcessing}
              className={classes.mainBtn}
              disabled={videoWithoutLine === undefined}
            >
              {t(I18N.location.BTN_STEP_START_ANALYSIS)}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }, [
    t,
    handleUpload,
    dispatch,
    appClasses,
    classes,
    uploads,
    videos,
    showUploadsList,
    locationId,
    isNotDemoLocation,
  ]);

  const content = useMemo((): ReactElement => {
    const showVideosList = videos.length > 0;
    const addFilesButton = (
      <Button
        color="primary"
        disabled={locationId === undefined}
        onClick={handleAddFiles}
        startIcon={<Add />}
        variant="text"
      >
        {t(I18N.location.BTN_ADD_FILES)}
      </Button>
    );

    if (locationId === undefined || (!showVideosList && !showUploadsList)) {
      return (
        <>
          <div className={classes.workFlowWrap}>
            {isContentLoaded && <WorkFlow locationId={locationId} />}
          </div>
          {!showTitle && (
            <Tabs
              value={visibleTabs.indexOf(currentTab)}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                key="video"
                className={classes.tab}
                icon={<CameraIcon />}
                label={<span>{t(I18N.location.TAB_FILES)}</span>}
              />
              <Tab
                key="stream"
                className={classes.tab}
                label={t(I18N.location.TAB_STREAMS)}
                icon={<WebcamIcon />}
              />
            </Tabs>
          )}
          <Typography
            className={classes.contentBlock}
            variant="body2"
            color={locationId === undefined ? "textPrimary" : "textSecondary"}
          >
            {t(
              locationId === undefined
                ? I18N.location.MSG_LOCATION_REQUIRED
                : I18N.location.MSG_EMPTY
            )}
          </Typography>
          {/* disabled upload button: just a UI element */}
          <div className={classes.contentBlock}>{addFilesButton}</div>
        </>
      );
    }

    return (
      <>
        <div className={classes.workFlowWrap}>
          {isContentLoaded && <WorkFlow locationId={locationId} />}
        </div>
        {showTitle && (
          <div className={classes.header}>
            <CameraIcon />
            <Typography variant="h4">{t(I18N.location.TITLE_VIDEO)}</Typography>
          </div>
        )}
        {!showTitle && (
          <Tabs
            value={visibleTabs.indexOf(currentTab)}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              key="video"
              className={classes.tab}
              icon={<CameraIcon />}
              label={<span>{t(I18N.location.TAB_FILES)}</span>}
            />
            <Tab
              key="stream"
              className={classes.tab}
              label={t(I18N.location.TAB_STREAMS)}
              icon={<WebcamIcon />}
            />
          </Tabs>
        )}

        {showVideosList && (
          <div className={classes.contentBlock}>
            <VideosList
              videos={videos}
              uploads={uploads}
              locationId={locationId}
            />
          </div>
        )}

        {showUploadsList && (
          <div className={classes.contentBlock}>
            <UploadsList
              uploads={visibleUploads}
              locationId={locationId}
              onRemove={handleRemove}
            />
          </div>
        )}

        <div className={classes.contentBlock}>
          {isNotDemoLocation && addFilesButton}
        </div>
      </>
    );
  }, [
    t,
    handleAddFiles,
    handleRemove,
    classes,
    locationId,
    showUploadsList,
    visibleUploads,
    uploads,
    videos,
    isNotDemoLocation,
  ]);

  return (
    <>
      {content}
      {nextStepControl}
      <input
        multiple
        onChange={handleFilesSelected}
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept=".flv,
        .mxf, .gxf, .ts, .ps, .3gp, .3gpp, .mpg,
        .wmv, .asf, .avi, .isma, .ismv, .dvr-ms, .mkv,
        .wav, .mov, .mp4, .m4a, .m4v"
      />
    </>
  );
}
