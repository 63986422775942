import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import { SliceName } from "../data-types";
import { useLocationCardStyles } from "./BaseLocationCard";

import { ReactComponent as PenIcon } from "../../../app/assets/pen.svg";
import { ReactComponent as ArchiveIcon } from "../../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../../app/assets/trashIcon.svg";
import { ReactComponent as OpenedEyeIcon } from "../../../app/assets/openedEye.svg";
import { ReactComponent as ClosedEyeIcon } from "../../../app/assets/closedEye.svg";
import { ReactComponent as RecoverIcon } from "../../../app/assets/recoverDataIcon.svg";

export interface MouseState {
  mouseX?: number;
  mouseY?: number;
}

type Callback = () => void;
interface GroupMenuProps {
  expanded: boolean;
  mouseState: MouseState;
  variant: SliceName;
  onExpand: Callback;
  onClose: Callback;

  onArchive?: Callback;
  onRename?: Callback;
  onRestore?: Callback;
  onDelete?: Callback;
}
export default function GroupContextMenu(props: GroupMenuProps): ReactElement {
  const { t } = useTranslation();
  const classes = useLocationCardStyles();

  const isArchive = props.variant === "archive";
  const isTrash = props.variant === "trash";
  const isMain = props.variant === "videoData";
  return (
    <Menu
      className={classes.menuContainer}
      keepMounted
      open={props.mouseState.mouseY !== undefined}
      onClose={props.onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        props.mouseState.mouseY !== undefined &&
        props.mouseState.mouseX !== undefined
          ? {
              top: props.mouseState.mouseY,
              left: props.mouseState.mouseX,
            }
          : undefined
      }
    >
      <MenuItem key="toggle" onClick={props.onExpand}>
        <ListItemIcon>
          {props.expanded ? <ClosedEyeIcon /> : <OpenedEyeIcon />}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            props.expanded ? (
              <Typography variant="caption">
                {t(I18N.main.BTN_HIDE_GROUP)}
              </Typography>
            ) : (
              <Typography variant="caption">
                {t(I18N.main.BTN_SHOW_GROUP)}
              </Typography>
            )
          }
        />
      </MenuItem>
      {isMain && (
        <MenuItem key="rename" onClick={props.onRename}>
          <ListItemIcon>
            <PenIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="caption">
                {t(I18N.main.BTN_RENAME_GROUP)}
              </Typography>
            }
          />
        </MenuItem>
      )}
      {isMain && (
        <MenuItem key="archive" onClick={props.onArchive}>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="caption">
                {t(I18N.main.BTN_ARCHIVE_GROUP)}
              </Typography>
            }
          />
        </MenuItem>
      )}
      {!isMain && (
        <MenuItem key="restore" onClick={props.onRestore}>
          <ListItemIcon>
            {isArchive ? <RecoverIcon /> : <RecoverIcon />}
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="caption">
                {t(I18N.main.BTN_RESTORE_LOCATION)}
              </Typography>
            }
          />
        </MenuItem>
      )}
      <MenuItem key="delete" onClick={props.onDelete}>
        <ListItemIcon>
          <TrashIcon />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            isTrash ? (
              <Typography variant="caption">
                {t(I18N.main.BTN_DELETE_GROUP)}
              </Typography>
            ) : (
              <Typography variant="caption">
                {t(I18N.main.BTN_TRASH_GROUP)}
              </Typography>
            )
          }
        />
      </MenuItem>
    </Menu>
  );
}
