import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ListItemText, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import I18N from "../app/i18n/strings";

interface SelectOption {
  id?: string;
  title: string;
}

interface SelectProps {
  className?: string;
  createText?: string;
  disabled?: boolean;
  error?: string;
  noOptionsText?: string;
  options: SelectOption[];
  placeHolder?: string;
  title?: string;
  value?: string;
  onCreate?: (title: string) => void;
  withoutLabel?: boolean;
  onChange: (value: string) => void;
  resetError: () => void;
}

const useStyles = makeStyles({
  paper: {
    borderRadius: "16px",
    background: "#FFFFFF",
  },
  listbox: {
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
      color: "#676666",
    },
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z')",
          transform: "translate(4px, 8px)",
        },
      },
    },
  },
});

export default function CreatableSelect(props: SelectProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const {
    className,
    createText,
    disabled,
    error,
    noOptionsText,
    options,
    placeHolder,
    value,

    onChange,
    onCreate,
    resetError,
  } = props;

  const handleChange = useCallback(
    async (event, newValue: SelectOption | string | null) => {
      if (newValue === null) {
        return;
      }

      if (typeof newValue === "string" || newValue.id === undefined) {
        if (onCreate === undefined) {
          return;
        }
        const title = typeof newValue === "string" ? newValue : newValue.title;

        setLoading(true);
        await onCreate(title);
        setLoading(false);
        return;
      }

      onChange(newValue.id);
    },
    [onChange, onCreate]
  );

  const handleInputChange = useCallback(
    (_, newInputValue: string) => {
      resetError();
      setInputValue(newInputValue);
    },
    [resetError, setInputValue]
  );

  const handleFilter = useCallback(
    (options: SelectOption[], params: { inputValue: string }) => {
      const input = params.inputValue.trim();
      const filtered = options.filter((group) => {
        const title = group.title.toLowerCase().trim();
        return title.includes(input.toLowerCase());
      });

      if (!loading && filtered.length === 0 && input !== "") {
        filtered.push({
          title: input,
        });
      }
      return filtered;
    },
    [loading]
  );

  const optionRenderer = useCallback(
    (option: SelectOption) => {
      if (option.id !== undefined) {
        return <ListItemText primary={option.title} />;
      }

      return <ListItemText primary={option.title} secondary={createText} />;
    },
    [createText]
  );

  const sortedOptions = useMemo(() => {
    return options.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  }, [options]);

  const selectedOption = options.find(({ id }) => id === value) ?? null;
  return (
    <Autocomplete
      autoHighlight
      className={className}
      disabled={disabled === true || loading}
      filterOptions={handleFilter}
      classes={{
        listbox: classes.listbox,
        paper: classes.paper,
        popupIndicator: classes.popupIndicator,
      }}
      forcePopupIcon
      freeSolo
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.title
      }
      inputValue={inputValue}
      loading={loading}
      loadingText={t(I18N.location.MSG_WAIT_CREATION)}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={sortedOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!inputHasFocus && inputValue === "" ? placeHolder : ""}
          variant="outlined"
          error={error !== undefined}
          helperText={error}
          onFocus={() => setInputHasFocus(true)}
          onBlur={() => {
            setInputHasFocus(false);
            if (selectedOption !== null) {
              setInputValue(selectedOption.title);
            }
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
      renderOption={optionRenderer}
      selectOnFocus
      value={options.find(({ id }) => id === value) ?? null}
    />
  );
}
