import React, { ReactElement } from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "transparent",

    "& .MuiCardContent-root": {
      padding: theme.spacing(4),
    },
  },
}));

interface MsgProps {
  message: string;
}

export default function EmptyMsgCard(props: MsgProps): ReactElement {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <CardContent>
        <Typography style={{ textAlign: "center" }} color="textSecondary">
          {props.message}
        </Typography>
      </CardContent>
    </Card>
  );
}
