import { Button, Card, CardContent, InputLabel, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import React, {
  ReactElement, useEffect, useMemo
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { actions, selectUserTariff } from './tariffSlice'

import { Cached, Update } from '@material-ui/icons'

import { useTranslation } from 'react-i18next'
import I18N from '../../../app/i18n/strings'
import Link from '../../../components/Link'
import pageStyles from '../../groups/components/pageStyles'
import tariffStyles from './tariffStyles'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 800
  },
  col1: {
    marginRight: 59
  },
  tariffRow: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(6.5),
    marginBottom: theme.spacing(4)
  },
  linearProgressBar: {
    width: 200
  },
  progressBarSuccessColor: {
    backgroundColor: theme.palette.success.main
  },
  progressBarWarningColor: {
    backgroundColor: theme.palette.info.main
  },
  progressBarErrorColor: {
    backgroundColor: theme.palette.error.main
  }
}))

export default function TariffTab (): ReactElement {
  const { t } = useTranslation()
  const classes = pageStyles()
  const cardClasses = useStyles()
  const tariffClasses = tariffStyles()
  const dispatch = useDispatch()

  const currentTariff = useSelector(selectUserTariff)

  useEffect(() => {
    if (currentTariff !== undefined) {
      dispatch(actions.setSelectedTariff({
        id: 0,
        tariffSnapshotId: currentTariff.id ?? '',
        name: currentTariff.name ?? '',
        maxHours: currentTariff.maxHours ?? 0,
        purchase: currentTariff.purchase ?? 1,
        isSystem: false
      }))
    }
  }, [dispatch, currentTariff])

  const progressBarColor = useMemo(() => {
    if (currentTariff === undefined) return cardClasses.progressBarErrorColor

    if (currentTariff?.timeBalancePercantage < 5) {
      return cardClasses.progressBarErrorColor
    }

    if (currentTariff?.timeBalancePercantage < 50) {
      return cardClasses.progressBarWarningColor
    }

    return cardClasses.progressBarSuccessColor
  }, [currentTariff,
    cardClasses.progressBarErrorColor,
    cardClasses.progressBarWarningColor,
    cardClasses.progressBarSuccessColor
  ])

  return (
    <>
      <div className={clsx(cardClasses.card, tariffClasses.card)}>
        <Card className={tariffClasses.cardWrap}>
          <CardContent className={tariffClasses.innerCard}>

            <div>
              <Typography className={tariffClasses.title} variant='h6'>
                {t(I18N.profile.LINK_PAYMENTS)}
              </Typography>
            </div>

            {currentTariff === undefined
              ? (<Typography>{t(I18N.main.common.MSG_LOADING_PROGRESS)}</Typography>)
              : (
                <div>
                  <div className={tariffClasses.grid}>
                    <div className={cardClasses.col1}>
                      <div className={cardClasses.tariffRow}>
                        <InputLabel>
                          {t(I18N.tariff.CURRENT_TITLE)}
                        </InputLabel>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <InputLabel>
                          {t(I18N.tariff.HOUR_BALANCE)}
                        </InputLabel>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <InputLabel>
                          {t(I18N.tariff.HOUR_LIMIT)}
                        </InputLabel>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <InputLabel>
                          {t(I18N.tariff.PAYMENT_DATE)}
                        </InputLabel>
                      </div>
                    </div>

                    <div>
                      <div className={cardClasses.tariffRow}>
                        <Typography variant='h3'>
                          {currentTariff?.name}
                        </Typography>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <div>
                          <Typography variant='h3'>
                            {currentTariff?.timeBalanceString}
                          </Typography>

                          {currentTariff !== undefined && (
                            <LinearProgress
                              className={cardClasses.linearProgressBar}
                              classes={{ barColorPrimary: progressBarColor }}
                              variant='determinate'
                              value={currentTariff?.timeBalancePercantage ?? 0}
                            />
                          )}
                        </div>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <Typography variant='h3'>
                          {currentTariff?.maxHours !== undefined ? `${currentTariff?.maxHours} ч.` : ''}
                        </Typography>
                      </div>

                      <div className={cardClasses.tariffRow}>
                        <Typography variant='h3'>
                          {currentTariff?.lastPaymentDate?.local().format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div className={cardClasses.tariffRow}>
                    <Link to='?tab=payments&inner=tariffs'>
                      <Button
                        color='primary'
                        className={clsx(classes.btn, tariffClasses.leftBtn)}
                        variant='outlined'
                        startIcon={<Cached />}
                      >
                        {t(I18N.tariff.CHANGE_TARIFF)}
                      </Button>
                    </Link>

                    {currentTariff?.isSystem && (
                      <Link to='?tab=payments&inner=confirmPayment'>
                        <Button
                          color='primary'
                          className={classes.btn}
                          variant='contained'
                          startIcon={<Update />}
                        >
                          {t(I18N.tariff.RESUME_SUBSCRIBE)}
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              )}
          </CardContent>
        </Card>
      </div>
    </>
  )
}
