import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addGroup as apiAddGroup,
  addLocation as apiAddLocation,
  updateGroup as apiUpdateGroup,
  updateLocation as apiUpdateLocation
} from '../api'
import { groupDtoToGroup, locationDtoToLocation } from '../data-mapper'
import { GroupMap } from '../data-types'
import {
  ARCHIVE_SLICE_NAME,
  GroupUpdateArgs,
  LocationArgs,
  LocationUpdateArgs,
  MAIN_SLICE_NAME,
  reloadGroupData,
  TRASH_SLICE_NAME
} from '../slice-utils'

/* ########### ARCHIVE ###########

  # Supported Actions

  Source\Dest.|       Archive       |           Trash
  ------------|---------------------|--------------------------
  Main        |  unarchiveLocation  | main:moveLocationToTrash
              |  unarchiveGroup     | main:moveLocationsToTrash
              |                     | main:moveGroupToTrash

  use #updateGroup to edit group attributes without moving the data between Main/Archive/Trash
*/

/*
 * Renames Group
 */
export const renameGroup = createAsyncThunk(
  `${MAIN_SLICE_NAME}/renameGroup`,
  async ({ group, update }: GroupUpdateArgs, { getState }) => {
    if (group.isTmp ?? false) {
      const newGroup = groupDtoToGroup(await apiAddGroup(update.title ?? group.title))
      return await reloadGroupData(newGroup.id, [
        ARCHIVE_SLICE_NAME,
        MAIN_SLICE_NAME,
        TRASH_SLICE_NAME
      ], getState())
    }

    await apiUpdateGroup({
      ...group,
      ...update
    })
  }
)

/*
 * Renames given Location
 */
export const renameLocation = createAsyncThunk(
  `${MAIN_SLICE_NAME}/renameLocation`,
  async ({ groupId, location, update }: LocationUpdateArgs, { getState }) => {
    if (location.isTmp ?? false) {
      const newLocation = await apiAddLocation(groupId, update.title as string)
      return locationDtoToLocation(newLocation)
    }

    await apiUpdateLocation({
      groupId: groupId,
      location: {
        ...location,
        ...update
      }
    })
  }
)

/**
 * Moves given Group into the Archive from Main
 */
export const archiveGroup = createAsyncThunk(
  `${MAIN_SLICE_NAME}/archiveGroup`,
  async (group: GroupMap, { getState }) => {
    await apiUpdateGroup({
      ...group,
      isArchived: true,
      isRemoved: undefined
    })

    return await reloadGroupData(group.id, [ARCHIVE_SLICE_NAME, TRASH_SLICE_NAME], getState())
  }
)

/*
 * Moves all available Locations from the given Group into the Archive from Main
 */
export const archiveLocation = createAsyncThunk(
  `${MAIN_SLICE_NAME}/archiveLocation`,
  async ({ groupId, location }: LocationArgs, { getState }) => {
    await apiUpdateLocation({
      groupId,
      location: {
        ...location,
        isArchived: true,
        isRemoved: false
      }
    })

    return await reloadGroupData(groupId, [ARCHIVE_SLICE_NAME], getState())
  }
)

/*
 * Moves given Group into the Archive from Main
 */
export const moveGroupToTrash = createAsyncThunk(
  `${MAIN_SLICE_NAME}/moveGroupToTrash`,
  async (group: GroupMap, { getState }) => {
    await apiUpdateGroup({
      ...group,
      isArchived: undefined,
      isRemoved: true
    })

    return await reloadGroupData(group.id, [TRASH_SLICE_NAME], getState())
  }
)

/*
 * Moves given Location into the Archive from Main
 */
export const moveLocationToTrash = createAsyncThunk(
  `${MAIN_SLICE_NAME}/moveLocationToTrash`,
  async ({ groupId, location }: LocationArgs, { getState }) => {
    await apiUpdateLocation({
      groupId,
      location: {
        ...location,
        isRemoved: true
      }
    })

    return await reloadGroupData(groupId, [TRASH_SLICE_NAME], getState())
  }
)

/*
 * Moves Locations in the given Group into the Archive from Main
 */
export const moveLocationsToTrash = createAsyncThunk(
  `${MAIN_SLICE_NAME}/moveLocationsToTrash`,
  async (group: GroupMap, { getState }) => {
    const locations = Array.from(group.locations.values())
    await Promise.all(locations.map(async location => await apiUpdateLocation({
      groupId: group.id,
      location: {
        ...location,
        isArchived: undefined,
        isRemoved: true
      }
    })))

    return await reloadGroupData(group.id, [ARCHIVE_SLICE_NAME], getState())
  }
)
