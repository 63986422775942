import React, { ReactElement } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import I18N from "../../app/i18n/strings";
import { useAppLayoutStyles } from "../../app/styles";
import { getCurrentTab } from "../../app/utils";

import Notice from "../../components/Notice";
import VisiusDrawer from "../../components/Drawer";
import VisiusAppBar from "../../components/AppBar";
import ArchiveTab from "./archive/ArchiveTab";
import MainTab from "./main/VideoTab";
import TrashTab from "./trash/TrashTab";

import { ReactComponent as HomeIcon } from "../../app/assets/homeIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../app/assets/trashIcon.svg";
import { ReactComponent as CameraIcon } from "../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../app/assets/webcamIcon.svg";
import { ReactComponent as MovieIcon } from "../../app/assets/movieIcon.svg";
import { ReactComponent as AnalyticIcon } from "../../app/assets/analyticIconSideBar.svg";

export interface PagesProps {
  leftPanelIsOpen?: boolean;
  handleDrawerToggle?: () => void;
}

export type TabId = "video" | "archive" | "trash";
export const tabs: TabId[] = ["video", "archive", "trash"];

const _getPageTitle = (currentTab: TabId): string => {
  switch (currentTab) {
    case "video": {
      return I18N.main.TITLE_VIDEO;
    }
    case "archive": {
      return I18N.main.TITLE_ARCHIVE;
    }
    case "trash": {
      return I18N.main.TITLE_TRASH;
    }
  }
};

const _getPageComponent = (
  currentTab: TabId,
  leftPanelIsOpen?: boolean
): ReactElement => {
  switch (currentTab) {
    case "video": {
      return <MainTab leftPanelIsOpen={leftPanelIsOpen} />;
    }
    case "archive": {
      return <ArchiveTab leftPanelIsOpen={leftPanelIsOpen} />;
    }
    case "trash": {
      return <TrashTab leftPanelIsOpen={leftPanelIsOpen} />;
    }
  }
};

export default function MainPage(props: PagesProps): ReactElement {
  const classes = useAppLayoutStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const tab = getCurrentTab(tabs, query);

  return (
    <div className={classes.root}>
      <Notice />
      <VisiusAppBar
        title={t(_getPageTitle(tab))}
        leftPanelIsOpen={props.leftPanelIsOpen}
      />

      <VisiusDrawer
        items={[
          {
            title: I18N.main.LINK_VIDEOS,
            link: "?tab=video",
            icon: <HomeIcon />,
            selected: tab === "video",
          },
          {
            title: I18N.common.LINK_LOCATION,
            link: "/location",
            icon: <MovieIcon />,
          },
          {
            title: I18N.common.LINK_ANALYTICS,
            link: "/location/dashboard",
            icon: <AnalyticIcon />,
          },
          {
            title: I18N.main.LINK_ARCHIVE,
            link: "?tab=archive",
            icon: <ArchiveIcon />,
            selected: tab === "archive",
          },
          {
            title: I18N.main.LINK_TRASH,
            link: "?tab=trash",
            icon: <TrashIcon />,
            selected: tab === "trash",
          },
        ]}
        buttons={[
          {
            title: I18N.main.BTN_ADD_VIDEO,
            link: "/location?tab=video",
            icon: <CameraIcon />,
          },
          {
            title: I18N.main.BTN_ADD_STREAM,
            link: "/location?tab=stream",
            icon: <WebcamIcon />,
          },
        ]}
        open={props.leftPanelIsOpen}
        handleDrawerToggle={props.handleDrawerToggle}
      />

      <div className={classes.content}>
        <div className={classes.toolbar} />
        <main
          className={
            tab === "archive" || tab === "trash"
              ? classes.onlyForTrashAndArchiveTabs
              : ""
          }
        >
          {_getPageComponent(tab, props.leftPanelIsOpen)}
        </main>
      </div>
    </div>
  );
}
