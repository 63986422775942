import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import I18N from '../../app/i18n/strings'
import { RootState } from '../../app/store'
import { actions as authActions, selectAuthenticated, selectAuthFinished } from '../auth/authSlice'
import { AUTH_STATE } from '../auth/authTypes'
import { unarchiveGroup, unarchiveLocation } from '../groups/archive/asyncActions'
import {
  archiveGroup,
  archiveLocation,
  moveGroupToTrash,
  moveLocationsToTrash,
  moveLocationToTrash,
  renameGroup
} from '../groups/main/asyncActions'
import { DATA_LOAD_STATE } from '../groups/slice-utils'
import {
  deleteGroup,
  deleteLocation,
  restoreGroup,
  restoreLocation
} from '../groups/trash/asyncActions'
import { moveVideos, startProcessing, stopProcessing } from '../location/locationSlice'
import { SIGNUP_STATE } from '../signup/signUpTypes'
import { CONFIRM_EMAIL_STATE } from '../confirm-email/confirmEmailSlice'
import { editProfile } from '../profile/profileSlice'
import { TARIFF_STATE } from '../profile/tariffs/tariffHelper'

type NotificationId = string

export enum NOTIFICATION_TYPE {
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error'
}

export interface Notification {
  id: NotificationId
  template: string
  type: NOTIFICATION_TYPE
  actionText?: string
  actionCallback?: () => void
  args?: Record<string, string | number>
}

export interface NotificationState {
  list: Notification[]
}

interface I18nMessage {
  template: string
  args?: Record<string, string | number>
}

export const initialState: NotificationState = {
  list: []
}

const info = (state: NotificationState, msg: I18nMessage): void => {
  state.list.push({
    id: uuid(),
    type: NOTIFICATION_TYPE.INFO,
    template: msg.template,
    args: msg.args
  })
}

const error = (state: NotificationState, msg: I18nMessage): void => {
  state.list.push({
    id: uuid(),
    type: NOTIFICATION_TYPE.ERROR,
    template: msg.template,
    args: msg.args
  })
}

const authSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    reset () {
      return initialState
    },
    info (state: NotificationState, action: PayloadAction<I18nMessage>) {
      info(state, action.payload)
    },
    error (state: NotificationState, action: PayloadAction<I18nMessage>) {
      error(state, action.payload)
    },
    close (state: NotificationState, action: PayloadAction<NotificationId>) {
      const notificationIndex = state.list.findIndex(({ id }) => id === action.payload)
      if (notificationIndex === -1) {
        return
      }

      state.list.splice(notificationIndex, 1)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.reset, (state) => {
        state.list = []
      })
      .addCase(renameGroup.rejected, (state, action) => {
        const group = action.meta.arg.group
        if (group.isTmp === true) {
          return info(state, {
            template: I18N.main.NOTIFY_ERROR_CREATE,
            args: { group: group.title }
          })
        }

        info(state, {
          template: I18N.main.NOTIFY_ERROR_RENAME,
          args: { group: group.title }
        })
      })
      .addCase(archiveGroup.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_ARCHIVE_GROUP,
          args: { group: action.meta.arg.title }
        })
      })
      .addCase(archiveGroup.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_ARCHIVE_GROUP_FAILED,
          args: { group: action.meta.arg.title }
        })
      })
      .addCase(archiveLocation.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_ARCHIVE_LOCATION,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(archiveLocation.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_ARCHIVE_LOCATION_FAILED,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(unarchiveGroup.fulfilled, (state, action) => {
        const groupMap = action.meta.arg
        info(state, {
          template: groupMap.isArchived ?? false
            ? I18N.main.NOTIFY_RESTORE_GROUP
            : I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(unarchiveGroup.rejected, (state, action) => {
        const groupMap = action.meta.arg
        error(state, {
          template: groupMap.isArchived ?? false
            ? I18N.main.NOTIFY_RESTORE_GROUP_FAILED
            : I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS_FAILED,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(unarchiveLocation.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_RESTORE_LOCATION,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(unarchiveLocation.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_RESTORE_LOCATION_FAILED,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(moveGroupToTrash.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_TRASH_GROUP,
          args: { group: action.meta.arg.title }
        })
      })
      .addCase(moveGroupToTrash.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_TRASH_GROUP_FAILED,
          args: { group: action.meta.arg.title }
        })
      })
      .addCase(moveLocationToTrash.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_TRASH_LOCATION,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(moveLocationToTrash.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_TRASH_LOCATION_FAILED,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(moveLocationsToTrash.fulfilled, (state, action) => {
        const groupMap = action.meta.arg
        info(state, {
          template: I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(moveLocationsToTrash.rejected, (state, action) => {
        const groupMap = action.meta.arg
        error(state, {
          template: I18N.main.NOTIFY_TRASH_MULTIPLE_LOCATIONS_FAILED,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        const groupMap = action.meta.arg
        info(state, {
          template: groupMap.isRemoved ?? false
            ? I18N.main.NOTIFY_DELETE_GROUP
            : I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        const groupMap = action.meta.arg
        error(state, {
          template: groupMap.isRemoved ?? false
            ? I18N.main.NOTIFY_DELETE_GROUP_FAILED
            : I18N.main.NOTIFY_DELETE_MULTIPLE_LOCATIONS_FAILED,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_DELETE_LOCATION,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_DELETE_LOCATION_FAILED,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(restoreLocation.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_RESTORE_LOCATION,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(restoreLocation.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_RESTORE_LOCATION_FAILED,
          args: { location: action.meta.arg.location.title }
        })
      })
      .addCase(restoreGroup.fulfilled, (state, action) => {
        const groupMap = action.meta.arg
        info(state, {
          template: groupMap.isRemoved ?? false
            ? I18N.main.NOTIFY_RESTORE_GROUP
            : I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(restoreGroup.rejected, (state, action) => {
        const groupMap = action.meta.arg
        error(state, {
          template: groupMap.isRemoved ?? false
            ? I18N.main.NOTIFY_RESTORE_GROUP_FAILED
            : I18N.main.NOTIFY_RESTORE_MULTIPLE_LOCATIONS_FAILED,
          args: {
            group: groupMap.title,
            count: [...groupMap.locations].length
          }
        })
      })
      .addCase(startProcessing.rejected, (state, action) => {
        error(state, { template: I18N.location.NOTIFY_START_PROCESSING_ERROR })
      })
      .addCase(stopProcessing.fulfilled, (state, action) => {
        info(state, { template: 'Обработка видео остановлена' })
      })
      .addCase(stopProcessing.rejected, (state, action) => {
        error(state, { template: 'Не удалось остановить обработку видео' })
      })
      .addCase(editProfile.fulfilled, (state) => {
        info(state, { template: I18N.profile.NOTIFY_PROFILE_SAVED })
      })
      .addCase(editProfile.rejected, (state) => {
        info(state, { template: I18N.profile.NOTIFY_PROFILE_SAVE_ERROR })
      })
      .addCase(moveVideos.rejected, (state, action) => {
        error(state, {
          template: I18N.main.NOTIFY_MOVE_VIDEOS_FAILED,
          args: { location: action.meta.arg.locationName }
        })
      })
      .addCase(moveVideos.fulfilled, (state, action) => {
        info(state, {
          template: I18N.main.NOTIFY_MOVE_VIDEOS,
          args: { location: action.meta.arg.locationName }
        })
      })
  }
})

export const {
  actions,
  reducer
} = authSlice

export const selectNotification = ({ notifications }: RootState): Notification | undefined => {
  return notifications.list[0]
}

export const selectAppLoading = ({ auth, confirmEmail, signUp, archive, videoData, trash, tariff }: RootState): boolean => {
  return auth.authState === AUTH_STATE.NONE ||
    auth.authState === AUTH_STATE.AUTHENTICATING ||
    auth.authState === AUTH_STATE.LOGIN_STARTED ||
    confirmEmail.state === CONFIRM_EMAIL_STATE.STARTED ||
    signUp.state === SIGNUP_STATE.SIGNUP_STARTED ||
    videoData.state === DATA_LOAD_STATE.STARTED ||
    archive.state === DATA_LOAD_STATE.STARTED ||
    trash.state === DATA_LOAD_STATE.STARTED ||
    tariff.tariffListState === TARIFF_STATE.LOADING_DATA
}

export const selectAppReady = (state: RootState): boolean => {
  const isAuthAttemptFinished = selectAuthFinished(state)
  const isAuthenticated = selectAuthenticated(state)
  return isAuthenticated || isAuthAttemptFinished
}
