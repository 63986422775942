import React, { ReactElement, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";

import clsx from "clsx";
import deepEqual from "deep-equal";

import { selectUserData } from "../features/auth/authSlice";
import { selectLocationIds } from "../features/groups/main/videoSlice";
import {
  UPLOAD_STATE,
  VIDEO_PREPROCESSING_STATE,
  VIDEO_STATE,
} from "../features/location/data-mapper";
import {
  getUploadsSelector,
  getVideosSelector,
} from "../features/location/selectors";

import Link from "./Link";
import VisiusAdminViewSelect from "./AdminViewSelect";
import CircularProgressWithLabel from "./CircularProgressWithLabel";

import logo from "../app/assets/logo.svg";
import I18N from "../app/i18n/strings";
import { bytes2str } from "../app/utils";
import { ReactComponent as GlobeIcon } from "../app/assets/globeIcon.svg";

import { COLORS } from "../theme";

interface MenuItem {
  title: string;
  link: string;
  selected?: boolean;
  icon: ReactElement;
}

interface ButtonItem {
  title: string;
  onClick?: () => void;

  icon?: ReactElement;
  link?: string;
  isDisabled?: boolean;
  isDevButton?: boolean;
}

interface DrawerProps {
  width?: number;
  items: MenuItem[];
  buttons?: ButtonItem[];
  open?: boolean;
  handleDrawerToggle?: () => void;
}

const drawerWidth = 240;

export const useStyle = makeStyles<Theme>((theme: Theme) => ({
  drawerButton: {
    textAlign: "center",
    margin: theme.spacing(3.5, 2, 1, 2),
    "& + $drawerButton": {
      marginTop: 0,
    },
    "& > a:hover": {
      textDecoration: "none",
    },
  },
  drawerPaper: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.spacing(3),
    boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05) !important",
    margin: "16px 0 16px 16px",
    "& .MuiToolbar-root": {
      justifyContent: "start",
      margin: "10px 0 0 5px",
    },

    "& $drawerButton": {
      "& .MuiButton-startIcon": {
        position: "relative",
        right: "1px",
      },
    },
  },
  progressContainer: {
    marginTop: "auto",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  progressItem: {
    marginTop: theme.spacing(2),
  },
  listLinks: {
    marginTop: theme.spacing(3.5),
    padding: theme.spacing(0, 0, 2.5),
    color: theme.palette.text.secondary,
    "&.MuiList-root .MuiLink-root": {
      margin: 0,
      padding: theme.spacing(1, 1, 1.4, 1),
    },

    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  linkButton: {
    "& .MuiListItem-gutters": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    "&:hover": {
      textDecoration: "none",
    },

    color: "inherit",
  },
  linkButtonSecondary: {
    backgroundColor: "transparent",
  },
  linkHome: {
    lineHeight: 0,
    cursor: "pointer",
  },
  adminViewDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  adminViewSelect: {
    padding: theme.spacing(2),
  },
  btn: {
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "flex-start",
    },

    "& .MuiButton-startIcon": {
      width: "20px",
      marginLeft: "3px",
    },

    "& .MuiButton-startIcon svg": {
      transform: "scale(0.9)",
    },
  },
  devButton: {
    lineHeight: 1.25,
    fontSize: "0.8em",
  },
  landingLink: {
    textAlign: "center",
    border: "none",
    margin: "20px auto",
    width: "100%",
    textTransform: "none",

    "&:hover": {
      background: "transparent",
    },

    "& .MuiTypography-root": {
      fontWeight: 600,
      marginLeft: 8,
    },

    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  height: `calc(100% - 32px)`,
  background: COLORS.ALMOST_WHITE,
  transition: theme.transitions.create(["width", "font-size"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(11),
  height: `calc(100% - 32px)`,
  background: COLORS.ALMOST_WHITE,
  transition: theme.transitions.create(["width", "font-size"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",

  "& .MuiButton-root": {
    minWidth: "48px",
    height: "36.5px",
  },

  "& .MuiButton-label": {
    fontSize: 0,
  },

  "& .MuiListItemText-root p": {
    fontSize: 0,
  },

  "#logo": {
    width: "81px",
    overflowX: "hidden",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function VisiusDrawer(props: DrawerProps): ReactElement {
  const classes = useStyle();
  const { t } = useTranslation();
  const locationIds = useSelector(selectLocationIds, deepEqual);
  const selectUploads = useMemo(
    () => getUploadsSelector(locationIds),
    [locationIds]
  );
  const selectVideos = useMemo(
    () => getVideosSelector(locationIds),
    [locationIds]
  );
  const uploads = useSelector(selectUploads, deepEqual);
  const videos = useSelector(selectVideos, deepEqual);

  const user = useSelector(selectUserData);
  const notStartedUploads = uploads.filter(
    ({ state }) => state === UPLOAD_STATE.NONE
  );
  const videosFinishedPreprocessing = videos.filter(({ state }) => {
    return state === VIDEO_STATE.UPLOADED;
  });

  const uploadProgress = useMemo((): ReactElement => {
    const failedUploads = uploads.filter(
      ({ state }) => state === UPLOAD_STATE.FAILED
    );
    const uploadedDataSize = uploads.reduce(
      (sum, { file, progress }) => sum + file.size * progress,
      0
    );
    const totalUploadsSize = uploads.reduce(
      (sum, { file }) => sum + file.size,
      0
    );

    if (failedUploads.length > 0) {
      return (
        <CircularProgressWithLabel
          variant="determinate"
          value={(uploadedDataSize / totalUploadsSize) * 100}
          title={t(I18N.main.TITLE_UPLOAD_PROGRESS)}
          subtitle={t(I18N.main.MSG_UPLOAD_FAILED, {
            count: failedUploads.length,
          })}
          type="error"
        />
      );
    }

    const inProgressUploads = uploads.filter(
      ({ state }) =>
        state === UPLOAD_STATE.CREATING || state === UPLOAD_STATE.UPLOADING
    );
    if (inProgressUploads.length > 0) {
      return (
        <CircularProgressWithLabel
          variant="determinate"
          value={(uploadedDataSize / totalUploadsSize) * 100}
          title={t(I18N.main.TITLE_UPLOAD_PROGRESS)}
          subtitle={t(I18N.main.MSG_UPLOAD_PROGRESS, {
            uploaded: bytes2str(uploadedDataSize),
            total: bytes2str(totalUploadsSize),
          })}
          type="warn"
        />
      );
    }

    return (
      <CircularProgressWithLabel
        variant="determinate"
        value={100}
        title={t(I18N.main.TITLE_UPLOAD_PROGRESS)}
        subtitle={t(I18N.main.MSG_UPLOAD_COMPLETED)}
        type="success"
      />
    );
  }, [t, uploads]);

  const preProcessingVideosProgress = useMemo((): ReactElement | null => {
    const failedPreprocessing = videos.filter(
      ({ state }) => state === VIDEO_STATE.FAILED
    );
    const inProgressPreprocessing = videos.filter(({ filePreprocessing }) => {
      return (
        filePreprocessing?.jobState === VIDEO_PREPROCESSING_STATE.PROCESSING
      );
    });
    const filePreprocessingProgress =
      inProgressPreprocessing.reduce((sum, { filePreprocessing }) => {
        const progress = filePreprocessing?.progress ?? 0;
        return sum + progress;
      }, 0) / inProgressPreprocessing.length;

    if (failedPreprocessing.length > 0) {
      return (
        <CircularProgressWithLabel
          variant="determinate"
          value={filePreprocessingProgress}
          title={t(I18N.main.TITLE_PREPROCESSING_PROGRESS)}
          subtitle={t(I18N.main.MSG_PREPROCESSING_FAILED, {
            count: failedPreprocessing.length,
          })}
          type="error"
        />
      );
    }

    if (inProgressPreprocessing.length > 0) {
      return (
        <CircularProgressWithLabel
          variant="determinate"
          value={filePreprocessingProgress}
          title={t(I18N.main.TITLE_PREPROCESSING_PROGRESS)}
          subtitle={t(I18N.main.MSG_PREPROCESSING_PROGRESS, {
            count: inProgressPreprocessing.length,
          })}
          type="info"
        />
      );
    }

    const inWaitPreprocessing = videos.filter(
      ({ state }) => state !== VIDEO_STATE.UPLOADED
    );
    if (inWaitPreprocessing.length > 0) {
      return (
        <CircularProgressWithLabel
          variant="determinate"
          value={0}
          title={t(I18N.main.TITLE_WAIT_START_PREPROCESSING)}
          subtitle={t(I18N.main.MSG_PREPROCESSING_PROGRESS, {
            count: inWaitPreprocessing.length,
          })}
          type="warn"
        />
      );
    }

    return null;
  }, [t, videos]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar>
        <div id="logo" className={classes.linkHome}>
          <img onClick={props.handleDrawerToggle} src={logo} alt="Visius" />
        </div>
      </Toolbar>
      <Divider variant="middle" />
      <List className={classes.listLinks}>
        {props.items.map(({ title, link, icon, selected }) => {
          if (
            title === I18N.common.LINK_HOME ||
            title === I18N.main.LINK_VIDEOS ||
            title === I18N.main.LINK_ARCHIVE ||
            title === I18N.main.LINK_TRASH
          ) {
            return (
              <div key={link}>
                <Link className={classes.linkButton} to={link} key={link}>
                  <ListItem button selected={selected}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">{t(title)}</Typography>
                      }
                    />
                  </ListItem>
                </Link>
                {/* {title === I18N.common.LINK_HOME ? <Divider /> : undefined} */}
              </div>
            );
          }
          return (
            <div className={classes.linkButtonSecondary} key={link}>
              <Link className={classes.linkButton} to={link} key={link}>
                <ListItem button selected={selected}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2">{t(title)}</Typography>
                    }
                  />
                </ListItem>
              </Link>
            </div>
          );
        })}
      </List>
      {props.buttons !== undefined && props.buttons.length > 0 && (
        <>
          <Divider variant="middle" />
          {props.buttons.map(
            (
              { title, link, onClick, icon, isDisabled, isDevButton = false },
              index
            ) => {
              const buttonElem = (
                <Button
                  fullWidth
                  startIcon={icon}
                  onClick={onClick}
                  variant={index === 0 ? "contained" : "outlined"}
                  color={isDevButton ? "default" : "primary"}
                  className={clsx(classes.btn, {
                    [classes.devButton]: isDevButton,
                  })}
                  disabled={isDisabled}
                >
                  {t(title)}
                </Button>
              );
              return (
                <p className={classes.drawerButton} key={title}>
                  {link !== undefined ? (
                    <Link to={link}>{buttonElem}</Link>
                  ) : (
                    buttonElem
                  )}
                </p>
              );
            }
          )}
        </>
      )}
      {user?.isAdmin === true && (
        <>
          <Divider className={classes.adminViewDivider} />
          <div className={classes.adminViewSelect}>
            <VisiusAdminViewSelect />
          </div>
        </>
      )}

      <div className={classes.progressContainer}>
        {
          // render upload progress if there are started/inprogress/completed uploads
          uploads.length > 0 && notStartedUploads.length !== uploads.length && (
            <div className={classes.progressItem}>{uploadProgress}</div>
          )
        }
        {videos.length > 0 &&
          videosFinishedPreprocessing.length !== videos.length && (
            <div className={classes.progressItem}>
              {preProcessingVideosProgress}
            </div>
          )}
      </div>

      <div>
        <Divider variant="middle" />
        <a
          href={"https://visius.ai/"}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Button
            startIcon={<GlobeIcon />}
            variant="text"
            color={"primary"}
            className={classes.landingLink}
            style={{ justifyContent: props.open ? "center" : "center" }}
          >
            <Typography
              style={{ display: props.open ? "block" : "none" }}
              variant="body2"
            >
              www.visius.ai
            </Typography>
          </Button>
        </a>
      </div>
    </Drawer>
  );
}
