import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import {
  FormControl,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import TimeRange, { DateRange } from "../../../components/DateRangePicker";
import usePanelStyles from "./componentsPanelStyles";
import I18N from "../../../app/i18n/strings";

const useStyles = makeStyles((theme: Theme) => ({
  timeRange: {
    marginTop: 12,
  },
  inputList: {
    padding: 0,
    "& .MuiListItem-container:first-child $listItem": {
      paddingTop: 0,
    },
    "& .MuiListItem-container:last-child $listItem": {
      paddingBottom: 0,
    },
  },
  listItem: {
    alignItems: "start",
    padding: "0 !important",
  },
}));

export interface CardProps {
  ranges: DateRange[];
  onChange: (v: DateRange[]) => void;
}

export default function TimeRangeRender({
  ranges,
  onChange,
}: CardProps): ReactElement {
  const classes = usePanelStyles();
  const classesThis = useStyles();
  const { t } = useTranslation();
  const startSelect = t(I18N.chart.TIME_RANGE_LABEL_SELECT);
  const [cut, setCut] = React.useState(startSelect);

  const handleChangeCut = useCallback(
    () =>
      (event: React.ChangeEvent<{ value: unknown }>): void => {
        setCut(event.target.value as string);
      },
    [setCut]
  );
  const handleChange = useCallback(
    (index: number, range: DateRange): void => {
      if (!moment(range.start).isValid() || !moment(range.end).isValid()) {
        return;
      }

      const newRanges = [...ranges];
      newRanges[index] = range;
      onChange(newRanges);
    },
    [onChange, ranges]
  );

  // Можно оставить функционал

  // const handleAdd = useCallback((): void => {
  //   onChange([...ranges, getEmptyInterval()])
  // }, [onChange, ranges])
  // const handleRemove = useCallback((index: number): void => {
  //   const newRanges = [...ranges]
  //   newRanges.splice(index, 1)
  //   onChange(newRanges)
  // }, [onChange, ranges])
  // const handleRestore = useCallback((): void => {
  //   onChange([getDefaultInterval()])
  // }, [onChange])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classesThis.timeRange}>
        <List className={classesThis.inputList}>
          {ranges.map((timeRange, index) => (
            <ListItem
              key={`${index}-${moment(timeRange.start).format()}-${moment(
                timeRange.end
              ).format()}`}
              className={classesThis.listItem}
            >
              <TimeRange
                variant="outlined"
                start={timeRange.start}
                end={timeRange.end}
                onChange={handleChange.bind(null, index)}
              />
              {/* <ListItemSecondaryAction>
                    <IconButton
                      disabled={index === 0 && ranges.length === 1}
                      edge='end'
                      onClick={handleRemove.bind(null, index)}
                    >
                      <Close />
                    </IconButton>
                  </ListItemSecondaryAction> */}
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <FormControl
          disabled
          variant="outlined"
          className={classes.formControl}
        >
          <Select
            title={t(I18N.chart.TIME_RANGE_LABEL_SELECT)}
            value={cut}
            onChange={handleChangeCut}
            className={classes.select}
            MenuProps={{
              classes: {
                paper: classes.dropDownList,
              },
            }}
          >
            <MenuItem
              key="0"
              disabled
              value={t(I18N.chart.TIME_RANGE_LABEL_SELECT)}
            >
              {t(I18N.chart.TIME_RANGE_LABEL_SELECT)}
            </MenuItem>
            <MenuItem
              key="1"
              value={t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_HOUR)}
            >
              {t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_HOUR)}
            </MenuItem>
            <MenuItem
              key="2"
              value={t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_DAY)}
            >
              {t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_DAY)}
            </MenuItem>
            <MenuItem
              key="3"
              value={t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_MONTH)}
            >
              {t(I18N.chart.TIME_RANGE_LABEL_SELECT_VALUE_MONTH)}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </MuiPickersUtilsProvider>
  );
}
