import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  actions,
  Notification,
  selectNotification,
} from "./notificationsSlice";

const AUTOHIDE_TIMEOUT = 5000;
const ANIMATION_TIMEOUT = 1000;

export default function NotificationSnack(): ReactElement | null {
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(
    (_: SyntheticEvent, reason?: SnackbarCloseReason): void => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
      setTimeout(() => {
        dispatch(actions.close((notification as Notification).id));
        setOpen(true);
      }, ANIMATION_TIMEOUT);
    },
    [dispatch, setOpen, notification]
  );

  if (notification === undefined) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={AUTOHIDE_TIMEOUT}
      onClose={handleClose}
      message={t(notification.template, notification.args)}
      action={
        <>
          {notification.actionText === undefined ? (
            <Button color="secondary" size="small">
              {notification.actionText}
            </Button>
          ) : null}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    />
  );
}
