import React, { ChangeEvent, ReactElement, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Container, TextField, Typography } from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import { TimeZoneName } from "../../../app/timezone";

import { actions, createStream } from "../locationSlice";
import { StreamData } from "../data-mapper";

import { LocationId } from "../../groups/data-types";
import TimeZoneSelect from "./components/TimeZoneSelect";
import useStyles from "../styles";

interface StreamCreatorProps {
  locationId?: LocationId;
  stream: StreamData;
}

export default function StreamCreator(props: StreamCreatorProps): ReactElement {
  const { locationId, stream } = props;
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [url, setUrl] = useState<string>(stream.url);

  const regex = /^rtsp/;

  // Handlers for changes within the stream options
  const handleUrlSave = useCallback(() => {
    dispatch(
      actions.setStream({
        locationId: locationId as LocationId,
        data: { ...stream, url },
      })
    );
  }, [dispatch, locationId, url, stream]);

  const handleUrlChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setUrl(target.value);
    },
    [setUrl]
  );

  const handleTimezoneChange = useCallback(
    (timeZone: TimeZoneName) => {
      dispatch(
        actions.setStream({
          locationId: locationId as LocationId,
          data: { ...stream, timeZone },
        })
      );
    },
    [dispatch, locationId, stream]
  );

  const handleStreamConnect = useCallback(() => {
    dispatch(
      createStream({
        locationId: locationId as LocationId,
        streamId: stream.id,
      })
    );
  }, [dispatch, stream, locationId]);

  return (
    <Container maxWidth="md" style={{ margin: 0, padding: 0 }}>
      <div className={classes.offsetContentBlock}>
        <Typography variant="body2" className={classes.inputLabel}>
          {t(I18N.location.MSG_ENTER_STREAM_URL)}
        </Typography>
        <TextField
          fullWidth
          required
          variant="outlined"
          margin="dense"
          label={!inputHasFocus && url === "" ? t("Укажите ссылку") : ""}
          onChange={handleUrlChange}
          onFocus={() => setInputHasFocus(true)}
          onBlur={() => {
            handleUrlSave();
            setInputHasFocus(false);
          }}
          value={url}
          className={classes.input}
          error={url === ""}
        />
        <Typography className={classes.inputHelper} color="textPrimary">
          {t(I18N.location.MSG_STREAM_URL_SAMPLES)}
          <br />
          rtsp://192.168.200.01:554/ISAPI/Streaming/Channels/101
          <br />
          rtsp://admin:12345@192.168.200.01:554/ISAPI/Streaming/Channels/101
        </Typography>
      </div>

      <div className={classes.offsetContentBlock}>
        <Typography variant="body2" className={classes.inputLabel}>
          {t(I18N.location.MSG_SELECT_TZ)}
        </Typography>
        <TimeZoneSelect
          timeZone={stream.timeZone}
          onChange={handleTimezoneChange}
        />
      </div>

      <div className={classes.steps}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.paragraph}
        >
          {t(I18N.location.MSG_TIMEZONE_WARNING_L1)}
          <br />
          {t(I18N.location.MSG_TIMEZONE_WARNING_L2)}
        </Typography>

        <Button
          color="primary"
          disabled={url === "" || !regex.test(url)}
          onClick={handleStreamConnect}
          variant="contained"
          className={classes.mainBtn}
        >
          {t(I18N.location.BTN_STEP_CONNECT_STREAM)}
        </Button>
      </div>
    </Container>
  );
}
