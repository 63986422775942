import React, { ReactElement } from 'react'
import { TickRendererProps } from '@visx/axis'

type TickLabelProps = TickRendererProps&{
  content: string[]
}

export default function TickLabel (props: TickLabelProps): ReactElement {
  // <svg>, <text> and <tspan> below all have default visX attributes,
  // but we split text content into multiple lines
  return (
    <svg
      y='0.25em'
      x='-0.25em'
      style={{ overflow: 'visible' }}
      fontSize={props.fontSize}
    >
      <text
        x={props.x}
        y={props.y}
        fill={props.fill}
        fontFamily={props.fontFamily}
        fontSize={props.fontSize}
        textAnchor={props.textAnchor}
      >
        {
          props.content.map((text, index) => {
            return (
              <tspan key={text} x={props.x} dy={index > 0 ? '1em' : undefined}>
                {text}
              </tspan>
            )
          })
        }
      </text>
    </svg>
  )
}
