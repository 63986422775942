import { ResultKeys } from '../../../components/charts/bar/types'
import { ChartVariant } from '../../../components/charts/Chart'
import { DateRange } from '../../../components/DateRangePicker'
import { RawResult } from './RawResult'

export type MaxYGetter<T> = (datum: T) => number

export type MaxYGetterGenerator<T> = (resultKeys: Array<ResultKeys<T>>, variant: ChartVariant) => MaxYGetter<T>

export function generateMaxYGetter<T> (resultKeys: Array<ResultKeys<T>>, variant: ChartVariant): MaxYGetter<T> {
  return (datum: T): number => {
    switch (variant) {
      case 'stack':
        return Math.max(
          resultKeys
            .map(key => datum[key] as unknown as number)
            .reduce((r, v) => r + v, 0),
          1)
      case 'group':
      default:
        return Math.max(
          ...resultKeys.map(key => datum[key] as unknown as number),
          1)
    }
  }
}

export function formatNumber (n: number): string {
  return n.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1' + String.fromCharCode(160))
}

export function getDateRange (data: RawResult[]): DateRange {
  const inputTime = data.map(({ time }) => time.getTime())
  const minDate = Math.min(...inputTime)
  const maxDate = Math.max(...inputTime)
  return {
    start: new Date(minDate),
    end: new Date(maxDate)
  }
}
