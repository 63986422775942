import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { KeyboardTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { makeStyles, Theme } from "@material-ui/core";
import { Schedule } from "@material-ui/icons";

import I18N from "../app/i18n/strings";

const useStyles = makeStyles((theme: Theme) => ({
  timePicker: {
    width: "50%",
    "& .MuiOutlinedInput-input": {
      paddingTop: 14.5,
      paddingBottom: 14.5,
      paddingRight: 8,
      paddingLeft: 16,
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "&:first-child": {
      marginRight: 12,
    },
    "& .MuiInputBase-root": {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      background: theme.palette.common.white,

      "& .MuiInputBase-input": {
        color: "#122045",
      },
      "&.Mui-disabled .MuiInputBase-input": {
        color: "#DFE6FB",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "&.Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },

      "& fieldset": {
        border: "none ",
      },
    },
  },
}));

export interface DateRange {
  start: Date;
  end: Date;
}

interface DateRangeProps {
  variant: "standard" | "outlined" | "filled";
  start: Date;
  end: Date;
  onChange: (value: DateRange) => void;
}

export default function DateRangePickers({
  variant,
  start,
  end,
  onChange,
}: DateRangeProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleFromChange = useCallback(
    (date: MaterialUiPickersDate): void => {
      if (date === null) {
        return;
      }
      onChange({ start: date.toDate(), end });
    },
    [onChange, end]
  );
  const handleToChange = useCallback(
    (date: MaterialUiPickersDate): void => {
      if (date === null) {
        return;
      }
      onChange({ start, end: date.toDate() });
    },
    [onChange, start]
  );

  return (
    <>
      <KeyboardTimePicker
        className={classes.timePicker}
        inputVariant={variant}
        ampm={false}
        value={start}
        onChange={handleFromChange}
        keyboardIcon={<Schedule />}
        autoOk
        placeholder={t(I18N.chart.TIME_RANGE_LABEL_TIME_FROM)}
      />
      <KeyboardTimePicker
        className={classes.timePicker}
        inputVariant={variant}
        ampm={false}
        value={end}
        onChange={handleToChange}
        okLabel="Ok"
        cancelLabel="Отмена"
        keyboardIcon={<Schedule />}
        placeholder={t(I18N.chart.TIME_RANGE_LABEL_TIME_TO)}
      />
    </>
  );
}
