import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, Theme, Typography } from "@material-ui/core";
import deepEqual from "deep-equal";

import I18N from "../../../app/i18n/strings";
import { GroupId, LocationId } from "../../groups/data-types";
import { loadGroups, selectNotInitialized } from "../../groups/main/videoSlice";

import {
  getGroupByLocationSelector,
  getLocationValuesSelector,
  selectGroupValues,
} from "../../location/selectors";
import usePanelStyles from "./componentsPanelStyles";
import CreatableSelect from "../../../components/CreatableSelect";

const useStyles = makeStyles((theme: Theme) => ({
  checkBox: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    "& .PrivateSwitchBase-root-45": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  inputLocation: {
    width: "100%",
    minWidth: 120,
    marginTop: theme.spacing(1.5),
    marginBottom: 0,

    "& .MuiFormLabel-root": {
      fontSize: 14,

      "&.Mui-disabled": {
        color: "#DFE6FB",
      },
    },

    "& .MuiInputBase-root": {
      color: "none",
      background: theme.palette.common.white,
      borderRadius: 12,
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "& fieldset": {
        border: "none ",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid #2C67FF",
      },

      "&.Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },
    },

    "& .MuiInputBase-root .Mui-disabled": {
      boxShadow: "none",
      border: "none",
    },
  },
  inputLocationAfter: {
    marginTop: 0,
    marginBottom: 0,
    minWidth: 120,
    width: "100%",
  },
}));

export interface LocationProps {
  locationId?: LocationId;
  onChange: (locationId?: LocationId) => void;
}

export default function LocationPicker(props: LocationProps): ReactElement {
  const classes = usePanelStyles();
  const classesThis = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { locationId, onChange } = props;
  const groups = useSelector(selectGroupValues, deepEqual);
  const [locationError, setLocationError] = useState<string>();
  const [groupError, setGroupError] = useState<string>();

  // load initial Group
  const selectInitialGroup = useMemo(
    () =>
      locationId !== undefined
        ? getGroupByLocationSelector(locationId)
        : () => undefined,
    [locationId]
  );
  const initialGroup = useSelector(selectInitialGroup);
  const [groupId, setGroupId] = useState<GroupId | undefined>(initialGroup?.id);

  // load current group's Locations
  const selectLocations = useMemo(() => {
    if (groupId === undefined) {
      return () => [];
    }
    return getLocationValuesSelector(groupId);
  }, [groupId]);
  const locations = useSelector(selectLocations);

  // unset Location Select value when Group has been changed
  const verifiedLocationId =
    locations.findIndex(({ id }) => locationId === id) !== -1
      ? locationId
      : undefined;

  const isNotInitialized = useSelector(selectNotInitialized);
  useEffect(() => {
    if (isNotInitialized) {
      dispatch(loadGroups());
    }
  }, [dispatch, isNotInitialized]);

  // in case Groups data isn't available from start,
  // set group ID once Groups data has been loaded
  useEffect(() => {
    if (groupId === undefined && initialGroup !== undefined) {
      setGroupId(initialGroup.id);
    }
  }, [setGroupId, groupId, initialGroup]);

  // reset Errors
  const handleGroupErrorReset = useCallback(
    () => setGroupError(undefined),
    [setGroupError]
  );
  const handleLocationErrorReset = useCallback(
    () => setLocationError(undefined),
    [setLocationError]
  );

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        Анализируемая локация
      </Typography>
      <CreatableSelect
        className={classesThis.inputLocation}
        value={groupId}
        options={groups}
        onChange={(groupId) => {
          setGroupId(groupId);
          onChange();
        }}
        resetError={handleGroupErrorReset}
        error={groupError}
        withoutLabel={true}
        createText={t(I18N.location.MSG_CREATE_GROUP)}
        noOptionsText={t(I18N.location.MSG_SELECT_EMPTY_GROUPS)}
        placeHolder={"Группа локаций"}
      />
      <CreatableSelect
        className={classesThis.inputLocation}
        disabled={groupId === undefined}
        value={verifiedLocationId}
        options={locations}
        onChange={onChange}
        resetError={handleLocationErrorReset}
        error={locationError}
        withoutLabel={true}
        createText={t(I18N.location.MSG_CREATE_LOCATION)}
        noOptionsText={t(I18N.location.MSG_SELECT_EMPTY_LOCATIONS)}
        placeHolder={"Локация"}
      />
    </div>
  );
}
