import { makeStyles, TextField, Theme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import deepEqual from 'deep-equal'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import I18N from '../app/i18n/strings'
import { actions as authActions, ADMIN_VIEW_STORAGE_KEY, selectUserData } from '../features/auth/authSlice'
import { UserDto } from '../features/auth/authTypes'

const formatUserName = ({ name, email }: UserDto): string => {
  if (name !== '') {
    return `${name} (${email})`
  }

  return email
}

const useStyles = makeStyles((theme: Theme) => ({
  autoCompleteList: {
    '& .MuiAutocomplete-option': {
      fontSize: 'small'
    }
  }
}))

export default function VisiusAdminViewSelect (): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const user = useSelector(selectUserData, deepEqual)
  const availableUserAccounts = useMemo(() => {
    const entries = user?.availableUsers ?? []
    return [...entries].sort((a, b) => {
      if (a.id === user?.id) { return -1 }
      if (b.id === user?.id) { return 1 }

      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      const aEmail = a.email.toLowerCase()
      const bEmail = b.email.toLowerCase()

      if (aName < bName) { return -1 }
      if (aName > bName) { return 1 }
      if (aEmail < bEmail) { return -1 }
      if (aEmail > bEmail) { return 1 }
      return 0
    })
  }, [user])
  const defaultId: string|undefined = sessionStorage.getItem(ADMIN_VIEW_STORAGE_KEY) ?? user?.id
  const defaultAccount = availableUserAccounts.find(({ id }) => id === defaultId)

  const handleAdminViewSwitch = useCallback((_, selectedUser: UserDto|null) => {
    if (selectedUser === null || selectedUser.id === sessionStorage.getItem(ADMIN_VIEW_STORAGE_KEY)) {
      return
    }

    sessionStorage.setItem(ADMIN_VIEW_STORAGE_KEY, selectedUser.id)
    dispatch(authActions.reset())
  }, [dispatch])

  return (
    <Autocomplete
      options={availableUserAccounts}
      defaultValue={defaultAccount}
      onChange={handleAdminViewSwitch}
      getOptionLabel={formatUserName}
      ListboxProps={{
        className: clsx('MuiAutocomplete-listbox', classes.autoCompleteList)
      }}
      groupBy={({ id }) => id === user?.id
        ? t(I18N.common.TITLE_MY_ACCOUNT)
        : t(I18N.common.TITLE_OTHER_ACCOUNTS)}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(I18N.common.SELECT_ADMIN_VIEW)}
          variant='outlined'
        />
      )}
    />
  )
}
