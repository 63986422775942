import React, { MouseEvent, ReactElement, useCallback, useMemo } from "react";
import { BarGroup, BarGroupHorizontal } from "@visx/shape";
import { scaleBand } from "@visx/scale";
import { localPoint } from "@visx/event";

import VerticalBarGroup from "./VerticalBarGroup";
import HorizontalBarGroup from "./HorizontalBarGroup";
import { ChartViewProps } from "./types";

export default function BarGroupView<T>(
  props: ChartViewProps<T, string>
): ReactElement {
  const {
    // dataset
    data,
    resultKeys,
    xScale,
    yScale,
    groupBy,

    // styling
    colorScale,
    hideLabels,
    opacity = 1,
    // height, width, horizontal

    // tooltips
    showTooltip,
    hideTooltip,
  } = props;

  const xGroupScale = useMemo(
    () =>
      scaleBand<string>({
        domain: resultKeys as string[],
        padding: 0.1,
        range: [0, xScale.bandwidth()],
        round: false,
      }),
    [xScale, resultKeys]
  );

  const getTooltipHandler = useCallback(
    (datumIndex: number) => {
      if (showTooltip === undefined) {
        return;
      }

      return (event: MouseEvent): void => {
        const coords = localPoint(
          (event.target as SVGElement).ownerSVGElement as Element,
          event
        );
        showTooltip({
          tooltipLeft: coords?.x,
          tooltipTop: coords?.y,
          tooltipData: data[datumIndex],
        });
      };
    },
    [showTooltip, data]
  );

  return (
    <>
      {props.horizontal === true ? (
        <BarGroupHorizontal
          data={data}
          keys={resultKeys}
          width={props.width}
          y0={groupBy}
          y0Scale={xScale}
          y1Scale={xGroupScale}
          xScale={yScale}
          color={colorScale}
        >
          {(barGroups) =>
            barGroups.map((barGroup) => {
              return (
                <HorizontalBarGroup
                  key={barGroup.index}
                  barGroup={barGroup}
                  onMouseLeave={hideTooltip}
                  onMouseOver={getTooltipHandler(barGroup.index)}
                  opacity={opacity}
                  hideLabels={hideLabels}
                  labels={resultKeys as string[]}
                />
              );
            })
          }
        </BarGroupHorizontal>
      ) : (
        <BarGroup
          data={data}
          keys={resultKeys}
          height={props.height}
          x0={groupBy}
          x0Scale={xScale}
          x1Scale={xGroupScale}
          yScale={yScale}
          color={colorScale}
        >
          {(barGroups) =>
            barGroups.map((barGroup) => {
              return (
                <VerticalBarGroup
                  key={barGroup.index}
                  barGroup={barGroup}
                  onMouseLeave={hideTooltip}
                  onMouseOver={getTooltipHandler(barGroup.index)}
                  opacity={opacity}
                  hideLabels={hideLabels}
                  labels={resultKeys as string[]}
                  maxBarHeight={props.height}
                />
              );
            })
          }
        </BarGroup>
      )}
    </>
  );
}
