import moment from "moment";
import { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { loadLocation as apiLoadLocation } from "../api";

import { AppThunk } from "../../../app/store";
import {
  createVideoSlice,
  deleteGroupState,
  deleteLocationState,
  MAIN_SLICE_NAME,
  setGroupBusy,
  setGroupIdle,
  setGroupState,
  setLocationBusy,
  setLocationIdle,
  VideoDataState,
  withGroup,
  withLocation,
} from "../slice-utils";

import { groupToGroupMap } from "../data-mapper";
import {
  GroupData,
  GroupId,
  GroupMap,
  LocationData,
  LocationId,
} from "../data-types";

import { restoreGroup, restoreLocation } from "../trash/asyncActions";
import { unarchiveGroup, unarchiveLocation } from "../archive/asyncActions";
import {
  archiveGroup,
  archiveLocation,
  moveGroupToTrash,
  moveLocationToTrash,
  renameGroup,
  renameLocation,
} from "./asyncActions";

const updateGroupState = (
  state: VideoDataState,
  action: PayloadAction<GroupData | undefined>
): void => {
  setGroupState(state, MAIN_SLICE_NAME, action.payload);
};

export const {
  actions,
  reducer,

  selectIsValidLocation,
  loadGroup,
  loadGroups,
  selectData,
  selectLoaded,
  selectLoadError,
  selectLoading,
  selectLocationIds,
  selectNotInitialized,
} = createVideoSlice(MAIN_SLICE_NAME, (builder) => {
  /* Groups */
  builder
    // rename
    .addCase(renameGroup.pending, (state, action) => {
      const { group } = action.meta.arg;
      withGroup(state, group.id, (groupMap) => {
        groupMap.isBusy = true;
        delete groupMap.error;
      });
    })
    .addCase(renameGroup.fulfilled, (state, action) => {
      const { group, update } = action.meta.arg;
      withGroup(state, group.id, (groupMap) => {
        if (group.isTmp ?? false) {
          const newGroup = action.payload as GroupData;
          state.data.delete(group.id);
          state.data.set(newGroup.id, groupToGroupMap(newGroup));
          return;
        }

        groupMap.title = update.title as string;
        groupMap.isBusy = false;
      });
    })
    .addCase(renameGroup.rejected, (state, action) => {
      const { group } = action.meta.arg;
      withGroup(state, group.id, (groupMap) => {
        state.data.set(group.id, {
          ...groupMap,
          error: action.error.message,
          isBusy: false,
        });
      });
    })
    // archive
    .addCase(archiveGroup.fulfilled, deleteGroupState)
    .addCase(archiveGroup.pending, setGroupBusy)
    .addCase(archiveGroup.rejected, setGroupIdle)
    // unarchive
    .addCase(unarchiveGroup.fulfilled, updateGroupState)
    // move to Trash
    .addCase(moveGroupToTrash.fulfilled, deleteGroupState)
    .addCase(moveGroupToTrash.pending, setGroupBusy)
    .addCase(moveGroupToTrash.rejected, setGroupIdle)
    // restore from Trash
    .addCase(restoreGroup.fulfilled, updateGroupState);

  /* Locations */
  builder
    // rename
    .addCase(renameLocation.pending, (state, action) => {
      const { groupId, location } = action.meta.arg;
      withLocation(state, groupId, location, (locationState) => {
        locationState.isBusy = true;
        delete locationState.error;
      });
    })
    .addCase(renameLocation.rejected, (state, action) => {
      const { groupId, location } = action.meta.arg;
      withLocation(state, groupId, location, (locationState) => {
        locationState.isBusy = false;
        locationState.error = action.error.message;
      });
    })
    .addCase(renameLocation.fulfilled, (state, action) => {
      const { groupId, location, update } = action.meta.arg;
      withLocation(state, groupId, location, (locationState) => {
        const group = state.data.get(groupId) as GroupMap;
        if (location.isTmp === true) {
          const newLocation = action.payload as LocationData;
          group.locations.delete(location.id);
          group.locations.set(newLocation.id, newLocation);
          return;
        }

        locationState.isBusy = false;
        locationState.title = update.title as string;
      });
    })
    // archive
    .addCase(archiveLocation.fulfilled, deleteLocationState)
    .addCase(archiveLocation.pending, setLocationBusy)
    .addCase(archiveLocation.rejected, setLocationIdle)
    // unarchive
    .addCase(unarchiveLocation.fulfilled, updateGroupState)
    // move to Trash
    .addCase(moveLocationToTrash.fulfilled, deleteLocationState)
    .addCase(moveLocationToTrash.pending, setLocationBusy)
    .addCase(moveLocationToTrash.rejected, setLocationIdle)
    // restore from Trash
    .addCase(restoreLocation.fulfilled, updateGroupState);
});

/**
 * Creates temporary (e.g not saved into the DB) Group
 *
 * @param defaultTitle - default Group title
 */
export const createTmpGroup =
  (defaultTitle: string, defaultId = uuid()): AppThunk =>
  async (dispatch) => {
    // We don't save Group to the Backend until User confirms Group title.
    // So the actual location creation happens in #renameGroup operation once the title is set
    const group: GroupMap = {
      id: defaultId,
      createdAt: moment(),
      title: defaultTitle,
      locations: new Map(),
      isTmp: true,
    };

    // It's only possible to create TMP group in the Main Tab
    dispatch(actions.addGroup(group));
  };

/**
 * Creates temporary (e.g not saved into the DB) Location
 *
 * @param groupId - Group ID
 * @param defaultTitle - default Location title
 */
export const createTmpLocation =
  (groupId: GroupId, defaultTitle: string): AppThunk =>
  async (dispatch) => {
    // We don't save location to the Backend until User confirms Location title.
    // So the actual location creation happens in #renameLocation operation once the title is set
    const location: LocationData = {
      createdAt: moment(),
      id: uuid(),
      isTmp: true,
      streams: 0,
      title: defaultTitle,
      videos: 0,
      isCameraConnected: null,
      videoFilesSize: 0,
      processingCount: 0,
      completedCount: 0,
    };
    dispatch(actions.addLocation({ groupId, location }));
  };

interface LocationUpdateOptions {
  groupId: GroupId;
  locationId: LocationId;
}

export const loadLocation =
  (options: LocationUpdateOptions): AppThunk =>
  async (dispatch, getState) => {
    const { groupId, locationId } = options;
    const location = await apiLoadLocation(locationId);
    dispatch(actions.addLocation({ groupId, location }));
  };
