import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/ru'
import { initReactI18next } from 'react-i18next'
import RU from './ru'
import { getLocale } from './locale'

moment.locale(getLocale())

const resources = {
  ru: RU
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLocale(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
  .catch(err => console.error(err))

export default i18n
