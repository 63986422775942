import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { BarStackHorizontal, BarStack } from '@visx/shape'

import { localPoint } from '@visx/event'

import { ChartViewProps, ResultKeys } from './types'
import VisiusBarStack from './BarStack'
import { BarStack as BarStackType } from '@visx/shape/lib/types/barStack'

export default function BarStackView<T> (props: ChartViewProps<T, string>): ReactElement {
  // bounds
  const {
    data,
    resultKeys,
    colorScale,
    xScale,
    yScale,
    opacity = 1,
    hideLabels,
    horizontal: isHorizontal = false,

    groupBy,
    showTooltip,
    hideTooltip
  } = props

  const getTooltipHandler = useCallback((datum: T) => {
    if (showTooltip === undefined) {
      return
    }

    return (event: MouseEvent): void => {
      const coords = localPoint((event.target as SVGElement).ownerSVGElement as Element, event)
      showTooltip({
        tooltipLeft: coords?.x,
        tooltipTop: coords?.y,
        tooltipData: datum
      })
    }
  }, [showTooltip])

  const barStackRenderer = (barStacks: Array<BarStackType<T, ResultKeys<T>>>): ReactElement[] => barStacks.map((barStack, index) => (
    <VisiusBarStack
      barStack={barStack}
      opacity={opacity}
      resultKeys={resultKeys}
      key={barStack.index}
      onMouseLeave={hideTooltip}
      tooltipHandler={getTooltipHandler}
      horizontal={isHorizontal}
      showTotal={index === barStacks.length - 1}
      hideLabels={hideLabels}
    />
  ))

  return (
    <>
      {
        isHorizontal ? (
          <BarStackHorizontal
            data={data}
            keys={resultKeys}
            y={groupBy}
            xScale={yScale}
            yScale={xScale}
            color={colorScale}
          >
            {barStackRenderer}
          </BarStackHorizontal>
        ) : (
          
          <BarStack
            data={data}
            keys={resultKeys}
            x={groupBy}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {barStackRenderer}
          </BarStack>

        )
      }
    </>
  )
}


