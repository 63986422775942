import { AnyAction } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import { RootState } from './store'

function actionTransformer (action: AnyAction): Record<string, unknown> {
  return action
}

function stateTransformer (state: RootState): Record<string, unknown> {
  return state
}

/**
 * Exports logger instance optimized for own needs
 */
export default createLogger({
  actionTransformer,
  stateTransformer
})
