import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import I18N from '../../app/i18n/strings'
import { useFormStyles } from '../../app/styles'
import Form, { getRequiredValidator } from '../../components/Form'
import VisiusLink from '../../components/Link'
import SignInTextField from '../../components/TextField'
import {
  actions,
  requestReset,
  selectIsPasswordResetStarted,
  selectPasswordResetError
} from './passwordResetSlice'

interface FormData {
  email: string
}

export default function ResetRequestForm (): ReactElement {
  const classes = useFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isResetStarted = useSelector(selectIsPasswordResetStarted)
  const resetError = useSelector(selectPasswordResetError)

  const _onSubmit = (data: FormData): void => {
    dispatch(requestReset(data.email))
  }

  return (
    <Form
      onSubmit={_onSubmit}
      validate={getRequiredValidator(['email'])}
    >
      <Typography className={classes.formIntro}>
        {t(I18N.passwordReset.MSG_WELCOME)}
      </Typography>
      <SignInTextField
        required
        label='Email'
        name='email'
        autoComplete='email'
        inputProps={{ type: 'email' }}
        error={Boolean(resetError)}
        autoFocus
        helperText={t(resetError ?? '')}
        onChange={() => {
          if (resetError === undefined) {
            return
          }
          dispatch(actions.reset())
        }}
      />
      <div className={classes.formFooterActions}>
        <VisiusLink to='/login' className={classes.loginBtnLink}>
          <Button
            variant='outlined'
            color='primary'
            disabled={isResetStarted}
            className={classes.loginBtn}
          >
            {t(I18N.passwordReset.BTN_BACK)}
          </Button>
        </VisiusLink>
        <Button
          className={classes.loginBtn}
          type='submit'
          variant='contained'
          color='primary'
          disabled={isResetStarted}
        >
          {t(I18N.passwordReset.BTN_RESET)}
        </Button>
      </div>
    </Form>
  )
}
