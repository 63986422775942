import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";

import { loadLocation } from "./locationSlice";
import {
  selectIsValidLocation,
  selectLoaded as selectGroupsLoaded,
} from "../groups/main/videoSlice";
import {
  getLoadedSelector,
  getNotInitializedSelector,
  getStreamsSelector,
  getVideosSelector,
} from "./selectors";

import I18N from "../../app/i18n/strings";
import { useAppLayoutStyles } from "../../app/styles";
import { getCurrentTab, hasCloneVideo } from "../../app/utils";
import { LOCATION_URL } from "./api";

import VisiusAppBar from "../../components/AppBar";
import DelayedProgress from "../../components/DelayedProgress";
import VisiusDrawer from "../../components/Drawer";
import VisiusLink from "../../components/Link";
import LocationControl from "./components/LocationControl";
import LineEditorTab from "./line-editor/LineEditorTab";

import { LocationId } from "../groups/data-types";
import { PagesProps } from "../groups/Page";
import StreamTab from "./streams/StreamsTab";
import VideosTab from "./videos/VideosTab";

import { ReactComponent as CameraIcon } from "../../app/assets/cameraIcon.svg";
import { ReactComponent as WebcamIcon } from "../../app/assets/webcamIcon.svg";
import { ReactComponent as HomeIcon } from "../../app/assets/homeIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../app/assets/archiveIcon.svg";
import { ReactComponent as TrashIcon } from "../../app/assets/trashIcon.svg";
import { ReactComponent as MovieIcon } from "../../app/assets/movieIcon.svg";
import { ReactComponent as AnalyticIcon } from "../../app/assets/analyticIconSideBar.svg";

export type TabId = "video" | "stream" | "line";

interface TabGetterOptions {
  queryString: string;
  hasStreams: boolean;
  hasVideos: boolean;
}

const tabs: TabId[] = ["video", "stream", "line"];
const visibleTabs: TabId[] = ["video", "stream"];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
    boxSizing: "border-box",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[400],
    boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
    borderRadius: theme.spacing(3),
    border: "1px solid rgba(44, 103, 255, 0.1)",
  },
  pageHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  locationControl: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  pageContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",

    "& .MuiTabs-root .MuiTabs-fixed .MuiTabs-flexContainer .Mui-selected svg path":
      {
        fill: theme.palette.primary.main,
      },
  },
  workFlowWrap: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
  fixedHeader: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: theme.spacing(16),
    width: (props: { leftPanelIsOpen?: boolean }) =>
      props.leftPanelIsOpen
        ? `calc(100% - 256px - 48px)`
        : `calc(100% - 104px - 48px)`,
    display: "flex",
    flexDirection: "column",
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    height: 38,
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(0.4),
    marginBottom: theme.spacing(1),
    position: "relative",
    bottom: "6px",
  },
  generalInfo: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    whiteSpace: "nowrap",
    marginRight: theme.spacing(3),
    color: theme.palette.primary.dark,
  },
  divider: {
    width: "100%",
    margin: theme.spacing(0, 0, 3, 0),
  },
  tab: {
    fontWeight: 600,
    color: "#122045",
    textTransform: "none",

    "& svg path": {
      fill: "#122045",
    },
  },
  analyticIcon: {
    transform: "scale(0.8)",
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
}));

/**
 * Returns current Tab based on URL + data state
 */
const _getCurrentLocationTab = ({
  queryString,
  hasStreams,
  hasVideos,
}: TabGetterOptions): TabId => {
  const tab = getCurrentTab(tabs, new URLSearchParams(queryString));
  if (tab === "line") {
    return "line";
  }

  if (hasVideos) {
    return "video";
  }

  if (hasStreams) {
    return "stream";
  }

  return tab;
};

/**
 * Returns page content by tab ID
 *
 * @param currentTab - tab ID
 * @param showPageTitle - whether to display page title or not
 * @param locationId - location ID
 * @param locationIdReplacing - location ID
 */
const _getPageComponent = (
  currentTab: TabId,
  showPageTitle: boolean,
  locationId?: LocationId,
  isContentLoaded?: boolean,
  handleTabChange?: (evt: any, tabIndex: number) => void
): ReactElement => {
  switch (currentTab) {
    case "video": {
      return (
        <VideosTab
          isContentLoaded={isContentLoaded}
          key={locationId}
          locationId={locationId}
          showTitle={showPageTitle}
          currentTab={currentTab}
          visibleTabs={visibleTabs}
          handleTabChange={handleTabChange}
        />
      );
    }
    case "stream": {
      return (
        <StreamTab
          isContentLoaded={isContentLoaded}
          key={locationId}
          locationId={locationId}
          showTitle={showPageTitle}
          currentTab={currentTab}
          visibleTabs={visibleTabs}
          handleTabChange={handleTabChange}
        />
      );
    }
    case "line": {
      return <LineEditorTab key={locationId} locationId={locationId} />;
    }
  }
};

export default function LocationPage(props: PagesProps): ReactElement {
  const { t } = useTranslation();
  const { leftPanelIsOpen } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const appClasses = useAppLayoutStyles();
  const classes = useStyles({ leftPanelIsOpen });

  // check :id param from the query for a valid LocationId
  const { id } = useParams<{ id?: string }>();
  const isValidLocation = useSelector(selectIsValidLocation(id));
  const locationId = isValidLocation === true ? (id as LocationId) : undefined;

  // Check if the Location already has any Videos
  const groupsLoaded = useSelector(selectGroupsLoaded);
  const selectVideos = useMemo(
    () => getVideosSelector(locationId),
    [locationId]
  );
  const selectStreams = useMemo(
    () => getStreamsSelector(locationId),
    [locationId]
  );
  const selectLocationLoaded = useMemo(
    () => getLoadedSelector(locationId),
    [locationId]
  );
  const selectNotInitialized = useMemo(
    () => getNotInitializedSelector(locationId),
    [locationId]
  );
  const videos = useSelector(selectVideos);
  const streams = useSelector(selectStreams);
  const hasVideos = useSelector(selectVideos).length > 0;
  const hasStreams =
    useSelector(selectStreams).filter(({ isTmp = false }) => !isTmp).length > 0;
  const isLocationNotInitialized = useSelector(selectNotInitialized);
  const isLocationLoaded = useSelector(selectLocationLoaded);

  const isDemoLocation = useMemo(() => hasCloneVideo(videos), [videos]);

  // Videos isProcessing - здесь будет проверка через состояние
  const videoWithLine =
    videos.find(({ line }) => line !== undefined) !== undefined;
  const streamWithLine =
    streams.find(({ line }) => line !== undefined) !== undefined;

  const isContentLoaded =
    groupsLoaded && (isLocationLoaded ?? locationId === undefined);
  // Handle videos/streams tabs change
  const currentTab = _getCurrentLocationTab({
    queryString: location.search,
    hasStreams,
    hasVideos,
  });

  const showPageTitle =
    hasVideos || hasStreams || !visibleTabs.includes(currentTab);

  const handleTabChange = useCallback(
    (evt, tabIndex: number) => {
      const query = new URLSearchParams(location.search);
      const value: TabId = tabs[tabIndex];
      if (value === currentTab) {
        return;
      }

      query.set("tab", value);
      history.push(`?${query.toString()}`);
    },
    [currentTab]
  );
  const handleLocationChanged = useCallback(
    (newLocationId?: LocationId) => {
      const tab = `?tab=${currentTab}`;

      if (newLocationId) {
        history.replace(
          LOCATION_URL.PAGE.replace(`{id}`, `${newLocationId}${tab}`)
        );
      } else {
        history.replace(LOCATION_URL.PAGE.replace(`{id}`, `${tab}`));
      }
    },
    [history, location]
  );

  // Load data corresponding to the current location ID
  const [lastLocationId, setLastLocationId] = useState<LocationId>();
  useEffect(() => {
    if (locationId !== undefined && lastLocationId !== locationId) {
      setLastLocationId(locationId);

      if (isLocationNotInitialized ?? false) {
        dispatch(loadLocation(locationId));
      }
    }
  }, [
    dispatch,
    setLastLocationId,
    isLocationNotInitialized,
    locationId,
    lastLocationId,
  ]);

  const gotoAnalyticsBtn = useMemo(() => {
    const isBtnDisabled = locationId === undefined;
    const btn = (
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AnalyticIcon className={classes.analyticIcon} />}
        style={{ whiteSpace: "nowrap", fontWeight: 600 }}
        disabled={isBtnDisabled}
      >
        {t(I18N.location.BTN_VIEW_ANALYTICS)}
      </Button>
    );

    if (isBtnDisabled) {
      return btn;
    }

    return (
      <VisiusLink to={`/location/${locationId as LocationId}/dashboard`}>
        {btn}
      </VisiusLink>
    );
  }, [t, locationId]);

  return (
    <div className={appClasses.root}>
      <VisiusAppBar
        title={t(I18N.location.TITLE_LOCATION_PAGE)}
        leftPanelIsOpen={props.leftPanelIsOpen}
      />
      <VisiusDrawer
        items={[
          { title: I18N.main.LINK_VIDEOS, link: "/", icon: <HomeIcon /> },
          {
            title: I18N.common.LINK_LOCATION,
            link: "#",
            icon: <MovieIcon />,
            selected: true,
          },
          {
            title: I18N.common.LINK_ANALYTICS,
            link:
              locationId !== undefined
                ? `/location/${locationId}/dashboard`
                : "/location/dashboard",
            icon: <AnalyticIcon />,
          },
          {
            title: I18N.main.LINK_ARCHIVE,
            link: "/main?tab=archive",
            icon: <ArchiveIcon />,
          },
          {
            title: I18N.main.LINK_TRASH,
            link: "/main?tab=trash",
            icon: <TrashIcon />,
          },
        ]}
        open={props.leftPanelIsOpen}
        handleDrawerToggle={props.handleDrawerToggle}
        buttons={[
          {
            title: I18N.main.BTN_ADD_VIDEO,
            link: "/location?tab=video",
            icon: <CameraIcon />,
          },
          {
            title: I18N.main.BTN_ADD_STREAM,
            link: "/location?tab=stream",
            icon: <WebcamIcon />,
          },
        ]}
      />
      <div className={appClasses.content}>
        <div className={appClasses.toolbar} />
        <main>
          <div className={classes.fixedHeader}>
            <div className={classes.headerWrapper}>
              <div className={classes.generalInfo}>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  variant="h3"
                >
                  Данные с локации
                </Typography>
              </div>
            </div>
            <Divider variant="middle" className={classes.divider} />
          </div>

          <Paper variant="elevation" className={classes.root}>
            <div className={classes.pageHeader}>
              <div className={classes.locationControl}>
                <LocationControl
                  locationId={locationId}
                  onChange={handleLocationChanged}
                />
              </div>
              <div>
                {isContentLoaded &&
                  (videoWithLine || streamWithLine || isDemoLocation) &&
                  gotoAnalyticsBtn}
              </div>
            </div>
            <div className={classes.pageContent}>
              {
                // If groups/videos/streams data is being loaded display DelayedProgress
                isContentLoaded ? (
                  <>
                    {_getPageComponent(
                      currentTab,
                      showPageTitle,
                      locationId,
                      isContentLoaded,
                      handleTabChange
                    )}
                  </>
                ) : (
                  <DelayedProgress variant="text" />
                )
              }
            </div>
          </Paper>
        </main>
      </div>
    </div>
  );
}
