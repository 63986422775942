import { Theme, Tooltip, withStyles } from "@material-ui/core";

export default withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    maxWidth: "none",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1.5),
    "&.MuiTooltip-tooltipPlacementBottom": {
      marginTop: theme.spacing(1),
    },
    "& .MuiTooltip-arrow": {
      color: theme.palette.common.white,
      "&::before": {
        boxShadow: theme.shadows[1],
      },
    },
  },
}))(Tooltip);
