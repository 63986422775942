import { makeStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "../theme";

export const VISIUS_COLORS = {
  black: "#2B313D",
  grey: "#1A1C1F",
  lightGrey: "#7C7C7C",

  INFO: "#5189F4",
  ERROR: "#EB5757",
  SUCCESS: "#27AE60",
  WARN: "#F1B90D",
  CAM_SUCCESS: "#35BD6E",

  INFO_BACKGROUND: "#D1E1FF",
  ERROR_BACKGROUND: "#FFC9C9",
  SUCCESS_BACKGROUND: "#C0EFD4",
  WARN_BACKGROUND: "#FFE9A6",
};

export const useFormStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    minHeight: "100%",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "100%",
  },
  pageFooter: {
    marginTop: theme.spacing(4),
  },
  avatar: {
    marginBottom: theme.spacing(4),
  },
  divider: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  formFooterActionsProfile: {
    marginTop: 10,
    display: "flex",
    textAlign: "center",
    justifyContent: "flex-start",

    "& .MuiButtonBase-root": {
      "&.Mui-disabled": {
        color: theme.palette.common.white,
        boxShadow: "none",
        background: "#DFE6FB",
      },
    },
  },
  formFooterActions: {
    marginTop: theme.spacing(6.25),
    textAlign: "center",
  },
  formIntro: {
    marginBottom: theme.spacing(6.25),
    textAlign: "center",
  },
  labeledInputField: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    marginBottom: theme.spacing(4),

    "& .MuiInputBase-root": {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      background: theme.palette.common.white,

      "&:has(.MuiSvgIcon-colorError) input::placeholder": {
        color: "red",
        opacity: 1,
      },

      "& input::placeholder": {
        color: theme.palette.grey[500],
        opacity: 1,
      },

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "& .Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },

      "& fieldset": {
        border: "none ",
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "14.5px 14px",
    },
    "& .MuiFormLabel-root": {
      minWidth: "144px",
      flexShrink: 0,
      fontSize: "14px",
      color: theme.palette.primary.dark,
      fontWeight: 500,
      flexGrow: 0,
      margin: "0 0 10px 0",

      "& span": {
        display: "none",
      },
    },
    "& .MuiTextField-root": {
      width: 280,
      margin: 0,
    },
  },
  labeledButtonField: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    marginBottom: theme.spacing(3),

    "& .MuiInputBase-root": {
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",
      background: theme.palette.common.white,

      "&:has(.MuiSvgIcon-colorError) input::placeholder": {
        color: "red",
        opacity: 1,
      },

      "& input::placeholder": {
        color: theme.palette.grey[500],
        opacity: 1,
      },

      "& .MuiInputBase-input": {
        color: "#122045",
      },

      "&:not(.Mui-focused):not(.Mui-disabled):hover fieldset": {
        border: "1px solid #DFE6FB",
      },

      "& .Mui-disabled": {
        color: "#DFE6FB",
        boxShadow: "none",
      },

      "& fieldset": {
        border: "none ",
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "14.5px 14px",
    },
    "& .MuiFormLabel-root": {
      minWidth: "144px",
      flexShrink: 0,
      flexGrow: 0,
      margin: "0 0 10px 0",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.75,
      paddingTop: theme.spacing(0.6),
    },
    "& .MuiTextField-root": {
      width: 280,
      margin: 0,
    },
    "& > a:hover": {
      textDecoration: "none",
    },
  },
  header: {
    fontSize: 48,
    fontWeight: 700,
    marginBottom: theme.spacing(2.5),
    textAlign: "center",
  },
  loginBtn: {
    textTransform: "none",
    whiteSpace: "normal",
    fontFamily: "Inter, sans-serif",
    fontSize: "20px",
    fontWeight: 500,
    padding: theme.spacing(1.75, 4),
    marginBottom: theme.spacing(2.5),
    letterSpacing: "initial",
    "& + $loginBtn": {
      marginLeft: theme.spacing(2),
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(178, 185, 202, 0.7)",
      color: COLORS.GREY2,
    },
  },
  loginBtnLink: {
    "&:hover": {
      textDecoration: "none",
    },
    "& + $loginBtn": {
      marginLeft: theme.spacing(2),
    },
  },
  formLink: {
    marginTop: theme.spacing(2.5),
    fontSize: 16,
    color: theme.palette.grey[300],
  },
  footerLink: {
    textDecoration: "underline",
  },
}));

export const useContentStyles = makeStyles<Theme>((theme: Theme) => ({
  alert: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
  },
  dimmer: {
    position: "relative",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0,0,0,0.75)",
    },
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));

export const useAppLayoutStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  profileCardContainer: {
    display: "flex",
    justifyContent: "start",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    "& main": {
      padding: theme.spacing(18, 2, 2, 6),
      flexGrow: 1,
    },
    "& main.hasMarginOffset": {
      padding: theme.spacing(2),
    },
  },
  linkButton: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  onlyForTrashAndArchiveTabs: {
    [theme.breakpoints.between("md", "lg")]: {
      padding: "196px 16px 16px 48px !important",
    },
  },
}));
