import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import I18N from '../../app/i18n/strings'
import { useFormStyles } from '../../app/styles'
import AuthTemplate from '../../components/AuthPage'
import Form, {
  FormValidatorOptions,
  getConfirmationValidator,
  getPhoneValidator,
  getRequiredValidator,
  getRulesValidator
} from '../../components/Form'
import VisiusLink from '../../components/Link'
import TextField from '../../components/TextField'
import {
  actions,
  selectIsSignUpStarted,
  selectSignUpError,
  signUp
} from './signUpSlice'

interface SignUpData {
  firstName: string
  email: string
  phoneNumber: string
  password: string
  confirmation: string
}

export default function SignUp (): ReactElement {
  const classes = useFormStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const isSignUpStarted = useSelector(selectIsSignUpStarted)
  const signUpError = useSelector(selectSignUpError)
  const signInLink = useMemo(() => (
    <VisiusLink to='/login' onClick={() => dispatch(actions.reset())}>
      {t(I18N.signUp.LINK_SIGN_IN)}
    </VisiusLink>
  ), [t, dispatch])

  const handleInput = useCallback((): void => {
    if (signUpError !== undefined) {
      dispatch(actions.reset())
    }
  }, [dispatch, signUpError])

  const handleSubmit = useCallback((data: SignUpData): void => {
    dispatch(signUp({
      name: data.firstName,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      history,
      redirectTo: '/'
    }))
  }, [dispatch, history])

  const handleValidation = useCallback((options: FormValidatorOptions<SignUpData>) => {
    return {
      ...getRequiredValidator(['firstName', 'email', 'phoneNumber', 'password', 'confirmation'])(options),
      ...getConfirmationValidator('password', 'confirmation')(options),
      ...getRulesValidator('password')(options),
      ...getPhoneValidator('phoneNumber')(options)
    }
  }, [])

  const licenseLink = (
    <VisiusLink to='#' color='textSecondary' className={classes.footerLink}>
      {t(I18N.signUp.LINK_LICENSE_AGREEMENT)}
    </VisiusLink>
  )

  return (
    <AuthTemplate title={t(I18N.signUp.TITLE_BASIC)}>
      <Form<SignUpData>
        onSubmit={handleSubmit}
        validate={handleValidation}
      >
        <Typography variant='body2' className={classes.formIntro}>
          {t(I18N.signUp.MSG_HAS_ACCOUNT)} {signInLink}
        </Typography>
        <TextField
          required
          label={t(I18N.signUp.LABEL_NAME)}
          name='firstName'
          autoComplete='name'
          autoFocus
          onChange={handleInput}
          errors={signUpError?.firstName ?? []}
        />
        <TextField
          required
          label='Email'
          name='email'
          autoComplete='email'
          onChange={handleInput}
          errors={signUpError?.email ?? []}
        />
        <TextField
          required
          label='Номер телефона'
          name='phoneNumber'
          autoComplete='phoneNumber'
          onChange={handleInput}
          errors={signUpError?.phoneNumber ?? []}
        />
        <TextField
          required
          autoComplete='new-password'
          name='password'
          label={t(I18N.signUp.LABEL_PASSWORD)}
          type='password'
          onChange={handleInput}
          errors={signUpError?.password ?? []}
        />
        <TextField
          required
          autoComplete='new-password'
          name='confirmation'
          onChange={handleInput}
          label={t(I18N.signUp.LABEL_CONFIRMATION)}
          type='password'
        />
        <div className={classes.formFooterActions}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={isSignUpStarted}
            className={classes.loginBtn}
          >
            {t(I18N.signUp.BTN_SIGNUP)}
          </Button>
          <Typography variant='body2' color='textSecondary'>
            {t(I18N.signUp.MSG_LICENSE_AGREEMENT)} {licenseLink}
          </Typography>
        </div>
      </Form>
    </AuthTemplate>
  )
}
