import moment, { Moment } from 'moment'
import { TimeZoneName } from '../../app/timezone'
import { ISODateString, LocationId } from '../groups/data-types'
import { Line } from './line-editor/components/DrawLine'

export type VideoId = string
export type StreamId = string
export type UploadId = string
export type DurationString = string

export enum VIDEO_STATE {
  UPLOADING,
  UPLOADED,
  FAILED
}

export enum UPLOAD_STATE {
  NONE,
  CREATING,
  UPLOADING,
  UPLOADED,
  FAILED
}

export enum VIDEO_PROCESSING_STATE {
  NOT_STARTED,
  PENDING,
  PREPARING,
  PROCESSING,
  INFRASTRUCTURE_PREPARED,
  PREPARING_INFRASTRUCTURE_ERROR,
  STOP_REQUESTED,
  STOPPING,
  STOPPED,
  FINISHED,
  STOPPING_ERROR,
  NOTIFICATION_ERROR
}

export enum VIDEO_PREPROCESSING_STATE {
  CANCELED = 'Canceled',
  FINISHED = 'Finished',
  PROCESSING = 'Processing',
  QUEUED = 'Queued',
  SCHEDULED = 'Scheduled'
}

export interface VideoFilePreprocessing {
  jobState: VIDEO_PREPROCESSING_STATE
  progress: number
}

export interface VideoData {
  id: VideoId
  name: string
  date: Moment
  duration: DurationString
  size: number
  state: VIDEO_STATE

  analyticsDate?: Moment
  isBusy?: boolean
  line?: Line
  sasUrl?: string
  thumbnailUrl?: string
  url?: string
  videoProcessingState: VIDEO_PROCESSING_STATE
  isClone: boolean
  originalVideoFileId: VideoId
  filePreprocessing?: VideoFilePreprocessing
  isBillingDenied: boolean
}

export interface StreamData {
  id: StreamId
  url: string
  timeZone: TimeZoneName
  isConnected: boolean
  analyticsDate?: Moment

  connectedAt?: Moment
  disconnectedAt?: Moment
  isBusy?: boolean
  isTmp?: boolean
  line?: Line
  thumbnailUrl?: string
  videoProcessingState: VIDEO_PROCESSING_STATE
}

export interface UploadData {
  file: File
  date: Moment|null
  state: UPLOAD_STATE
  progress: number
  locationId: LocationId
  fileId?: VideoId
  width?: number
  height?: number
}

export interface VideoFileDto {
  id: VideoId
  fileName: string
  startVideoDateTime: ISODateString
  duration: DurationString
  fileSize: number
  createAt: ISODateString
  videoFileState: VIDEO_STATE
  locationId: LocationId
  thumbnailUrl: string|null
  url: string|null
  countingLine: {
    x1: number
    x2: number
    y1: number
    y2: number
  } | null
  videoProcessingState: VIDEO_PROCESSING_STATE
  isClone: boolean
  originalVideoFileId: VideoId
  filePreprocessing: VideoFilePreprocessing
  isBillingDenied: boolean
}

export interface StreamDto {
  id: StreamId
  ipAddress: string
  createAt: ISODateString
  connectionDateTime: ISODateString
  disconnectionDateTime: ISODateString
  isCameraConnected: boolean
  authorId: string
  locationId: string
  thumbnailUrl: string|null
  countingLine: {
    x1: number
    y1: number
    x2: number
    y2: number
  }|null
  dateTimeZoneId: string
  videoProcessingState: VIDEO_PROCESSING_STATE
}

/**
 * Converts Stream DTO (backend data format) to the frontend data format
 * @param dto - stream description from the backend
 * @returns {StreamData} - Stream representation for the frontend store
 */
export const streamDtoToStream = (dto: StreamDto): StreamData => {
  const line = dto.countingLine !== null
    ? [
      { x: dto.countingLine.x1, y: dto.countingLine.y1 },
      { x: dto.countingLine.x2, y: dto.countingLine.y2 }
    ]
    : undefined

  return {
    id: dto.id,
    url: dto.ipAddress,
    thumbnailUrl: dto.thumbnailUrl !== null ? dto.thumbnailUrl : undefined,
    line,

    // TODO: set actual timezone value
    timeZone: dto.dateTimeZoneId,
    connectedAt: moment(dto.connectionDateTime),
    disconnectedAt: moment(dto.disconnectionDateTime),
    isConnected: dto.isCameraConnected,
    videoProcessingState: dto.videoProcessingState
  }
}

/**
 * Converts VideoFile DTO (backend data format) to the frontend data format
 * @param dto - Video description from the backend
 * @returns {StreamData} - Video representation for the frontend store
 */
export const videoDtoToData = (dto: VideoFileDto): VideoData => {
  const line = dto.countingLine !== null
    ? [
      { x: dto.countingLine.x1, y: dto.countingLine.y1 },
      { x: dto.countingLine.x2, y: dto.countingLine.y2 }
    ]
    : undefined
  return {
    id: dto.id,
    date: moment.utc(dto.startVideoDateTime),
    name: dto.fileName,
    size: dto.fileSize,
    duration: moment.utc(dto.duration, 'HH:mm:SS').format('HH:mm:SS'),
    state: dto.videoFileState,
    thumbnailUrl: dto.thumbnailUrl !== null ? dto.thumbnailUrl : undefined,
    url: dto.url ?? undefined,
    line,
    videoProcessingState: dto.videoProcessingState,
    isBillingDenied: dto.isBillingDenied,
    isClone: dto.isClone,
    originalVideoFileId: dto.originalVideoFileId,
    filePreprocessing: dto.filePreprocessing
  }
}
