import React, { ReactElement, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CardContent,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";

import {
  actions,
  editProfile,
  ProfileUpdate,
  selectError,
  selectStarted,
} from "./profileSlice";

import I18N from "../../app/i18n/strings";
import { useFormStyles } from "../../app/styles";

import Form, {
  FormValidatorOptions,
  getConfirmationValidator,
  getRequiredValidator,
  getRulesValidator,
} from "../../components/Form";
import TextField from "../../components/TextField";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 800,
    background: "transparent",
    "& .MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(6),
    },
    border: 0,
  },
  content: {
    padding: theme.spacing(0, 6, 6, 6),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  saveBtn: {
    marginLeft: theme.spacing(2),
  },
}));

interface FormData {
  password: string;
  newPassword: string;
  confirmation: string;
}

export default function ProfileTab(): ReactElement {
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const classes = useFormStyles();
  const changePasswordClasses = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileError = useSelector(selectError);
  const submitStarted = useSelector(selectStarted);
  const history = useHistory();

  const _onChange = (e: any): void => {
    if (profileError !== undefined) {
      dispatch(actions.reset());
    }

    const newPassword = (
      document.getElementsByName("newPassword")[0] as HTMLInputElement
    ).value;
    const confirmation = (
      document.getElementsByName("confirmation")[0] as HTMLInputElement
    ).value;
    setPasswordsMatch(newPassword === confirmation);
  };
  const _onSubmit = (data: FormData): void => {
    const update: ProfileUpdate = {
      currentPassword: data.password,
      newPassword: data.newPassword,
    };
    dispatch(editProfile(update));
  };

  const handleValidation = useCallback(
    (options: FormValidatorOptions<FormData>) => {
      return {
        ...getRequiredValidator(["password", "newPassword", "confirmation"])(
          options
        ),
        ...getConfirmationValidator("newPassword", "confirmation")(options),
        ...getRulesValidator("newPassword")(options),
      };
    },
    []
  );

  return (
    <Card className={changePasswordClasses.root} variant="outlined">
      <CardContent className={changePasswordClasses.content}>
        <Form<FormData> onSubmit={_onSubmit} validate={handleValidation}>
          <Typography className={changePasswordClasses.title} variant="h4">
            {t(I18N.profile.TITLE_CHANGE_PASSWORD)}
          </Typography>
          <div className={classes.labeledInputField}>
            <InputLabel>{t(I18N.profile.LABEL_OLD_PASSWORD)}</InputLabel>
            <TextField
              variant="outlined"
              autoComplete="current-password"
              name="password"
              type="password"
              onChange={_onChange}
              errors={profileError?.password}
              errorPosition="right"
              placeholder={t(I18N.profile.PLACEHOLDER_OLD_PASSWORD)}
            />
          </div>
          <div className={classes.labeledInputField}>
            <InputLabel>{t(I18N.profile.LABEL_NEW_PASSWORD)}</InputLabel>
            <TextField
              variant="outlined"
              autoComplete="new-password"
              name="newPassword"
              type="password"
              onChange={_onChange}
              placeholder={t(I18N.profile.PLACEHOLDER_NEW_PASSWORD)}
              errorPosition="right"
            />
          </div>
          <div className={classes.labeledInputField}>
            <InputLabel>{t(I18N.profile.LABEL_CONFIRMATION)}</InputLabel>
            <TextField
              variant="outlined"
              autoComplete="new-password"
              name="confirmation"
              type="password"
              onChange={_onChange}
              placeholder={t(I18N.profile.PLACEHOLDER_CONFIRMATION)}
              errorPosition="right"
            />
          </div>
          <div className={classes.formFooterActionsProfile}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t(I18N.profile.BTN_BACK)}
            </Button>
            <Button
              className={changePasswordClasses.saveBtn}
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitStarted || !passwordsMatch}
              startIcon={<Save />}
            >
              {t(I18N.profile.BTN_CHANGE_PASSWORD)}
            </Button>
          </div>
        </Form>
      </CardContent>
    </Card>
  );
}
