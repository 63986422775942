import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";

import I18N from "../../../app/i18n/strings";
import { moveLocationToTrash } from "../main/asyncActions";
import { unarchiveLocation } from "./asyncActions";

import BaseLocationCard, {
  LocationCardProps,
} from "../components/BaseLocationCard";
import LocationSummary from "../components/LocationSummary";
import LocationTitle from "../components/LocationTitle";

import { ReactComponent as TrashIcon } from "../../../app/assets/trashIcon.svg";
import { ReactComponent as RecoverIcon } from "../../../app/assets/recoverDataIcon.svg";

export default function ArchiveLocationCard({
  groupId,
  data,
}: LocationCardProps): ReactElement {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const _moveToMain = (): void => {
    dispatch(unarchiveLocation({ groupId, location: data }));
  };
  const _moveToTrash = (): void => {
    dispatch(moveLocationToTrash({ groupId, location: data }));
  };

  return (
    <BaseLocationCard
      groupId={groupId}
      data={data}
      menuItems={[
        <MenuItem key="restore" onClick={_moveToMain}>
          <ListItemIcon>
            <RecoverIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="caption">
                {t(I18N.main.BTN_RESTORE_LOCATION)}
              </Typography>
            }
          />
        </MenuItem>,
        <MenuItem key="delete" onClick={_moveToTrash}>
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="caption">
                {t(I18N.main.BTN_DELETE_LOCATION)}
              </Typography>
            }
          />
        </MenuItem>,
      ]}
    >
      <LocationTitle title={data.title} />
      <LocationSummary data={data} />
    </BaseLocationCard>
  );
}
