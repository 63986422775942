import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { Check } from "@material-ui/icons";
import {
  withStyles,
  StepConnector,
  makeStyles,
  StepIconProps,
} from "@material-ui/core";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { LocationId } from "../../groups/data-types";
import { getUploadsSelector, getVideosSelector } from "../selectors";
import { StreamData, UPLOAD_STATE, VIDEO_STATE } from "../data-mapper";

import I18N from "../../../app/i18n/strings";
import { hasCloneVideo } from "../../../app/utils";

import { useWorkFlowStyles } from "./workflowStyle";

import clsx from "clsx";
import { COLORS } from "../../../theme";

interface WorkFlowProps {
  locationId?: LocationId;
  addLineStep?: boolean;
  streams?: StreamData[];
}

enum COMPLETED_STEPS {
  START,
  PICK_LOCATION,
  ADD_VIDEO,
  PICK_DATE_TIME,
  UPLOAD_TO_SERVER,
  ADD_LINE,
  START_PROCESSING,
}

const WorkFlowConnector = withStyles({
  alternativeLabel: {
    top: 16,
    left: "calc(-50% + 8px)",
    right: "calc(50% + 8px)",
    borderWidth: 4,
    borderRadius: 16,
    borderColor: COLORS.BLUE1,
    backgroundColor: COLORS.BLUE1,
    borderStyle: "solid",
    maxWidth: 184,
  },
  active: {
    "& $line": {
      borderColor: COLORS.BLUE5,
    },
  },
  completed: {
    "& $line": {
      borderColor: COLORS.BLUE5,
    },
  },
  disabled: {
    "& $line": {
      backgroundColor: COLORS.BLUE1,
      borderColor: COLORS.BLUE1,
    },
  },
  line: {
    backgroundColor: COLORS.BLUE1,
    borderColor: COLORS.BLUE1,
    borderWidth: 8,
    borderRadius: 1,
  },
})(StepConnector);

const useWorkFlowIconStyles = makeStyles({
  root: {
    zIndex: 5,
    color: COLORS.BLUE1,
    display: "flex",
    alignItems: "center",
    borderWidth: 8,
    borderRadius: 32,
    borderColor: COLORS.BLUE5,
    borderStyle: "solid",
    backgroundColor: COLORS.BLUE5,
  },
  text: {
    color: COLORS.WHITE,
    zIndex: 1,
    fontSize: 18,
  },
  active: {
    color: COLORS.BLUE1,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: COLORS.BLUE1,
  },
  completed: {
    backgroundColor: COLORS.BLUE5,
    color: COLORS.WHITE,
    zIndex: 1,
    fontSize: 18,
  },
});

function WorkFlowStepIcon(props: StepIconProps): ReactElement {
  const classes = useWorkFlowIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed === true ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function getStepsVideos(): string[] {
  return [
    I18N.location.WORKFLOW_PICK_LOCATION,
    I18N.location.WORKFLOW_ADD_VIDEOS,
    I18N.location.WORKFLOW_PICK_DATE_TIME,
    I18N.location.WORKFLOW_UPLOAD_TO_SERVER,
    I18N.location.WORKFLOW_ADD_LINE,
    I18N.location.WORKFLOW_START_PROCESSING,
  ];
}

function getStepsStream(): string[] {
  return [
    I18N.location.WORKFLOW_PICK_LOCATION,
    I18N.location.WORKFLOW_ADD_VIDEO_LINK,
    I18N.location.WORKFLOW_PICK_TIME_ZONE,
    I18N.location.WORKFLOW_SEND_TO_SERVER,
    I18N.location.WORKFLOW_ADD_LINE,
    I18N.location.WORKFLOW_START_PROCESSING,
  ];
}

export default function VisiusWorkFlow(
  props: WorkFlowProps
): ReactElement | null {
  const { t } = useTranslation();
  const { locationId, streams, addLineStep } = props;
  const classes = useWorkFlowStyles();
  const [completedStep, setCompletedStep] = useState(0);

  const isTmpStream = streams?.find(({ isTmp }) => isTmp);

  const location = useLocation();
  const selectVideos = useMemo(
    () => getVideosSelector(locationId),
    [locationId]
  );
  const selectUploads = useMemo(
    () => getUploadsSelector(locationId),
    [locationId]
  );
  const videos = useSelector(selectVideos);
  const uploads = useSelector(selectUploads);

  const query = new URLSearchParams(location.search);
  const isStreamTab = query.get("tab") === "stream" && videos.length === 0;
  const steps = isStreamTab ? getStepsStream() : getStepsVideos();

  const isDemoLocation = useMemo(() => hasCloneVideo(videos), [videos]);
  const savedStreams = streams?.filter(({ isTmp = false }) => !isTmp);

  useEffect(() => {
    const isDateInvalid =
      uploads.findIndex(({ date }) => {
        return date === null || !date.isValid();
      }) !== -1;
    const hasUploading =
      uploads.findIndex(({ state }) => {
        return state !== UPLOAD_STATE.UPLOADED && state !== UPLOAD_STATE.FAILED;
      }) !== -1;
    const hasUploadedVideo =
      videos.findIndex(({ state }) => {
        return state === VIDEO_STATE.UPLOADED;
      }) !== -1;
    const hasUploadingVideo =
      videos.findIndex(({ state }) => {
        return state === VIDEO_STATE.UPLOADING;
      }) !== -1;

    const videoWithLine = videos.find(({ line }) => line !== undefined);

    // Это пригодится позже
    // const isStartProcessing = videos.find(({ videoProcessingState }) => {
    //   console.log(videoProcessingState)
    //   return videoProcessingState > 0
    // }) !== undefined

    if (locationId !== undefined && !isStreamTab) {
      if (addLineStep) {
        return setCompletedStep(COMPLETED_STEPS.UPLOAD_TO_SERVER);
      }

      if (hasUploading && isDateInvalid) {
        return setCompletedStep(COMPLETED_STEPS.ADD_VIDEO);
      }

      if (hasUploading || hasUploadingVideo) {
        return setCompletedStep(COMPLETED_STEPS.PICK_DATE_TIME);
      }

      if (hasUploadedVideo && videoWithLine === undefined) {
        return setCompletedStep(COMPLETED_STEPS.UPLOAD_TO_SERVER);
      }

      // здесь будет нормальная проверка через статус обработки - isStartProcessing
      // if (!uploadHasVideo && !isVideoUploading && isVideoUploaded &&
      //   (!videoHasNotLine || videoWithLine !== undefined)) {
      //   return setActiveStep(COMPLETED_STEPS.ADD_LINE)
      // }

      if (hasUploadedVideo && videoWithLine !== undefined) {
        return setCompletedStep(COMPLETED_STEPS.START_PROCESSING);
      }

      return setCompletedStep(COMPLETED_STEPS.PICK_LOCATION);
    }
    if (locationId !== undefined && isStreamTab && streams !== undefined) {
      if (addLineStep) {
        return setCompletedStep(COMPLETED_STEPS.UPLOAD_TO_SERVER);
      }

      if (savedStreams && savedStreams.length > 0) {
        return setCompletedStep(COMPLETED_STEPS.PICK_DATE_TIME);
      }

      if (
        isTmpStream !== undefined &&
        isTmpStream?.url.length !== 0 &&
        isTmpStream?.timeZone.length !== 0
      ) {
        return setCompletedStep(COMPLETED_STEPS.PICK_DATE_TIME);
      }

      // if (hasUploadedVideo && videoWithLine !== undefined) {
      //   return setCompletedStep(COMPLETED_STEPS.START_PROCESSING);
      // }

      return setCompletedStep(COMPLETED_STEPS.PICK_LOCATION);
    }
    return setCompletedStep(COMPLETED_STEPS.START);
  }, [
    uploads,
    videos,
    locationId,
    streams,
    isStreamTab,
    completedStep,
    isTmpStream,
  ]);

  if (completedStep === COMPLETED_STEPS.START_PROCESSING || isDemoLocation) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={completedStep}
        className={classes.stepperRoot}
        connector={<WorkFlowConnector />}
      >
        {steps.map((label, id) => {
          const tLabel = t(label);
          return (
            <Step key={tLabel}>
              <StepLabel
                StepIconComponent={
                  id <= completedStep ? WorkFlowStepIcon : undefined
                }
                className={classes.stepLabel}
              >
                {tLabel}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
