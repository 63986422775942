import React, { ReactElement, useCallback, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import {
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import {
  Link as LinkIcon,
  PlayArrow,
  RadioButtonChecked,
} from "@material-ui/icons";

import { selectUserData } from "../../../auth/authSlice";
import { actions as notificationActions } from "../../../notifications/notificationsSlice";
import { StreamData, VIDEO_PROCESSING_STATE } from "../../data-mapper";

import I18N from "../../../../app/i18n/strings";
import { VISIUS_COLORS } from "../../../../app/styles";
import ModalVideoPlayer from "../../../../components/ModalVideoPlayer";
/* eslint no-void: ["error", { "allowAsStatement": true }] */

const MAX_ROW_TITLE_WIDTH = 300;
interface StreamListProps {
  streams: StreamData[];
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "auto",
  },
  icon: {
    display: "block",
  },
  processingError: {
    color: VISIUS_COLORS.ERROR,
  },
  processing: {
    color: VISIUS_COLORS.WARN,
  },
  connected: {
    color: VISIUS_COLORS.SUCCESS,
  },
  disconnected: {
    color: VISIUS_COLORS.lightGrey,
  },
  tdLinks: {
    whiteSpace: "nowrap",
  },
  streamTitle: {
    [theme.breakpoints.down(1580)]: {
      whiteSpace: "nowrap",
      maxWidth: MAX_ROW_TITLE_WIDTH,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export default function StreamsList(props: StreamListProps): ReactElement {
  const { streams } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(selectUserData);
  const isAdmin = user?.isAdmin === true;
  const dispatch = useDispatch();

  const [videoOpened, setVideoOpened] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>();

  const handleCopyLink = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      const url = (evt.target as HTMLElement).closest("a")?.href;
      void (async () => {
        try {
          if (url === undefined) {
            throw new Error("Link not found");
          }

          await navigator.clipboard.writeText(url);
          dispatch(
            notificationActions.info({
              template: "Ссылка скопирована в буфер обмена",
            })
          );
        } catch (err) {
          console.error(err);
        }
      })();
    },
    [dispatch]
  );

  const handleVideoOpen = useCallback(
    (evt: MouseEvent<HTMLAnchorElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      const url = (evt.target as HTMLElement).closest("a")?.href;
      if (url !== undefined) {
        setVideoUrl(url);
        setVideoOpened(true);
      }
    },
    [setVideoUrl, setVideoOpened]
  );

  const handleVideoClose = useCallback(
    () => setVideoOpened(false),
    [setVideoOpened]
  );

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t(I18N.location.TABLE_STREAM_URL)}</TableCell>
              <TableCell>{t(I18N.location.TABLE_STREAM_CREATED_AT)}</TableCell>
              <TableCell>
                {t(I18N.location.TABLE_STREAM_DISCONNECTED_AT)}
              </TableCell>
              <TableCell>{t(I18N.location.TABLE_DURATION)}</TableCell>
              {isAdmin && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {streams.map((stream) => {
              const processingState = stream.videoProcessingState;

              const streamNoHasProcessing =
                processingState === VIDEO_PROCESSING_STATE.NOT_STARTED ||
                processingState === VIDEO_PROCESSING_STATE.STOPPED;
              const streamHasConnected =
                processingState === VIDEO_PROCESSING_STATE.PROCESSING;
              const streamHasProcessingError =
                processingState === VIDEO_PROCESSING_STATE.NOTIFICATION_ERROR ||
                processingState ===
                  VIDEO_PROCESSING_STATE.PREPARING_INFRASTRUCTURE_ERROR ||
                processingState === VIDEO_PROCESSING_STATE.STOPPING_ERROR;

              const getIndicatorClass = (): string => {
                if (streamNoHasProcessing) {
                  return classes.disconnected;
                }

                if (streamHasConnected) {
                  return classes.connected;
                }

                if (streamHasProcessingError) {
                  return classes.processingError;
                }

                return classes.processing;
              };

              return (
                <TableRow key={stream.id}>
                  <TableCell>
                    <RadioButtonChecked
                      fontSize="small"
                      className={getIndicatorClass()}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.streamTitle}
                    component="th"
                    scope="row"
                  >
                    {stream.url}
                  </TableCell>
                  <TableCell>
                    {stream.connectedAt !== undefined
                      ? stream.connectedAt.format()
                      : ""}
                  </TableCell>
                  <TableCell>
                    {streamNoHasProcessing
                      ? stream.disconnectedAt?.format() ?? ""
                      : t(I18N.location.MSG_STREAM_IS_CONNECTED)}
                  </TableCell>
                  <TableCell>
                    {streamNoHasProcessing
                      ? t(I18N.location.MSG_STREAM_TIME, {
                          hours: `${(stream.disconnectedAt ?? moment()).diff(
                            stream.connectedAt,
                            "h"
                          )}`,
                        })
                      : t(I18N.location.MSG_STREAM_IS_CONNECTED)}
                  </TableCell>
                  {isAdmin && (
                    <TableCell className={classes.tdLinks}>
                      <Link href={stream.url} onClick={handleCopyLink}>
                        <IconButton size="small">
                          <LinkIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      <Link href={stream.url} onClick={handleVideoOpen}>
                        <IconButton size="small">
                          <PlayArrow fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {videoUrl !== undefined && (
        <ModalVideoPlayer
          open={videoOpened}
          onClose={handleVideoClose}
          url={videoUrl}
        />
      )}
    </>
  );
}
