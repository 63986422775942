import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import {
  BarGroupBar,
  BarGroupHorizontal as BarGroupHorizontalType,
} from "@visx/shape/lib/types/barGroup";
import { Text } from "@visx/text";
import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
// import { round } from '../../../app/utils'
import { BarGroupProps } from "./types";
import { d3format } from "../../../app/i18n/d3";
import { useSelector } from "react-redux";
import {
  selectIsMenMode,
  selectIsMenUniteMode,
  selectPeopleMode,
} from "../../../features/dashboard/dashboardSlice";

// const BAR_LABEL_PRECISION = 1
const LABEL_LEFT_OFFSET = 5;
const MIN_LABELED_BAR_HEIGHT = 20;

export default function HorizontalBarGroup(
  props: BarGroupProps<BarGroupHorizontalType<string>>
): ReactElement {
  const {
    barGroup,
    hideLabels = false,
    opacity,
    labels,
    onMouseOver,
    onMouseLeave,
  } = props;
  const getBarKey = (
    barGroup: BarGroupHorizontalType<string>,
    bar: BarGroupBar<string>
  ): string => {
    return `bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`;
  };

  const [hovered, setHovered] = useState(false);
  const handleMouseOver = useCallback(
    (evt: MouseEvent) => {
      setHovered(true);
      if (onMouseOver === undefined) {
        return;
      }
      onMouseOver(evt);
    },
    [onMouseOver, setHovered]
  );

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
    if (onMouseLeave === undefined) {
      return;
    }
    onMouseLeave();
  }, [onMouseLeave, setHovered]);

  const isMenMode = useSelector(selectIsMenMode);
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const peopleMode = useSelector(selectPeopleMode);
  const regexMen = /\w+Men\b/;
  const matchesMen =
    labels
      ?.map((item) => item.match(regexMen))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const regexWomen = /\w+Women\b/;
  const matchesWomen =
    labels
      ?.map((item) => item.match(regexWomen))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const regexUnknown = /\w+Unknown\b/;
  const matchesUnknown =
    labels
      ?.map((item) => item.match(regexUnknown))
      .flat()
      .filter((i) => i !== null).length ?? 0;

  const filteredBarGroup = isMenMode
    ? barGroup.bars.filter(
        (i) => i.key !== "cars" && i.key !== "bikes" && i.key !== "men"
      )
    : barGroup.bars.filter(
        (i) => i.key === "cars" || i.key === "bikes" || i.key === "men"
      );

  const averageY =
    barGroup.bars.length !== 0
      ? barGroup.bars.reduce((sum, obj) => sum + obj.y, 0) /
        barGroup.bars.length
      : 0;

  const onlyMenBars = useMemo(() => {
    const onlyMenBars: BarGroupBar<string>[] = [];

    filteredBarGroup.forEach((i) => {
      const newBar: BarGroupBar<string> = {
        index: i.index,
        color: i.color,
        height: i.height,
        key: i.key,
        value: i.value,
        width: i.width,
        x: i.x,
        y: averageY - i.height,
      };

      if (i.key === "oldMen") {
        newBar.width = i.width / matchesMen;
      }

      if (i.key === "middleMen") {
        newBar.width = i.width / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        newBar.x = oldMen ? oldMen.width / matchesMen : 0;
      }

      if (i.key === "youngMen") {
        newBar.width = i.width / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        const middleMen = filteredBarGroup.find((i) => i.key === "middleMen");
        newBar.x =
          (oldMen ? oldMen.width / matchesMen : 0) +
          (middleMen ? middleMen.width / matchesMen : 0);
      }

      if (i.key === "unknownMen") {
        newBar.width = i.width / matchesMen;
        const oldMen = filteredBarGroup.find((i) => i.key === "oldMen");
        const middleMen = filteredBarGroup.find((i) => i.key === "middleMen");
        const youngMen = filteredBarGroup.find((i) => i.key === "youngMen");

        newBar.x =
          (oldMen ? oldMen.width / matchesMen : 0) +
          (middleMen ? middleMen.width / matchesMen : 0) +
          (youngMen ? youngMen.width / matchesMen : 0);
      }

      if (i.key === "oldWomen") {
        newBar.width = i.width / matchesWomen;

        newBar.y += i.height;
      }

      if (i.key === "middleWomen") {
        newBar.width = i.width / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");

        newBar.y += i.height;

        newBar.x = oldWomen ? oldWomen.width / matchesWomen : 0;
      }

      if (i.key === "youngWomen") {
        newBar.width = i.width / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");

        const middleWomen = filteredBarGroup.find(
          (i) => i.key === "middleWomen"
        );

        newBar.y += i.height;

        newBar.x =
          (oldWomen ? oldWomen.width / matchesWomen : 0) +
          (middleWomen ? middleWomen.width / matchesWomen : 0);
      }

      if (i.key === "unknownWomen") {
        newBar.width = i.width / matchesWomen;

        const oldWomen = filteredBarGroup.find((i) => i.key === "oldWomen");
        const middleWomen = filteredBarGroup.find(
          (i) => i.key === "middleWomen"
        );
        const youngWomen = filteredBarGroup.find((i) => i.key === "youngWomen");

        newBar.y += i.height;

        newBar.x =
          (oldWomen ? oldWomen.width / matchesWomen : 0) +
          (middleWomen ? middleWomen.width / matchesWomen : 0) +
          (youngWomen ? youngWomen.width / matchesWomen : 0);
      }

      if (i.key === "oldUnknown") {
        newBar.width = i.width / matchesUnknown;

        newBar.y += i.height * 2;
      }

      if (i.key === "middleUnknown") {
        newBar.width = i.width / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");

        newBar.y += i.height * 2;

        newBar.x = oldUnknown ? oldUnknown.width / matchesUnknown : 0;
      }

      if (i.key === "youngUnknown") {
        newBar.width = i.width / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");
        const middleUnknown = filteredBarGroup.find(
          (i) => i.key === "middleUnknown"
        );

        newBar.y += i.height * 2;

        newBar.x =
          (oldUnknown ? oldUnknown.width / matchesUnknown : 0) +
          (middleUnknown ? middleUnknown.width / matchesUnknown : 0);
      }

      if (i.key === "unknownUnknown") {
        newBar.width = i.width / matchesUnknown;

        const oldUnknown = filteredBarGroup.find((i) => i.key === "oldUnknown");
        const middleUnknown = filteredBarGroup.find(
          (i) => i.key === "middleUnknown"
        );
        const youngUnknown = filteredBarGroup.find(
          (i) => i.key === "youngUnknown"
        );

        newBar.y += i.height * 2;

        newBar.x =
          (oldUnknown ? oldUnknown.width / matchesUnknown : 0) +
          (middleUnknown ? middleUnknown.width / matchesUnknown : 0) +
          (youngUnknown ? youngUnknown.width / matchesUnknown : 0);
      }

      onlyMenBars.push(newBar);
    });

    return onlyMenBars;
  }, [barGroup, peopleMode]);

  const onlyUniteMenBars = useMemo(() => {
    const data: BarGroupBar<string>[] = [];

    barGroup.bars
      .filter((i) => i.key === "men")
      .forEach((i) => {
        const newBar: BarGroupBar<string> = {
          index: i.index,
          color: i.color,
          height: i.height,
          key: i.key,
          value: i.value,
          width: i.width,
          x: i.x,
          y: averageY,
        };

        data.push(newBar);
      });

    return data;
  }, [barGroup]);

  const defaultBars = useMemo(() => {
    const data: BarGroupBar<string>[] = [];

    filteredBarGroup.forEach((i) => {
      const newBar: BarGroupBar<string> = {
        index: i.index,
        color: i.color,
        height: i.height,
        key: i.key,
        value: i.value,
        width: i.width,
        x: i.x,
        y: averageY - i.height,
      };

      if (i.index === 0) {
        newBar.y += i.height;
      }

      if (i.index === 1) {
        newBar.y += i.height * 2;
      }

      data.push(newBar);
    });

    return data;
  }, [barGroup]);

  return (
    <Group
      left={0}
      top={barGroup.y0}
      onMouseMove={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {isMenMode &&
        !isMenUniteMode &&
        onlyMenBars.map((bar) => (
          <Group key={getBarKey(barGroup, bar)}>
            <Bar
              x={bar.x}
              y={bar.y}
              width={bar.width}
              height={bar.height}
              fill={bar.color}
              opacity={opacity}
              rx={1}
              ry={1}
            />
            {hovered && (
              <rect
                x={bar.x}
                y={bar.y}
                rx={1}
                ry={1}
                width={bar.width}
                height={bar.height}
                fill="url(#pattern-hover-bar)"
              />
            )}
            {!hideLabels && bar.height > MIN_LABELED_BAR_HEIGHT && (
              <Text
                x={bar.width - bar.x + LABEL_LEFT_OFFSET}
                y={bar.y + bar.height / 2}
                textAnchor="start"
                verticalAnchor="middle"
                width={bar.width}
                fontSize="small"
                style={{ userSelect: "none" }}
              >
                {d3format(bar.value)}
              </Text>
            )}
          </Group>
        ))}

      {isMenMode &&
        isMenUniteMode &&
        onlyUniteMenBars.map((bar) => (
          <Group key={getBarKey(barGroup, bar)}>
            <Bar
              x={bar.x}
              y={bar.y}
              width={bar.width}
              height={bar.height}
              fill={bar.color}
              opacity={opacity}
              rx={1}
              ry={1}
            />
            {hovered && (
              <rect
                x={bar.x}
                y={bar.y}
                rx={1}
                ry={1}
                width={bar.width}
                height={bar.height}
                fill="url(#pattern-hover-bar)"
              />
            )}
            {!hideLabels && bar.height > MIN_LABELED_BAR_HEIGHT && (
              <Text
                x={bar.width - bar.x + LABEL_LEFT_OFFSET}
                y={bar.y + bar.height / 2}
                textAnchor="start"
                verticalAnchor="middle"
                width={bar.width}
                fontSize="small"
                style={{ userSelect: "none" }}
              >
                {d3format(bar.value)}
              </Text>
            )}
          </Group>
        ))}

      {!isMenMode &&
        !isMenUniteMode &&
        defaultBars.map((bar) => (
          <Group key={getBarKey(barGroup, bar)}>
            <Bar
              x={bar.x}
              y={bar.y}
              width={bar.width}
              height={bar.height}
              fill={bar.color}
              opacity={opacity}
              rx={1}
              ry={1}
            />
            {hovered && (
              <rect
                x={bar.x}
                y={bar.y}
                rx={1}
                ry={1}
                width={bar.width}
                height={bar.height}
                fill="url(#pattern-hover-bar)"
              />
            )}
            {!hideLabels && bar.height > MIN_LABELED_BAR_HEIGHT && (
              <Text
                x={bar.width - bar.x + LABEL_LEFT_OFFSET}
                y={bar.y + bar.height / 2}
                textAnchor="start"
                verticalAnchor="middle"
                width={bar.width}
                fontSize="small"
                style={{ userSelect: "none" }}
              >
                {d3format(bar.value)}
              </Text>
            )}
          </Group>
        ))}
    </Group>
  );
}
