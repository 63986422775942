import React, { ReactElement } from "react";

import { Group } from "@visx/group";
import Arc from "@visx/shape/lib/shapes/Arc";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Text } from "@visx/text";

import { ScaleOrdinal } from "d3-scale";
import { d3locale } from "../../../app/i18n/d3";
import { COLORS } from "../../../theme";
import { ResultKeys } from "../bar/types";

type PieInputData<T> = Array<{
  label: ResultKeys<T> | "etc";
  value: number;
}>;

export interface PieProps<T> {
  width: number;
  height: number;
  resultKeys: Array<ResultKeys<T>>;
  colorScale: ScaleOrdinal<ResultKeys<T>, string>;
  data: Array<{
    label: ResultKeys<T>;
    value: number;
  }>;
  getTooltipHandler: (
    datumIndex: string
  ) => ((event: MouseEvent) => void) | undefined;
  tTip: false | JSX.Element | undefined;
  hideTooltip: () => void;
  containerRef: (element: HTMLElement | SVGElement | null) => void;
}

export default function PieChart<T>({
  width,
  height,
  colorScale,
  data,
  resultKeys,
  getTooltipHandler,
  tTip,
  hideTooltip,
  containerRef,
}: PieProps<T>): ReactElement | null {
  if (width < 10) {
    return null;
  }

  const innerWidth = width;
  const innerHeight = height;
  const radius =
    width <= 220
      ? Math.min(innerWidth, innerHeight) / 2.4
      : Math.min(innerWidth, innerHeight) / 2.8;
  const donutThickness = width <= 220 ? 45 : 60;
  const total = data.reduce((sum, { value }) => sum + value, 0);

  const etcNumber = data.reduce((sum, { label, value }) => {
    if (resultKeys.includes(label)) {
      return sum;
    }

    return sum + value;
  }, 0);

  const pieData: PieInputData<T> = data.filter(({ label }) =>
    resultKeys.includes(label)
  );
  if (etcNumber > 0) {
    pieData.push({ label: "etc", value: etcNumber });
  }

  return (
    <svg
      ref={containerRef}
      width={width}
      height={width <= 220 ? height / 1.2 : height / 1.38}
    >
      <Group>
        <Pie
          left={width / 2}
          top={0}
          data={pieData}
          pieValue={({ value }) => value}
          pieSortValues={() => -1}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          fill="grey"
        >
          {(pie) => {
            // we use 2 sequential pie.arcs.map cycles to ensure
            // that Text is not overlapped by Arcs
            return (
              <Group
                left={width / 2}
                top={width <= 220 ? height / 2.4 : height / 2.8}
              >
                {pie.arcs.map((arc) => {
                  const isEtcArc = arc.data.label === "etc";
                  return (
                    <Arc
                      key={`${arc.data.label}-arc`}
                      data={arc.data}
                      startAngle={arc.startAngle}
                      endAngle={arc.endAngle}
                      values={"100%"}
                      fill={
                        isEtcArc
                          ? COLORS.BLACK
                          : colorScale(arc.data.label as ResultKeys<T>)
                      }
                      outerRadius={radius}
                      innerRadius={radius - donutThickness}
                      padAngle={0.05}
                      cornerRadius={5}
                      onMouseOver={getTooltipHandler(arc.data.label)}
                      onMouseLeave={hideTooltip}
                    />
                  );
                })}
                <Text
                  textAnchor="middle"
                  fill="#E2E0E0"
                  y={8}
                  style={{
                    fontWeight: 700,
                    fontSize: 24,
                  }}
                >
                  100%
                </Text>
                {pie.arcs.map((arc, index) => {
                  const [x, y] = pie.path.centroid(arc);
                  if (arc.value / total > 0.08)
                    return (
                      <Text
                        key={`${arc.data.label}-label`}
                        fill={COLORS.WHITE}
                        fontWeight={600}
                        fontSize={12}
                        x={x}
                        y={y}
                        textAnchor="middle"
                        onMouseOver={getTooltipHandler(arc.data.label)}
                        onMouseLeave={hideTooltip}
                      >
                        {d3locale.format(".2~%")(arc.value / total)}
                      </Text>
                    );
                })}
              </Group>
            );
          }}
        </Pie>
        {tTip}
      </Group>
    </svg>
  );
}
