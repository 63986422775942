import React, { ReactElement } from "react";

import { makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";

import DetailStatsCard from "./DetailStatsCard";

export interface DetailData {
  old: number;
  middle: number;
  young: number;
  unknown: number;
}

interface IGenderStats {
  detailData?: DetailData;
  colorOld: string;
  colorMiddle: string;
  colorYoung: string;
  colorUnknown: string;
  hideDetails: boolean;
}

const useStyles = (color?: string): ReturnType<typeof makeStyles> =>
  makeStyles((theme: Theme) => ({
    detailInfo: {
      display: "flex",
      justifyContent: "space-between",
      height: "70px",
      transition: "height .3s, opacity .3s",
    },
    detailInfoHide: {
      height: 0,
      opacity: 0,
      transition: "height .3s, opacity .3s",
    },
    detailItem: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      marginBottom: "2px",
    },
    detailMenItemSquare: {
      width: "10px",
      height: "10px",
      borderRadius: "2px",
    },
    detailItemCount: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      marginBottom: "2px",
      justifyContent: "right",

      "& .MuiTypography-root": {
        fontWeight: 500,
        color: theme.palette.text.secondary,
      },
    },
  }));

export default function GenderStats(props: IGenderStats): ReactElement {
  const classes = useStyles()();

  return (
    <>
      <div
        style={{
          height: "10px",
          width: "100%",
          marginBottom: props.hideDetails ? "" : "10px",
        }}
      >
        <DetailStatsCard
          colorOld={props.colorOld}
          colorMiddle={props.colorMiddle}
          colorYoung={props.colorYoung}
          colorUnknown={props.colorUnknown}
          detailData={props.detailData}
        />
      </div>
      <div
        className={clsx(classes.detailInfo, {
          [classes.detailInfoHide]: props.hideDetails,
        })}
      >
        <div>
          {props.detailData?.old ? (
            <div className={classes.detailItem}>
              <div
                className={classes.detailMenItemSquare}
                style={{ background: props.colorOld }}
              ></div>
              <Typography variant="body2">45+</Typography>
            </div>
          ) : null}

          {props.detailData?.middle ? (
            <div className={classes.detailItem}>
              <div
                className={classes.detailMenItemSquare}
                style={{ background: props.colorMiddle }}
              ></div>
              <Typography variant="body2">15-45</Typography>
            </div>
          ) : null}
          {props.detailData?.young ? (
            <div className={classes.detailItem}>
              <div
                className={classes.detailMenItemSquare}
                style={{ background: props.colorYoung }}
              ></div>
              <Typography variant="body2">0-15</Typography>
            </div>
          ) : null}
          {props.detailData?.unknown ? (
            <div className={classes.detailItem}>
              <div
                className={classes.detailMenItemSquare}
                style={{ background: props.colorUnknown }}
              ></div>
              <Typography variant="body2">Н/О</Typography>
            </div>
          ) : null}
        </div>
        <div>
          {props.detailData?.old ? (
            <div className={classes.detailItemCount}>
              <Typography variant="body2">{props.detailData.old}</Typography>
            </div>
          ) : null}

          {props.detailData?.middle ? (
            <div className={classes.detailItemCount}>
              <Typography variant="body2">{props.detailData.middle}</Typography>
            </div>
          ) : null}
          {props.detailData?.young ? (
            <div className={classes.detailItemCount}>
              <Typography variant="body2">{props.detailData.young}</Typography>
            </div>
          ) : null}
          {props.detailData?.unknown ? (
            <div className={classes.detailItemCount}>
              <Typography variant="body2">
                {props.detailData.unknown}
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
