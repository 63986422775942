import React, {
  MouseEvent,
  ReactElement,
  useState,
  PropsWithChildren,
} from "react";

import { MenuItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { VideocamOffOutlined } from "@material-ui/icons";
import MoreIcon from "@material-ui/icons/MoreHoriz";

import { VISIUS_CARD_HEIGHT, VISIUS_CARD_WIDTH } from "../../../theme";
import { GroupId, LocationData } from "../data-types";
import UploadStats from "./UploadStats";

export const useLocationCardStyles = makeStyles<Theme>((theme: Theme) => ({
  /* Location card */
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    columnGap: "12px",
    width: VISIUS_CARD_WIDTH,
    height: VISIUS_CARD_HEIGHT,
    cursor: "pointer",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    transition: "border-color 0.5s ease, color 0.5s ease",
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: "24px",
    boxShadow: "0px 4px 8px rgba(18, 32, 69, 0.05)",

    [theme.breakpoints.between("md", "lg")]: {
      width: 349,
    },

    "&.inactive": {
      cursor: "default",
      "&:hover $thumbnail": {
        transform: "none",
      },
    },
    "& .MuiCardHeader-menuItems": {
      position: "relative",
      zIndex: 1,
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(1),
    width: `calc(100% - 134px - 16px)`,
    padding: 0,
    marginTop: theme.spacing(3),
    bottom: 0,
    // .last-child isn't required, but we use it here to override material styles
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  cardHeader: {
    position: "absolute",
    width: "220px",
    right: 0,
    alignItems: "center",
    padding: "16px 16px 0 0",
    top: 0,
    [theme.breakpoints.between("md", "lg")]: {
      width: "190px",
    },

    "& svg path": {
      fill: theme.palette.primary.main,
    },
  },

  /* "Add location" card */
  iconCard: {
    color: theme.palette.text.secondary,
    transition: "opacity 0.5s ease",
    "&$enabled:hover": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    "&:hover $thumbnail": {
      transform: "none",
    },
    "&::before": {
      display: "none",
    },
  },
  disabled: {
    opacity: 0.5,
  },
  menuContainer: {
    "& .MuiPaper-root": {
      borderRadius: "16px",
      background: theme.palette.common.white,
      boxShadow: "0px 4px 12px rgba(18, 32, 69, 0.1)",

      "& .MuiList-root .MuiButtonBase-root:last-of-type": {
        color: theme.palette.error.main,

        "& svg path": {
          fill: theme.palette.error.main,
        },
      },

      "& svg ": {
        maxWidth: 13,
        "& path": {
          fill: "#676666",
        },
      },

      "& .MuiListItem-root": {
        padding: "10px 16px",
        maxHeight: "16px",
        boxSizing: "content-box",

        "& .MuiListItemIcon-root": {
          minWidth: "30px",
        },

        "& .MuiTypography-root": {
          fontWeight: 500,
        },
      },
    },

    "& .MuiList-padding": {
      padding: "5px 0",
    },
  },
  enabled: {
    opacity: 1,
    background: "transparent",
    boxShadow: "none",
    border: "1px solid rgba(44, 103, 255, 0.1)",

    "& svg path": {
      fill: theme.palette.primary.main,
    },
  },

  /* special element to create dimmed effect on card background */
  dimmer: {
    display: "flex",
    alignItems: "center",
  },

  /* Location thumbnail */
  thumbnail: {
    display: "flex",
    objectFit: "cover",
    height: theme.spacing(17),
    width: theme.spacing(17),
    borderRadius: "20px",
  },
  thumbnailContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "140px",
    marginBottom: theme.spacing(3),

    "& path": {
      fill: "rgba(18, 32, 69, 0.25)",
    },
  },

  /* Location title and video/streams number */
  actionsButton: {
    padding: 0,
  },

  /* Intercepts 'click' events outside of <input> in rename mode */
  clickAwayHandler: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
  },

  /* Misc */
  icon: {
    marginBottom: "12px",
  },
}));

export interface LocationCardProps {
  groupId: GroupId;
  data: LocationData;

  menuItems?: Array<ReturnType<typeof MenuItem>>;
  renameMode?: boolean;
  onClick?: () => void;
}

export interface MainLocationCardProps extends LocationCardProps {
  prohibitedTitles: string[];
}

function stopPropagation(evt: MouseEvent): void {
  evt.stopPropagation();
}

export default function BaseLocationCard(
  props: PropsWithChildren<LocationCardProps>
): ReactElement {
  const classes = useLocationCardStyles();
  const { onClick, data, renameMode = false, menuItems = [] } = props;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  /* Action menu handlers */
  const handleMenuOpen = (event: MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  let className = classes.card;
  if (onClick === undefined) {
    className += " inactive";
  }

  return (
    <Card variant="outlined" className={className} onClick={onClick}>
      {renameMode && (
        <div className={classes.clickAwayHandler} onClick={stopPropagation} />
      )}
      <div className={classes.dimmer}>
        {data.thumbnail !== undefined ? (
          <img
            className={classes.thumbnail}
            src={data.thumbnail}
            alt={data.title}
          />
        ) : (
          <div className={classes.thumbnail}>
            <VideocamOffOutlined
              style={{
                width: "100%",
                height: "100%",
                padding: 40,
                background: "rgba(18, 32, 69, 0.5)",
                borderRadius: "20px",
              }}
              className={`${classes.icon} ${classes.thumbnailContent}`}
            />
          </div>
        )}
      </div>
      {menuItems.length > 0 && (
        <>
          <Menu
            className={classes.menuContainer}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
          >
            {props.menuItems}
          </Menu>
          <CardHeader
            className={classes.cardHeader}
            subheader={<UploadStats data={data} locationId={props.data.id} />}
            action={
              <IconButton
                className={classes.actionsButton}
                onClick={handleMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            }
          />
        </>
      )}
      <CardContent className={classes.cardContent}>
        {props.children}
      </CardContent>
    </Card>
  );
}
