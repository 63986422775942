import React, {
  ReactElement, useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import {
  createInvoicePayment,
  getSelectedTariff,
  selectInvoiceStarted,
  selectInvoiceSuccessed
} from '../tariffSlice'

import { Button, Card, CardContent, InputLabel, makeStyles, TextField, Theme, Typography } from '@material-ui/core'

import pageStyles from '../../../groups/components/pageStyles'
import I18N from '../../../../app/i18n/strings'
import Form, { FormValidatorOptions, getRequiredValidator } from '../../../../components/Form'
import { InvoiceFormDto } from '../tariffTypes'
import { selectUserData } from '../../../auth/authSlice'
import { useFormStyles } from '../../../../app/styles'
import { Redirect, useHistory } from 'react-router'
import tariffStyles, { MAX_TARIFF_CARD_WIDTH } from '../tariffStyles'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: MAX_TARIFF_CARD_WIDTH
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7)
  },
  tariffInfo: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface FormData {
  name: string
  phoneNumber: string
}

export default function InvoiceForm (): ReactElement {
  const { t } = useTranslation()
  const classes = pageStyles()
  const cardClasses = useStyles()
  const formClasses = useFormStyles()
  const tariffClasses = tariffStyles()

  const dispatch = useDispatch()

  const userData = useSelector(selectUserData)
  const selectedTariff = useSelector(getSelectedTariff)
  const submitStarted = useSelector(selectInvoiceStarted)
  const isSubmitCompleted = useSelector(selectInvoiceSuccessed)
  const history = useHistory()

  const onSubmit = useCallback((data: FormData): void => {
    if (selectedTariff !== undefined && userData !== undefined) {
      const invoiceForm: InvoiceFormDto = {
        name: data.name,
        phoneNumber: data.phoneNumber,
        tariffSnapshotId: selectedTariff.tariffSnapshotId,
        userId: userData.id,
        tariffName: selectedTariff.name,
        email: userData.email
      }
      dispatch(createInvoicePayment(invoiceForm))
    }
  }, [selectedTariff, dispatch, userData])

  // Redirect to the initially requested page after a successfull request
  if (isSubmitCompleted) {
    return <Redirect to='/profile?tab=payments&inner=invoiceSuccess' />
  }

  const navigateBack = (): void => {
    history.goBack()
  }

  return (
    <>
      <div className={clsx(cardClasses.card, tariffClasses.card)}>
        <Card className={tariffClasses.cardWrap}>
          <CardContent className={tariffClasses.flexContent}>

            <div>
              <Typography variant='h2' className={cardClasses.title}>
                {t(I18N.invoice.LABEL_DESCRIPTION)}
              </Typography>

              {selectedTariff !== undefined && (
                <Form<FormData>
                  onSubmit={onSubmit}
                  validate={(options: FormValidatorOptions<FormData>) => getRequiredValidator(['name', 'phoneNumber'])(options)}
                >

                  <div className={formClasses.labeledInputField}>
                    <InputLabel required>
                      {t(I18N.profile.LABEL_NAME)}
                    </InputLabel>
                    <TextField
                      required
                      variant='outlined'
                      placeholder={t(I18N.profile.PLACEHOLDER_NAME)}
                      name='name'
                      autoComplete='name'
                      defaultValue={userData?.name}
                    />
                  </div>
                  <div className={formClasses.labeledInputField}>
                    <InputLabel required>
                      {t(I18N.profile.LABEL_PHONE)}
                    </InputLabel>
                    <TextField
                      required
                      variant='outlined'
                      placeholder={t(I18N.profile.PLACEHOLDER_PHONE)}
                      name='phoneNumber'
                      autoComplete='phone'
                      defaultValue={userData?.phoneNumber}
                    />
                  </div>

                  <div className={formClasses.formFooterActionsProfile}>
                    <Button
                      color='primary'
                      className={clsx(classes.btn, tariffClasses.leftBtn)}
                      variant='outlined'
                      onClick={navigateBack}
                    >
                      {t(I18N.common.BTN_BACK)}
                    </Button>

                    <Button
                      type='submit'
                      className={classes.btn}
                      variant='contained'
                      color='primary'
                      disabled={submitStarted || selectedTariff === undefined}
                    >
                      {t(I18N.common.BTN_SENT)}
                    </Button>
                  </div>
                </Form>
              )}

              {selectedTariff === undefined && (
                <div>
                  <Typography variant='h6' className={cardClasses.tariffInfo}>
                    {t(I18N.payments.OPERATION_CANCELLED)}
                  </Typography>

                  <div className={formClasses.formFooterActionsProfile}>
                    <Button
                      color='primary'
                      className={clsx(classes.btn, tariffClasses.leftBtn)}
                      variant='outlined'
                      onClick={navigateBack}
                    >
                      {t(I18N.common.BTN_BACK)}
                    </Button>
                  </div>
                </div>
              )}
            </div>

          </CardContent>
        </Card>
      </div>
    </>
  )
}
