import { createAsyncThunk } from '@reduxjs/toolkit'
import { GroupMap } from '../data-types'
import {
  ARCHIVE_SLICE_NAME,
  LocationArgs,
  MAIN_SLICE_NAME,
  reloadGroupData,
  TRASH_SLICE_NAME
} from '../slice-utils'
import {
  deleteGroup as apiDeleteGroup,
  deleteLocation as apiDeleteLocation,
  updateGroup as apiUpdateGroup,
  updateLocation as apiUpdateLocation
} from '../api'
import { RootState } from '../../../app/store'
import { getSourceIdsSelector } from '../../location/selectors'
import { deleteAnalytics } from '../../../app/db'

/* ########### TRASH ###########

  # Supported Actions

  Source\Dest.|      Main        |     Archive     |   Black Hole
  ------------|------------------|-----------------|---------------
  Trash       | restoreGroup     | restoreGroup    | deleteGroup
              | restoreLocation  | restoreLocation | deleteLocation

*/

/**
 * Permanently deletes given Group
 */
export const deleteGroup = createAsyncThunk(
  `${TRASH_SLICE_NAME}/deleteGroup`,
  async (group: GroupMap, thunkApi) => {
    const state: RootState = thunkApi.getState()
    const locations = Array.from(group.locations.values())
    const promises: Array<Promise<void>> = []
    for (const location of locations) {
      const sourceIds = getSourceIdsSelector(location.id)(state)
      promises.push(deleteAnalytics(sourceIds))
    }

    if (group.isRemoved ?? false) {
      promises.push(apiDeleteGroup(group.id))
    } else {
      for (const location of locations) {
        promises.push(apiDeleteLocation(location.id))
      }
    }

    await Promise.all(promises)
  }
)

/**
 * Permanently deletes given Location
 */
export const deleteLocation = createAsyncThunk(
  `${TRASH_SLICE_NAME}/deleteLocation`,
  async ({ location }: LocationArgs, thunkApi) => {
    const state: RootState = thunkApi.getState()
    const sourceIds = getSourceIdsSelector(location.id)(state)
    await Promise.all([
      apiDeleteLocation(location.id),
      deleteAnalytics(sourceIds)
    ])
  }
)

/**
 * Moves given Location into the Main/Archive tab from Trash
 */
export const restoreLocation = createAsyncThunk(
  `${TRASH_SLICE_NAME}/restoreLocation`,
  async ({ groupId, location }: LocationArgs, { getState }) => {
    await apiUpdateLocation({
      groupId,
      location: {
        ...location,
        isRemoved: false
      }
    })
    return await reloadGroupData(groupId, [MAIN_SLICE_NAME, ARCHIVE_SLICE_NAME], getState())
  }
)

/**
 * Moves the Group into the Main/Archive from Trash
 */
export const restoreGroup = createAsyncThunk(
  `${TRASH_SLICE_NAME}/restoreGroup`,
  async (group: GroupMap, { getState }) => {
    if (group.isRemoved ?? false) {
      await apiUpdateGroup({
        ...group,
        isArchived: undefined,
        isRemoved: false
      })
    } else {
      const locations = Array.from(group.locations.values())
      await Promise.all(locations.map(async location => await apiUpdateLocation({
        groupId: group.id,
        location: {
          ...location,
          isArchived: undefined,
          isRemoved: false
        }
      })))
    }

    return await reloadGroupData(group.id, [ARCHIVE_SLICE_NAME, MAIN_SLICE_NAME], getState())
  }
)
