import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { selectIsMenMode, selectIsMenUniteMode } from "../dashboardSlice";

import { ChartProps } from "../../../components/charts/Chart";
import { AggregationResult } from "../chart-types";

import { RawResult } from "./RawResult";
import ResultGroupChart from "./ResultAggregation";

const sum = (...args: number[]): number => {
  return args.reduce((res, arg) => res + arg);
};

export default function TotalNumberChart(
  props: ChartProps<RawResult>
): ReactElement {
  const isMenUniteMode = useSelector(selectIsMenUniteMode);
  const isMenMode = useSelector(selectIsMenMode);

  return (
    <ResultGroupChart
      {...props}
      vertical={isMenUniteMode || !isMenMode || isMenMode ? true : false}
      aggregator={(key, values): AggregationResult => ({
        key,
        men: sum(...values.map(({ men }) => men)),
        cars: sum(...values.map(({ cars }) => cars)),
        bikes: sum(...values.map(({ bikes }) => bikes)),
        oldMen: sum(...values.map(({ oldMen }) => oldMen)),
        middleMen: sum(...values.map(({ middleMen }) => middleMen)),
        youngMen: sum(...values.map(({ youngMen }) => youngMen)),
        unknownMen: sum(...values.map(({ unknownMen }) => unknownMen)),
        oldWomen: sum(...values.map(({ oldWomen }) => oldWomen)),
        middleWomen: sum(...values.map(({ middleWomen }) => middleWomen)),
        youngWomen: sum(...values.map(({ youngWomen }) => youngWomen)),
        unknownWomen: sum(...values.map(({ unknownWomen }) => unknownWomen)),
        oldUnknown: sum(...values.map(({ oldUnknown }) => oldUnknown)),
        middleUnknown: sum(...values.map(({ middleUnknown }) => middleUnknown)),
        youngUnknown: sum(...values.map(({ youngUnknown }) => youngUnknown)),
        unknownUnknown: sum(
          ...values.map(({ unknownUnknown }) => unknownUnknown)
        ),
      })}
    />
  );
}
