import React, {
  ReactElement, useEffect
} from 'react'
import clsx from 'clsx'

import { useTranslation } from 'react-i18next'
import { Button, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core'
import Link from '../../../../components/Link'

import I18N from '../../../../app/i18n/strings'
import { useFormStyles } from '../../../../app/styles'
import { actions } from '../tariffSlice'
import { useDispatch } from 'react-redux'
import tariffStyles, { MAX_TARIFF_CARD_WIDTH } from '../tariffStyles'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: MAX_TARIFF_CARD_WIDTH
  },
  innerCard: {
    padding: theme.spacing(6),
    justifyContent: 'start'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7)
  }
}))

export default function InvoiceSuccessInfo (): ReactElement {
  const { t } = useTranslation()
  const cardClasses = useStyles()
  const formClasses = useFormStyles()
  const tariffClasses = tariffStyles()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.clearInvoiceFormState())
  }, [dispatch])

  return (
    <>
      <div className={tariffClasses.card}>
        <Card className={tariffClasses.cardWrap}>
          <CardContent className={clsx(tariffClasses.flexContent, cardClasses.innerCard)}>

            <div>
              <Typography variant='h2' className={cardClasses.title}>
                {t(I18N.invoice.TITLE_SUCCESS)}
              </Typography>

              <Typography variant='h6'>
                {t(I18N.invoice.LABEL_SUCCESS)}
              </Typography>
            </div>

            <div className={formClasses.formFooterActionsProfile}>
              <Link to='?tab=payments&inner=current'>
                <Button
                  color='primary'
                  className={tariffClasses.leftBtn}
                  variant='contained'
                >
                  {t(I18N.invoice.UNDERSTAND_BTN)}
                </Button>
              </Link>
            </div>

          </CardContent>
        </Card>
      </div>
    </>
  )
}
