import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Moment } from "moment";

import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  Cancel,
  CloudOffOutlined,
  CloudUploadOutlined,
  ErrorOutline,
} from "@material-ui/icons";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { actions, getUploadId } from "../../locationSlice";
import { LocationId } from "../../../groups/data-types";
import { UPLOAD_STATE, UploadData } from "../../data-mapper";

import I18N from "../../../../app/i18n/strings";
import { VISIUS_COLORS } from "../../../../app/styles";
import { bytes2str, isValidFileType } from "../../../../app/utils";

import DateTimePicker from "../../../../components/DateTimePicker";
import Tooltip from "../../../../components/Tooltip";

const WIDE_COLUMN_MIN_WIDTH = 270;
const MAX_ROW_TITLE_WIDTH = 300;

export interface FileDateChangeArgs {
  file: File;
  date: Moment | null;
}

interface UploadListProps {
  uploads: UploadData[];
  locationId: LocationId;
  onRemove: (file: File) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: "auto",
    marginBottom: theme.spacing(1),
  },
  icon: {
    display: "block",
  },
  notStarted: {
    color: VISIUS_COLORS.lightGrey,
  },
  progress: {
    color: theme.palette.warning.light,
  },
  completed: {
    color: theme.palette.success.light,
  },
  failed: {
    color: theme.palette.error.light,
  },
  failedUploadStatus: {
    display: "flex",
    alignItems: "center",
  },
  tdPadding: {
    paddingLeft: theme.spacing(1),

    "& .MuiButtonBase-root span svg": {
      maxHeight: 16,
      marginRight: 26,
      color: "#888888",
    },
    "& .MuiButtonBase-root ": {
      background: "transparent",
    },
  },
  tdWide: {
    minWidth: WIDE_COLUMN_MIN_WIDTH,

    [theme.breakpoints.down("xl")]: {
      minWidth: WIDE_COLUMN_MIN_WIDTH - 80,
    },
  },
  tdIcon: {
    // align with icon buttons
    padding: 3,
  },
  videoTitle: {
    whiteSpace: "nowrap",
    maxWidth: MAX_ROW_TITLE_WIDTH,
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down("xl")]: {
      maxWidth: 230,
    },
  },
  datePicker: {
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderWidth: 1,
    },

    "& .MuiInputBase-input": {
      paddingBottom: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
    },
  },
  fileTooltip: {
    display: "flex",
  },
  fileTooltipIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function UploadsList(props: UploadListProps): ReactElement {
  const { uploads, locationId, onRemove } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getDateChangeHandler = useCallback(
    (file: File, currentDate: MaterialUiPickersDate) => {
      return (date: MaterialUiPickersDate) => {
        if (currentDate === null && (date === null || !date.isValid())) {
          return;
        }

        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);
        video.addEventListener("canplaythrough", () => {
          dispatch(
            actions.updateUpload({
              key: getUploadId(file),
              locationId,
              update: {
                date: date?.isValid() === true ? date : null,
                width: video.videoWidth,
                height: video.videoHeight,
              },
            })
          );
        });
      };
    },
    [dispatch, locationId]
  );

  const getRemoveHandler = useCallback(
    (file: File) => {
      return () => onRemove(file);
    },
    [onRemove]
  );

  const handleRemoveAll = useCallback(() => {
    uploads.forEach(({ file }) => onRemove(file));
  }, [onRemove, uploads]);

  const hasRemoveAllBtn =
    uploads.findIndex(({ state }) => {
      return state === UPLOAD_STATE.NONE || state === UPLOAD_STATE.FAILED;
    }) !== -1;

  return (
    <TableContainer>
      <Typography
        style={{ fontWeight: 700, fontSize: 15, marginBottom: 20 }}
        color="textPrimary"
        variant="body2"
      >
        Загрузка видео в локацию
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className={classes.tdPadding}>
                {hasRemoveAllBtn && (
                  <IconButton size="small" onClick={handleRemoveAll}>
                    <Cancel />
                  </IconButton>
                )}
              </div>
            </TableCell>
            <TableCell>{t(I18N.location.TABLE_FILE_NAME)}</TableCell>
            <TableCell className={classes.tdWide}>
              {t(I18N.location.TABLE_DATE)}
            </TableCell>
            <TableCell>{t(I18N.location.TABLE_DURATION)}</TableCell>
            <TableCell>{t(I18N.location.TABLE_SIZE)}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            // UploadsList is intended to display only uploads with NONE/CREATING/FAILED statuses.
            // I.e. only uploads without a corresponding video entry are supported.
            // Uploads having a corresponding video entry should be displayed in the VideosList
            uploads.map(({ date, file, state, progress }) => {
              const isNotValidFileType = !isValidFileType(file);

              return (
                <TableRow key={getUploadId(file)}>
                  <TableCell>
                    <div className={classes.tdPadding}>
                      {state === UPLOAD_STATE.NONE && (
                        <IconButton
                          size="small"
                          onClick={getRemoveHandler(file)}
                        >
                          <Cancel />
                        </IconButton>
                      )}
                      {state === UPLOAD_STATE.CREATING && (
                        <div className={classes.tdIcon}>
                          <CloudUploadOutlined
                            fontSize="small"
                            className={`${classes.icon} ${classes.progress}`}
                          />
                        </div>
                      )}
                      {state === UPLOAD_STATE.FAILED && (
                        <div className={classes.failedUploadStatus}>
                          <IconButton
                            size="small"
                            onClick={getRemoveHandler(file)}
                          >
                            <Cancel />
                          </IconButton>
                          <CloudOffOutlined
                            fontSize="small"
                            className={`${classes.icon} ${classes.failed}`}
                          />
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <Tooltip
                    open={isNotValidFileType}
                    title={
                      <div className={classes.fileTooltip}>
                        <ErrorOutline
                          className={classes.fileTooltipIcon}
                          color="error"
                        />
                        <Typography>
                          {t(I18N.location.MSG_UPLOAD_NOT_VALID_FILE)}
                        </Typography>
                      </div>
                    }
                  >
                    <TableCell>
                      <Typography
                        className={classes.videoTitle}
                        variant="body2"
                        color={isNotValidFileType ? "error" : "textPrimary"}
                      >
                        {file.name}
                      </Typography>
                    </TableCell>
                  </Tooltip>
                  <TableCell>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePicker
                        date={date}
                        onChange={getDateChangeHandler(file, date)}
                      />
                    </LocalizationProvider>
                  </TableCell>
                  <TableCell>&mdash;</TableCell>
                  <TableCell>{bytes2str(file.size)}</TableCell>
                  <TableCell />
                </TableRow>
              );
            })
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell style={{ color: "#BBBBBB" }}>
              {t(I18N.location.MSG_NUM_FILES, { count: uploads.length })}
            </TableCell>
            <TableCell />
            <TableCell>&mdash;</TableCell>
            <TableCell style={{ color: "#BBBBBB" }}>
              {bytes2str(
                uploads.reduce((total, { file }) => total + file.size, 0)
              )}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
