const I18N = {
  chart: {
    DATE_RANGE_LABEL_DAYS_OF_WEEK: 'chart.dateRange.daysOfWeek',
    DATE_RANGE_LABEL_DATE_FROM: 'chart.dateRange.date.from',
    DATE_RANGE_LABEL_DATE_TO: 'chart.dateRange.date.to',
    DATE_RANGE_TITLE: 'chart.dateRange.title',

    LEGEND_YOUNG: 'chart.legend.young',
    LEGEND_MIDDLE: 'chart.legend.middle',
    LEGEND_OLD: 'chart.legend.old',
    LEGEND_UNKNOWN_AGE: 'chart.legend.unknownAge',
    
    LEGEND_FEMALE: 'chart.legend.female',
    LEGEND_MALE: 'chart.legend.male',
    LEGEND_UNKNOWN_GENDER: 'chart.legend.unknownGender',

    LEGEND_MEN: 'chart.legend.people',
    LEGEND_YOUNGMEN: 'chart.legend.youngMen',
    LEGEND_MIDDLEMEN: 'chart.legend.middelMen',
    LEGEND_OLDMEN: 'chart.legend.oldMen',
    LEGEND_UNKNOWNMEN: 'chart.legend.unknownMen',
    LEGEND_YOUNGWOMEN: 'chart.legend.youngWomen',
    LEGEND_MIDDLEWOMEN: 'chart.legend.middelWomen',
    LEGEND_OLDWOMEN: 'chart.legend.oldWomen',
    LEGEND_UNKNOWNWOMEN: 'chart.legend.unknownWomen',
    LEGEND_YOUNGUNKNOWN: 'chart.legend.youngUnknown',
    LEGEND_MIDDLEUNKNOWN: 'chart.legend.middelUnknown',
    LEGEND_OLDUNKNOWN: 'chart.legend.oldUnknown',
    LEGEND_UNKNOWNUNKNOWN: 'chart.legend.unknownUnknown',

    LEGEND_CARS: 'chart.legend.car',
    LEGEND_BIKES: 'chart.legend.bikes',
    LEGEND_ETC: 'chart.legend.etc',
    LEGEND_TITLE: 'chart.legend.title',

    LOCATION_PICKER_CHECKBOX_LABEL: 'chart.locationPicker.checkbox.label',

    OPTIONS_PANEL_TITLE: 'chart.optionsPanel.title',
    OPTIONS_PANEL_SHOW_BUTTON: 'chart.optionsPanel.showbutton',
    OPTIONS_PANEL_BUTTON: 'chart.optionsPanel.button',
    OPTIONS_PANEL_TOGGLE_BUTTON: 'chart.optionsPanel.toggleButton',

    MSG_EMPTY_QUERY_RESULT: 'chart.msg.emptyQuery',
    MSG_LOADING: 'chart.msg.loading',
    MSG_SELECT_LOCATION: 'chart.msg.selectLocation',

    TIME_RANGE_LABEL_TIME_FROM: 'chart.timeRange.time.from',
    TIME_RANGE_LABEL_TIME_TO: 'chart.timeRange.time.to',
    TIME_RANGE_LABEL_SELECT: 'chart.timeRange.select.label',
    TIME_RANGE_LABEL_SELECT_VALUE_MINUTE: 'chart.timeRange.select.minute',
    TIME_RANGE_LABEL_SELECT_VALUE_HOUR: 'chart.timeRange.select.hour',
    TIME_RANGE_LABEL_SELECT_VALUE_DAY: 'chart.timeRange.select.day',
    TIME_RANGE_LABEL_SELECT_VALUE_WEEK: 'chart.timeRange.select.week',
    TIME_RANGE_LABEL_SELECT_VALUE_MONTH: 'chart.timeRange.select.month',

    TITLE: 'chart.title.main',
    TITLE_RAW_RESULT_CHART_TITLE: 'chart.title.rawResult{{from}}{{to}}',
    TITLE_RELATIVE_CHART: 'chart.title.relativeData',
    TITLE_RELATIVE_WALKERS_CHART: 'chart.title.relativeWalkersData',
    TITLE_AVERAGE_CHART: 'chart.title.average',
    TITLE_MIN_CHART: 'chart.title.min',
    TITLE_MAX_CHART: 'chart.title.max',
    TITLE_SUM_CHART: 'chart.title.sum',

    TOOLTIP_AVERAGE: ['chart.tooltip.average1', 'chart.tooltip.average2'],
    TOOLTIP_AVERAGE_SUMMARY: 'chart.tooltip.averageSummary',
    TOOLTIP_MAX: ['chart.tooltip.max1', 'chart.tooltip.max2'],
    TOOLTIP_MAX_SUMMARY: 'chart.tooltip.maxSummary',
    TOOLTIP_MIN: ['chart.tooltip.min1', 'chart.tooltip.min2'],
    TOOLTIP_MIN_SUMMARY: 'chart.tooltip.minSummary',
    TOOLTIP_TOTAL_PIE: ['chart.tooltip.tPie1', 'chart.tooltip.tPie2'],
    TOOLTIP_TOTAL_PIE_SUMMARY: 'chart.tooltip.tPieSummary',
    TOOLTIP_RAW: ['chart.tooltip.raw1', 'chart.tooltip.raw2'],
    TOOLTIP_RAW_SUMMARY: 'chart.tooltip.rawSummary',
    TOOLTIP_RELATIVE: ['chart.tooltip.relative1', 'chart.tooltip.relative2'],
    TOOLTIP_RELATIVE_SUMMARY: 'chart.tooltip.relativeSummary',
    TOOLTIP_SUM: ['chart.tooltip.sum1', 'chart.tooltip.sum2'],
    TOOLTIP_SUM_SUMMARY: 'chart.tooltip.sumSummary'
  },
  common: {
    ERROR_FIELD_REQUIRED: 'common.err.fieldrequired',
    ERROR_UNKNOWN: 'common.err.unknown',
    LINK_ANALYTICS: 'common.link.analytics',
    LINK_CREATE_ACCOUNT: 'common.link.noAccountSignUp',
    LINK_FORGOT_PASSWORD: 'common.link.forgotPassword',
    LINK_HELP: 'common.link.help',
    LINK_HOME: 'common.link.home',
    LINK_LOGIN: 'common.link.signIn',
    LINK_LOGOUT: 'common.link.logout',
    LINK_LOCATION: 'common.link.location',
    LINK_MAIN: 'common.link.main',
    LINK_PAYMENTS: 'common.link.payments',
    LINK_PROFILE: 'common.link.profile',
    LINK_SUPPORT: 'common.link.support',
    OK: 'common.ok',
    CANCEL: 'common.cancel',
    PLEASE_WAIT: 'common.msg.pleaseWait',
    SELECT_ADMIN_VIEW: 'common.select.adminView',
    TITLE_MY_ACCOUNT: 'common.title.myAccount',
    TITLE_OTHER_ACCOUNTS: 'common.title.otherAccounts',
    VALIDATION_DATE_INVALID: 'common.validation.dateInvalid',
    VALIDATION_DATE_TOO_EARLY: 'common.validation.dateToEarly',
    VALIDATION_DATE_TOO_FAR: 'common.validation.dateToFar',
    BTN_BACK: 'common.btn.back',
    BTN_CONTINUE: 'common.btn.continue',
    BTN_CHANGE: 'common.btn.change',
    BTN_SENT: 'common.btn.sent'
  },
  location: {
    BTN_ADD_FILES: 'location.btn.addFiles',
    BTN_ADD_STREAM: 'location.btn.addStream',
    BTN_DELETE_VIDEO: 'location.btn.deleteVideo',
    BTN_MOVE_VIDEO: 'location.btn.moveVideo',
    BTN_SAVE_LINE: 'location.btn.saveLine',
    BTN_STEP_BACK: 'location.btn.stepBack',
    BTN_STEP_CONNECT_STREAM: 'location.btn.stepConnectStream',
    BTN_STEP_DRAW_LINE: 'location.btn.drawLine',
    BTN_STEP_START_ANALYSIS: 'location.btn.startAnalysis',
    BTN_STEP_CONNECT_CAMERA: 'location.btn.connectCamera',
    BTN_STEP_DISABLE_CAMERA: 'location.btn.disableCamera',
    BTN_STEP_UPLOAD: 'location.btn.upload',
    BTN_WATCH: 'location.btn.watch',
    BTN_VIEW_ANALYTICS: 'location.btn.viewAnalytics',
    DLG_DELETE_VIDEOS: 'location.dlg.deleteVideos{{count}}',
    DLG_REPLACE_VIDEOS: 'location.dlg.replaceVideos',
    MSG_CREATE_GROUP: 'location.msg.createGroup',
    MSG_CREATE_LOCATION: 'location.msg.createLocation',
    MSG_DELETE_VIDEOS: 'location.msg.deleteVideos',
    MSG_DELETE_VIDEOS_1: 'location.msg.deleteVideos1',
    MSG_DELETE_VIDEOS_2: 'location.msg.deleteVideos2',
    MSG_DELETE_VIDEOS_TITLE: 'location.msg.deleteVideosTitle',
    MSG_EDIT_LINE: 'location.msg.editLine',
    MSG_EDIT_LINE_TWO: 'location.msg.editLineTwo',
    MSG_ENTER_STREAM_URL: 'location.msg.enterStreamUrl',
    MSG_EMPTY: 'location.msg.empty',
    MSG_UPLOAD_NOT_VALID_FILE: 'location.msg.upload.notValidFile',
    MSG_LOCATION_REQUIRED: 'location.msg.required',
    MSG_NUM_FILES: 'location.msg.numFiles{{count}}',
    MSG_SELECT_LOCATION: 'location.msg.selectLocation',
    MSG_SELECT_EMPTY_LOCATIONS: 'location.msg.selectEmptyLocation',
    MSG_SELECT_EMPTY_GROUPS: 'location.msg.selectEmptyGroup',
    MSG_SELECT_GROUP: 'location.msg.selectGroup',
    MSG_SELECT_TZ: 'location.msg.selectTz',
    MSG_SET_DATE_TIME: 'location.msg.setDateTime',
    MSG_STREAM_IS_CONNECTED: 'location.msg.streamIsConnected',
    MSG_STREAM_TIME: 'location.msg.streamTime',
    MSG_STREAM_URL_SAMPLES: 'location.msg.streamUrlSamples',
    MSG_TIMEZONE_WARNING_L1: 'location.msg.timezoneWarning1',
    MSG_TIMEZONE_WARNING_L2: 'location.msg.timezoneWarning2',
    MSG_UPLOADING: 'location.msg.uploading',
    MSG_UPLOAD_PROCESSING: 'location.msg.uploadProcessing',
    MSG_UPLOAD_COMPLETED: 'location.msg.uploadCompleted',
    MSG_UPLOAD_FAILED: 'location.msg.uploadFailed',
    MSG_BILLING_FAILED: 'location.msg.billingFailed',
    MSG_VIDEO_BUSY: 'location.msg.videoBusy',
    MSG_VIDEO_NOT_FOUND: 'location.msg.videoNotFound',
    MSG_WAIT_CREATION: 'location.msg.waitCreation',
    MSG_WAIT_UPLOADS: 'location.msf.waitUploads',
    NOTIFY_PROCESSING_REQUESTED: 'location.notification.processingRequested',
    NOTIFY_PROCESSING_PENDING: 'location.notification.processingPending',
    NOTIFY_PROCESSING_PREPARING_INFRASTRUCTURE: 'location.notification.processingPreparingInfrastructure',
    NOTIFY_PROCESSING_PREPARING_INFRASTRUCTURE_ERROR: 'location.notification.processingPreparingInfrastructureError',
    NOTIFY_PROCESSING_ANALYSIS: 'location.notification.processingAnalysis',
    NOTIFY_PROCESSING_INFRASTRUCTURE_PREPARED: 'location.notification.processingInfrastructurePrepared',
    NOTIFY_PROCESSING_STOPPING: 'location.notification.processingStopping',
    NOTIFY_PROCESSING_STOPPING_ERROR: 'location.notification.processingStoppingError',
    NOTIFY_PROCESSING_STOPPED: 'location.notification.processingStopped',
    NOTIFY_PROCESSING_FINISHED: 'location.notification.processingFinished',
    NOTIFY_STREAM_REMOVED: 'location.notification.streamRemoved{{url}}',
    NOTIFY_STREAM_PREPARED: 'location.notification.streamPrepared{{url}}',
    NOTIFY_STREAM_PREPARED_ERROR: 'location.notification.streamPreparedError{{url}}{{errorMessage}}',
    NOTIFY_STREAM_SAVED: 'location.notification.streamSaved',
    NOTIFY_STREAM_SAVE_ERROR: 'location.notification.streamSaveError',
    NOTIFY_VIDEO_PREPARED: 'location.notification.videoPrepared{{file}}',
    NOTIFY_VIDEO_PREPARED_ERROR: 'location.notification.videoPreparedError{{file}}{{errorMessage}}',
    NOTIFY_VIDEO_REMOVED: 'location.notification.videoRemoved{{file}}',
    NOTIFY_VIDEO_SAVED: 'location.notification.videoSaved',
    NOTIFY_VIDEO_SAVE_ERROR: 'location.notification.videoSaveError',
    NOTIFY_LINE_SAVED: 'location.notification.lineSaved',
    NOTIFY_START_PROCESSING_SUCCESS: 'location.notification.startProcessingSuccess',
    NOTIFY_START_PROCESSING_ERROR: 'location.notification.startProcessingError',
    SELECT_GROUPS: 'location.select.groups',
    SELECT_LOCATION: 'location.select.locations',
    TAB_FILES: 'location.tab.files',
    TAB_STREAMS: 'location.tab.streams',
    TABLE_FILE_NAME: 'location.table.fileName',
    TABLE_DATE: 'location.table.date',
    TABLE_DURATION: 'location.table.duration',
    TABLE_SIZE: 'location.table.size',
    TABLE_STREAM_URL: 'location.table.streamUrl',
    TABLE_STREAM_CREATED_AT: 'location.table.streamConnectedAt',
    TABLE_STREAM_DISCONNECTED_AT: 'location.table.streamDisconnectedAt',
    TITLE_LOCATION_PAGE: 'location.title.page',
    TITLE_STREAM: 'location.title.stream',
    TITLE_VIDEO: 'location.title.video',
    TITLE_UPLOADS: 'location.title.uploads',
    WORKFLOW_PICK_LOCATION: 'location.workflow.pickLocation',
    WORKFLOW_ADD_VIDEOS: 'location.workflow.addVideos',
    WORKFLOW_ADD_VIDEO_LINK: 'location.workflow.addVideoLink',
    WORKFLOW_PICK_DATE_TIME: 'location.workflow.pickDateAndTime',
    WORKFLOW_PICK_TIME_ZONE: 'location.workflow.pickTimeZone',
    WORKFLOW_UPLOAD_TO_SERVER: 'location.workflow.loadOnServer',
    WORKFLOW_SEND_TO_SERVER: 'location.workflow.sendOnServer',
    WORKFLOW_ADD_LINE: 'location.workflow.addLine',
    WORKFLOW_START_PROCESSING: 'location.workflow.pickLocationAnalysis'
  },
  main: {
    common: {
      MSG_LOADING_PROGRESS: 'main.common.msg.loadingProgress'
    },
    videoData: {
      MSG_EMPTY: 'main.videoData.msg.empty'
    },
    BTN_ADD_GROUP: 'main.btn.addGroup',
    BTN_ADD_STREAM: 'main.btn.addStream',
    BTN_ADD_VIDEO: 'main.btn.setVideo',
    BTN_ARCHIVE_GROUP: 'main.btn.archiveGroup',
    BTN_ARCHIVE_LOCATION: 'main.btn.archiveLocation',
    BTN_DELETE_ALL: 'main.btn.deleteAll',
    BTN_DELETE_GROUP: 'main.btn.deleteGroup',
    BTN_DELETE_LOCATION: 'main.btn.deleteLocation',
    BTN_DELETE_PERM_LOCATION: 'main.btn.deletePermanentlyLocation',
    BTN_HIDE_GROUP: 'main.btn.hideGroup',
    BTN_RENAME_GROUP: 'main.btn.renameGroup',
    BTN_RENAME_LOCATION: 'main.btn.renameLocation',
    BTN_RESTORE_ALL: 'main.btn.restoreAll',
    BTN_RESTORE_FROM_TRASH_ALL: 'main.btn.restoreTrashAll',
    BTN_RESTORE_LOCATION: 'main.btn.restoreLocation',
    BTN_SHOW_GROUP: 'main.btn.showGroup',
    BTN_TRASH_ALL: 'main.btn.trashAll',
    BTN_TRASH_GROUP: 'main.btn.trashGroup',
    DLG_CANCEL: 'main.dlg.cancel',
    DLG_DELETE_GROUP_CONFIRM: 'main.dlg.deleteGroupConfirm',
    DLG_DELETE_GROUP_LOCATIONS_SUMMARY: 'main.dlg.deleteGroupLocationsSummary{{title}}',
    DLG_DELETE_GROUP_LOCATIONS: 'main.dlg.deleteGroupLocations',
    DLG_DELETE_GROUP_SUMMARY: 'main.dlg.deleteGroupSummary{{title}}',
    DLG_DELETE_GROUP: 'main.dlg.deleteGroup',
    DLG_DELETE_LOCATION_CONFIRM: 'main.dlg.deleteLocationConfirm',
    DLG_DELETE_LOCATION_SUMMARY: 'main.dlg.deleteLocationSummary{{title}}',
    DLG_DELETE_LOCATION: 'main.dlg.deleteLocation',
    DLG_EMPTY_TRASH_SUMMARY: 'main.dlg.emptyTrashSummary{{count}}',
    DLG_EMPTY_TRASH: 'main.dlg.emptyTrash',
    ERROR_INVALID_DATA: 'main.err.invalidData',
    ERROR_LOCATION_EXISTS: 'main.err.locationExists',
    ERROR_LOCATION_NAME_REQUIRED: 'main.err.locationNameRequired',
    LINK_ADD_LOCATION: 'main.link.addLocation',
    LINK_ARCHIVE: 'main.link.tabArchive',
    LINK_TRASH: 'main.link.tabTrash',
    LINK_VIDEOS: 'main.link.tabVideo',
    LINK_TARIFF: 'main.link.tabTariff',
    MSG_EMPTY_GROUP: 'main.msg.emptyGroup',
    MSG_PREPROCESSING_PROGRESS: 'main.msg.preProcessing',
    MSG_UPLOAD_COMPLETED: 'main.msg.uploadCompleted',
    MSG_UPLOAD_FAILED: 'main.msg.uploadFailed{{count}}',
    MSG_PREPROCESSING_FAILED: 'main.msg.preprocessingFailed{{count}}',
    MSG_UPLOAD_PROGRESS: 'main.msg.uploadProgress{{uploaded}}{{total}}',
    MSG_VIDEO_FILES_NUMBER: 'main.msg.videoNumber{{count}}',
    MSG_VIDEO_NONE: 'main.msg.videoNone',
    MSG_VIDEO_STREAM: 'main.msg.videoStream',
    NOTIFY_ARCHIVE_GROUP_FAILED: 'main.notification.archiveGroupFailed{{group}}',
    NOTIFY_ARCHIVE_GROUP: 'main.notification.archiveGroup{{group}}',
    NOTIFY_ARCHIVE_LOCATION_FAILED: 'main.notification.archiveLocationFailed{{location}}',
    NOTIFY_ARCHIVE_LOCATION: 'main.notification.archiveLocation{{location}}',
    NOTIFY_ERROR_CREATE: 'main.notification.errorCreate{{group}}',
    NOTIFY_ERROR_RENAME: 'main.notification.errorRename{{group}}',
    NOTIFY_DELETE_GROUP_FAILED: 'main.notification.deleteGroupFailed{{group}}',
    NOTIFY_DELETE_GROUP: 'main.notification.deleteGroup{{group}}',
    NOTIFY_DELETE_LOCATION_FAILED: 'main.notification.deleteLocationFailed{{location}}',
    NOTIFY_DELETE_LOCATION: 'main.notification.deleteLocation{{location}}',
    NOTIFY_DELETE_MULTIPLE_LOCATIONS_FAILED: 'main.notification.deleteMultipleLocationsFailed{{location}}',
    NOTIFY_DELETE_MULTIPLE_LOCATIONS: 'main.notification.deleteMultipleLocations{{location}}',
    NOTIFY_LOCATION_SAVE_FAILED: 'main.notification.locationSaveFailed{{location}}',
    NOTIFY_MOVE_VIDEOS: 'main.notification.moveVideos{{location}}',
    NOTIFY_MOVE_VIDEOS_FAILED: 'main.notification.moveVideosFailed{{location}}',
    NOTIFY_RESTORE_GROUP_FAILED: 'main.notification.restoreGroupFailed{{group}}',
    NOTIFY_RESTORE_GROUP: 'main.notification.restoreGroup{{group}}',
    NOTIFY_RESTORE_LOCATION_FAILED: 'main.notification.restoreLocationFailed{{location}}',
    NOTIFY_RESTORE_LOCATION: 'main.notification.restoreLocation{{location}}',
    NOTIFY_RESTORE_MULTIPLE_LOCATIONS_FAILED: 'main.notification.restoreMultipleLocationsFailed{{location}}{{count}}',
    NOTIFY_RESTORE_MULTIPLE_LOCATIONS: 'main.notification.restoreMultipleLocations{{location}}{{count}}',
    NOTIFY_TRASH_GROUP_FAILED: 'main.notification.trashGroupFailed{{group}}',
    NOTIFY_TRASH_GROUP: 'main.notification.trashGroup{{group}}',
    NOTIFY_TRASH_LOCATION_FAILED: 'main.notification.trashLocationFailed{{location}}',
    NOTIFY_TRASH_LOCATION: 'main.notification.trashLocation{{location}}',
    NOTIFY_TRASH_MULTIPLE_LOCATIONS_FAILED: 'main.notification.trashMultipleLocationsFailed{{group}}{{count}}',
    NOTIFY_TRASH_MULTIPLE_LOCATIONS: 'main.notification.trashMultipleLocations{{group}}{{count}}',
    SUBTITLE_ARCHIVE: 'main.subtitle.archive',
    SUBTITLE_COMPLETED_UPLOADS: 'main.subtitle.uploadsCompleted{{count}}',
    SUBTITLE_FAILED_UPLOADS: 'main.subtitle.uploadsFailed{{count}}',
    SUBTITLE_PROGRESS_UPLOADS: 'main.subtitle.uploadsProgress{{count}}',
    SUBTITLE_TRASH: 'main.subtitle.trash',
    SUBTITLE_VIDEO: 'main.subtitle.groupsAndLocations',
    TITLE_ARCHIVE: 'main.title.archive',
    TITLE_BELONGS_TO: 'main.title.belongsTo',
    TITLE_PREPROCESSING_PROGRESS: 'main.title.preProcessing',
    TITLE_WAIT_START_PREPROCESSING: 'main.title.waitPreProcessing',
    TITLE_TRASH: 'main.title.trash',
    TITLE_UPLOAD_PROGRESS: 'main.title.uploadProgress',
    TITLE_VIDEO: 'main.title.video',
    TMP_GROUP_TITLE: 'main.const.newGroup',
    TMP_LOCATION_TITLE: 'main.const.newLocation'
  },
  profile: {
    BTN_BACK: 'profile.btn.back',
    BTN_CONFIRM_EMAIL: 'profile.btn.confirmEmail',
    BTN_CHANGE_PASSWORD: 'profile.btn.changePassword',
    BTN_EDIT_PROFILE: 'profile.btn.saveChanges',
    ERROR_INCORRECT_PASSWORD: 'profile.error.incorrectPassword',
    ERROR_UNKNOWN: 'profile.err.unknown',
    LABEL_COMPANY: 'profile.label.company',
    LABEL_CONFIRMATION: 'profile.label.confirmPassword',
    LABEL_EMAIL: 'profile.label.email',
    LABEL_NAME: 'profile.label.name',
    LABEL_NEW_PASSWORD: 'profile.label.newPassword',
    LABEL_OLD_PASSWORD: 'profile.label.oldPassword',
    LABEL_PASSWORD: 'profile.label.password',
    LABEL_PHONE: 'profile.label.phone',
    LINK_NOTIFICATIONS: 'profile.link.notifications',
    LINK_PAYMENTS: 'profile.link.payments',
    LINK_PROFILE: 'profile.link.profile',
    MSG_NOT_IMPLEMENTED: 'profile.msg.notImplemented',
    NOTIFY_PROFILE_SAVED: 'profile.notification.saved',
    NOTIFY_PROFILE_SAVE_ERROR: 'profile.notification.saveError',
    PLACEHOLDER_COMPANY: 'profile.placeholder.company',
    PLACEHOLDER_CONFIRMATION: 'profile.placeholder.confirmation',
    PLACEHOLDER_EMAIL: 'profile.placeholder.email',
    PLACEHOLDER_NAME: 'profile.placeholder.name',
    PLACEHOLDER_PASSWORD: 'profile.placeholder.password',
    PLACEHOLDER_NEW_PASSWORD: 'profile.placeholder.newPassword',
    PLACEHOLDER_OLD_PASSWORD: 'profile.placeholder.oldPassword',
    PLACEHOLDER_PHONE: 'profile.placeholder.phone',
    TITLE_CHANGE_PASSWORD: 'profile.title.changePassword',
    TITLE_NOTIFICATIONS: 'profile.title.notifications',
    TITLE_PAYMENTS: 'profile.title.payments',
    TITLE_PROFILE: 'profile.title.profile',
    TITLE_TARIFF_SELECT: 'profile.title.tariffSelect'
  },
  signIn: {
    BTN_LOGIN: 'signIn.btn.login',
    ERROR_INVALID_INPUT_DATA: 'signIn.err.invalidEmailPassword',
    ERROR_NOT_FOUND: 'signIn.err.userNotFound',
    ERROR_UNKNOWN: 'signIn.err.unknown',
    LABEL_PASSWORD: 'signIn.label.password',
    LINK_SIGN_UP: 'signUp.link.signUp',
    MSG_NO_ACCOUNT: 'signUp.msg.noAccount',
    TITLE_SIGN_IN: 'signIn.title'
  },
  signUp: {
    BTN_SIGNUP: 'signUp.btn',
    ERROR_INVALID_INPUT_DATA: 'signUp.err.invalidInputData',
    ERROR_DUPLICATE_USER_EMAIL: 'signUp.err.duplicateUserEmail',
    ERROR_INVALID_USER_EMAIL: 'signUp.err.invalidUserEmail',
    ERROR_AUTH_FAILED: 'signUp.err.authFailed',
    LABEL_CONFIRMATION: 'signUp.label.confirmPassword',
    LABEL_NAME: 'signUp.label.name',
    LABEL_PASSWORD: 'signUp.label.password',
    LINK_LICENSE_AGREEMENT: 'signUp.link.licenseAgreement',
    LINK_SIGN_IN: 'signUp.link.login',
    MSG_HAS_ACCOUNT: 'signUp.msg.hasAccount',
    MSG_LICENSE_AGREEMENT: 'signUp.msg.licenseAgreement',
    TITLE_BASIC: 'signUp.title.'
  },
  tariff: {
    CURRENT_TITLE: 'tariff.current.title',
    CURRENT_STRING: 'tariff.current.string',
    PAYMENT_DATE: 'tariff.payment.date',
    HOUR_LIMIT: 'tariff.hour.limit',
    HOUR_BALANCE: 'tariff.hour.balance',
    CHANGE_TARIFF: 'tariff.change.tariff',
    RESUME_SUBSCRIBE: 'tariff.resume.subscribe',
    CHOOSE_TARIFF: 'tariff.choose.tariff',
    SELECT_TARIFF: 'tariff.select.tariff',
    RESUME_TARIFF: 'tariff.resume.tariff',
    COMMON_TITLE: 'tariff.common.title',
    COMMON_FEATURE1: 'tariff.common.feature1',
    COMMON_FEATURE2: 'tariff.common.feature2',
    COMMON_FEATURE3: 'tariff.common.feature3',
    PROCESSING_HOURS: 'tariff.processing.hour',
    NOTIFY_TIME_OVER: 'tariff.notify.timeOver',
    BUY_MORE: 'tariff.buy.more',
    HOUR_BALANCE_TITLE: 'tariff.hourBalance.title',
    HOUR_BALANCE_SUBTITLE: 'tariff.hourBalance.subtitle'
  },
  payments: {
    CONFIRM_TITLE: 'payments.confirm.title',
    SELECTED_STRING: 'payments.selected.string',
    BY_TARIFF: 'payments.by.tariff',
    TO_PAYMENTS: 'payments.to.payments',
    SELECT_PAYMENTS_TYPE: 'payments.select.type',
    CARD_TYPE: 'payments.card.type',
    PAYMENT_INVOICE_TYPE: 'payments.invoice.type',
    OPERATION_CANCELLED: 'payments.operation.cancelled'
  },
  invoice: {
    LABEL_DESCRIPTION: 'invoice.label.description',
    LABEL_NAME: 'invoice.label.name',
    LABEL_PHONE: 'invoice.label.phone',
    TITLE_SUCCESS: 'invoice.title.success',
    LABEL_SUCCESS: 'invoice.label.success',
    UNDERSTAND_BTN: 'invoice.understand.btn'
  },
  confirmEmail: {
    BTN_RESEND: 'confirmEmail.btn.resend',
    LINK_GO_HOME: 'confirmEmail.link.home',
    MSG_INVALID_LINK: 'confirmEmail.msg.invalidLink',
    MSG_RESEND_COMPLETED: 'confirmEmail.msg.resendCompleted',
    MSG_RESEND_FAILED: 'confirmEmail.msg.resendFailed',
    MSG_SEND_COMPLETED: 'confirmEmail.msg.sendCompleted',
    TITLE_BASIC: 'confirmEmail.title.basic',
    TITLE_ERROR: 'confirmEmail.title.error',
    TITLE_PLEASE_CONFIRM: 'confirmEmail.title.pleaseConfirm',
    TITLE_SUCCESS: 'confirmEmail.title.success'
  },
  passwordReset: {
    BTN_BACK: 'passwordReset.btn.back',
    BTN_CHANGE_PASSWORD: 'passwordReset.btn.changePassword',
    BTN_RESET: 'passwordReset.btn.reset',
    BTN_RESET_REQUESTED: 'passwordReset.btn.requested',
    BTN_RETRY: 'passwordReset.btn.retry',
    ERROR_PASSWORDS_MISMATCH: 'passwordReset.err.passwordsMismatch',
    ERROR_PHONE_NUMBER_INCORRECT: 'passwordReset.err.phoneNumberIncorrect',
    ERROR_RESET_FAILED_NOT_FOUND: 'passwordReset.err.userNotFound',
    ERROR_RESET_FAILED: 'passwordReset.err.resetFailed',
    LABEL_CONFIRMATION: 'passwordReset.label.confirmation',
    LABEL_NEW_PASSWORD: 'passwordReset.label.newPassword',
    LINK_RESET: 'passwordReset.link.forgotPassword',
    MSG_CHECK_EMAIL: 'passwordReset.msg.checkEmail{{email}}',
    MSG_COMPLETED: 'passwordReset.msg.completed',
    MSG_NEW_PASSWORD: 'passwordReset.msg.enterNewPassword',
    MSG_WELCOME: 'passwordReset.msg.welcome',
    TITLE_BASIC: 'passwordReset.title.basic',
    TITLE_CHECK_EMAIL: 'passwordReset.title.checkEmail',
    TITLE_COMPLETED: 'passwordReset.title.completed',
    TITLE_RESET: 'passwordReset.title.reset',
    ERROR_INVALID_PASSWORD_LENGTH: 'passwordReset.err.invalidPasswordLength',
    ERROR_INVALID_PASSWORD_SYMBOL: 'passwordReset.err.invalidPasswordSymbol',
    ERROR_INVALID_PASSWORD_UPPERCASE: 'passwordReset.err.invalidPasswordUppercase',
    ERROR_INVALID_PASSWORD_NUMBER: 'passwordReset.err.invalidPasswordNumber'
  }
}

export default I18N
