import { blue, indigo, teal } from '@material-ui/core/colors'
import { Group } from '@visx/group'
import { Bar } from '@visx/shape'
import { BarGroupBar } from '@visx/shape/lib/types/barGroup'
import { BarStack, SeriesPoint } from '@visx/shape/lib/types/barStack'
import { Text } from '@visx/text'
import { TextProps } from '@visx/text/lib/Text'
import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { d3format } from '../../../app/i18n/d3'
import { ResultKeys } from './types'

const MIN_LABEL_WIDTH = 30
const TOTAL_LABEL_OFFSET = 5
const BAR_INNER_PADDING = 0.1

type BarStackBar<Datum> = Omit<BarGroupBar<string>, 'key' | 'value'> & {
  /** Processed bar Datum with bar bounds and original datum. */
  bar: SeriesPoint<Datum>
  /** stack key */
  key: string
}

interface BarLabelProps<Datum> {
  bar: BarStackBar<Datum>
}

interface StackLabelProps<Datum> {
  bar: BarStackBar<Datum>
  resultKeys: Array<ResultKeys<Datum>>
  horizontal?: boolean
}

interface BarStackProps<T> {
  barStack: BarStack<T, ResultKeys<T>>
  opacity: number
  resultKeys: Array<ResultKeys<T>>
  hideLabels?: boolean
  showTotal?: boolean
  horizontal?: boolean

  tooltipHandler?: (datum: T) => ((event: MouseEvent) => void)|undefined
  onMouseLeave?: () => void
}

/**
 * Regular Bar label
 *
 * @param {BarStackBar} bar - the bar being rendered
 * @returns {ReactElement} - bar's label
 */
function BarLabel<T> ({ bar }: BarLabelProps<T>): ReactElement {
  return (
    <Text
      x={bar.width / 2 + bar.x}
      y={bar.y + bar.height / 2}
      textAnchor='middle'
      verticalAnchor='middle'
      width={bar.width}
      fontSize='small'
      style={{ userSelect: 'none' }}
      fill={isDark(bar.color) ? 'white' : 'black'}
    >
      {
        (() => {
          const key = bar.key as keyof T
          const value = bar.bar.data[key]
          if (typeof value === 'number') {
            return d3format(value)
          }

          return ''
        })()
      }
    </Text>
  )
}

/**
 * Row's total label
 *
 * @param {BarStackBar} bar - the last bar in a row
 * @returns {ReactElement} - row's total label
 */
function StackLabel<T> ({ bar, resultKeys, horizontal: isHorizontal = false }: StackLabelProps<T>): ReactElement {
  const getValue = useCallback(() => {
    const value: number = resultKeys.reduce((sum, key) => {
      const value = bar.bar.data[key]
      if (typeof value === 'number') {
        return sum + value
      }

      return sum
    }, 0)
    return d3format(value)
  }, [bar.bar.data, resultKeys])

  const textProps: TextProps = isHorizontal
    ? {
      x: bar.width + bar.x + TOTAL_LABEL_OFFSET,
      y: bar.y + bar.height / 2,
      textAnchor: 'start',
      verticalAnchor: 'middle'
    }
    : {
      x: bar.width / 2 + bar.x,
      y: bar.y - TOTAL_LABEL_OFFSET,
      textAnchor: 'middle',
      verticalAnchor: 'end',
      width: bar.width
    }

  return (
    <Text
      {...textProps}
      fontSize='small'
      style={{ userSelect: 'none' }}
    >
      {getValue()}
    </Text>
  )
}

function isDark (color: string): boolean {
  const darkColors: string[] = [blue[500], indigo[500], teal[500]]
  return darkColors.includes(color)
}

export default function VisiusBarStack<T> (props: BarStackProps<T>): ReactElement {
  const {
    barStack,
    opacity,
    resultKeys,
    hideLabels = false,
    horizontal: isHorizontal = false,
    showTotal = false,

    tooltipHandler,
    onMouseLeave
  } = props

  return (
    <Group
      key={barStack.index}
      onMouseLeave={onMouseLeave}
    >
      {
        barStack.bars.map(bar => {
          return (
            <Group key={`barstack-horizontal-${barStack.index}-${bar.index}`}>
              {
                isHorizontal
                  ? (
                    <>
                      <Bar
                        onMouseOver={tooltipHandler !== undefined ? tooltipHandler(bar.bar.data) : undefined}
                        x={bar.x}
                        y={bar.y + bar.height * BAR_INNER_PADDING}
                        width={bar.width}
                        height={bar.height * (1 - 2 * BAR_INNER_PADDING)}
                        fill={bar.color}
                        opacity={opacity}
                      />
                      {
                        bar.width > MIN_LABEL_WIDTH && (
                          <BarLabel bar={bar} />
                        )
                      }
                      {
                        showTotal && (
                          <StackLabel bar={bar} resultKeys={resultKeys} horizontal={isHorizontal} />
                        )
                      }
                    </>
                  )
                  : (
                    <>
                      <Bar
                        onMouseOver={tooltipHandler !== undefined ? tooltipHandler(bar.bar.data) : undefined}
                        x={bar.x + bar.width * BAR_INNER_PADDING}
                        y={bar.y}
                        width={bar.width * (1 - 2 * BAR_INNER_PADDING)}
                        height={bar.height}
                        fill={bar.color}
                        opacity={opacity}
                      />
                      {!hideLabels && bar.width > MIN_LABEL_WIDTH && (
                        <>
                          {
                            bar.height > MIN_LABEL_WIDTH && (
                              <BarLabel bar={bar} />
                            )
                          }
                          {
                            showTotal && (
                              <StackLabel bar={bar} resultKeys={resultKeys} horizontal={isHorizontal} />
                            )
                          }
                        </>
                      )}
                    </>
                  )
              }
            </Group>
          )
        }
        )
      }
    </Group>
  )
}
