import { config } from 'dotenv'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { v4 as uuid } from 'uuid'
import App from './App'
import './app/i18n/i18n'
import { store } from './app/store'
import * as serviceWorker from './serviceWorker'

config()

// HUB_SESSION_ID is used to manage signalR subscriptions
// the value should be unique for each browser's tab
if (sessionStorage.getItem('HUB_SESSION_ID') === null) {
  sessionStorage.setItem('HUB_SESSION_ID', uuid())
}

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
