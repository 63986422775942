import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import deepEqual from "deep-equal";
import {
  loadGroups,
  selectData,
  selectLoading,
  selectNotInitialized,
} from "./trashSlice";

import I18N from "../../../app/i18n/strings";
import { declOfNum, sortByDateDesc } from "../../../app/utils";

import DelayedProgress from "../../../components/DelayedProgress";
import Group from "../components/Group";
import pageStyles from "../components/pageStyles";

import { groupToGroupMap } from "../data-mapper";
import { GroupData } from "../data-types";
import { deleteGroup, restoreGroup } from "./asyncActions";
import TrashLocationCard from "./TrashLocationCard";

import { ReactComponent as FolderIcon } from "../../../app/assets/folderIcon.svg";
import { ReactComponent as LocationIcon } from "../../../app/assets/locationIcon.svg";
import { ReactComponent as MovieIcon } from "../../../app/assets/movieIcon.svg";
import { ReactComponent as RecoverDataIcon } from "../../../app/assets/recoverDataIcon.svg";
import { ReactComponent as DeleteForeverIcon } from "../../../app/assets/deleteForeverIcon.svg";

function filterGroups(group: GroupData): boolean {
  return Boolean(group.isRemoved) || group.locations.length > 0;
}

export const useStyle = makeStyles<Theme>((theme: Theme) => ({
  generalInfo: {
    display: "flex",

    [theme.breakpoints.down(1580)]: {
      marginRight: "auto",
      marginBottom: theme.spacing(1.5),
    },
  },
  headerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down(1580)]: {
      flexDirection: "column",
      alignItems: "flex-end",
      marginBottom: theme.spacing(1),
    },
  },
  recoverIcon: {
    margin: "0 7px 0 7px",

    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  deleteIcon: {
    margin: "0 7px 0 7px",

    "& path": {
      fill: theme.palette.common.white,
    },
  },
}));

export default function TrashTab({
  leftPanelIsOpen,
}: {
  leftPanelIsOpen?: boolean;
}): ReactElement {
  const groups: GroupData[] = useSelector(selectData, deepEqual);
  const { t } = useTranslation();
  const isLoading = useSelector(selectLoading);
  const isNotInitialized = useSelector(selectNotInitialized);
  const trashClasses = useStyle();
  const classes = pageStyles({ leftPanelIsOpen });
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const declensionsOfGroup = ["группа", "группы", "групп"];
  const declensionsOfLocation = ["локация", "локации", "локаций"];
  const declensionsOfVideo = ["видеофайл", "видеофайла", "видеофайлов"];

  const handleRestore = useCallback(() => {
    for (const group of groups) {
      const groupMap = groupToGroupMap(group);
      dispatch(restoreGroup(groupMap));
    }
  }, [dispatch, groups]);

  const doDelete = useCallback(() => {
    setDeleteDialogOpen(false);
    for (const group of groups) {
      const groupMap = groupToGroupMap(group);
      dispatch(deleteGroup(groupMap));
    }
  }, [dispatch, groups]);

  const handleDelete = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);
  const handleDeleteCancel = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [setDeleteDialogOpen]);

  useEffect(() => {
    if (isNotInitialized) {
      dispatch(loadGroups());
    }
  }, [dispatch, isNotInitialized]);

  const data = useMemo(() => groups.filter(filterGroups), [groups]);
  const groupList = useMemo(() => {
    if (isLoading || isNotInitialized) {
      return <DelayedProgress variant="text" />;
    }
    if (data.length === 0) {
      return <Typography variant="body2">{t("Корзина пуста")}</Typography>;
    }
    return data.sort(sortByDateDesc).map((group) => {
      return (
        <Group
          key={group.id}
          data={group}
          locationComponent={TrashLocationCard}
          full={Boolean(group.isRemoved)}
          variant="trash"
        />
      );
    });
  }, [t, data, isLoading, isNotInitialized]);
  const locationCount = useMemo(() => {
    return data.reduce((total, { locations }) => total + locations.length, 0);
  }, [data]);

  const numOfLocations: number = data.reduce((total, currentItem) => {
    total += currentItem.locations.length;
    return total;
  }, 0);

  const numOfVideos: number = data
    .map((item) => item.locations)
    .flat()
    .reduce((total, currentItem) => {
      total += currentItem.videos ?? 0;
      return total;
    }, 0);

  return (
    <>
      <div className={classes.header}>
        <div className={trashClasses.headerWrapper}>
          <div className={trashClasses.generalInfo}>
            <Typography
              className={classes.title}
              color="textSecondary"
              variant="h3"
            >
              {t(I18N.main.SUBTITLE_TRASH)}
            </Typography>

            <div className={classes.infoItems}>
              <Button startIcon={<FolderIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${data.length} ${declOfNum(
                    data.length,
                    declensionsOfGroup
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<LocationIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfLocations} ${declOfNum(
                    numOfLocations,
                    declensionsOfLocation
                  )}`}
                </Typography>
              </Button>

              <Button startIcon={<MovieIcon />} variant="text">
                <Typography color="inherit" variant="body2">
                  {`${numOfVideos} ${declOfNum(
                    numOfVideos,
                    declensionsOfVideo
                  )}`}
                </Typography>
              </Button>
            </div>
          </div>

          <div>
            <Button
              color="primary"
              className={classes.btn}
              variant="outlined"
              startIcon={
                <RecoverDataIcon className={trashClasses.recoverIcon} />
              }
              disabled={data.length === 0}
              onClick={handleRestore}
            >
              {t(I18N.main.BTN_RESTORE_FROM_TRASH_ALL)}
            </Button>

            <Button
              color="primary"
              className={classes.btn}
              variant="contained"
              startIcon={
                <DeleteForeverIcon className={trashClasses.deleteIcon} />
              }
              disabled={data.length === 0}
              onClick={handleDelete}
            >
              {t(I18N.main.BTN_DELETE_ALL)}
            </Button>
          </div>
        </div>
        <Divider className={classes.divider} />
      </div>

      <div>{groupList}</div>
      <Dialog
        classes={{ paper: classes.trashPaper }}
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>
          <Typography style={{ fontWeight: 600 }} variant="h4">
            {t(I18N.main.DLG_EMPTY_TRASH)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              {t(I18N.main.DLG_EMPTY_TRASH_SUMMARY, { count: locationCount })}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            <Typography
              style={{ textTransform: "none" }}
              color="primary"
              variant="body2"
            >
              {t(I18N.main.DLG_CANCEL)}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={doDelete}
            style={{
              background: "linear-gradient(90deg, #FF5F49 0%, #FF3D00 99.94%)",
              borderRadius: 12,
            }}
            autoFocus
          >
            <Typography
              style={{ textTransform: "none", color: "white" }}
              variant="body2"
            >
              {t(I18N.main.DLG_DELETE_LOCATION_CONFIRM)}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
